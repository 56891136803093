import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { AdminHeader_Query } from "~/__relay_artifacts__/AdminHeader_Query.graphql";
import { AdminHeader } from "~/components/AdminHeader";

const query = graphql`
  query AdminHeader_Query($search: String) {
    viewer {
      ...AdminHeader_viewer
    }
  }
`;

export const AdminHeaderContainer: React.FC = () => {
  const { props, error } = useQuery<AdminHeader_Query>(query);

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  return props.viewer ? (
    <AdminHeader viewerRef={props.viewer} />
  ) : (
    <div>not found</div>
  );
};
