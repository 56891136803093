import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { Link } from "react-router-dom";

import { PromotionCampaignListTableCard_Query } from "~/__relay_artifacts__/PromotionCampaignListTableCard_Query.graphql";
import { PromotionCampaignListTableCard_promotion } from "~/__relay_artifacts__/PromotionCampaignListTableCard_promotion.graphql";
import { ListTable } from "~/components/ListTable";
import { PromotionAttachButton } from "~/components/PromotionAttachButton";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { useRefetch, useRelayEnvironment } from "~/lib/relay-hooks";
import detachPromotionMutation from "~/mutations/DetachPromotionMutation";

type Props = {
  projectId: string;
  promotionRef: FragmentRef<PromotionCampaignListTableCard_promotion>;
};

const promotionFragment = graphql`
  fragment PromotionCampaignListTableCard_promotion on Promotion {
    id
    campaigns(first: 100)
      @connection(key: "PromotionCampaignListTableCard_campaigns") {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const query = graphql`
  query PromotionCampaignListTableCard_Query(
    $projectId: ID!
    $promotionId: ID!
  ) {
    project(id: $projectId) {
      promotion(id: $promotionId) {
        ...PromotionCampaignListTableCard_promotion
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 200,
  },
}));

export const PromotionCampaignListTableCard: React.FC<Props> = ({
  promotionRef,
  projectId,
}) => {
  const classes = useStyles();
  const environment = useRelayEnvironment();
  const { enqueueSnackbar } = useSnackbar();
  const [promotion, refetch] =
    useRefetch<PromotionCampaignListTableCard_promotion>(
      promotionFragment,
      promotionRef
    );

  const refetchPromotion = React.useCallback(() => {
    refetch<PromotionCampaignListTableCard_Query>(query, {
      projectId,
      promotionId: promotion.id,
    });
  }, [refetch, projectId, promotion]);

  const handleDetachCampaign = React.useCallback(
    async (campaignId: string) => {
      try {
        await detachPromotionMutation(environment, {
          campaignId,
        });
        refetchPromotion();
        enqueueSnackbar("分離しました", { variant: "success" });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [environment, enqueueSnackbar, refetchPromotion]
  );

  const campaigns = React.useMemo(() => {
    const edges = promotion.campaigns?.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [promotion.campaigns]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          キャンペーン一覧
        </Typography>
        <PromotionAttachButton
          promotionId={promotion.id}
          onSubmitCompleted={() => {
            refetchPromotion();
          }}
        />
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign.id}>
              <TableCell>{atob(campaign.id)}</TableCell>
              <TableCell>
                <Link to={`/projects/${projectId}/campaigns/${campaign.id}`}>
                  {campaign.title}
                </Link>
              </TableCell>
              <TableCell>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="このキャンペーンをプロモーションから分離しますか？"
                  onAgree={async (changeDialog) => {
                    handleDetachCampaign(campaign.id);
                    changeDialog(false);
                  }}
                >
                  解除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
    </Paper>
  );
};
