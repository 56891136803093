import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeletePlacementSettingMutation } from "~/__relay_artifacts__/DeletePlacementSettingMutation.graphql";

const mutation = graphql`
  mutation DeletePlacementSettingMutation(
    $input: DeletePlacementSettingInput!
  ) {
    deletePlacementSetting(input: $input) {
      deletedPlacementSettingId
    }
  }
`;

const commit = (
  environment: Environment,
  placementSetId: string,
  placementSettingId: string
) =>
  commitMutation<DeletePlacementSettingMutation>(environment, {
    mutation,
    variables: {
      input: { placementSettingId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: placementSetId,
        connectionKeys: [
          {
            key: "PlacementSettingListTableCard_placementSettings",
          },
        ],
        pathToConnection: ["placementSet", "placementSettings"],
        deletedIDFieldName: "deletedPlacementSettingId",
      },
    ],
  });

export default commit;
