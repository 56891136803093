import { Button, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FormikSelectField } from "@vrize/vrizead-use";
import { Form, useFormikContext } from "formik";
import * as React from "react";
import * as yup from "yup";

export type SelectableItem = {
  id: string;
  title: string;
};

export type FormValues = {
  selectableItems: SelectableItem[];
  id: string | null;
};

export const schema = yup.object({
  scheduleSetId: yup.string(),
});

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export const AdScheduleSetSelectButtonForm: React.FC = () => {
  const classes = useStyles();
  const { values, isSubmitting } = useFormikContext<FormValues>();

  const idOptions = React.useMemo(
    () => [
      { value: "", label: "none" },
      ...values.selectableItems.map((item) => ({
        value: item.id,
        label: item.title,
      })),
    ],
    [values.selectableItems]
  );

  return (
    <Form>
      <FormikSelectField
        name="id"
        label="候補"
        formControlVariant="outlined"
        formControlClassName={classes.formControl}
        options={idOptions}
      />
      <Button
        fullWidth
        color="primary"
        variant="outlined"
        type="submit"
        disabled={isSubmitting}
      >
        更新する
      </Button>
    </Form>
  );
};
