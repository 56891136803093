import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Form, useFormikContext } from "formik";
import * as React from "react";

type Props = {
  submitButtonTitle?: string;
  onSubmit?: any;
};

const StyledDialogContent = styled(DialogContent)({
  // NOTE: DialogContentのchildrenで<Grid container ... />を使うとスクロール領域が発生してしまうため
  overflow: "visible",
});

export const DialogForm: React.FC<Props> = ({
  children,
  submitButtonTitle,
  onSubmit,
}) => {
  const { isSubmitting, handleSubmit } = useFormikContext();
  return (
    <>
      <StyledDialogContent>
        <Form>{children}</Form>
      </StyledDialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          onClick={onSubmit || handleSubmit}
        >
          {submitButtonTitle || "保存する"}
        </Button>
      </DialogActions>
    </>
  );
};
