import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { UserList_Query } from "~/__relay_artifacts__/UserList_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import UserListTableCard from "~/components/UserListTableCard";

const query = graphql`
  query UserList_Query($count: Int, $cursor: String, $orderBy: UserOrder) {
    ...UserListTableCard_root
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader title="ユーザー管理" backTo="/" />
    {children}
  </>
);

export const UserList: React.FC = () => {
  const { props, error } = useQuery<UserList_Query>(query, {
    count: 100,
    orderBy: { field: "CREATED_AT", direction: "DESC" },
  });

  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <UserListTableCard root={props} />
    </Wrapper>
  );
};
