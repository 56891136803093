import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { PromotionListTableCard_project } from "~/__relay_artifacts__/PromotionListTableCard_project.graphql";
import { PromotionList_Query } from "~/__relay_artifacts__/PromotionList_Query.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { PromotionCreateForm } from "~/containers/PromotionCreateForm";
import { PromotionEditForm } from "~/containers/PromotionEditForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import deletePromotionMutation from "~/mutations/DeletePromotionMutation";

type Props = {
  projectRef: PromotionList_Query["response"]["project"];
};

const fragment = graphql`
  fragment PromotionListTableCard_project on Project {
    promotions(first: 100)
      @connection(key: "PromotionListTableCard_promotions") {
      __id
      edges {
        node {
          id
          title
          ...PromotionEditForm_promotion
        }
      }
      totalCount
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 200,
  },
}));

export const PromotionListTableCard: FC<Props> = ({ projectRef }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();
  const environment = useRelayEnvironment();
  const [perPage, setPerPage] = useState(100);
  const project = useFragment<PromotionListTableCard_project>(
    fragment,
    projectRef
  );
  const totalCount = project.promotions.totalCount;

  const promotions = useMemo(() => {
    const edges = project.promotions.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [project.promotions, page, perPage]);

  const onDeleteClick = useCallback(
    async (id: string) => {
      try {
        const { deletePromotion } = await deletePromotionMutation(
          environment,
          id,
          projectId
        );
        const deletedPromotionId =
          deletePromotion && deletePromotion.deletedPromotionId;
        if (!deletedPromotionId) throw new Error("assertion failed");
        enqueueSnackbar(`プロモーションを削除しました。`, {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, environment, projectId]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          プロモーション一覧
        </Typography>
        <DialogButton
          title="プロモーション作成"
          render={({ close }) => (
            <PromotionCreateForm
              onSubmitCompleted={close}
              connectionId={project.promotions.__id}
            />
          )}
        >
          作成
        </DialogButton>
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promotions.map((promotion) => (
            <TableRow key={promotion.id}>
              <TableCell>{atob(promotion.id)}</TableCell>
              <TableCell>
                <Link to={`/projects/${projectId}/promotions/${promotion.id}`}>
                  {promotion.title}
                </Link>
              </TableCell>
              <TableCell>
                <DialogButton
                  title="編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <PromotionEditForm
                      promotionRef={promotion}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="このプロモーションを削除しますか？"
                  onAgree={() => onDeleteClick(promotion.id)}
                >
                  削除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(event) => setPerPage(Number(event.target.value))}
      />
    </Paper>
  );
};
