import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { omit } from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { ScheduleSetEditForm_scheduleSet } from "~/__relay_artifacts__/ScheduleSetEditForm_scheduleSet.graphql";
import {
  FormValues,
  ScheduleSetForm,
  schema,
} from "~/components/ScheduleSetForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateScheduleSetMutation } from "~/mutations/UpdateScheduleSetMutation";

type Props = {
  scheduleSetRef: FragmentRef<ScheduleSetEditForm_scheduleSet>;
  onSubmitCompleted: () => void;
};

const scheduleSetFragment = graphql`
  fragment ScheduleSetEditForm_scheduleSet on ScheduleSet {
    id
    title
    latestVersion
  }
`;

export const ScheduleSetEditForm: React.FC<Props> = ({
  scheduleSetRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateScheduleSetMutation } = useUpdateScheduleSetMutation();
  const scheduleSet = useFragment<ScheduleSetEditForm_scheduleSet>(
    scheduleSetFragment,
    scheduleSetRef
  );

  const initialValues = React.useMemo(
    () => omit(["id", "latestVersion"], scheduleSet) as FormValues,
    [scheduleSet]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateScheduleSet } = await updateScheduleSetMutation({
          id: scheduleSet.id,
          clientVersion: scheduleSet.latestVersion,
          ...values,
        });
        const _scheduleSet = updateScheduleSet?.scheduleSet;
        if (!_scheduleSet) throw new Error("assertion failed");
        enqueueSnackbar("スケジュールセットを更新しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      scheduleSet,
      updateScheduleSetMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={ScheduleSetForm}
    />
  );
};
