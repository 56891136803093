import {
  DimensionInput,
  MeasureInput,
  OrderInput,
} from "~/__relay_artifacts__/GrossBudgetConsumptionReportListContainer_Query.graphql";

export const measures: MeasureInput[] = [
  { value: "DATE" },
  { value: "OS" },
  { value: "WIN_COUNT" },
  { value: "GROSS_BUDGET_CONSUMPTION" },
];

export const dimensions: DimensionInput[] = [
  { value: "OS" },
  { value: "DATE" },
];

export const orders: OrderInput[] = [{ value: "DATE" }, { value: "OS" }];
