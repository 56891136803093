import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { MonthlyBudgetEditForm_monthlyBudget } from "~/__relay_artifacts__/MonthlyBudgetEditForm_monthlyBudget.graphql";
import {
  FormValues,
  MonthlyBudgetForm,
  schema,
} from "~/components/MonthlyBudgetForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateMonthlyBudgetMutation } from "~/mutations/UpdateMonthlyBudgetMutation";

type Props = {
  monthlyBudgetRef: FragmentRef<MonthlyBudgetEditForm_monthlyBudget>;
  onSubmitCompleted: () => void;
};

const fragment = graphql`
  fragment MonthlyBudgetEditForm_monthlyBudget on MonthlyBudget {
    id
    date
    amount
    latestVersion
  }
`;

const MonthlyBudgetEditForm: React.FC<Props> = ({
  monthlyBudgetRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateMonthlyBudgetMutation } = useUpdateMonthlyBudgetMutation();
  const monthlyBudget = useFragment<MonthlyBudgetEditForm_monthlyBudget>(
    fragment,
    monthlyBudgetRef
  );

  const initialValues = React.useMemo<FormValues>(
    () => ({
      date: monthlyBudget.date,
      amount: monthlyBudget.amount,
    }),
    [monthlyBudget]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateMonthlyBudget } = await updateMonthlyBudgetMutation({
          id: monthlyBudget.id,
          date: values.date,
          amount: values.amount,
          clientVersion: monthlyBudget.latestVersion,
        });
        if (!updateMonthlyBudget?.monthlyBudget) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("日予算スケジュールを更新しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      monthlyBudget,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
      updateMonthlyBudgetMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => <MonthlyBudgetForm {...formikHelpers} />}
    </Formik>
  );
};

export default MonthlyBudgetEditForm;
