import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import {
  CreativeCopyForm as CreativeForm,
  FormValues,
  schema,
} from "~/components/CreativeCopyForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import copyCreativeMutation from "~/mutations/CopyCreativeMutation";

type Props = {
  creativeId: string;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  height: 80,
};

export const CreativeCopyForm: React.FC<Props> = ({
  onSubmitCompleted,
  creativeId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { projectId } = useParams<{ projectId: string }>();
  const environment = useRelayEnvironment();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        await copyCreativeMutation(
          environment,
          projectId,
          creativeId,
          values.height
        );
        enqueueSnackbar("クリエイティブをコピーしました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
      creativeId,
      projectId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={CreativeForm}
    />
  );
};
