import { Button, Grid } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

export type FormValues = yup.Asserts<typeof schema>;

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  name: yup.string().required(),
  freeePartnerId: yup.string().nullable(),
});

export const ProjectForm: React.FC<Props> = ({ isSubmitting }) => (
  <Form>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField name="name" label="名前" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField name="freeePartnerId" label="パートナーID" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit"
          color="primary"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
        >
          保存する
        </Button>
      </Grid>
    </Grid>
  </Form>
);
