/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BulkUpdateAdSlotPlacementInput = {
    clientMutationId?: string | null;
    adSlotId: string;
    proportion?: number | null;
    correctionTerm?: number | null;
    isEnabled?: boolean | null;
};
export type BulkUpdateAdSlotPlacementMutationVariables = {
    input: BulkUpdateAdSlotPlacementInput;
    first?: number | null;
    after?: string | null;
    isDistributedCampaigns?: boolean | null;
};
export type BulkUpdateAdSlotPlacementMutationResponse = {
    readonly bulkUpdateAdSlotPlacement: {
        readonly adSlot: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"AdSlotPlacementListTableCard_adSlot">;
        } | null;
    } | null;
};
export type BulkUpdateAdSlotPlacementMutation = {
    readonly response: BulkUpdateAdSlotPlacementMutationResponse;
    readonly variables: BulkUpdateAdSlotPlacementMutationVariables;
};



/*
mutation BulkUpdateAdSlotPlacementMutation(
  $input: BulkUpdateAdSlotPlacementInput!
  $first: Int
  $after: String
  $isDistributedCampaigns: Boolean
) {
  bulkUpdateAdSlotPlacement(input: $input) {
    adSlot {
      id
      ...AdSlotPlacementListTableCard_adSlot
    }
  }
}

fragment AdSlotPlacementListTableCard_adSlot on AdSlot {
  id
  name
  tagId
  sspProvider
  width
  height
  platform
  bundle
  adxBillingId
  placements(first: $first, after: $after, isDistributedCampaigns: $isDistributedCampaigns) {
    edges {
      node {
        id
        isEnabled
        correctionTerm
        proportion
        latestVersion
        ad {
          id
          title
          campaign {
            id
            title
            project {
              id
            }
          }
        }
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDistributedCampaigns"
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isDistributedCampaigns",
    "variableName": "isDistributedCampaigns"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkUpdateAdSlotPlacementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BulkUpdateAdSlotPlacementPayload",
        "kind": "LinkedField",
        "name": "bulkUpdateAdSlotPlacement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlot",
            "kind": "LinkedField",
            "name": "adSlot",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdSlotPlacementListTableCard_adSlot"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "BulkUpdateAdSlotPlacementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BulkUpdateAdSlotPlacementPayload",
        "kind": "LinkedField",
        "name": "bulkUpdateAdSlotPlacement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlot",
            "kind": "LinkedField",
            "name": "adSlot",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sspProvider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platform",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bundle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adxBillingId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "PlacementConnection",
                "kind": "LinkedField",
                "name": "placements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Placement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isEnabled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "correctionTerm",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "proportion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestVersion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Ad",
                            "kind": "LinkedField",
                            "name": "ad",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Campaign",
                                "kind": "LinkedField",
                                "name": "campaign",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Project",
                                    "kind": "LinkedField",
                                    "name": "project",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": [
                  "isDistributedCampaigns"
                ],
                "handle": "connection",
                "key": "AdSlotPlacementListTableCard_placements",
                "kind": "LinkedHandle",
                "name": "placements"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6aeedb9863600ccb15cac8206c4444b",
    "id": null,
    "metadata": {},
    "name": "BulkUpdateAdSlotPlacementMutation",
    "operationKind": "mutation",
    "text": "mutation BulkUpdateAdSlotPlacementMutation(\n  $input: BulkUpdateAdSlotPlacementInput!\n  $first: Int\n  $after: String\n  $isDistributedCampaigns: Boolean\n) {\n  bulkUpdateAdSlotPlacement(input: $input) {\n    adSlot {\n      id\n      ...AdSlotPlacementListTableCard_adSlot\n    }\n  }\n}\n\nfragment AdSlotPlacementListTableCard_adSlot on AdSlot {\n  id\n  name\n  tagId\n  sspProvider\n  width\n  height\n  platform\n  bundle\n  adxBillingId\n  placements(first: $first, after: $after, isDistributedCampaigns: $isDistributedCampaigns) {\n    edges {\n      node {\n        id\n        isEnabled\n        correctionTerm\n        proportion\n        latestVersion\n        ad {\n          id\n          title\n          campaign {\n            id\n            title\n            project {\n              id\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e3d78345fdceb6e6646b9e1f8d8eba62';
export default node;
