import { Button, Grid, List, ListItem, ListItemText } from "@material-ui/core";
import * as DateFns from "date-fns";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { GrossBudgetConsumptionReportList_Query } from "~/__relay_artifacts__/GrossBudgetConsumptionReportList_Query.graphql";
import { GrossBudgetConsumptionReportList_project } from "~/__relay_artifacts__/GrossBudgetConsumptionReportList_project.graphql";
import { useRefetch } from "~/lib/relay-hooks";

import { dimensions, measures, orders } from "./constants";

type ReportPeriod = {
  since: string;
  till: string;
};

type Props = {
  campaignId: string;
  currentSettingMonth: string;
  projectRef: FragmentRef<GrossBudgetConsumptionReportList_project>;
  reportPeriod: ReportPeriod;
};

const query = graphql`
  query GrossBudgetConsumptionReportList_Query(
    $projectId: ID!
    $campaignId: ID!
    $month: ISO8601Date!
    $measures: [MeasureInput!]!
    $dimensions: [DimensionInput!]!
    $filter: FilterInput!
    $orders: [OrderInput!]
  ) {
    project(id: $projectId) {
      ...GrossBudgetConsumptionReportList_project
    }
  }
`;

const fragment = graphql`
  fragment GrossBudgetConsumptionReportList_project on Project {
    id
    report(
      measures: $measures
      dimensions: $dimensions
      filter: $filter
      orders: $orders
    ) {
      measures
    }
    campaign(id: $campaignId) {
      monthlyBudget {
        amount
      }
      dailyBudgets(first: 50, month: $month) {
        edges {
          node {
            date
            baseAmount
          }
        }
      }
    }
  }
`;

const useProject = (
  projectRef: Props["projectRef"],
  campaignId: string,
  month: string,
  reportPeriod: ReportPeriod
) => {
  const [project, refetch] =
    useRefetch<GrossBudgetConsumptionReportList_project>(fragment, projectRef);

  const refetchProject = React.useCallback(() => {
    const variables = {
      projectId: project.id,
      campaignId,
      month,
      measures,
      dimensions,
      filter: {
        periodSince: reportPeriod.since,
        periodTill: reportPeriod.till,
        campaignIds: [campaignId],
      },
      orders,
    };
    refetch<GrossBudgetConsumptionReportList_Query>(query, variables);
  }, [
    project.id,
    refetch,
    campaignId,
    month,
    reportPeriod.since,
    reportPeriod.till,
  ]);

  return { project, refetchProject };
};

export const GrossBudgetConsumptionReportList: React.FC<Props> = ({
  projectRef,
  campaignId,
  currentSettingMonth,
  reportPeriod,
}) => {
  const yesterday = DateFns.endOfYesterday();

  const { project, refetchProject } = useProject(
    projectRef,
    campaignId,
    currentSettingMonth,
    reportPeriod
  );

  const onSubmit = React.useCallback(async () => {
    await refetchProject();
  }, [refetchProject]);

  const monthlyBudget = project.campaign?.monthlyBudget.amount || 0;

  const sumGrossBudgetConsumption = React.useMemo(() => {
    const keys = measures.map((m) => m.value);
    const grossBudgetConsumptions = project.report.measures.map((measure) => {
      const map: Record<string, any> = {};
      measure.forEach((value, i) => {
        const field = keys[i];
        map[field] = value;
      });
      return parseInt(map["GROSS_BUDGET_CONSUMPTION"]);
    });
    return grossBudgetConsumptions.reduce(
      (sum, grossBudgetConsumption) => sum + grossBudgetConsumption,
      0
    );
  }, [project.report.measures]);

  const dailyBudgets = React.useMemo(() => {
    const edges = project.campaign?.dailyBudgets.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [project.campaign?.dailyBudgets.edges]);

  const sumDailyBudgetSinceToday = React.useMemo(() => {
    return dailyBudgets.reduce((sum, dailyBudget) => {
      const date = DateFns.parseISO(dailyBudget.date);
      const amount = DateFns.isAfter(date, yesterday)
        ? dailyBudget.baseAmount
        : 0;
      return sum + amount;
    }, 0);
  }, [dailyBudgets, yesterday]);

  return (
    <List>
      <ListItem>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <>
              <ListItemText
                primary={`消化金額集計期間 : ${DateFns.format(
                  DateFns.parseISO(reportPeriod.since),
                  "yyyy/MM/dd"
                )} ~ ${DateFns.format(
                  DateFns.parseISO(reportPeriod.till),
                  "yyyy/MM/dd"
                )}`}
              />
              <ListItemText
                primary="残り予算"
                secondary={monthlyBudget - sumGrossBudgetConsumption}
              />
            </>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={onSubmit}
            >
              再取得
            </Button>
          </Grid>
        </Grid>
      </ListItem>

      <ListItem>
        <ListItemText
          primary="今日以降設定されてる日予算合計値(未入力日未補完)"
          secondary={sumDailyBudgetSinceToday}
        />
      </ListItem>
    </List>
  );
};
