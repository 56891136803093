import { useFormErrorHandler } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";

import { ExpenseEditForm_expense } from "~/__relay_artifacts__/ExpenseEditForm_expense.graphql";
import { ExpenseListTableCard_campaign } from "~/__relay_artifacts__/ExpenseListTableCard_campaign.graphql";
import { ExpenseForm, FormValues, schema } from "~/components/ExpenseForm";
import { noop } from "~/lib/noop";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateExpenseMutationMutation } from "~/mutations/UpdateExpenseMutation";

type ExpenseRef = NonNullable<
  NonNullable<ExpenseListTableCard_campaign["expenses"]["edges"]>[0]
>["node"];

type Props = {
  expenseRef: ExpenseRef;
  selectableBeforeDate: boolean;
  onSubmitCompleted?: () => void;
};

const expenseFragment = graphql`
  fragment ExpenseEditForm_expense on Expense {
    id
    date
    rate
    campaign {
      id
    }
    latestVersion
  }
`;

export const ExpenseEditForm: React.FC<Props> = ({
  expenseRef,
  selectableBeforeDate,
  onSubmitCompleted = noop,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateExpenseMutation } = useUpdateExpenseMutationMutation();
  const expense = useFragment<ExpenseEditForm_expense>(
    expenseFragment,
    expenseRef
  );

  const initialValues = React.useMemo(
    () => ({
      date: DateFns.parseISO(expense.date),
      rate: expense.rate,
      maxAssignableExpenseRate: 10,
    }),
    [expense]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ date, rate, maxAssignableExpenseRate }, { setErrors }) => {
      if (
        maxAssignableExpenseRate &&
        maxAssignableExpenseRate < rate &&
        !window.confirm(
          `追加費用の上限(${maxAssignableExpenseRate})を超えています。本当に更新しますか？`
        )
      ) {
        return;
      }

      try {
        const { updateExpense } = await updateExpenseMutation({
          id: expense.id,
          date: DateFns.formatISO(date, { representation: "date" }),
          rate,
          clientVersion: expense.latestVersion,
        });
        if (!updateExpense?.expense) throw new Error("assertion failed");
        enqueueSnackbar("追加費用を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      updateExpenseMutation,
      expense,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => (
        <ExpenseForm
          {...formikHelpers}
          campaignId={expense.campaign.id}
          selectableBeforeDate={selectableBeforeDate}
        />
      )}
    </Formik>
  );
};
