import { Grid } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  note: string | null;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  note: yup.string().required(),
});

export const AdSlotNoteForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          multiline
          rows={3}
          rowsMax={20}
          name="note"
          label="メモ"
        />
      </Grid>
    </Grid>
  </DialogForm>
);
