import {
  List,
  ListItem,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { graphql } from "react-relay";

import { AdDetail_ad } from "~/__relay_artifacts__/AdDetail_ad.graphql";
import { AdScheduleSetCard_ad } from "~/__relay_artifacts__/AdScheduleSetCard_ad.graphql";
import { AdScheduleSetSelectButton } from "~/containers/AdScheduleSetSelectButton";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  adRef: AdDetail_ad;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  paper: {
    margin: theme.spacing(3),
  },
}));

const adFragment = graphql`
  fragment AdScheduleSetCard_ad on Ad {
    ...AdScheduleSetSelectButton_ad
  }
`;

export const AdScheduleSetCard: React.FC<Props> = ({ adRef }) => {
  const classes = useStyles();
  const ad = useFragment<AdScheduleSetCard_ad>(adFragment, adRef);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">スケジュール</Typography>
        </Toolbar>
        <List>
          <ListItem>
            <AdScheduleSetSelectButton adRef={ad} />
          </ListItem>
        </List>
      </Paper>
    </div>
  );
};
