import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Theme,
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  variant: ButtonProps["variant"];
  color: ButtonProps["color"];
  keyword: string;
  confirmValue: string;
  onAgree: (changeDialog: (state: boolean) => void) => void;
};

export const DeleteButton: React.FC<Props> = ({
  variant,
  color,
  children,
  keyword,
  confirmValue,
  onAgree,
}) => {
  const classes = useStyles();
  const [dialogState, openDialog, closeDialog, changeDialog] = useOpenState();
  const [deleteName, setDeleteName] = React.useState("");
  const [agreeDisable, setAgreeDisable] = React.useState(true);

  const handleDialogButtonClick = React.useCallback<React.MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const handleDialogClose = React.useCallback<React.MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      closeDialog();
    },
    [closeDialog]
  );

  const handleCancelButtonClick = React.useCallback<React.MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      closeDialog();
    },
    [closeDialog]
  );

  const handleAgreeButtonClick = React.useCallback<React.MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      onAgree((state: boolean) => changeDialog(state));
    },
    [changeDialog, onAgree]
  );

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      setDeleteName(event.target.value);
      setAgreeDisable(event.target.value !== confirmValue);
    },
    [confirmValue]
  );

  return (
    <>
      <Button
        className={classes.button}
        variant={variant}
        color={color}
        onClick={handleDialogButtonClick}
      >
        {children}
      </Button>
      <Dialog
        open={dialogState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">
          この{keyword}を削除しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {keyword}の名前を入力して確認してください。
          </DialogContentText>
          <TextField value={deleteName} onChange={handleChange} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCancelButtonClick}>
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={handleAgreeButtonClick}
            disabled={agreeDisable}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
