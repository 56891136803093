import { graphql } from "react-relay";
import { useCallback } from "reactn";
import commitMutation from "relay-commit-mutation-promise";

import { DeleteCampaignTrackerSettingMutation } from "~/__relay_artifacts__/DeleteCampaignTrackerSettingMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteCampaignTrackerSettingMutation(
    $input: DeleteCampaignTrackerSettingInput!
  ) {
    deleteCampaignTrackerSetting(input: $input) {
      campaign {
        id
        tracker {
          id
          title
        }
      }
    }
  }
`;

export const useDeleteCampaignTrackerSettingMutation = () => {
  const environment = useRelayEnvironment();

  const deleteCampaignTrackerSettingMutation = useCallback(
    (campaignId: string) =>
      commitMutation<DeleteCampaignTrackerSettingMutation>(environment, {
        mutation,
        variables: { input: { campaignId } },
      }),
    [environment]
  );

  return { deleteCampaignTrackerSettingMutation };
};
