/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateScheduleInput = {
    clientMutationId?: string | null;
    clientVersion?: number | null;
    id: string;
    since: string;
    till: string;
};
export type UpdateScheduleMutationVariables = {
    input: UpdateScheduleInput;
};
export type UpdateScheduleMutationResponse = {
    readonly updateSchedule: {
        readonly schedule: {
            readonly id: string;
            readonly createdAt: string;
            readonly since: string | null;
            readonly till: string | null;
            readonly latestVersion: number | null;
        } | null;
    } | null;
};
export type UpdateScheduleMutation = {
    readonly response: UpdateScheduleMutationResponse;
    readonly variables: UpdateScheduleMutationVariables;
};



/*
mutation UpdateScheduleMutation(
  $input: UpdateScheduleInput!
) {
  updateSchedule(input: $input) {
    schedule {
      id
      createdAt
      since
      till
      latestVersion
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSchedulePayload",
    "kind": "LinkedField",
    "name": "updateSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "since",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "till",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latestVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateScheduleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateScheduleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1e38b4dd8970ff9ab1d37a46e1348551",
    "id": null,
    "metadata": {},
    "name": "UpdateScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateScheduleMutation(\n  $input: UpdateScheduleInput!\n) {\n  updateSchedule(input: $input) {\n    schedule {\n      id\n      createdAt\n      since\n      till\n      latestVersion\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4add14935fb8e20a26e071869b4e5c12';
export default node;
