import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router-dom";
import { useQuery } from "relay-hooks";

import { ClientCampaignList_Query } from "~/__relay_artifacts__/ClientCampaignList_Query.graphql";
import { ClientCampaignListTableCard } from "~/components/ClientCampaignListTableCard";

const query = graphql`
  query ClientCampaignList_Query($projectId: ID!, $first: Int, $after: String) {
    project(id: $projectId) {
      ...ClientCampaignListTableCard_project
    }
  }
`;

export const ClientCampaignList: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<ClientCampaignList_Query>(query, {
    projectId,
    first: 100,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  if (!props.project) return <div>not found</div>;
  return <ClientCampaignListTableCard projectRef={props.project} />;
};
