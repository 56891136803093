import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateCreativeInput,
  CreateCreativeMutation,
} from "~/__relay_artifacts__/CreateCreativeMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreateCreativeMutation($input: CreateCreativeInput!) {
    createCreative(input: $input) {
      creativeEdge {
        node {
          id
          title
          adm
          height
          width
          createdAt
          updatedAt
          sdkVersion
          periodTill
          periodSince
          metaAdmVersion
          metaOs
          metaPosition
          note
          archived
          ...CreativeEditForm_creative
        }
      }
    }
  }
`;

export const useCreateCreativeMutation = () => {
  const environment = useRelayEnvironment();

  const createCreativeMutation = React.useCallback(
    (input: CreateCreativeInput) =>
      commitMutation<CreateCreativeMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.projectId,
            connectionInfo: [
              {
                key: "CreativeListTableCard_creatives",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "creativeEdge",
          },
        ],
      }),
    [environment]
  );

  return { createCreativeMutation };
};
