/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type Position = "INLINE" | "OVERLAY" | "UNKNOWN" | "%future added value";
export type UpdateCreativeInput = {
    clientMutationId?: string | null;
    adm?: string | null;
    archived?: boolean | null;
    clientVersion?: number | null;
    height?: number | null;
    id: string;
    iurl?: string | null;
    tagList?: Array<string> | null;
    title?: string | null;
    metaAdmVersion?: string | null;
    metaOs?: Os | null;
    metaPosition?: Position | null;
    note?: string | null;
    periodSince?: string | null;
    periodTill?: string | null;
    viewableValidationEnabled: boolean;
    width?: number | null;
};
export type UpdateCreativeMutationVariables = {
    input: UpdateCreativeInput;
};
export type UpdateCreativeMutationResponse = {
    readonly updateCreative: {
        readonly creative: {
            readonly id: string;
            readonly title: string;
            readonly adm: string | null;
            readonly height: number | null;
            readonly width: number | null;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly sdkVersion: string | null;
            readonly periodTill: string | null;
            readonly periodSince: string | null;
            readonly metaAdmVersion: string | null;
            readonly note: string | null;
            readonly metaOs: Os | null;
            readonly metaPosition: Position | null;
            readonly archived: boolean;
            readonly latestVersion: number | null;
            readonly " $fragmentRefs": FragmentRefs<"CreativeEditForm_creative">;
        } | null;
    } | null;
};
export type UpdateCreativeMutation = {
    readonly response: UpdateCreativeMutationResponse;
    readonly variables: UpdateCreativeMutationVariables;
};



/*
mutation UpdateCreativeMutation(
  $input: UpdateCreativeInput!
) {
  updateCreative(input: $input) {
    creative {
      id
      title
      adm
      height
      width
      createdAt
      updatedAt
      sdkVersion
      periodTill
      periodSince
      metaAdmVersion
      note
      metaOs
      metaPosition
      archived
      latestVersion
      ...CreativeEditForm_creative
    }
  }
}

fragment CreativeEditForm_creative on Creative {
  id
  title
  adm
  width
  height
  iurl
  tagList
  note
  periodTill
  periodSince
  metaPosition
  metaOs
  metaAdmVersion
  archived
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adm",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sdkVersion",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodTill",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodSince",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaAdmVersion",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaOs",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaPosition",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestVersion",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCreativePayload",
        "kind": "LinkedField",
        "name": "updateCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Creative",
            "kind": "LinkedField",
            "name": "creative",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreativeEditForm_creative"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCreativePayload",
        "kind": "LinkedField",
        "name": "updateCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Creative",
            "kind": "LinkedField",
            "name": "creative",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iurl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagList",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f871a1c5c73ff317ecb13edffe74f729",
    "id": null,
    "metadata": {},
    "name": "UpdateCreativeMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCreativeMutation(\n  $input: UpdateCreativeInput!\n) {\n  updateCreative(input: $input) {\n    creative {\n      id\n      title\n      adm\n      height\n      width\n      createdAt\n      updatedAt\n      sdkVersion\n      periodTill\n      periodSince\n      metaAdmVersion\n      note\n      metaOs\n      metaPosition\n      archived\n      latestVersion\n      ...CreativeEditForm_creative\n    }\n  }\n}\n\nfragment CreativeEditForm_creative on Creative {\n  id\n  title\n  adm\n  width\n  height\n  iurl\n  tagList\n  note\n  periodTill\n  periodSince\n  metaPosition\n  metaOs\n  metaAdmVersion\n  archived\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = 'b23def5f75eeb2b1133f5138afbfc098';
export default node;
