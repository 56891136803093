import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { CreativeNoteEditForm_creative } from "~/__relay_artifacts__/CreativeNoteEditForm_creative.graphql";
import {
  CreativeNoteForm,
  FormValues,
  schema,
} from "~/components/CreativeNoteForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateCreativeMutation } from "~/mutations/UpdateCreativeMutation";

type Props = {
  creativeRef: FragmentRef<CreativeNoteEditForm_creative>;
  onSubmitCompleted: () => void;
};

const creativeFragment = graphql`
  fragment CreativeNoteEditForm_creative on Creative {
    id
    title
    adm
    width
    height
    iurl
    tagList
    note
    latestVersion
  }
`;

export const CreativeNoteEditForm: React.FC<Props> = ({
  creativeRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateCreativeMutation } = useUpdateCreativeMutation();
  const creative = useFragment<CreativeNoteEditForm_creative>(
    creativeFragment,
    creativeRef
  );

  const initialValues = React.useMemo(
    () =>
      ({
        title: creative.title,
        adm: creative.adm,
        width: creative.width,
        height: creative.height,
        iurl: creative.iurl,
        viewableValidationEnabled: false,
        tagList: creative.tagList || [],
        note: creative.note,
      } as FormValues),
    [creative]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        if (!values.adm || !values.title) throw new Error("assertion failed.");
        const { updateCreative } = await updateCreativeMutation({
          id: creative.id,
          clientVersion: creative.latestVersion,
          ...values,
        });
        if (!updateCreative?.creative) throw new Error("assertion failed");
        enqueueSnackbar("クリエイティブを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      creative,
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      updateCreativeMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={CreativeNoteForm}
    />
  );
};
