import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpsertExpenseInput,
  UpsertExpenseMutation,
} from "~/__relay_artifacts__/UpsertExpenseMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpsertExpenseMutation($input: UpsertExpenseInput!) {
    upsertExpense(input: $input) {
      expense {
        id
        date
        rate
      }
    }
  }
`;

export const useUpsertExpenseMutation = () => {
  const environment = useRelayEnvironment();

  const upsertExpenseMutation = React.useCallback(
    (input: UpsertExpenseInput) =>
      commitMutation<UpsertExpenseMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { upsertExpenseMutation };
};
