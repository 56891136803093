/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExcludeScheduleEditForm_excludeSchedule = {
    readonly id: string;
    readonly name: string;
    readonly since: string;
    readonly till: string;
    readonly latestVersion: number | null;
    readonly " $refType": "ExcludeScheduleEditForm_excludeSchedule";
};
export type ExcludeScheduleEditForm_excludeSchedule$data = ExcludeScheduleEditForm_excludeSchedule;
export type ExcludeScheduleEditForm_excludeSchedule$key = {
    readonly " $data"?: ExcludeScheduleEditForm_excludeSchedule$data;
    readonly " $fragmentRefs": FragmentRefs<"ExcludeScheduleEditForm_excludeSchedule">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExcludeScheduleEditForm_excludeSchedule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "since",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "till",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "ExcludeSchedule",
  "abstractKey": null
};
(node as any).hash = '67ddcee5d8d091b8b2ef5f8cab67c37d';
export default node;
