import { TextFieldProps } from "@material-ui/core";
import { FormikNumberField, FormikTextField } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import * as React from "react";

type FormValues = {
  input: number | string | null;
};

export type Props =
  | ({
      type: "number";
      value: number | null;
      precision?: number;
      onSubmit: FormikConfig<FormValues>["onSubmit"];
    } & Omit<TextFieldProps, "onSubmit">)
  | ({
      type: "text";
      value: string | null;
      precision?: number;
      onSubmit: FormikConfig<FormValues>["onSubmit"];
    } & Omit<TextFieldProps, "onSubmit">);

export const EditableTextField: React.FC<Props> = ({
  type,
  value,
  precision,
  onSubmit,
  ...textFieldProps
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={{ input: value }}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <>
        {type === "text" && (
          <FormikTextField
            {...textFieldProps}
            name="input"
            // @ts-expect-error
            label={undefined}
            onBlur={() => handleSubmit()}
          />
        )}
        {type === "number" && (
          <FormikNumberField
            {...textFieldProps}
            name="input"
            // @ts-expect-error
            label={undefined}
            precision={precision}
            onBlur={() => handleSubmit()}
          />
        )}
      </>
    )}
  </Formik>
);
