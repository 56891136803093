import { Grid } from "@material-ui/core";
import { FormikSelectField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { PlacementSetSelectForm_Query } from "~/__relay_artifacts__/PlacementSetSelectForm_Query.graphql";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  placementSetId: string;
};

const query = graphql`
  query PlacementSetSelectForm_Query {
    placementSets(first: 100) {
      edges {
        node {
          id
          title
        }
      }
      totalCount
    }
  }
`;

type Props = FormikProps<FormValues>;

export const PlacementSetSelectForm: React.FC<Props> = () => {
  const { props } = useQuery<PlacementSetSelectForm_Query>(query, {
    count: 100,
  });

  if (!props || !props.placementSets) return <></>;

  const edges = (props.placementSets && props.placementSets.edges) || [];
  const placementSetOptions = edges.map((edge) => {
    const node = edge && edge.node;
    if (!node) throw new Error("assertion failed");
    return {
      label: node.title,
      value: node.id,
    };
  });

  return (
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikSelectField
            fullWidth
            required
            name="placementSetId"
            label="プレイスメントセット"
            options={placementSetOptions}
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
};
