import {
  AppBar,
  Breadcrumbs,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ArrowBack as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

type BreadcrumbItem = {
  title: string;
  link: string;
};

type Props = {
  title?: string | React.ReactNode;
  tabs?: any;
  backTo?: string;
  breadcrumbs?: BreadcrumbItem[];
  shouldUseHistoryBack?: boolean;
};

const useStyles = makeStyles({
  breadcrumbs: {
    "min-height": 40,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

const RouteBreadcrumbs: React.FC<{ items: BreadcrumbItem[] }> = ({ items }) => {
  const lastItem = items.pop();
  if (!lastItem) throw new Error("assertion failed");
  return (
    <Breadcrumbs aria-label="Breadcrumb">
      {items.map((item) => (
        <Link key={item.link} color="inherit" to={item.link}>
          {item.title}
        </Link>
      ))}
      <Typography color="textPrimary">{lastItem.title}</Typography>
    </Breadcrumbs>
  );
};

export const PageHeader: React.FC<Props> = ({
  title,
  children,
  tabs,
  backTo,
  breadcrumbs,
  shouldUseHistoryBack = false,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBackClick = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <AppBar position="static" elevation={1} color="default">
        <Toolbar>
          {backTo && (
            <Link to={backTo}>
              <IconButton aria-label="Menu" className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Link>
          )}
          {shouldUseHistoryBack && (
            <IconButton
              aria-label="Menu"
              className={classes.menuButton}
              onClick={handleBackClick}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          {children}
        </Toolbar>
        {tabs}
      </AppBar>
      {breadcrumbs && (
        <Toolbar className={classes.breadcrumbs}>
          <RouteBreadcrumbs items={breadcrumbs} />
        </Toolbar>
      )}
    </>
  );
};
