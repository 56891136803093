import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { PlacementSetList_Query } from "~/__relay_artifacts__/PlacementSetList_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { PlacementSetListTableCard } from "~/components/PlacementSetListTableCard";

const query = graphql`
  query PlacementSetList_Query {
    ...PlacementSetListTableCard_root
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader title="プレイスメントセット管理" backTo="/" />
    {children}
  </>
);

export const PlacementSetList: React.FC = () => {
  const { props, error } = useQuery<PlacementSetList_Query>(query, {
    count: 100,
  });

  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <PlacementSetListTableCard placementSetRef={props} />
    </Wrapper>
  );
};
