import { Theme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { styled } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as React from "react";
import Linkify from "react-linkify";
import { FragmentRef, graphql } from "react-relay";

import { NotifyMessages_user } from "~/__relay_artifacts__/NotifyMessages_user.graphql";
import { useFragment } from "~/lib/relay-hooks";
import { useMarkMessageAsReadMutation } from "~/mutations/MarkMessageAsReadMutation";

type Props = {
  userRef: FragmentRef<NotifyMessages_user>;
};

const userFragment = graphql`
  fragment NotifyMessages_user on User {
    id
    unreadMessages(first: 10)
      @connection(key: "NotifyMessages_unreadMessages") {
      edges {
        node {
          id
          body
        }
      }
    }
  }
`;

const StyledWrapper = styled("div")<Theme>(({ theme }) => ({
  margin: theme.spacing(3),
}));

const StyledAlert = styled(Alert)<Theme>(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const NotifyMessages: React.FC<Props> = ({ userRef }) => {
  const user = useFragment<NotifyMessages_user>(userFragment, userRef);
  const { markMessageAsReadMutation } = useMarkMessageAsReadMutation(user.id);
  const { enqueueSnackbar } = useSnackbar();

  const unreadMessages = React.useMemo(() => {
    const edges = user.unreadMessages?.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion error");
      return edge?.node;
    });
  }, [user.unreadMessages]);

  const handleMarkAsRead = React.useCallback(
    async (messageId: string) => {
      try {
        const { markMessageAsRead } = await markMessageAsReadMutation({
          messageId,
        });
        const deletedMessageId = markMessageAsRead?.deletedMessageId;
        if (!deletedMessageId) throw new Error("assertion failed");
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [markMessageAsReadMutation, enqueueSnackbar]
  );

  return (
    <StyledWrapper>
      {unreadMessages.map((message) => (
        <StyledAlert
          severity="info"
          key={message.id}
          onClose={() => handleMarkAsRead(message.id)}
        >
          {message.body?.split(/\n|\r\n|\r/).map((b) => (
            <Linkify>
              {b}
              <br />
            </Linkify>
          ))}
        </StyledAlert>
      ))}
    </StyledWrapper>
  );
};
