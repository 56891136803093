import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateAdScheduleSetInput,
  UpdateAdScheduleSetMutation,
} from "~/__relay_artifacts__/UpdateAdScheduleSetMutation.graphql";

const mutation = graphql`
  mutation UpdateAdScheduleSetMutation($input: UpdateAdScheduleSetInput!) {
    updateAdScheduleSet(input: $input) {
      ad {
        ...AdScheduleSetCard_ad
      }
    }
  }
`;

export const useUpdateAdScheduleSetMutation = () => {
  const environment = useRelayEnvironment();

  const updateAdScheduleSetMutation = useCallback(
    (input: UpdateAdScheduleSetInput) =>
      commitMutation<UpdateAdScheduleSetMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateAdScheduleSetMutation };
};
