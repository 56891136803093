/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateScheduleInput = {
    clientMutationId?: string | null;
    scheduleSetId: string;
    since: string;
    till: string;
};
export type CreateScheduleMutationVariables = {
    input: CreateScheduleInput;
};
export type CreateScheduleMutationResponse = {
    readonly createSchedule: {
        readonly scheduleEdge: {
            readonly node: {
                readonly id: string;
                readonly since: string | null;
                readonly till: string | null;
            } | null;
        } | null;
    } | null;
};
export type CreateScheduleMutation = {
    readonly response: CreateScheduleMutationResponse;
    readonly variables: CreateScheduleMutationVariables;
};



/*
mutation CreateScheduleMutation(
  $input: CreateScheduleInput!
) {
  createSchedule(input: $input) {
    scheduleEdge {
      node {
        id
        since
        till
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSchedulePayload",
    "kind": "LinkedField",
    "name": "createSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleEdge",
        "kind": "LinkedField",
        "name": "scheduleEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "since",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "till",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateScheduleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateScheduleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7953c949dd1de376a5b9362b94e6fb3f",
    "id": null,
    "metadata": {},
    "name": "CreateScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation CreateScheduleMutation(\n  $input: CreateScheduleInput!\n) {\n  createSchedule(input: $input) {\n    scheduleEdge {\n      node {\n        id\n        since\n        till\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '189148040555854d266cbb4c0bcaf32f';
export default node;
