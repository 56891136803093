import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteBlockMutation } from "~/__relay_artifacts__/DeleteBlockMutation.graphql";

const mutation = graphql`
  mutation DeleteBlockMutation($input: DeleteBlockInput!) {
    deleteBlock(input: $input) {
      deletedBlockId
    }
  }
`;

const commit = (environment: Environment, blockId: string) =>
  commitMutation<DeleteBlockMutation>(environment, {
    mutation,
    variables: {
      input: { blockId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: "client:root",
        connectionKeys: [{ key: "BlockList_blocks" }],
        pathToConnection: ["root", "blocks"],
        deletedIDFieldName: "deletedBlockId",
      },
    ],
  });

export default commit;
