import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateAdSlotInput,
  UpdateAdSlotMutation,
} from "~/__relay_artifacts__/UpdateAdSlotMutation.graphql";

const mutation = graphql`
  mutation UpdateAdSlotMutation($input: UpdateAdSlotInput!) {
    updateAdSlot(input: $input) {
      adSlot {
        id
        name
        domain
        tagId
        sspProvider
        width
        height
        bundle
        platform
        adxBillingId
        archived
        ...AdSlotEditForm_adSlot
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdateAdSlotInput) =>
  commitMutation<UpdateAdSlotMutation>(environment, {
    mutation,
    variables: { input },
  });

export default commit;
