/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignDomainRestrictionStatus = "ALLOW" | "DENY" | "%future added value";
export type CampaignDomainRestrictionListCard_campaign = {
    readonly id: string;
    readonly campaignDomainRestrictions: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly domain: string;
                readonly status: CampaignDomainRestrictionStatus;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "CampaignDomainRestrictionListCard_campaign";
};
export type CampaignDomainRestrictionListCard_campaign$data = CampaignDomainRestrictionListCard_campaign;
export type CampaignDomainRestrictionListCard_campaign$key = {
    readonly " $data"?: CampaignDomainRestrictionListCard_campaign$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignDomainRestrictionListCard_campaign">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "campaignDomainRestrictions"
        ]
      }
    ]
  },
  "name": "CampaignDomainRestrictionListCard_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "campaignDomainRestrictions",
      "args": null,
      "concreteType": "CampaignDomainRestrictionConnection",
      "kind": "LinkedField",
      "name": "__CampaignDomainRestrictionListCard_campaignDomainRestrictions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignDomainRestrictionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CampaignDomainRestriction",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "domain",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();
(node as any).hash = 'b2625b37956125265c88a2e77e419d3f';
export default node;
