/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PromotionAttachButton_QueryVariables = {
    projectId: string;
};
export type PromotionAttachButton_QueryResponse = {
    readonly project: {
        readonly campaigns: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string;
                    readonly promotion: {
                        readonly id: string;
                    } | null;
                } | null;
            } | null> | null;
        };
    } | null;
};
export type PromotionAttachButton_Query = {
    readonly response: PromotionAttachButton_QueryResponse;
    readonly variables: PromotionAttachButton_QueryVariables;
};



/*
query PromotionAttachButton_Query(
  $projectId: ID!
) {
  project(id: $projectId) {
    campaigns(first: 150) {
      edges {
        node {
          id
          title
          promotion {
            id
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 150
    }
  ],
  "concreteType": "CampaignConnection",
  "kind": "LinkedField",
  "name": "campaigns",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Promotion",
              "kind": "LinkedField",
              "name": "promotion",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "campaigns(first:150)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PromotionAttachButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PromotionAttachButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "108af331c1583095f01b8b423d85ec28",
    "id": null,
    "metadata": {},
    "name": "PromotionAttachButton_Query",
    "operationKind": "query",
    "text": "query PromotionAttachButton_Query(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    campaigns(first: 150) {\n      edges {\n        node {\n          id\n          title\n          promotion {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '8b879cbd6b3a159bc28085e13f5d379f';
export default node;
