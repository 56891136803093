/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRole = "ADMIN" | "NORMAL" | "%future added value";
export type ProjectDrawerMenuList_viewer = {
    readonly role: UserRole;
    readonly " $fragmentRefs": FragmentRefs<"AccountButton_viewer">;
    readonly " $refType": "ProjectDrawerMenuList_viewer";
};
export type ProjectDrawerMenuList_viewer$data = ProjectDrawerMenuList_viewer;
export type ProjectDrawerMenuList_viewer$key = {
    readonly " $data"?: ProjectDrawerMenuList_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectDrawerMenuList_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDrawerMenuList_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountButton_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '7ac16547cca0f0d076e65df619d2b3b7';
export default node;
