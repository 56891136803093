/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignList_project = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"CampaignListTableCard_project" | "CreativeListTableCard_project" | "ScheduleSetList_project" | "TrackerList_project">;
    readonly " $refType": "CampaignList_project";
};
export type CampaignList_project$data = CampaignList_project;
export type CampaignList_project$key = {
    readonly " $data"?: CampaignList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignList_project">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignList_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignListTableCard_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreativeListTableCard_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScheduleSetList_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TrackerList_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
(node as any).hash = '40ef372f7f2ddf702a6e71cd5f39ffae';
export default node;
