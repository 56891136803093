import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AdDetailPage } from "~/pages/AdDetailPage";
import { AdSlotList } from "~/pages/AdSlotList";
import { AdSlotPlacementList } from "~/pages/AdSlotPlacementList";
import { BlockList } from "~/pages/BlockList";
import { CampaignDetail } from "~/pages/CampaignDetail";
import { CampaignList } from "~/pages/CampaignList";
import { CampaignListForAdmin } from "~/pages/CampaignListForAdmin";
import { ClientCampaignDailyBudgetList } from "~/pages/ClientCampaignDailyBudgetList";
import { ClientCampaignList } from "~/pages/ClientCampaignList";
import { ClientCreativeList } from "~/pages/ClientCreativeList";
import { CreativeDetail } from "~/pages/CreativeDetail";
import { DenyTagIdList } from "~/pages/DenyTagIdList";
import { Home } from "~/pages/Home";
import { InvoiceList } from "~/pages/InvoiceList";
import { MaintenanceList } from "~/pages/MaintenanceList";
import { MembershipListPage } from "~/pages/MembershipListPage";
import { MessageList } from "~/pages/MessageList";
import { PlacementSetList } from "~/pages/PlacementSetList";
import { PlacementSettingList } from "~/pages/PlacementSettingList";
import { ProjectCreate } from "~/pages/ProjectCreate";
import { ProjectEdit } from "~/pages/ProjectEdit";
import { PromotionDetail } from "~/pages/PromotionDetail";
import { PromotionList } from "~/pages/PromotionList";
import { Signin } from "~/pages/Signin";
import { Signup } from "~/pages/Signup";
import { Top } from "~/pages/Top";
import { UserList } from "~/pages/UserList";

import { AuthenticatedRoute, UnauthenticatedRoute } from "./AuthRoutes";
import { CampaignRoot } from "./CampaignRoot";
import { Layout } from "./Layout";
import { ProjectRoot } from "./ProjectRoot";

type Props = {
  isSignedIn: boolean;
};

export const Routes: FC<Props> = ({ isSignedIn }) => (
  <Switch>
    <UnauthenticatedRoute
      exact
      isSignedIn={isSignedIn}
      path="/signin"
      component={Signin}
    />
    <UnauthenticatedRoute
      exact
      isSignedIn={isSignedIn}
      path="/signup"
      component={Signup}
    />
    <AuthenticatedRoute
      exact
      isSignedIn={isSignedIn}
      path="/"
      component={Top}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      path="/admin"
      render={() => (
        <Layout mode="admin">
          <Switch>
            <Route exact path="/admin/users" component={UserList} />
            <Route exact path="/admin/ad_slots" component={AdSlotList} />
            <Route
              exact
              path="/admin/ad_slots/:adSlotId/placements"
              component={AdSlotPlacementList}
            />
            <Route exact path="/admin/blocks" component={BlockList} />
            <Route exact path="/admin/deny_tag_ids" component={DenyTagIdList} />
            <Route
              exact
              path="/admin/campaigns"
              component={CampaignListForAdmin}
            />
            <Route
              exact
              path="/admin/placement_sets"
              component={PlacementSetList}
            />
            <Route
              exact
              path="/admin/placement_sets/:placementSetId/placement_settings"
              component={PlacementSettingList}
            />
            <Route exact path="/admin/messages" component={MessageList} />
            <Route
              exact
              path="/admin/maintenances"
              component={MaintenanceList}
            />
            <Route
              exact
              path="/admin/projects/create"
              component={ProjectCreate}
            />
          </Switch>
        </Layout>
      )}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      path="/projects/:projectId"
      render={() => (
        <ProjectRoot>
          <Layout mode="project">
            <Switch>
              <Route exact path="/projects/:projectId/home" component={Home} />
              <Route
                exact
                path="/projects/:projectId/campaigns"
                component={CampaignList}
              />
              <Route
                exact
                path="/projects/:projectId/campaigns/:id"
                render={() => (
                  <CampaignRoot>
                    <Switch>
                      <Route
                        exact
                        path="/projects/:projectId/campaigns/:id"
                        component={CampaignDetail}
                      />
                    </Switch>
                  </CampaignRoot>
                )}
              />
              <Route
                exact
                path="/projects/:projectId/members"
                component={MembershipListPage}
              />
              <Route
                exact
                path="/projects/:projectId/daily_budgets"
                component={ClientCampaignList}
              />
              <Route
                exact
                path="/projects/:projectId/creatives"
                component={ClientCreativeList}
              />
              <Route
                exact
                path="/projects/:projectId/daily_budgets/:id"
                render={() => (
                  <CampaignRoot>
                    <Switch>
                      <Route
                        exact
                        path="/projects/:projectId/daily_budgets/:id"
                        component={ClientCampaignDailyBudgetList}
                      />
                    </Switch>
                  </CampaignRoot>
                )}
              />
              <Route
                exact
                path="/projects/:projectId/creatives/:id"
                component={CreativeDetail}
              />
              <Route
                exact
                path="/projects/:projectId/edit"
                component={ProjectEdit}
              />
              <Route
                exact
                path="/projects/:projectId/campaigns/:campaignId/ads/:id"
                component={AdDetailPage}
              />
              <Route
                exact
                path="/projects/:projectId/invoices"
                component={InvoiceList}
              />
              <Route
                exact
                path="/projects/:projectId/promotions"
                component={PromotionList}
              />
              <Route
                exact
                path="/projects/:projectId/promotions/:id"
                component={PromotionDetail}
              />
              <Redirect to="/projects/:projectId/home" />
            </Switch>
          </Layout>
        </ProjectRoot>
      )}
    />
  </Switch>
);
