import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { MembershipRoleEditForm_membership } from "~/__relay_artifacts__/MembershipRoleEditForm_membership.graphql";
import {
  FormValues,
  MembershipRoleForm,
  schema,
} from "~/components/MembershipRoleForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updateMembershipRoleMutation from "~/mutations/UpdateMembershipRoleMutation";

type Props = {
  membershipRef: FragmentRef<MembershipRoleEditForm_membership>;
  onSubmitCompleted: () => void;
};

const membershipFragment = graphql`
  fragment MembershipRoleEditForm_membership on Membership {
    id
    role
  }
`;

export const MembershipRoleEditForm: React.FC<Props> = ({
  membershipRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const membership = useFragment<MembershipRoleEditForm_membership>(
    membershipFragment,
    membershipRef
  );

  const initialValues = React.useMemo(
    () => ({ role: membership.role } as FormValues),
    [membership]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateMembershipRole } = await updateMembershipRoleMutation(
          environment,
          {
            id: membership.id,
            ...values,
          }
        );
        const _membership = updateMembershipRole?.membership;
        if (!_membership) throw new Error("assertion failed");
        enqueueSnackbar("メンバー役割を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      environment,
      membership.id,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={MembershipRoleForm}
    />
  );
};
