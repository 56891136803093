/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PromotionCampaignListTableCard_promotion = {
    readonly id: string;
    readonly campaigns: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "PromotionCampaignListTableCard_promotion";
};
export type PromotionCampaignListTableCard_promotion$data = PromotionCampaignListTableCard_promotion;
export type PromotionCampaignListTableCard_promotion$key = {
    readonly " $data"?: PromotionCampaignListTableCard_promotion$data;
    readonly " $fragmentRefs": FragmentRefs<"PromotionCampaignListTableCard_promotion">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "campaigns"
        ]
      }
    ]
  },
  "name": "PromotionCampaignListTableCard_promotion",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "campaigns",
      "args": null,
      "concreteType": "CampaignConnection",
      "kind": "LinkedField",
      "name": "__PromotionCampaignListTableCard_campaigns_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Promotion",
  "abstractKey": null
};
})();
(node as any).hash = '35c2821f3a15d4ff3aa831918b223504';
export default node;
