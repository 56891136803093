import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateMonthlyBudgetInput,
  CreateMonthlyBudgetMutation,
} from "~/__relay_artifacts__/CreateMonthlyBudgetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreateMonthlyBudgetMutation($input: CreateMonthlyBudgetInput!) {
    createMonthlyBudget(input: $input) {
      monthlyBudgetEdge {
        node {
          id
          date
          amount
          createdAt
          updatedAt
          ...MonthlyBudgetEditForm_monthlyBudget
        }
      }
    }
  }
`;

export const useCreateMonthlyBudgetMutation = () => {
  const environment = useRelayEnvironment();

  const createMonthlyBudgetMutation = React.useCallback(
    (input: CreateMonthlyBudgetInput) =>
      commitMutation<CreateMonthlyBudgetMutation>(environment, {
        mutation,
        variables: {
          input,
        },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.campaignId,
            connectionInfo: [
              {
                key: "MonthlyBudgetListTableCard_monthlyBudgets",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "monthlyBudgetEdge",
          },
        ],
      }),
    [environment]
  );

  return { createMonthlyBudgetMutation };
};
