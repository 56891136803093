import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { omit } from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy } from "~/__relay_artifacts__/ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy.graphql";
import {
  FormValues,
  ThompsonFilteringStrategyForm,
  schema,
} from "~/components/ThompsonFilteringStrategyForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updateThompsonFilteringStrategyMutation from "~/mutations/UpdateThompsonFilteringStrategyMutation";

type Props = {
  thompsonFilteringStrategyRef: FragmentRef<ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy>;
  onSubmitCompleted: () => void;
};

const thompsonFilteringStrategyFragment = graphql`
  fragment ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy on ThompsonFilteringStrategy {
    id
    cpa
    cvr
    cpmCorrectionTerm
    ctrCorrectionTerm
    initialDistributionAlpha
    initialDistributionBeta
  }
`;

export const ThompsonFilteringStrategyEditForm: React.FC<Props> = ({
  thompsonFilteringStrategyRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const thompsonFilteringStrategy =
    useFragment<ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy>(
      thompsonFilteringStrategyFragment,
      thompsonFilteringStrategyRef
    );

  const initialValues = React.useMemo(
    () => omit(["id"], thompsonFilteringStrategy) as FormValues,
    [thompsonFilteringStrategy]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const res = await updateThompsonFilteringStrategyMutation(environment, {
          id: thompsonFilteringStrategy.id,
          ...values,
        });
        if (!res.updateThompsonFilteringStrategy?.thompsonFilteringStrategy) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("フィルタを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
      thompsonFilteringStrategy.id,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={ThompsonFilteringStrategyForm}
    />
  );
};
