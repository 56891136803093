import { useSnackbar } from "notistack";
import * as React from "react";

import { CreativeListTableCard_project } from "~/__relay_artifacts__/CreativeListTableCard_project.graphql";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { useUpdateCreativeMutation } from "~/mutations/UpdateCreativeMutation";

type Creative = NonNullable<
  NonNullable<
    NonNullable<CreativeListTableCard_project["creatives"]["edges"]>[0]
  >["node"]
>;

type Props = {
  creative: Creative;
  onSubmitCompleted: () => void;
};

export const ArchiveButton: React.FC<Props> = ({
  creative,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateCreativeMutation } = useUpdateCreativeMutation();

  const handleArchive = React.useCallback(async () => {
    try {
      await updateCreativeMutation({
        id: creative.id,
        viewableValidationEnabled: false,
        archived: true,
        clientVersion: creative.latestVersion,
      });
      onSubmitCompleted();
      enqueueSnackbar("アーカイブしました。", { variant: "success" });
    } catch {
      enqueueSnackbar("アーカイブに失敗しました。", { variant: "error" });
    }
  }, [updateCreativeMutation, creative, onSubmitCompleted, enqueueSnackbar]);

  return (
    <ConfirmButton
      variant="outlined"
      color="secondary"
      confirmTitle={`このキャンペーンをアーカイブしますか？`}
      disabled={creative.archived}
      onAgree={async (changeDialog) => {
        await handleArchive();
        changeDialog(false);
      }}
    >
      {creative.archived ? "アーカイブ済み" : "アーカイブ"}
    </ConfirmButton>
  );
};
