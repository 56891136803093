import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles, styled, withStyles } from "@material-ui/styles";
import * as React from "react";
import { ConnectionConfig, FragmentRef, graphql } from "react-relay";
import { useToggle } from "react-use";

import { CampaignAdList_root } from "~/__relay_artifacts__/CampaignAdList_root.graphql";
import { noop } from "~/lib/noop";
import { usePagination } from "~/lib/relay-hooks";

type Ad = {
  id: string;
  title: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  used: boolean;
};

type SearchParams = {
  os: "IOS" | "ANDROID" | null;
  width: number | null;
  height: number | null;
};

type Props = {
  rootRef: FragmentRef<CampaignAdList_root>;
  selectedAdIds: string[];
  onAdAddClick: (adId: string) => void;
  searchParams: SearchParams;
  usedAdIds: string[];
};

const fragment = graphql`
  fragment CampaignAdList_root on Query {
    selectableAds(
      first: $first
      after: $after
      os: $os
      width: $width
      height: $height
    ) @connection(key: "CampaignAdList_selectableAds", filters: []) {
      edges {
        node {
          id
          title
          createdAt
          updatedAt
          status
          campaign {
            id
            title
          }
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.selectableAds,
  getFragmentVariables: (preVars, _) => ({
    ...preVars,
  }),
  getVariables: (_, { count, cursor }, fragmentVariables) => ({
    ...fragmentVariables,
    first: count,
    after: cursor,
  }),
  query: graphql`
    query CampaignAdList_Query(
      $os: Os
      $width: Int
      $height: Int
      $first: Int
      $after: String
    ) {
      ...CampaignAdList_root
    }
  `,
};

const StyledAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

const StyledList = styled(List)({
  width: "100%",
  overflowX: "scroll",
});

const StyledAddButton = styled(Button)({
  whiteSpace: "nowrap",
  margin: 3,
});

const useStyles = makeStyles((theme: Theme) => ({
  count: {
    padding: theme.spacing(1),
    textAlign: "right",
    marginRight: theme.spacing(3),
  },
  box: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}));

const getButtonLabel = (selected: boolean, used: boolean) => {
  switch (true) {
    case used:
      return "割当済み";
    case selected:
      return "選択済み";
    default:
      return "追加";
  }
};

export const CampaignAdList: React.FC<Props> = ({
  selectedAdIds,
  onAdAddClick,
  rootRef,
  usedAdIds,
}) => {
  const classes = useStyles();
  const [expanded, toggle] = useToggle(true);
  const [root, { hasMore, loadMore }] = usePagination<CampaignAdList_root>(
    fragment,
    rootRef
  );

  const campaigns = React.useMemo(() => {
    const adEdges = root.selectableAds.edges || [];

    const campaignsFromAds = adEdges.map((adEdge) => {
      if (!adEdge?.node) throw new Error("assertion error");
      return adEdge.node.campaign;
    });

    const uniqueCampaigns = [
      ...new Map(campaignsFromAds.map((i) => [i["id"], i])).values(),
    ];

    return uniqueCampaigns.map((campaign) => {
      const ads: Ad[] = [];
      adEdges.forEach((adEdge) => {
        if (!adEdge?.node) throw new Error("assertion error");
        if (adEdge.node.campaign.id === campaign.id)
          ads.push({
            ...adEdge.node,
            used: usedAdIds.includes(adEdge.node.id),
          });
      });
      return { ...campaign, ads };
    });
  }, [root, usedAdIds]);

  return (
    <>
      {campaigns.map((campaign) => (
        <StyledAccordion
          square
          expanded={expanded}
          key={campaign.id}
          onChange={toggle}
        >
          <StyledAccordionSummary>
            <Typography variant="body1">{`キャンペーン名: ${
              campaign.title
            }(ID: ${atob(campaign.id)})`}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <StyledList>
              {campaign.ads.map(({ id, title, status, used }) => (
                <ListItem key={id} button selected={selectedAdIds.includes(id)}>
                  <ListItemText>
                    <Typography>{`広告名: ${title}(ID: ${atob(
                      id
                    )})`}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Status: {status}
                    </Typography>
                  </ListItemText>
                  <Box display="flex" flexDirection="column" width={100}>
                    <StyledAddButton
                      variant="outlined"
                      disabled={used}
                      color={
                        selectedAdIds.includes(id) ? "secondary" : "primary"
                      }
                      onClick={() => onAdAddClick(id)}
                    >
                      {getButtonLabel(selectedAdIds.includes(id), used)}
                    </StyledAddButton>
                  </Box>
                </ListItem>
              ))}
            </StyledList>
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
      <Divider />
      <Box display="flex" justifyContent="flex-end" className={classes.box}>
        <Button
          onClick={() => {
            hasMore() && loadMore(connectionConfig, 100, noop);
          }}
          variant="contained"
          color="primary"
        >
          さらに取得
        </Button>
      </Box>
    </>
  );
};
