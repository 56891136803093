import { VFC } from "react";
import { FragmentRef, graphql } from "relay-runtime";

import { CampaignRestrictionListCard_campaign } from "~/__relay_artifacts__/CampaignRestrictionListCard_campaign.graphql";
import { useFragment } from "~/lib/relay-hooks";

import { CampaignDenyTagIdListCard } from "./CampaignDenyTagIdListCard";
import { CampaignDomainRestrictionListCard } from "./CampaignDomainRestrictionListCard";

type Props = {
  campaignRef: FragmentRef<CampaignRestrictionListCard_campaign>;
};

const fragment = graphql`
  fragment CampaignRestrictionListCard_campaign on Campaign {
    ...CampaignDomainRestrictionListCard_campaign
    ...CampaignDenyTagIdListCard_campaign
  }
`;

export const CampaignRestrictionListCard: VFC<Props> = ({ campaignRef }) => {
  const campaign = useFragment<CampaignRestrictionListCard_campaign>(
    fragment,
    campaignRef
  );

  return (
    <>
      <CampaignDenyTagIdListCard campaignRef={campaign} />
      <CampaignDomainRestrictionListCard campaignRef={campaign} />
    </>
  );
};
