/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreatePromotionInput = {
    clientMutationId?: string | null;
    projectId: string;
    title: string;
};
export type CreatePromotionMutationVariables = {
    input: CreatePromotionInput;
    connections: Array<string>;
};
export type CreatePromotionMutationResponse = {
    readonly createPromotion: {
        readonly promotion: {
            readonly id: string;
            readonly title: string;
        };
    } | null;
};
export type CreatePromotionMutation = {
    readonly response: CreatePromotionMutationResponse;
    readonly variables: CreatePromotionMutationVariables;
};



/*
mutation CreatePromotionMutation(
  $input: CreatePromotionInput!
) {
  createPromotion(input: $input) {
    promotion {
      id
      title
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Promotion",
  "kind": "LinkedField",
  "name": "promotion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePromotionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePromotionPayload",
        "kind": "LinkedField",
        "name": "createPromotion",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatePromotionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePromotionPayload",
        "kind": "LinkedField",
        "name": "createPromotion",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "promotion",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PromotionEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3c432ec291a5f6c8b9b59cf28294e6e2",
    "id": null,
    "metadata": {},
    "name": "CreatePromotionMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePromotionMutation(\n  $input: CreatePromotionInput!\n) {\n  createPromotion(input: $input) {\n    promotion {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '044bc98532c9a5b1728aaeeb4df58b21';
export default node;
