import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { CopyCampaignMutation } from "~/__relay_artifacts__/CopyCampaignMutation.graphql";

const mutation = graphql`
  mutation CopyCampaignMutation($input: CopyCampaignInput!) {
    copyCampaign(input: $input) {
      campaignEdge {
        node {
          id
          title
          status
          createdAt
          updatedAt
          adomain
          ads {
            totalCount
          }
          os
          osVersionMin
          ...CampaignEditForm_campaign
        }
      }
    }
  }
`;

let clientMutationId = 0;

const commit = (
  environment: Environment,
  projectId: string,
  campaignId: string
) =>
  commitMutation<CopyCampaignMutation>(environment, {
    mutation,
    variables: {
      input: {
        clientMutationId: `${clientMutationId++}`,
        campaignId,
      },
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: projectId,
        connectionInfo: [
          {
            key: "CampaignListTableCard_campaigns",
            filters: [],
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "campaignEdge",
      },
    ],
  });

export default commit;
