import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateInvoiceInput,
  CreateInvoiceMutation,
} from "~/__relay_artifacts__/CreateInvoiceMutation.graphql";

const mutation = graphql`
  mutation CreateInvoiceMutation($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      invoiceEdge {
        node {
          id
          freeeInvoiceId
          title
          periodSince
          periodTill
          invoiceNumber
          billingDate
          dueDate
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateInvoiceInput) =>
  commitMutation<CreateInvoiceMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.projectId,
        connectionInfo: [
          {
            key: "InvoiceListTableCard_invoices",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "invoiceEdge",
      },
    ],
  });

export default commit;
