import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateTrackerInput,
  CreateTrackerMutation,
} from "~/__relay_artifacts__/CreateTrackerMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreateTrackerMutation($input: CreateTrackerInput!) {
    createTracker(input: $input) {
      trackerEdge {
        node {
          id
          title
          provider
          parameters
          associationType
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const useCreateTrackerMutation = () => {
  const environment = useRelayEnvironment();

  const createTrackerMutation = useCallback(
    (input: CreateTrackerInput) =>
      commitMutation<CreateTrackerMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.projectId,
            connectionInfo: [
              {
                key: "TrackerList_trackers",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "trackerEdge",
          },
        ],
      }),
    [environment]
  );

  return { createTrackerMutation };
};
