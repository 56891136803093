import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { FormValues, PricingForm, schema } from "~/components/PricingForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createPricingMutation from "~/mutations/CreatePricingMutation";

type Props = {
  adSlotId: string;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  logicType: "MANUAL",
  manualBidPrice: null,
};

export const PricingCreateForm: React.FC<Props> = ({
  adSlotId,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const formErrorHandler = useFormErrorHandler();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createPricing } = await createPricingMutation(environment, {
          adSlotId,
          ...values,
        });
        if (!createPricing?.adSlot) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("入札設定を作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        formErrorHandler(err, setErrors);
      }
    },
    [
      adSlotId,
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <PricingForm />
    </Formik>
  );
};
