import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteMonthlyBudgetInput,
  DeleteMonthlyBudgetMutation,
} from "~/__relay_artifacts__/DeleteMonthlyBudgetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteMonthlyBudgetMutation($input: DeleteMonthlyBudgetInput!) {
    deleteMonthlyBudget(input: $input) {
      deletedMonthlyBudgetId
    }
  }
`;

export const useDeleteMonthlyBudgetMutation = (campaignId: string) => {
  const environment = useRelayEnvironment();

  const deleteMonthlyBudgetMutation = React.useCallback(
    (input: DeleteMonthlyBudgetInput) =>
      commitMutation<DeleteMonthlyBudgetMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: campaignId,
            connectionKeys: [
              { key: "MonthlyBudgetListTableCard_monthlyBudgets" },
            ],
            pathToConnection: ["campaign", "monthlyBudgets"],
            deletedIDFieldName: "deletedMonthlyBudgetId",
          },
        ],
      }),
    [campaignId, environment]
  );

  return { deleteMonthlyBudgetMutation };
};
