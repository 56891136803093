/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type CreatePlacementSettingInput = {
    clientMutationId?: string | null;
    placementSetId: string;
    adSlotId: string;
};
export type CreatePlacementSettingMutationVariables = {
    input: CreatePlacementSettingInput;
};
export type CreatePlacementSettingMutationResponse = {
    readonly createPlacementSetting: {
        readonly placementSettingEdge: {
            readonly node: {
                readonly id: string;
                readonly adSlot: {
                    readonly id: string;
                    readonly name: string | null;
                    readonly width: number | null;
                    readonly height: number | null;
                    readonly tagId: string | null;
                    readonly bundle: string | null;
                    readonly note: string | null;
                    readonly bannerFormat: string | null;
                    readonly platform: AdSlotPlatform | null;
                    readonly sspProvider: AdSlotSspProvider;
                    readonly targetWinRate: number;
                };
            } | null;
        } | null;
    } | null;
};
export type CreatePlacementSettingMutation = {
    readonly response: CreatePlacementSettingMutationResponse;
    readonly variables: CreatePlacementSettingMutationVariables;
};



/*
mutation CreatePlacementSettingMutation(
  $input: CreatePlacementSettingInput!
) {
  createPlacementSetting(input: $input) {
    placementSettingEdge {
      node {
        id
        adSlot {
          id
          name
          width
          height
          tagId
          bundle
          note
          bannerFormat
          platform
          sspProvider
          targetWinRate
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePlacementSettingPayload",
    "kind": "LinkedField",
    "name": "createPlacementSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PlacementSettingEdge",
        "kind": "LinkedField",
        "name": "placementSettingEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlacementSetting",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdSlot",
                "kind": "LinkedField",
                "name": "adSlot",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bundle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bannerFormat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platform",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sspProvider",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetWinRate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePlacementSettingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePlacementSettingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5d6bcb028fc9a69e3c2b5d3a4afaebc6",
    "id": null,
    "metadata": {},
    "name": "CreatePlacementSettingMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePlacementSettingMutation(\n  $input: CreatePlacementSettingInput!\n) {\n  createPlacementSetting(input: $input) {\n    placementSettingEdge {\n      node {\n        id\n        adSlot {\n          id\n          name\n          width\n          height\n          tagId\n          bundle\n          note\n          bannerFormat\n          platform\n          sspProvider\n          targetWinRate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0ce27a37c62bb3fe58dbb2259a6a0c90';
export default node;
