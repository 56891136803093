/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectEdit_QueryVariables = {
    projectId: string;
};
export type ProjectEdit_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectEditForm_project">;
    } | null;
};
export type ProjectEdit_Query = {
    readonly response: ProjectEdit_QueryResponse;
    readonly variables: ProjectEdit_QueryVariables;
};



/*
query ProjectEdit_Query(
  $projectId: ID!
) {
  project(id: $projectId) {
    ...ProjectEditForm_project
    id
  }
}

fragment ProjectEditForm_project on Project {
  name
  freeePartnerId
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectEditForm_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "freeePartnerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "30825dcba08d0c0787525bb1f5059b6a",
    "id": null,
    "metadata": {},
    "name": "ProjectEdit_Query",
    "operationKind": "query",
    "text": "query ProjectEdit_Query(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    ...ProjectEditForm_project\n    id\n  }\n}\n\nfragment ProjectEditForm_project on Project {\n  name\n  freeePartnerId\n  id\n}\n"
  }
};
})();
(node as any).hash = 'ebf5d014877fe21e6ae3e5e270bb2c5e';
export default node;
