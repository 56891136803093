import {
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { ConnectionConfig } from "relay-hooks";

import { MessageListTableCard_root } from "~/__relay_artifacts__/MessageListTableCard_root.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { TableCell } from "~/components/atoms/TableCell";
import { noop } from "~/lib/noop";
import { usePagination } from "~/lib/relay-hooks";

import { MessageCreateForm } from "./MessageForm";

type Props = {
  rootRef: FragmentRef<MessageListTableCard_root>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 100,
  },
}));

const rootFragment = graphql`
  fragment MessageListTableCard_root on Query {
    messages(first: $first, after: $after)
      @connection(key: "MessageListTableCard_messages") {
      edges {
        node {
          id
          body
        }
      }
      totalCount
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.root.messages,
  getVariables: (_, { count, cursor }) => ({ first: count, after: cursor }),
  query: graphql`
    query MessageListTableCard_Query($first: Int, $after: String) {
      ...MessageListTableCard_root
    }
  `,
};

export const MessageListTableCard: React.FC<Props> = ({ rootRef }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [root, { hasMore, loadMore }] =
    usePagination<MessageListTableCard_root>(rootFragment, rootRef);
  const totalCount = root.messages.totalCount;

  const messages = React.useMemo(() => {
    const edges = root.messages.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [page, perPage, root.messages.edges]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          メッセージ管理
        </Typography>
        <DialogButton
          title="作成"
          render={({ close }) => (
            <MessageCreateForm onSubmitCompleted={close} />
          )}
        >
          作成
        </DialogButton>
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>本文</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map((message) => (
            <TableRow key={message.id}>
              <TableCell>{atob(message.id)}</TableCell>
              <TableCell>
                {message.body?.replace(/\n|\r\n|\r/g, "\\n")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          hasMore() && loadMore(connectionConfig, perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(e) => setPerPage(parseInt(e.target.value))}
      />
    </Paper>
  );
};
