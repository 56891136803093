import {
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { graphql } from "react-relay";

import logo from "~/3dad_logo.png";
import { ProjectHeader_Query } from "~/__relay_artifacts__/ProjectHeader_Query.graphql";
import { ProjectHeader_project } from "~/__relay_artifacts__/ProjectHeader_project.graphql";
import { ProjectHeader_viewer } from "~/__relay_artifacts__/ProjectHeader_viewer.graphql";
import { ProjectDrawerMenuList } from "~/components/DrawerMenuList";
import { ProjectSelectionButton } from "~/components/ProjectSelectionButton";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  projectRef: ProjectHeader_Query["response"]["project"];
  viewerRef: ProjectHeader_Query["response"]["viewer"];
};

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: theme.zIndex.appBar - 1,
    width: DRAWER_WIDTH,
  },
  appBar: {},
  logo: {
    width: 124,
  },
  logoWrapper: {
    width: DRAWER_WIDTH,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
}));

const viewerFragment = graphql`
  fragment ProjectHeader_viewer on User {
    id
    ...AccountButton_viewer
    ...AdminDrawerMenuList_viewer
    ...ProjectSelectionButton_viewer
    ...ProjectDrawerMenuList_viewer
  }
`;

const projectFragment = graphql`
  fragment ProjectHeader_project on Project {
    ...ProjectSelectionButton_project
    ...ProjectDrawerMenuList_project
  }
`;

export const ProjectHeader: React.FC<Props> = ({ projectRef, viewerRef }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const viewer = useFragment<ProjectHeader_viewer>(viewerFragment, viewerRef);
  const project = useFragment<ProjectHeader_project>(
    projectFragment,
    projectRef
  );

  const handleToggle = React.useCallback(() => {
    setDrawerOpen((s) => !s);
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Hidden smUp implementation="css">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.logoWrapper}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          <div className={classes.grow}>
            <ProjectSelectionButton
              key="project"
              projectRef={project}
              viewerRef={viewer}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClick={handleToggle}
          onClose={handleToggle}
        >
          <div className={classes.toolbar} />
          <ProjectDrawerMenuList projectRef={project} viewerRef={viewer} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          open
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
        >
          <div className={classes.toolbar} />
          <ProjectDrawerMenuList projectRef={project} viewerRef={viewer} />
        </Drawer>
      </Hidden>
    </div>
  );
};
