import { List, TextField, Typography } from "@material-ui/core";
import * as R from "ramda";
import * as React from "react";
import { graphql } from "react-relay";

import { ProjectList_Query } from "~/__relay_artifacts__/ProjectList_Query.graphql";
import { ProjectList_viewer } from "~/__relay_artifacts__/ProjectList_viewer.graphql";
import { Top_Query } from "~/__relay_artifacts__/Top_Query.graphql";
import { useRefetch } from "~/lib/relay-hooks";

import { ProjectListItem } from "./ProjectListItem";

type Props = {
  viewerRef: Top_Query["response"]["viewer"];
};

const viewerFragment = graphql`
  fragment ProjectList_viewer on User {
    email
    projects(
      first: 2147483647 # max GraphQLInt
      search: $search
    ) @connection(key: "ProjectList_projects") {
      edges {
        node {
          id
          updatedAt
          ...ProjectListItem_project
        }
      }
    }
  }
`;

const query = graphql`
  query ProjectList_Query($search: String) {
    viewer {
      ...ProjectList_viewer
    }
  }
`;

const useRefetchViewer = (viewerRef: Props["viewerRef"]) => {
  const [viewer, refetch] = useRefetch<ProjectList_viewer>(
    viewerFragment,
    viewerRef
  );

  const refetchViewer = React.useCallback(
    (search: string) => {
      refetch<ProjectList_Query>(query, { search });
    },
    [refetch]
  );

  return { viewer, refetchViewer };
};

export const ProjectList: React.FC<Props> = ({ viewerRef }) => {
  const { viewer, refetchViewer } = useRefetchViewer(viewerRef);

  const projects = React.useMemo(() => {
    const nodes = (viewer.projects.edges || []).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
    return R.sort<typeof nodes[0]>(R.descend(R.prop("updatedAt")))(nodes);
  }, [viewer.projects.edges]);

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      refetchViewer(e.target.value);
    },
    [refetchViewer]
  );

  return (
    <>
      <TextField
        fullWidth
        label="絞り込み検索"
        onChange={handleTextFieldChange}
      />
      <List>
        {R.isEmpty(projects) ? (
          <Typography variant="subtitle1">
            プロジェクトが作成されておりません
          </Typography>
        ) : (
          projects.map((project) => (
            <ProjectListItem key={project.id} projectRef={project} />
          ))
        )}
      </List>
    </>
  );
};
