/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RefreshAdPlacementsInput = {
    clientMutationId?: string | null;
    adId: string;
};
export type RefreshAdPlacementsMutationVariables = {
    input: RefreshAdPlacementsInput;
};
export type RefreshAdPlacementsMutationResponse = {
    readonly refreshAdPlacements: {
        readonly ad: {
            readonly placements: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly correctionTerm: number;
                        readonly isEnabled: boolean;
                        readonly proportion: number;
                        readonly latestVersion: number | null;
                    } | null;
                } | null> | null;
                readonly totalCount: number;
                readonly pageInfo: {
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor: string | null;
                    readonly endCursor: string | null;
                };
            };
        } | null;
    } | null;
};
export type RefreshAdPlacementsMutation = {
    readonly response: RefreshAdPlacementsMutationResponse;
    readonly variables: RefreshAdPlacementsMutationVariables;
};



/*
mutation RefreshAdPlacementsMutation(
  $input: RefreshAdPlacementsInput!
) {
  refreshAdPlacements(input: $input) {
    ad {
      placements {
        edges {
          node {
            id
            correctionTerm
            isEnabled
            proportion
            latestVersion
          }
        }
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PlacementConnection",
  "kind": "LinkedField",
  "name": "placements",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PlacementEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Placement",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "correctionTerm",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "proportion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestVersion",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPreviousPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startCursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endCursor",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefreshAdPlacementsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RefreshAdPlacementsPayload",
        "kind": "LinkedField",
        "name": "refreshAdPlacements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefreshAdPlacementsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RefreshAdPlacementsPayload",
        "kind": "LinkedField",
        "name": "refreshAdPlacements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28cc1976f5a7df797a736a171814a6ae",
    "id": null,
    "metadata": {},
    "name": "RefreshAdPlacementsMutation",
    "operationKind": "mutation",
    "text": "mutation RefreshAdPlacementsMutation(\n  $input: RefreshAdPlacementsInput!\n) {\n  refreshAdPlacements(input: $input) {\n    ad {\n      placements {\n        edges {\n          node {\n            id\n            correctionTerm\n            isEnabled\n            proportion\n            latestVersion\n          }\n        }\n        totalCount\n        pageInfo {\n          hasPreviousPage\n          hasNextPage\n          startCursor\n          endCursor\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '197e31cd5b415918d4b5cfc20508d05f';
export default node;
