import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateCampaignStatusInput,
  UpdateCampaignStatusMutation,
} from "~/__relay_artifacts__/UpdateCampaignStatusMutation.graphql";

const mutation = graphql`
  mutation UpdateCampaignStatusMutation($input: UpdateCampaignStatusInput!) {
    updateCampaignStatus(input: $input) {
      campaign {
        id
        status
        latestVersion
      }
    }
  }
`;

export const useUpdateCampaignStatusMutation = () => {
  const environment = useRelayEnvironment();

  const updateCampaignStatusMutation = useCallback(
    (input: UpdateCampaignStatusInput) =>
      commitMutation<UpdateCampaignStatusMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateCampaignStatusMutation };
};
