import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { InvoiceList_Query } from "~/__relay_artifacts__/InvoiceList_Query.graphql";
import { InvoiceListTableCard } from "~/components/InvoiceListTableCard";
import { PageHeader } from "~/components/PageHeader";

const query = graphql`
  query InvoiceList_Query($projectId: ID!) {
    project(id: $projectId) {
      ...InvoiceListTableCard_project
    }
  }
`;

export const InvoiceList: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<InvoiceList_Query>(query, {
    projectId,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project) return <div>not found</div>;
  return (
    <>
      <PageHeader title="請求書" />
      <InvoiceListTableCard projectRef={props.project} />
    </>
  );
};
