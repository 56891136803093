import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateThompsonFilteringStrategyInput,
  UpdateThompsonFilteringStrategyMutation,
} from "~/__relay_artifacts__/UpdateThompsonFilteringStrategyMutation.graphql";

const mutation = graphql`
  mutation UpdateThompsonFilteringStrategyMutation(
    $input: UpdateThompsonFilteringStrategyInput!
  ) {
    updateThompsonFilteringStrategy(input: $input) {
      thompsonFilteringStrategy {
        id
        cpa
        cvr
        cpmCorrectionTerm
        ctrCorrectionTerm
        initialDistributionAlpha
        initialDistributionBeta
      }
    }
  }
`;

const commit = (
  environment: Environment,
  input: UpdateThompsonFilteringStrategyInput
) =>
  commitMutation<UpdateThompsonFilteringStrategyMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
