import {
  Button,
  FormControlLabel,
  Paper,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useGlobal } from "reactn";
import { useDebouncedCallback } from "use-debounce";

import { AdSlotListTableCard_root } from "~/__relay_artifacts__/AdSlotListTableCard_root.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { TruncatedTextWithTooltip } from "~/components/TruncatedTextWithTooltip";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { AdSlotCreateForm } from "~/containers/AdSlotCreateForm";
import { AdSlotEditForm } from "~/containers/AdSlotEditForm";
import { PricingCreateForm } from "~/containers/PricingCreateForm";
import { PricingEditForm } from "~/containers/PricingEditForm";
import { noop } from "~/lib/noop";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import { useQueryString } from "~/lib/useQueryString";
import deletePricingMutation from "~/mutations/DeletePricingMutation";
import { useRestoreAdSlotMutation } from "~/mutations/RestoreAdSlotMutation";

import { AdSlotFilterButton } from "./AdSlotFilterButton";
import { FormValues } from "./AdSlotFilterForm";
import { ArchiveButton } from "./ArchiveButton";

type Props = {
  relay: RelayPaginationProp;
  root: AdSlotListTableCard_root;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 100,
  },
  spacer: {
    flex: "1 1 10%",
  },
  actions: {
    display: "flex",
  },
  textField: {
    width: 300,
  },
}));

const useAdSlotFilter = (relay: Props["relay"], perPage: number) => {
  const { params, changeQueryString } = useQueryString<FormValues>();
  const [caches, setCaches] = useGlobal("adSlotFilterCache");
  const [formValues, setFormValues] = useState<FormValues>({
    ...caches,
    ...params,
  });

  const refetch = useCallback(
    (values: FormValues, search?: string, withArchived?: boolean) => {
      relay.refetchConnection(perPage, noop, {
        withDeleted: values.withDeleted,
        orderBy: { field: values.orderField, direction: values.orderDirection },
        domain: values.domain,
        bundle: values.bundle,
        width: values.width,
        height: values.height,
        sspProvider: values.sspProvider,
        platform: values.platform,
        logicType: values.logicType,
        adxBillingId: values.adxBillingId,
        search,
        withArchived,
      });
    },
    [perPage, relay]
  );

  const handleSubmit = useCallback(
    (values: FormValues, search?: string, withArchived?: boolean) => {
      setFormValues(values);
      setCaches(values);
      refetch(values, search, withArchived);
      search ?? changeQueryString(values);
    },
    [changeQueryString, refetch, setCaches]
  );

  return {
    formValues,
    handleSubmit,
  };
};

const AdSlotListTableCard: FC<Props> = ({ relay, root }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [search, setSearch] = useState("");
  const { formValues, handleSubmit } = useAdSlotFilter(relay, perPage);
  const { restoreAdSlotMutation } = useRestoreAdSlotMutation();
  const handleTextFieldSubmit = useDebouncedCallback(handleSubmit, 250);
  const [withArchived, setWithArchived] = useState(false);
  const environment = useRelayEnvironment();

  const adSlots = useMemo(() => {
    const edges = root.adSlots?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      const { id, role } = root.viewer;
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return {
        ...node,
        isEditable: role === "ADMIN" && id !== node.id,
      };
    });
  }, [page, perPage, root.adSlots, root.viewer]);

  const handleTextFieldChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => setSearch(e.target.value), []);

  const handleWithArchivedChange = useCallback(() => {
    setWithArchived(!withArchived);
    handleTextFieldSubmit(formValues, search, withArchived);
  }, [
    withArchived,
    setWithArchived,
    formValues,
    handleTextFieldSubmit,
    search,
  ]);

  const handleRestoreAdSlotButtunClick = useCallback(
    async (adSlotId: string) => {
      try {
        await restoreAdSlotMutation({ adSlotId });
        enqueueSnackbar("配信枠を復元しました", { variant: "success" });
      } catch (_) {
        enqueueSnackbar("配信枠の復元に失敗しました", { variant: "error" });
      }
    },
    [enqueueSnackbar, restoreAdSlotMutation]
  );

  const handleArchiveCompleted = useCallback(() => {
    handleTextFieldSubmit(formValues, search, withArchived);
  }, [formValues, handleTextFieldSubmit, search, withArchived]);

  const onDeletePricing = useCallback(
    async (pricingId: string) => {
      try {
        const { deletePricing } = await deletePricingMutation(environment, {
          pricingId,
        });
        const deletedPricingId =
          deletePricing && deletePricing.deletedPricingId;
        if (!deletedPricingId) throw new Error("assertion failed");
        enqueueSnackbar("入札設定を削除しました", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, environment]
  );

  useEffect(() => {
    handleTextFieldSubmit(formValues, search, withArchived);
  }, [formValues, handleTextFieldSubmit, search, withArchived]);

  useEffect(() => {
    relay.loadMore(perPage, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const connectionId = root.adSlots.__id;

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          配信枠一覧
        </Typography>
        <DialogButton
          title="広告枠作成"
          render={({ close }) => (
            <AdSlotCreateForm
              onSubmitCompleted={close}
              connectionId={connectionId}
            />
          )}
        >
          作成
        </DialogButton>
        <FormControlLabel
          label="アーカイブ済みデータを含める"
          control={
            <Switch
              checked={withArchived}
              onChange={handleWithArchivedChange}
            />
          }
        />
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <TextField
            value={search}
            className={classes.textField}
            placeholder="Tag ID or 名前で検索"
            onChange={handleTextFieldChange}
          />
          <AdSlotFilterButton formValues={formValues} onSubmit={handleSubmit} />
        </div>
      </Toolbar>
      <ListTable minWidth={2000}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>プロバイダ</TableCell>
            <TableCell>タグID</TableCell>
            <TableCell>プレイスメント</TableCell>
            <TableCell>プラットフォーム</TableCell>
            <TableCell>OS</TableCell>
            <TableCell>入札方式</TableCell>
            <TableCell>バンドル</TableCell>
            <TableCell>ドメイン</TableCell>
            <TableCell>サイズ</TableCell>
            <TableCell>ADX BILLING ID</TableCell>
            <TableCell>目標勝率</TableCell>
            <TableCell>メタ情報</TableCell>
            <TableCell>メモ</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adSlots.map((adSlot) => (
            <TableRow key={adSlot.id} selected={!!adSlot.deletedAt}>
              <TableCell>{atob(adSlot.id).replace("AdSlot-", "")}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip tooltip={adSlot.name || "未設定"}>
                  {adSlot.name}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>{adSlot.sspProvider}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip tooltip={adSlot.tagId || "未設定"}>
                  {adSlot.tagId}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>
                <Link to={`ad_slots/${adSlot.id}/placements`}>
                  {adSlot?.placements.totalCount || "0"}件
                </Link>
              </TableCell>
              <TableCell>{adSlot.platform || "未設定"}</TableCell>
              <TableCell>{adSlot.os?.join("/") || "未設定"}</TableCell>
              <TableCell>
                {adSlot.pricing ? (
                  <>
                    <DialogButton
                      title="入札設定の編集"
                      render={({ close }) => (
                        <PricingEditForm
                          pricingRef={adSlot.pricing!}
                          onSubmitCompleted={close}
                        />
                      )}
                    >
                      {adSlot.pricing.logicType}
                    </DialogButton>
                    <ConfirmButton
                      variant="outlined"
                      color="inherit"
                      confirmTitle="この入札設定を削除しますか？"
                      onAgree={() => onDeletePricing(adSlot.pricing!.id)}
                    >
                      削除
                    </ConfirmButton>
                  </>
                ) : (
                  <DialogButton
                    title="トラッキングURL設定作成"
                    render={({ close }) => (
                      <PricingCreateForm
                        adSlotId={adSlot.id}
                        onSubmitCompleted={() => {
                          close();
                        }}
                      />
                    )}
                  >
                    作成
                  </DialogButton>
                )}
              </TableCell>
              <TableCell>{adSlot.bundle || "未設定"}</TableCell>
              <TableCell>{adSlot.domain}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip
                  tooltip={"banner.format (h,w) : " + adSlot.bannerFormat || ""}
                >
                  {adSlot.width || "未設定"}, {adSlot.height || "未設定"}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>{adSlot.adxBillingId || "未設定"}</TableCell>
              <TableCell>{adSlot.targetWinRate * 100}%</TableCell>
              <TableCell>
                <DialogButton
                  minWidth={600}
                  title="メタ情報"
                  variant="outlined"
                  disabled={!adSlot.metadata}
                  render={() => (
                    <SyntaxHighlighter
                      language="json"
                      style={atomOneLight}
                      customStyle={{ width: 600, fontSize: 14 }}
                      showLineNumbers
                    >
                      {adSlot.metadata}
                    </SyntaxHighlighter>
                  )}
                >
                  表示
                </DialogButton>
              </TableCell>
              <TableCell>
                <TruncatedTextWithTooltip tooltip={adSlot.note || "メモ無し"}>
                  {adSlot.note}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>
                {adSlot.isEditable && !adSlot.deletedAt && (
                  <>
                    <DialogButton
                      title="広告枠編集"
                      variant="outlined"
                      color="primary"
                      render={({ close }) => (
                        <AdSlotEditForm
                          adSlotRef={adSlot}
                          onSubmitCompleted={close}
                        />
                      )}
                    >
                      編集
                    </DialogButton>
                    <ArchiveButton
                      adSlot={adSlot}
                      onSubmitCompleted={handleArchiveCompleted}
                    />
                  </>
                )}
                {adSlot.isEditable && !!adSlot.deletedAt && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => handleRestoreAdSlotButtunClick(adSlot.id)}
                  >
                    復元
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={root.adSlots?.totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(event) => setPerPage(Number(event.target.value))}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  AdSlotListTableCard,
  {
    root: graphql`
      fragment AdSlotListTableCard_root on Query {
        viewer {
          id
          role
          email
        }
        adSlots(
          first: $count
          after: $cursor
          withDeleted: $withDeleted
          orderBy: $orderBy
          domain: $domain
          bundle: $bundle
          width: $width
          height: $height
          sspProvider: $sspProvider
          platform: $platform
          logicType: $logicType
          adxBillingId: $adxBillingId
          search: $search
          withArchived: $withArchived
        ) @connection(key: "AdSlotListTableCard_adSlots", filters: []) {
          __id
          edges {
            node {
              id
              name
              domain
              tagId
              bundle
              note
              width
              height
              sspProvider
              platform
              bannerFormat
              os
              metadata
              deletedAt
              archived
              pricing {
                id
                logicType
                manualBidPrice
                ...PricingEditForm_pricing
              }
              placements {
                totalCount
              }
              adxBillingId
              targetWinRate
              ...AdSlotEditForm_adSlot
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps: (props) => props?.root.adSlots,
    getVariables: (_, { count, cursor }) => ({ count, cursor }),
    query: graphql`
      query AdSlotListTableCard_Query(
        $count: Int
        $cursor: String
        $withDeleted: Boolean!
        $orderBy: AdSlotOrder!
        $domain: String
        $bundle: String
        $width: Int
        $height: Int
        $sspProvider: [AdSlotSspProvider!]!
        $platform: [AdSlotPlatform!]!
        $logicType: [PricingLogic!]!
        $adxBillingId: String
        $search: String
        $withArchived: Boolean
      ) {
        ...AdSlotListTableCard_root
      }
    `,
  }
);
