import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FormikTextField } from "@vrize/vrizead-use";
import { Formik, useField } from "formik";
import * as R from "ramda";
import { FC, useCallback, useMemo } from "react";
import * as yup from "yup";

import { DialogChipInput } from "~/components/DialogChipInput";
import { noop } from "~/lib/noop";

type Props = {
  name: string;
};

type Value = { key: string; value: string }[];

const schema = yup.object({
  key: yup.string().required(),
  value: yup.string().required(),
});

const initialValues = {
  key: "",
  value: "",
};

const StyledDialogContent = styled(DialogContent)({
  minWidth: 500,
});

export const ParametersChipForm: FC<Props> = ({ name }) => {
  const [{ value = [] }, , { setValue }] = useField<Value>(name);

  const formattedClick = useMemo(
    () =>
      value.map((v) => {
        const key = `key: ${v.key}`;
        const value = `value: ${v.value}`;
        return [key, value].join(", ");
      }),
    [value]
  );

  const handleChipInputDelete = useCallback(
    (i: number) => {
      setValue(R.remove(i, 1, value));
    },
    [setValue, value]
  );

  const handleFormikSubmit = useCallback(
    (formValues: Value[0], index: number | undefined) => {
      setValue(
        !R.isNil(index)
          ? R.update(index, formValues, value)
          : [...value, formValues]
      );
    },
    [setValue, value]
  );

  return (
    <DialogChipInput
      label="parameters"
      value={formattedClick}
      onDelete={handleChipInputDelete}
    >
      {({ close, index }) => (
        <Formik
          initialValues={!R.isNil(index) ? value[index] : initialValues}
          schema={schema}
          onSubmit={noop}
        >
          {({ values }) => (
            <>
              <DialogTitle>パラメーター編集</DialogTitle>
              <StyledDialogContent>
                <FormikTextField
                  required
                  fullWidth
                  margin="normal"
                  name="key"
                  label="キー"
                />
                <FormikTextField
                  required
                  fullWidth
                  margin="normal"
                  name="value"
                  label="値"
                />
              </StyledDialogContent>
              <DialogActions>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleFormikSubmit(values, index);
                    close();
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      )}
    </DialogChipInput>
  );
};
