import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateAdSlotInput,
  CreateAdSlotMutation,
} from "~/__relay_artifacts__/CreateAdSlotMutation.graphql";

const mutation = graphql`
  mutation CreateAdSlotMutation(
    $connections: [ID!]!
    $input: CreateAdSlotInput!
  ) {
    createAdSlot(input: $input) {
      adSlotEdge @appendEdge(connections: $connections) {
        node {
          id
          name
          domain
          tagId
          sspProvider
          width
          height
          bundle
          platform
          adxBillingId
          targetWinRate
          placements {
            totalCount
          }
          bannerFormat
          deletedAt
          note
          ...AdSlotEditForm_adSlot
        }
      }
    }
  }
`;

const commit = (
  environment: Environment,
  input: CreateAdSlotInput,
  connectionId: string
) =>
  commitMutation<CreateAdSlotMutation>(environment, {
    mutation,
    variables: {
      input,
      connections: [connectionId],
    },
  });

export default commit;
