import { Button, DialogActions, DialogContent } from "@material-ui/core";
import * as React from "react";

import { CreativePreview } from "~/components/CreativePreview";
import { ResponsiveDialog } from "~/components/ResponsiveDialog";

type Props = {
  open: boolean;
  html: string;
  height: number;
  width: number;
  onClose: () => void;
};

export const CreativePreviewDialog: React.FC<Props> = ({
  open,
  html,
  height,
  width,
  onClose,
}) => {
  const [playing, setPlaying] = React.useState(true);

  const handleButtonClick = React.useCallback(() => {
    setPlaying(false);
    setTimeout(() => setPlaying(true), 0);
  }, []);

  return (
    <ResponsiveDialog
      title="クリエイティブプレビュー"
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <CreativePreview
          html={html}
          height={height}
          width={width}
          playing={playing}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleButtonClick}
        >
          再生
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
};
