import { Button, CardActions } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";

type FormValues = {
  email: string;
};

type Props = FormikProps<FormValues>;

export const AccountEmailForm: React.FC<Props> = ({ isSubmitting }) => (
  <Form>
    <FormikTextField fullWidth name="email" label="Email" margin="normal" />
    <CardActions className="md-divider-border md-divider-border--top">
      <Button
        fullWidth
        variant="outlined"
        type="submit"
        disabled={isSubmitting}
      >
        保存
      </Button>
    </CardActions>
  </Form>
);
