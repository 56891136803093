import { Grid } from "@material-ui/core";
import { FormikSwitchField } from "@vrize/vrizead-use";
import { Formik } from "formik";
import * as React from "react";

import { DialogForm } from "~/components/DialogForm";

export type FormValues = {
  showIsEnabledOnly: boolean;
};

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

export const PlacementFilterForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
}) => (
  <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
    <DialogForm>
      <Grid container spacing={3}>
        <FormikSwitchField
          name="showIsEnabledOnly"
          label="配信ステータスがオンのデータのみ表示"
        />
      </Grid>
    </DialogForm>
  </Formik>
);
