import { Grid } from "@material-ui/core";
import { FormikSelectField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  role: "ADMIN" | "ADVERTISER" | "GUEST";
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  role: yup.string().oneOf(["ADMIN", "ADVERTISER", "GUEST"]).required(),
});

const roleOptions = [
  { value: "ADMIN", label: "管理者" },
  { value: "ADVERTISER", label: "広告主" },
  { value: "GUEST", label: "ゲスト" },
];

export const MembershipRoleForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikSelectField
          name="role"
          label="役割"
          required
          fullWidth
          options={roleOptions}
        />
      </Grid>
    </Grid>
  </DialogForm>
);
