import {
  ConnectionHandler,
  RecordProxy,
  RecordSourceSelectorProxy,
} from "relay-runtime";

export type ConnectionUpdaterOptions = {
  parentId: string;
  connectionName: string;
};

export const sharedDeleteUpdater = (
  itemId: string,
  store: RecordSourceSelectorProxy,
  { parentId, connectionName }: ConnectionUpdaterOptions
) => {
  const proxy = store.get(parentId)!;
  const conn = ConnectionHandler.getConnection(proxy, connectionName);
  if (!conn) return;
  ConnectionHandler.deleteNode(conn, itemId);
};

export const sharedCreateUpdater = (
  store: RecordSourceSelectorProxy,
  itemType: string,
  node: RecordProxy,
  { parentId, connectionName }: ConnectionUpdaterOptions
) => {
  const proxy = store.get(parentId)!;
  const connection = ConnectionHandler.getConnection(proxy, connectionName)!;
  const newEdge = ConnectionHandler.createEdge(
    store,
    connection,
    node,
    `${itemType}Edge`
  );
  ConnectionHandler.insertEdgeBefore(connection, newEdge);
};
