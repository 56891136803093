/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type Position = "INLINE" | "OVERLAY" | "UNKNOWN" | "%future added value";
export type CreativeEditForm_creative = {
    readonly id: string;
    readonly title: string;
    readonly adm: string | null;
    readonly width: number | null;
    readonly height: number | null;
    readonly iurl: string | null;
    readonly tagList: ReadonlyArray<string> | null;
    readonly note: string | null;
    readonly periodTill: string | null;
    readonly periodSince: string | null;
    readonly metaPosition: Position | null;
    readonly metaOs: Os | null;
    readonly metaAdmVersion: string | null;
    readonly archived: boolean;
    readonly latestVersion: number | null;
    readonly " $refType": "CreativeEditForm_creative";
};
export type CreativeEditForm_creative$data = CreativeEditForm_creative;
export type CreativeEditForm_creative$key = {
    readonly " $data"?: CreativeEditForm_creative$data;
    readonly " $fragmentRefs": FragmentRefs<"CreativeEditForm_creative">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreativeEditForm_creative",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iurl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tagList",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodTill",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metaPosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metaOs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metaAdmVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "Creative",
  "abstractKey": null
};
(node as any).hash = '7ce6be8cdf32b15258f7f6f01c252090';
export default node;
