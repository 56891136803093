import { Badge, Tooltip } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import * as R from "ramda";
import * as React from "react";

import { DialogButton } from "~/components/DialogButton";

import { FormValues, PlacementFilterForm } from "./PlacementFilterForm";
import { filterInitialValues } from "./constants";

export type Props = {
  formValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

const StyledSmallSearchIcon = styled(SearchIcon)({
  fontSize: 20,
});

export const PlacementFilterButton: React.FC<Props> = ({
  formValues,
  onSubmit,
}) => {
  const isFilteredByKey = React.useMemo(() => {
    const keys = Object.keys(filterInitialValues);
    return keys.reduce(
      (acum, key) => ({
        ...acum,
        // @ts-ignore
        [key]: !R.equals(filterInitialValues[key], formValues[key]),
      }),
      {} as { [_: string]: boolean }
    );
  }, [formValues]);

  const isFiltered = React.useMemo(() => {
    const keys = Object.keys(filterInitialValues);
    return keys.filter((key) => isFilteredByKey[key]).length > 0;
  }, [isFilteredByKey]);

  return (
    <Tooltip
      disableHoverListener={!isFiltered}
      title={
        <>
          {isFilteredByKey.showIsEnabledOnly && (
            <p>配信ステータスがオンのデータのみ表示</p>
          )}
        </>
      }
    >
      <DialogButton
        title="フィルター"
        render={({ close }) => (
          <PlacementFilterForm
            initialValues={formValues}
            onSubmit={(values) => {
              onSubmit(values);
              close();
            }}
          />
        )}
      >
        <Badge color="secondary" variant="dot" invisible={!isFiltered}>
          <StyledSmallSearchIcon />
        </Badge>
      </DialogButton>
    </Tooltip>
  );
};
