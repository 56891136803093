/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PromotionEditForm_promotion = {
    readonly id: string;
    readonly title: string;
    readonly " $refType": "PromotionEditForm_promotion";
};
export type PromotionEditForm_promotion$data = PromotionEditForm_promotion;
export type PromotionEditForm_promotion$key = {
    readonly " $data"?: PromotionEditForm_promotion$data;
    readonly " $fragmentRefs": FragmentRefs<"PromotionEditForm_promotion">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromotionEditForm_promotion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Promotion",
  "abstractKey": null
};
(node as any).hash = '2e0376150a5667b09b477b3365929aaa';
export default node;
