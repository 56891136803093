import { Divider, List } from "@material-ui/core";
import { useOpenState } from "@vrize/vrizead-use";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";

import { PlacementSettingFormList_QueryResponse } from "~/__relay_artifacts__/PlacementSettingFormList_Query.graphql";
import { PlacementSettingListTableCard_placementSet } from "~/__relay_artifacts__/PlacementSettingListTableCard_placementSet.graphql";
import { AdSlotListItem } from "~/components/AdSlotListItem";
import { ResponsiveDialog } from "~/components/ResponsiveDialog";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createPlacementSettingMutation from "~/mutations/CreatePlacementSettingMutation";

import { PlacementSettingParameterForm } from "./PlacementSettingParameterForm";

type Props = {
  placementSettingRef: PlacementSettingListTableCard_placementSet["placementSettings"];
  adSlotsRef: PlacementSettingFormList_QueryResponse;
};

export const Query = graphql`
  query PlacementSettingFormList_Query(
    $count: Int
    $cursor: String
    $withDeleted: Boolean!
    $orderBy: AdSlotOrder!
    $domain: String
    $bundle: String
    $width: Int
    $height: Int
    $sspProvider: [AdSlotSspProvider!]!
    $platform: [AdSlotPlatform!]!
    $logicType: [PricingLogic!]!
    $adxBillingId: String
    $search: String
    $withArchived: Boolean
  ) {
    adSlots(
      first: $count
      after: $cursor
      withDeleted: $withDeleted
      orderBy: $orderBy
      domain: $domain
      bundle: $bundle
      width: $width
      height: $height
      sspProvider: $sspProvider
      platform: $platform
      logicType: $logicType
      adxBillingId: $adxBillingId
      search: $search
      withArchived: $withArchived
    ) {
      edges {
        node {
          id
          name
          sspProvider
          tagId
          platform
          bundle
          width
          height
          adxBillingId
          targetWinRate
        }
      }
    }
  }
`;

export const PlacementSettingFormList: React.FC<Props> = ({
  adSlotsRef,
  placementSettingRef,
}) => {
  const environment = useRelayEnvironment();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, openDialog, closeDialog] = useOpenState();
  const [selectedAdSlotId, setSelectedAdSlotId] = React.useState<string | null>(
    null
  );
  const { placementSetId } = useParams<{ placementSetId: string }>();

  const placementSettings = React.useMemo(() => {
    const edges = (placementSettingRef && placementSettingRef.edges) || [];
    return edges.map((edge) => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [placementSettingRef]);

  const adSlots = React.useMemo(() => {
    const edges = adSlotsRef.adSlots?.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return {
        ...node,
        selected: !!placementSettings.find((ps) => ps.adSlot.id === node.id),
      };
    });
  }, [adSlotsRef.adSlots, placementSettings]);

  const selectedAdSlot = React.useMemo(
    () => adSlots.find((adSlot) => adSlot.id === selectedAdSlotId),
    [adSlots, selectedAdSlotId]
  );

  const handleCreatePlacementSetting = React.useCallback(
    async (adSlotId: string) => {
      try {
        const { createPlacementSetting } = await createPlacementSettingMutation(
          environment,
          {
            placementSetId: placementSetId,
            adSlotId: adSlotId,
          }
        );
        if (!createPlacementSetting?.placementSettingEdge) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("プレイスメントセットにAdSlotを追加しました", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar("プレイスメントセットへのAdSlotの追加に失敗しました", {
          variant: err,
        });
      }
    },
    [enqueueSnackbar, environment, placementSetId]
  );

  const handleAdSlotAddClick = React.useCallback(
    (id: string) => {
      setSelectedAdSlotId(id);
      const adSlot = adSlots.find((adSlot) => adSlot.id === id);
      if (adSlot?.sspProvider === "VRIZEAD") {
        handleCreatePlacementSetting(adSlot.id);
        return;
      }
      openDialog();
    },
    [openDialog, adSlots, handleCreatePlacementSetting]
  );

  return (
    <List>
      {adSlots.map((adSlot) => (
        <>
          <AdSlotListItem
            key={adSlot.id}
            adSlotId={adSlot.id}
            adSlotName={adSlot.name || "未設定"}
            sspProvider={adSlot.sspProvider}
            tagId={adSlot.tagId || "未設定"}
            platform={adSlot.platform || "未設定"}
            adxBillingId={adSlot.adxBillingId || "未設定"}
            bundle={adSlot.bundle || "未設定"}
            width={String(adSlot.width) || "未設定"}
            height={String(adSlot.height) || "未設定"}
            selected={adSlot.selected}
            onAddClick={handleAdSlotAddClick}
          />
          <Divider />
        </>
      ))}
      <ResponsiveDialog
        open={dialogOpen}
        title={`${selectedAdSlot?.name}を追加しますか？`}
        onClose={closeDialog}
      >
        {selectedAdSlot && (
          <PlacementSettingParameterForm
            placementSetId={placementSetId}
            adSlotId={selectedAdSlot.id}
            sspProvider={selectedAdSlot.sspProvider}
            onSubmitCompleted={() => {
              closeDialog();
            }}
          />
        )}
      </ResponsiveDialog>
    </List>
  );
};
