import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { CopyCreativeMutation } from "~/__relay_artifacts__/CopyCreativeMutation.graphql";

const mutation = graphql`
  mutation CopyCreativeMutation($input: CopyCreativeInput!) {
    copyCreative(input: $input) {
      creativeEdge {
        node {
          id
          title
          adm
          height
          width
          createdAt
          updatedAt
          sdkVersion
          periodTill
          periodSince
          ...CreativeEditForm_creative
        }
      }
    }
  }
`;

const commit = (
  environment: Environment,
  projectId: string,
  creativeId: string,
  height: number
) =>
  commitMutation<CopyCreativeMutation>(environment, {
    mutation,
    variables: {
      input: {
        creativeId,
        height,
      },
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: projectId,
        connectionInfo: [
          {
            key: "CreativeListTableCard_creatives",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "creativeEdge",
      },
    ],
  });

export default commit;
