import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";
import { useDebounce } from "use-debounce";

import { BlockList_Query } from "~/__relay_artifacts__/BlockList_Query.graphql";
import { BlockList_root } from "~/__relay_artifacts__/BlockList_root.graphql";
import { containsInsensitive } from "~/lib/containsInsensitive";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import createBlockMutation from "~/mutations/CreateBlockMutation";
import deleteBlockMutation from "~/mutations/DeleteBlockMutation";

import { ListTable } from "./ListTable";

type Props = {
  blockRef: NonNullable<BlockList_Query["response"]>;
};

const blockFragment = graphql`
  fragment BlockList_root on Query {
    blocks(first: 150) @connection(key: "BlockList_blocks") {
      edges {
        node {
          id
          domain
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

export const BlockList: React.FC<Props> = ({ blockRef }) => {
  const classes = useStyles();
  const [alertDialogOpen, openAlertDialog, closeAlertDialog] = useOpenState();
  const [formDialogOpen, openFormDialog, closeFormDialog] = useOpenState();
  const fragmentBlocks = useFragment<BlockList_root>(blockFragment, blockRef);
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [domainValidation, setDomainValidation] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [domainText, setDomainText] = React.useState("");
  const [deleteId, setDeleteId] = React.useState("");

  const blocks = React.useMemo(() => {
    const edges = fragmentBlocks.blocks?.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return {
        id: node.id,
        domain: node.domain,
      };
    });
  }, [fragmentBlocks.blocks]);

  const [filteredBlocks] = useDebounce(
    blocks.filter(({ domain }) => containsInsensitive(searchText, domain)),
    250
  );

  const handleDeleteBlock = React.useCallback(
    async (blockId: string) => {
      try {
        await deleteBlockMutation(environment, blockId);
        enqueueSnackbar("ブロックリストから削除しました", {
          variant: "success",
        });
        closeAlertDialog();
        setDeleteId("");
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, environment, closeAlertDialog]
  );

  const handleCreateBlock = React.useCallback(
    async (domain: string) => {
      try {
        await createBlockMutation(environment, { domain });
        enqueueSnackbar("ブロックリストに追加しました", { variant: "success" });
        closeFormDialog();
        setDomainText("");
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, environment, closeFormDialog]
  );

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setSearchText(e.target.value);
  }, []);

  const handleDomainTextChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      setDomainText(e.target.value);
      if (domainText !== "") setDomainValidation(false);
    },
    [domainText]
  );

  const handleAddButtonClick = React.useCallback(() => {
    domainText === ""
      ? setDomainValidation(!domainValidation)
      : handleCreateBlock(domainText);
  }, [domainText, domainValidation, handleCreateBlock]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          ブロックリストの一覧
        </Typography>
        <Button onClick={openFormDialog}>追加</Button>
      </Toolbar>
      <Toolbar>
        <TextField
          fullWidth
          label="絞り込み検索"
          value={searchText}
          onChange={handleTextFieldChange}
        />
      </Toolbar>
      <ListTable minWidth={500}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>ドメイン</TableCell>
            <TableCell align="center">アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBlocks.map((block) => (
            <TableRow key={block.id}>
              <TableCell>{atob(block.id)}</TableCell>
              <TableCell>{block.domain}</TableCell>
              <TableCell align="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    openAlertDialog();
                    setDeleteId(block.id);
                  }}
                >
                  削除
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <Dialog open={alertDialogOpen} onClose={closeAlertDialog}>
        <DialogTitle>ブロックリストから削除しますか？</DialogTitle>
        <DialogActions>
          <Button autoFocus color="primary" onClick={closeAlertDialog}>
            キャンセル
          </Button>
          <Button color="secondary" onClick={() => handleDeleteBlock(deleteId)}>
            削除
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={formDialogOpen} onClose={closeFormDialog}>
        <DialogTitle>ブロックリストへの追加</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="ドメイン"
            value={domainText}
            error={domainValidation}
            onChange={handleDomainTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleAddButtonClick}>
            追加
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
