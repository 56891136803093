/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateExcludeScheduleInput = {
    clientMutationId?: string | null;
    campaignId: string;
    name: string;
    since: string;
    till: string;
};
export type CreateExcludeScheduleMutationVariables = {
    input: CreateExcludeScheduleInput;
};
export type CreateExcludeScheduleMutationResponse = {
    readonly createExcludeSchedule: {
        readonly excludeScheduleEdge: {
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly since: string;
                readonly till: string;
            } | null;
        } | null;
    } | null;
};
export type CreateExcludeScheduleMutation = {
    readonly response: CreateExcludeScheduleMutationResponse;
    readonly variables: CreateExcludeScheduleMutationVariables;
};



/*
mutation CreateExcludeScheduleMutation(
  $input: CreateExcludeScheduleInput!
) {
  createExcludeSchedule(input: $input) {
    excludeScheduleEdge {
      node {
        id
        name
        since
        till
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateExcludeSchedulePayload",
    "kind": "LinkedField",
    "name": "createExcludeSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExcludeScheduleEdge",
        "kind": "LinkedField",
        "name": "excludeScheduleEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExcludeSchedule",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "since",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "till",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateExcludeScheduleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateExcludeScheduleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41add346120aeca1f375fcdb7875cacd",
    "id": null,
    "metadata": {},
    "name": "CreateExcludeScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation CreateExcludeScheduleMutation(\n  $input: CreateExcludeScheduleInput!\n) {\n  createExcludeSchedule(input: $input) {\n    excludeScheduleEdge {\n      node {\n        id\n        name\n        since\n        till\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '39211c5dbfde1b8ea51f9c3b7ebd2b48';
export default node;
