import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import {
  FormValues,
  PlacementSetSelectForm,
} from "~/components/PlacementSetSelectForm";
import { useAddPlacementSetMutation } from "~/mutations/AddPlacementSetMutation";

type Props = {
  isEnabled: boolean | undefined;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  placementSetId: "",
};

export const PlacementSetAddForm: React.FC<Props> = ({
  isEnabled,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { id } = useParams<{ id: string }>();
  const { addPlacementSetMutation } = useAddPlacementSetMutation(isEnabled);

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        await addPlacementSetMutation({
          adId: id,
          placementSetId: values.placementSetId,
        });
        enqueueSnackbar("追加しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      addPlacementSetMutation,
      enqueueSnackbar,
      formErrorHandler,
      id,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={PlacementSetSelectForm}
    />
  );
};
