/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreativeDetail_QueryVariables = {
    projectId: string;
    creativeId: string;
};
export type CreativeDetail_QueryResponse = {
    readonly project: {
        readonly creative: {
            readonly " $fragmentRefs": FragmentRefs<"CreativeDetail_creative">;
        } | null;
    } | null;
};
export type CreativeDetail_Query = {
    readonly response: CreativeDetail_QueryResponse;
    readonly variables: CreativeDetail_QueryVariables;
};



/*
query CreativeDetail_Query(
  $projectId: ID!
  $creativeId: ID!
) {
  project(id: $projectId) {
    creative(id: $creativeId) {
      ...CreativeDetail_creative
      id
    }
    id
  }
}

fragment AdxSubmitForm_creative on Creative {
  id
  width
  height
}

fragment CreativeDetail_creative on Creative {
  id
  title
  adm
  height
  width
  createdAt
  updatedAt
  sdkVersion
  note
  adxStatus
  adxSubmittedAt
  project {
    ...CreativeSetting_project
    id
  }
  ...CreativeEditForm_creative
}

fragment CreativeEditForm_creative on Creative {
  id
  title
  adm
  width
  height
  iurl
  tagList
  note
  periodTill
  periodSince
  metaPosition
  metaOs
  metaAdmVersion
  archived
  latestVersion
}

fragment CreativeSetting_project on Project {
  trackers {
    edges {
      node {
        id
        title
        associationType
      }
    }
  }
  creative(id: $creativeId) {
    id
    title
    createdAt
    adxStatus
    adxSubmittedAt
    tracker {
      id
      title
      associationType
    }
    ...AdxSubmitForm_creative
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creativeId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "creativeId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adxStatus",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adxSubmittedAt",
  "storageKey": null
},
v11 = [
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "associationType",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativeDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Creative",
            "kind": "LinkedField",
            "name": "creative",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreativeDetail_creative"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreativeDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Creative",
            "kind": "LinkedField",
            "name": "creative",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adm",
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sdkVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrackerConnection",
                    "kind": "LinkedField",
                    "name": "trackers",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrackerEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tracker",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "Creative",
                    "kind": "LinkedField",
                    "name": "creative",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tracker",
                        "kind": "LinkedField",
                        "name": "tracker",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iurl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagList",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodTill",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodSince",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaOs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaAdmVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latestVersion",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2eeccd2035bfad05e1fac005ca9ca860",
    "id": null,
    "metadata": {},
    "name": "CreativeDetail_Query",
    "operationKind": "query",
    "text": "query CreativeDetail_Query(\n  $projectId: ID!\n  $creativeId: ID!\n) {\n  project(id: $projectId) {\n    creative(id: $creativeId) {\n      ...CreativeDetail_creative\n      id\n    }\n    id\n  }\n}\n\nfragment AdxSubmitForm_creative on Creative {\n  id\n  width\n  height\n}\n\nfragment CreativeDetail_creative on Creative {\n  id\n  title\n  adm\n  height\n  width\n  createdAt\n  updatedAt\n  sdkVersion\n  note\n  adxStatus\n  adxSubmittedAt\n  project {\n    ...CreativeSetting_project\n    id\n  }\n  ...CreativeEditForm_creative\n}\n\nfragment CreativeEditForm_creative on Creative {\n  id\n  title\n  adm\n  width\n  height\n  iurl\n  tagList\n  note\n  periodTill\n  periodSince\n  metaPosition\n  metaOs\n  metaAdmVersion\n  archived\n  latestVersion\n}\n\nfragment CreativeSetting_project on Project {\n  trackers {\n    edges {\n      node {\n        id\n        title\n        associationType\n      }\n    }\n  }\n  creative(id: $creativeId) {\n    id\n    title\n    createdAt\n    adxStatus\n    adxSubmittedAt\n    tracker {\n      id\n      title\n      associationType\n    }\n    ...AdxSubmitForm_creative\n  }\n}\n"
  }
};
})();
(node as any).hash = '2af9c4a68203cbdebc1827e874514fbb';
export default node;
