/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdCategory = "COMIC_BOOKS" | "DRAWING_SKETCHING" | "VIDEO_COMPUTER_GAMES" | "%future added value";
export type AdPlatform = "ALL" | "APP" | "SITE" | "%future added value";
export type AdSspProvider = "ADSTIR" | "ADX" | "ALL" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type CreateAdInput = {
    clientMutationId?: string | null;
    campaignId: string;
    title: string;
    category?: Array<AdCategory> | null;
    isRotatable: boolean;
    adomain?: string | null;
    ignoreIdfa: boolean;
    platform?: AdPlatform | null;
    sspProvider?: AdSspProvider | null;
    width?: number | null;
    height?: number | null;
};
export type CreateAdMutationVariables = {
    input: CreateAdInput;
};
export type CreateAdMutationResponse = {
    readonly createAd: {
        readonly adEdge: {
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly adomain: string | null;
                readonly status: CampaignStatus;
                readonly createdAt: string;
                readonly placements: {
                    readonly totalCount: number;
                };
                readonly " $fragmentRefs": FragmentRefs<"AdEditForm_ad">;
            } | null;
        } | null;
    } | null;
};
export type CreateAdMutation = {
    readonly response: CreateAdMutationResponse;
    readonly variables: CreateAdMutationVariables;
};



/*
mutation CreateAdMutation(
  $input: CreateAdInput!
) {
  createAd(input: $input) {
    adEdge {
      node {
        id
        title
        adomain
        status
        createdAt
        placements {
          totalCount
        }
        ...AdEditForm_ad
      }
    }
  }
}

fragment AdEditForm_ad on Ad {
  id
  title
  adomain
  categories
  isRotatable
  ignoreIdfa
  width
  height
  sspProvider
  platform
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adomain",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PlacementConnection",
  "kind": "LinkedField",
  "name": "placements",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAdPayload",
        "kind": "LinkedField",
        "name": "createAd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdEdge",
            "kind": "LinkedField",
            "name": "adEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Ad",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AdEditForm_ad"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAdPayload",
        "kind": "LinkedField",
        "name": "createAd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdEdge",
            "kind": "LinkedField",
            "name": "adEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Ad",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "categories",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRotatable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ignoreIdfa",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sspProvider",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platform",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c74e2532018a0457ff4fe7d317805455",
    "id": null,
    "metadata": {},
    "name": "CreateAdMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAdMutation(\n  $input: CreateAdInput!\n) {\n  createAd(input: $input) {\n    adEdge {\n      node {\n        id\n        title\n        adomain\n        status\n        createdAt\n        placements {\n          totalCount\n        }\n        ...AdEditForm_ad\n      }\n    }\n  }\n}\n\nfragment AdEditForm_ad on Ad {\n  id\n  title\n  adomain\n  categories\n  isRotatable\n  ignoreIdfa\n  width\n  height\n  sspProvider\n  platform\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = '7371354cc483774fc2fdb8ef71a7eb89';
export default node;
