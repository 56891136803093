import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import { CreativeForm, FormValues, schema } from "~/components/CreativeForm";
import { useCreateCreativeMutation } from "~/mutations/CreateCreativeMutation";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues = {
  viewableValidationEnabled: true,
  metaOs: "ALL",
  metaAdmVersion: "0.0.1",
  metaPosition: "OVERLAY",
} as FormValues;

export const CreativeCreateForm: React.FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { projectId } = useParams<{ projectId: string; id: string }>();
  const { createCreativeMutation } = useCreateCreativeMutation();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      if (!values.adm || !values.title) {
        throw new Error("assertion failed.");
      }
      try {
        const { createCreative } = await createCreativeMutation({
          projectId,
          ...values,
        });
        if (!createCreative?.creativeEdge) throw new Error("assertion failed");
        enqueueSnackbar("クリエイティブを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      createCreativeMutation,
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      projectId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={CreativeForm}
    />
  );
};
