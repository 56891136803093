/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type ScheduleSetAdListButton_scheduleSet = {
    readonly id: string;
    readonly ads: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly status: CampaignStatus;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "ScheduleSetAdListButton_scheduleSet";
};
export type ScheduleSetAdListButton_scheduleSet$data = ScheduleSetAdListButton_scheduleSet;
export type ScheduleSetAdListButton_scheduleSet$key = {
    readonly " $data"?: ScheduleSetAdListButton_scheduleSet$data;
    readonly " $fragmentRefs": FragmentRefs<"ScheduleSetAdListButton_scheduleSet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleSetAdListButton_scheduleSet",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AdConnection",
      "kind": "LinkedField",
      "name": "ads",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Ad",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleSet",
  "abstractKey": null
};
})();
(node as any).hash = 'f6a9ac1648e6f688ca870c6ba4c0222b';
export default node;
