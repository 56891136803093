/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteCreativeTrackerSettingInput = {
    clientMutationId?: string | null;
    creativeId: string;
};
export type DeleteCreativeTrackerSettingMutationVariables = {
    input: DeleteCreativeTrackerSettingInput;
};
export type DeleteCreativeTrackerSettingMutationResponse = {
    readonly deleteCreativeTrackerSetting: {
        readonly creative: {
            readonly id: string;
            readonly tracker: {
                readonly id: string;
                readonly title: string;
            } | null;
        } | null;
    } | null;
};
export type DeleteCreativeTrackerSettingMutation = {
    readonly response: DeleteCreativeTrackerSettingMutationResponse;
    readonly variables: DeleteCreativeTrackerSettingMutationVariables;
};



/*
mutation DeleteCreativeTrackerSettingMutation(
  $input: DeleteCreativeTrackerSettingInput!
) {
  deleteCreativeTrackerSetting(input: $input) {
    creative {
      id
      tracker {
        id
        title
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteCreativeTrackerSettingPayload",
    "kind": "LinkedField",
    "name": "deleteCreativeTrackerSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Creative",
        "kind": "LinkedField",
        "name": "creative",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Tracker",
            "kind": "LinkedField",
            "name": "tracker",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCreativeTrackerSettingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCreativeTrackerSettingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a8da72dca04d5a77efbde5b2b14c83a4",
    "id": null,
    "metadata": {},
    "name": "DeleteCreativeTrackerSettingMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCreativeTrackerSettingMutation(\n  $input: DeleteCreativeTrackerSettingInput!\n) {\n  deleteCreativeTrackerSetting(input: $input) {\n    creative {\n      id\n      tracker {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b0e28b00fbe8fb722c7da4a9eff6397a';
export default node;
