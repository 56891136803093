import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { PromotionEditForm_promotion } from "~/__relay_artifacts__/PromotionEditForm_promotion.graphql";
import { FormValues, PromotionForm, schema } from "~/components/PromotionForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updatePromotionMutation from "~/mutations/UpdatePromotionMutation";

type Props = {
  promotionRef: FragmentRef<PromotionEditForm_promotion>;
  onSubmitCompleted: () => void;
};

const promotionFragment = graphql`
  fragment PromotionEditForm_promotion on Promotion {
    id
    title
  }
`;

export const PromotionEditForm: React.FC<Props> = ({
  promotionRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const promotion = useFragment<PromotionEditForm_promotion>(
    promotionFragment,
    promotionRef
  );
  const { id } = promotion;

  const initialValues = React.useMemo(
    () => ({ ...promotion } as FormValues),
    [promotion]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updatePromotion } = await updatePromotionMutation(environment, {
          id,
          ...values,
        });
        const promotion = updatePromotion && updatePromotion.promotion;
        if (!promotion) throw new Error("assertion failed");
        enqueueSnackbar("プロモーションを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [environment, id, enqueueSnackbar, onSubmitCompleted, formErrorHandler]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={PromotionForm}
    />
  );
};
