/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AdxCreativeStatus = "APPROVED" | "CONDITIONALLY_APPROVED" | "DISAPPROVED" | "NOT_CHECKED" | "PENDING_REVIEW" | "UNKNOWN" | "%future added value";
export type SubmitAdxCreativeInput = {
    clientMutationId?: string | null;
    creativeId: string;
    adomain: string;
    width: number;
    height: number;
};
export type SubmitAdxCreativeMutationVariables = {
    input: SubmitAdxCreativeInput;
};
export type SubmitAdxCreativeMutationResponse = {
    readonly submitAdxCreative: {
        readonly creativeEdge: {
            readonly node: {
                readonly id: string;
                readonly width: number | null;
                readonly height: number | null;
                readonly adxStatus: AdxCreativeStatus | null;
                readonly adxSubmittedAt: string | null;
            } | null;
        } | null;
    } | null;
};
export type SubmitAdxCreativeMutation = {
    readonly response: SubmitAdxCreativeMutationResponse;
    readonly variables: SubmitAdxCreativeMutationVariables;
};



/*
mutation SubmitAdxCreativeMutation(
  $input: SubmitAdxCreativeInput!
) {
  submitAdxCreative(input: $input) {
    creativeEdge {
      node {
        id
        width
        height
        adxStatus
        adxSubmittedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitAdxCreativePayload",
    "kind": "LinkedField",
    "name": "submitAdxCreative",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreativeEdge",
        "kind": "LinkedField",
        "name": "creativeEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Creative",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adxStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adxSubmittedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitAdxCreativeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitAdxCreativeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "918dd41f129c5865d63696021a2faa77",
    "id": null,
    "metadata": {},
    "name": "SubmitAdxCreativeMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitAdxCreativeMutation(\n  $input: SubmitAdxCreativeInput!\n) {\n  submitAdxCreative(input: $input) {\n    creativeEdge {\n      node {\n        id\n        width\n        height\n        adxStatus\n        adxSubmittedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '210f77df3e6c44bfcdc55b739e0f2e34';
export default node;
