import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { CopyAdMutation } from "~/__relay_artifacts__/CopyAdMutation.graphql";

const mutation = graphql`
  mutation CopyAdMutation($input: CopyAdInput!) {
    copyAd(input: $input) {
      adEdge {
        node {
          id
          title
          adomain
          status
          createdAt
          placements {
            totalCount
          }
          ...AdEditForm_ad
        }
      }
    }
  }
`;

let clientMutationId = 0;

const commit = (environment: Environment, campaignId: string, adId: string) =>
  commitMutation<CopyAdMutation>(environment, {
    mutation,
    variables: {
      input: {
        clientMutationId: `${clientMutationId++}`,
        adId,
      },
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: campaignId,
        connectionInfo: [
          {
            key: "AdListTableCard_ads",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "adEdge",
      },
    ],
  });

export default commit;
