/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdminHeader_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"AccountButton_viewer" | "AdminDrawerMenuList_viewer" | "ProjectSelectionButton_viewer" | "ProjectDrawerMenuList_viewer">;
    readonly " $refType": "AdminHeader_viewer";
};
export type AdminHeader_viewer$data = AdminHeader_viewer;
export type AdminHeader_viewer$key = {
    readonly " $data"?: AdminHeader_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"AdminHeader_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminHeader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountButton_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminDrawerMenuList_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectSelectionButton_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDrawerMenuList_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'b47cb1c06da35ca5edfd496073f7c301';
export default node;
