export const filterInitialValues = {
  withDeleted: false,
  orderField: "SSP_PROVIDER",
  orderDirection: "DESC",
  domain: null,
  bundle: null,
  width: null,
  height: null,
  adxBillingId: null,
  logicType: [],
  sspProvider: [],
  platform: [],
};
