import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import {
  FormValues,
  PlacementSetForm,
  schema,
} from "~/components/PlacementSetForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createPlacementSetMutation from "~/mutations/CreatePlacementSetMutation";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  title: "",
};

export const PlacementSetCreateForm: React.FC<Props> = ({
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values) => {
      try {
        const { createPlacementSet } = await createPlacementSetMutation(
          environment,
          values
        );

        if (!createPlacementSet?.placementSetEdge)
          throw new Error("assertion failed");
        enqueueSnackbar("プレイスメントセットを作成しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar("プレイスメントセットの作成に失敗しました", {
          variant: err,
        });
      }
    },
    [enqueueSnackbar, environment, onSubmitCompleted]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={PlacementSetForm}
    />
  );
};
