import {
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  InputBase,
  Popover,
  Theme,
  fade,
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";

import { DialogButton, Props as DialogButtonProps } from "./DialogButton";

export type Props = {
  title: string;
  placeholder?: string;
  loading?: boolean;
  renderDialogContent: DialogButtonProps["render"];
  renderFilterContent?: (props: { close: () => void }) => ReactNode;
  renderFreeContent?: (props: { close: () => void }) => ReactNode;
  freeContentTitle?: string;
  onSubmit: (value: string) => void;
} & Omit<ButtonProps, "onSubmit">;

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginLeft: 0,
    marginRight: theme.spacing(2),
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },

    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  icon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "12ch",

      "&:focus": {
        width: "20ch",
      },
    },
  },
  paper: {
    padding: theme.spacing(3),
    boxSizing: "border-box",
  },
}));

const inputProps = { "aria-label": "search" };

export const SearchDialogButton: FC<Props> = ({
  children,
  title,
  placeholder = "Search…",
  renderDialogContent,
  renderFilterContent,
  renderFreeContent,
  freeContentTitle,
  loading = false,
  onSubmit,
  ...buttonProps
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [freeAnchorEl, setFreeAnchorEl] = useState<HTMLElement | null>(null);
  const submit = useDebouncedCallback(onSubmit, 250);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    []
  );

  const handleFilterOpen = useCallback((e: any) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleFreeOpen = useCallback((e: any) => {
    setFreeAnchorEl(e.currentTarget);
  }, []);

  const handleFreeClose = useCallback(() => {
    setFreeAnchorEl(null);
  }, []);

  useEffect(() => {
    submit(value);
  }, [submit, value]);

  return (
    <DialogButton
      {...buttonProps}
      fullScreen
      enableAlert={false}
      title={title}
      render={renderDialogContent}
      toolbarContent={
        <>
          <div className={classes.search}>
            <div className={classes.icon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={placeholder}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={inputProps}
              value={value}
              onChange={handleChange}
            />
            {loading && (
              <div className={classes.icon} style={{ top: 0, right: 0 }}>
                <CircularProgress color="inherit" size={20} />
              </div>
            )}
          </div>
          {renderFilterContent && (
            <>
              <IconButton color="inherit" onClick={handleFilterOpen}>
                <FilterListIcon />
              </IconButton>
              <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                classes={{ paper: classes.paper }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleFilterClose}
              >
                {renderFilterContent({ close: handleFilterClose })}
              </Popover>
            </>
          )}
          {renderFreeContent && (
            <>
              <Button color="inherit" onClick={handleFreeOpen}>
                {freeContentTitle}
              </Button>
              <Popover
                open={!!freeAnchorEl}
                anchorEl={freeAnchorEl}
                classes={{ paper: classes.paper }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleFreeClose}
              >
                {renderFreeContent({ close: handleFreeClose })}
              </Popover>
            </>
          )}
        </>
      }
    >
      {children}
    </DialogButton>
  );
};
