import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import { DeleteMaintenanceMutation } from "~/__relay_artifacts__/DeleteMaintenanceMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteMaintenanceMutation($input: DeleteMaintenanceInput!) {
    deleteMaintenance(input: $input) {
      deletedMaintenanceId
    }
  }
`;

export const useDeleteMaintenanceMutation = () => {
  const environment = useRelayEnvironment();

  const deleteMaintenanceMutation = React.useCallback(
    (maintenanceId: string, config: any) =>
      commitMutation<DeleteMaintenanceMutation>(environment, {
        mutation,
        variables: { input: { maintenanceId } },
        configs: [config],
      }),
    [environment]
  );

  return { deleteMaintenanceMutation };
};
