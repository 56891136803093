/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdatePromotionInput = {
    clientMutationId?: string | null;
    id: string;
    title: string;
};
export type UpdatePromotionMutationVariables = {
    input: UpdatePromotionInput;
};
export type UpdatePromotionMutationResponse = {
    readonly updatePromotion: {
        readonly promotion: {
            readonly id: string;
            readonly title: string;
            readonly " $fragmentRefs": FragmentRefs<"PromotionEditForm_promotion">;
        } | null;
    } | null;
};
export type UpdatePromotionMutation = {
    readonly response: UpdatePromotionMutationResponse;
    readonly variables: UpdatePromotionMutationVariables;
};



/*
mutation UpdatePromotionMutation(
  $input: UpdatePromotionInput!
) {
  updatePromotion(input: $input) {
    promotion {
      id
      title
      ...PromotionEditForm_promotion
    }
  }
}

fragment PromotionEditForm_promotion on Promotion {
  id
  title
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePromotionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePromotionPayload",
        "kind": "LinkedField",
        "name": "updatePromotion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Promotion",
            "kind": "LinkedField",
            "name": "promotion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PromotionEditForm_promotion"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePromotionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePromotionPayload",
        "kind": "LinkedField",
        "name": "updatePromotion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Promotion",
            "kind": "LinkedField",
            "name": "promotion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38abb4a923c93ed9fcc701060efada9f",
    "id": null,
    "metadata": {},
    "name": "UpdatePromotionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePromotionMutation(\n  $input: UpdatePromotionInput!\n) {\n  updatePromotion(input: $input) {\n    promotion {\n      id\n      title\n      ...PromotionEditForm_promotion\n    }\n  }\n}\n\nfragment PromotionEditForm_promotion on Promotion {\n  id\n  title\n}\n"
  }
};
})();
(node as any).hash = '66528616f406cf5353e2aac12d8ba548';
export default node;
