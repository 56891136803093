import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { ProjectHeader_Query } from "~/__relay_artifacts__/ProjectHeader_Query.graphql";
import { ProjectHeader } from "~/components/ProjectHeader";

const query = graphql`
  query ProjectHeader_Query($projectId: ID!, $search: String) {
    project(id: $projectId) {
      ...ProjectHeader_project
    }
    viewer {
      ...ProjectHeader_viewer
    }
  }
`;

const Container: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<ProjectHeader_Query>(query, {
    projectId,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  return props.project && props.viewer ? (
    <ProjectHeader projectRef={props.project} viewerRef={props.viewer} />
  ) : (
    <div>not found</div>
  );
};

export default Container;
