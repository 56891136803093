import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { graphql } from "react-relay";

import { AdDetail_ad } from "~/__relay_artifacts__/AdDetail_ad.graphql";
import { ThompsonFilteringStrategyCard_thompsonFilteringStrategy } from "~/__relay_artifacts__/ThompsonFilteringStrategyCard_thompsonFilteringStrategy.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ThompsonFilteringStrategyEditForm } from "~/containers/ThompsonFilteringStrategyEditForm";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  thompsonFilteringStrategyRef: AdDetail_ad["thompsonFilteringStrategy"];
};

const useStyles = makeStyles({
  root: {
    marginTop: 24,
  },
  paper: {
    margin: 24,
  },
});

const thompsonFilteringStrategyFragment = graphql`
  fragment ThompsonFilteringStrategyCard_thompsonFilteringStrategy on ThompsonFilteringStrategy {
    id
    cpa
    cvr
    cpmCorrectionTerm
    ctrCorrectionTerm
    initialDistributionAlpha
    initialDistributionBeta
    ...ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy
  }
`;

export const ThompsonFilteringStrategyCard: React.FC<Props> = ({
  thompsonFilteringStrategyRef,
}) => {
  const classes = useStyles();
  const thompsonFilteringStrategy =
    useFragment<ThompsonFilteringStrategyCard_thompsonFilteringStrategy>(
      thompsonFilteringStrategyFragment,
      thompsonFilteringStrategyRef
    );

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        {!thompsonFilteringStrategy ? (
          <Typography variant="subtitle1">
            Thompson sampling設定はありません
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle1">Thompson sampling設定</Typography>
            <DialogButton
              title="Thompson sampling設定編集"
              render={({ close }) => (
                <ThompsonFilteringStrategyEditForm
                  thompsonFilteringStrategyRef={thompsonFilteringStrategy}
                  onSubmitCompleted={close}
                />
              )}
            >
              編集する
            </DialogButton>
          </>
        )}
      </Toolbar>
      {thompsonFilteringStrategy && (
        <List>
          <ListItem>
            <ListItemText
              primary="CAP"
              secondary={thompsonFilteringStrategy.cpa}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="CVR"
              secondary={thompsonFilteringStrategy.cvr}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="CPM Correction Term"
              secondary={thompsonFilteringStrategy.cpmCorrectionTerm}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="CTR Correction Term"
              secondary={thompsonFilteringStrategy.ctrCorrectionTerm}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Initial Destribution Alpah"
              secondary={thompsonFilteringStrategy.initialDistributionAlpha}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Initial Destribution Beta"
              secondary={thompsonFilteringStrategy.initialDistributionBeta}
            />
          </ListItem>
        </List>
      )}
    </Paper>
  );
};
