/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateMonthlyBudgetInput = {
    clientMutationId?: string | null;
    campaignId: string;
    date: string;
    amount: number;
};
export type CreateMonthlyBudgetMutationVariables = {
    input: CreateMonthlyBudgetInput;
};
export type CreateMonthlyBudgetMutationResponse = {
    readonly createMonthlyBudget: {
        readonly monthlyBudgetEdge: {
            readonly node: {
                readonly id: string;
                readonly date: string;
                readonly amount: number;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly " $fragmentRefs": FragmentRefs<"MonthlyBudgetEditForm_monthlyBudget">;
            } | null;
        } | null;
    } | null;
};
export type CreateMonthlyBudgetMutation = {
    readonly response: CreateMonthlyBudgetMutationResponse;
    readonly variables: CreateMonthlyBudgetMutationVariables;
};



/*
mutation CreateMonthlyBudgetMutation(
  $input: CreateMonthlyBudgetInput!
) {
  createMonthlyBudget(input: $input) {
    monthlyBudgetEdge {
      node {
        id
        date
        amount
        createdAt
        updatedAt
        ...MonthlyBudgetEditForm_monthlyBudget
      }
    }
  }
}

fragment MonthlyBudgetEditForm_monthlyBudget on MonthlyBudget {
  id
  date
  amount
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMonthlyBudgetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMonthlyBudgetPayload",
        "kind": "LinkedField",
        "name": "createMonthlyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MonthlyBudgetEdge",
            "kind": "LinkedField",
            "name": "monthlyBudgetEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MonthlyBudget",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MonthlyBudgetEditForm_monthlyBudget"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMonthlyBudgetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMonthlyBudgetPayload",
        "kind": "LinkedField",
        "name": "createMonthlyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MonthlyBudgetEdge",
            "kind": "LinkedField",
            "name": "monthlyBudgetEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MonthlyBudget",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ddeb0ebce1c60d12bf01cfe44e9ef63",
    "id": null,
    "metadata": {},
    "name": "CreateMonthlyBudgetMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMonthlyBudgetMutation(\n  $input: CreateMonthlyBudgetInput!\n) {\n  createMonthlyBudget(input: $input) {\n    monthlyBudgetEdge {\n      node {\n        id\n        date\n        amount\n        createdAt\n        updatedAt\n        ...MonthlyBudgetEditForm_monthlyBudget\n      }\n    }\n  }\n}\n\nfragment MonthlyBudgetEditForm_monthlyBudget on MonthlyBudget {\n  id\n  date\n  amount\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = '8fa0013d018ba0d8b28e81d004d710fd';
export default node;
