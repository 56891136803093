/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ScheduleSetList_QueryVariables = {
    projectId: string;
    first?: number | null;
    after?: string | null;
};
export type ScheduleSetList_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"ScheduleSetList_project">;
    } | null;
};
export type ScheduleSetList_Query = {
    readonly response: ScheduleSetList_QueryResponse;
    readonly variables: ScheduleSetList_QueryVariables;
};



/*
query ScheduleSetList_Query(
  $projectId: ID!
  $first: Int
  $after: String
) {
  project(id: $projectId) {
    ...ScheduleSetList_project
    id
  }
}

fragment ScheduleEditForm_schedule on Schedule {
  id
  since
  till
  latestVersion
}

fragment ScheduleListButton_scheduleSet on ScheduleSet {
  id
  schedules(first: 100) {
    edges {
      node {
        id
        till
        since
        ...ScheduleEditForm_schedule
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ScheduleSetAdListButton_scheduleSet on ScheduleSet {
  id
  ads {
    edges {
      node {
        id
        title
        status
      }
    }
  }
}

fragment ScheduleSetEditForm_scheduleSet on ScheduleSet {
  id
  title
  latestVersion
}

fragment ScheduleSetList_project on Project {
  id
  scheduleSets(first: $first, after: $after) {
    totalCount
    edges {
      node {
        id
        title
        createdAt
        ...ScheduleListButton_scheduleSet
        ...ScheduleSetAdListButton_scheduleSet
        ...ScheduleSetEditForm_scheduleSet
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestVersion",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleSetList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleSetList_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScheduleSetList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ScheduleSetConnection",
            "kind": "LinkedField",
            "name": "scheduleSets",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleSetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleSet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "concreteType": "ScheduleConnection",
                        "kind": "LinkedField",
                        "name": "schedules",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Schedule",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "till",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "since",
                                    "storageKey": null
                                  },
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": "schedules(first:100)"
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "ScheduleList_schedules",
                        "kind": "LinkedHandle",
                        "name": "schedules"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdConnection",
                        "kind": "LinkedField",
                        "name": "ads",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Ad",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "ScheduleSetList_scheduleSets",
            "kind": "LinkedHandle",
            "name": "scheduleSets"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e49fb3a3f697be73d3e106cf54e9980",
    "id": null,
    "metadata": {},
    "name": "ScheduleSetList_Query",
    "operationKind": "query",
    "text": "query ScheduleSetList_Query(\n  $projectId: ID!\n  $first: Int\n  $after: String\n) {\n  project(id: $projectId) {\n    ...ScheduleSetList_project\n    id\n  }\n}\n\nfragment ScheduleEditForm_schedule on Schedule {\n  id\n  since\n  till\n  latestVersion\n}\n\nfragment ScheduleListButton_scheduleSet on ScheduleSet {\n  id\n  schedules(first: 100) {\n    edges {\n      node {\n        id\n        till\n        since\n        ...ScheduleEditForm_schedule\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ScheduleSetAdListButton_scheduleSet on ScheduleSet {\n  id\n  ads {\n    edges {\n      node {\n        id\n        title\n        status\n      }\n    }\n  }\n}\n\nfragment ScheduleSetEditForm_scheduleSet on ScheduleSet {\n  id\n  title\n  latestVersion\n}\n\nfragment ScheduleSetList_project on Project {\n  id\n  scheduleSets(first: $first, after: $after) {\n    totalCount\n    edges {\n      node {\n        id\n        title\n        createdAt\n        ...ScheduleListButton_scheduleSet\n        ...ScheduleSetAdListButton_scheduleSet\n        ...ScheduleSetEditForm_scheduleSet\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd87eb91b6bc2b5a78e217256622bcc82';
export default node;
