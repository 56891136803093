import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import { FormValues, PromotionForm, schema } from "~/components/PromotionForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createPromotionMutation from "~/mutations/CreatePromotionMutation";

type Props = {
  onSubmitCompleted: () => void;
  connectionId: string;
};

const initialValues = {
  title: "",
};

export const PromotionCreateForm: React.FC<Props> = ({
  onSubmitCompleted,
  connectionId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createPromotion } = await createPromotionMutation(
          environment,
          {
            projectId,
            ...values,
          },
          connectionId
        );
        if (!createPromotion?.promotion) throw new Error("assertion failed");
        enqueueSnackbar("プロモーションを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
      projectId,
      connectionId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={PromotionForm}
    />
  );
};
