import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { Home_Query } from "~/__relay_artifacts__/Home_Query.graphql";
import MembershipList from "~/components/MembershipList";
import { PageHeader } from "~/components/PageHeader";

const query = graphql`
  query MembershipListPage_Query($projectId: ID!, $first: Int, $after: String) {
    project(id: $projectId) {
      ...MembershipList_project
    }
    viewer {
      ...MembershipList_viewer
    }
  }
`;

export const MembershipListPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<Home_Query>(query, {
    projectId,
    first: 100,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  return (
    <>
      <PageHeader title="メンバー一覧" />
      <MembershipList project={props.project!} viewer={props.viewer} />
    </>
  );
};
