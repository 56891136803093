import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdatePlacementIsEnabledInput,
  UpdatePlacementIsEnabledMutation,
} from "~/__relay_artifacts__/UpdatePlacementIsEnabledMutation.graphql";

const mutation = graphql`
  mutation UpdatePlacementIsEnabledMutation(
    $input: UpdatePlacementIsEnabledInput!
  ) {
    updatePlacementIsEnabled(input: $input) {
      placement {
        id
        isEnabled
        latestVersion
      }
    }
  }
`;

export const useUpdatePlacementIsEnabledMutation = () => {
  const environment = useRelayEnvironment();

  const updatePlacementIsEnabledMutation = useCallback(
    (input: UpdatePlacementIsEnabledInput) =>
      commitMutation<UpdatePlacementIsEnabledMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updatePlacementIsEnabledMutation };
};
