import * as React from "react";
import { graphql } from "react-relay";

import { CampaignReportFormContainer_project } from "~/__relay_artifacts__/CampaignReportFormContainer_project.graphql";
import { ReportList_project } from "~/__relay_artifacts__/ReportList_project.graphql";
import { ReportList as ReportListComponent } from "~/components/ReportList";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  projectRef: CampaignReportFormContainer_project;
  shouldDisplay: boolean;
  measureFields: string[];
};

const projectFragment = graphql`
  fragment ReportList_project on Project {
    id
    report(
      measures: $measures
      dimensions: $dimensions
      filter: $filter
      orders: $orders
    ) {
      measures
    }
  }
`;

export const ReportList: React.FC<Props> = ({
  shouldDisplay,
  measureFields,
  projectRef,
}) => {
  const project = useFragment<ReportList_project>(projectFragment, projectRef);
  return (
    <>
      {shouldDisplay && (
        <ReportListComponent project={project} measureFields={measureFields} />
      )}
    </>
  );
};
