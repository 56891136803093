import { VFC } from "react";
import { FragmentRef, graphql } from "relay-runtime";

import { CampaignDebugSettingCard_campaign } from "~/__relay_artifacts__/CampaignDebugSettingCard_campaign.graphql";
import { useFragment } from "~/lib/relay-hooks";

import { CampaignAllowIPListCard } from "./CampaignAllowIpListCard";

type Props = {
  campaignRef: FragmentRef<CampaignDebugSettingCard_campaign>;
};

const fragment = graphql`
  fragment CampaignDebugSettingCard_campaign on Campaign {
    ...CampaignAllowIpListCard_campaign
  }
`;

export const CampaignDebugSettingCard: VFC<Props> = ({ campaignRef }) => {
  const campaign = useFragment<CampaignDebugSettingCard_campaign>(
    fragment,
    campaignRef
  );

  return (
    <>
      <CampaignAllowIPListCard campaignRef={campaign} />
    </>
  );
};
