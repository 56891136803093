/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdCreativeList_QueryVariables = {
    projectId: string;
    adId: string;
    search?: string | null;
};
export type AdCreativeList_QueryResponse = {
    readonly project: {
        readonly ad: {
            readonly " $fragmentRefs": FragmentRefs<"AdCreativeList_ad">;
        } | null;
    } | null;
};
export type AdCreativeList_Query = {
    readonly response: AdCreativeList_QueryResponse;
    readonly variables: AdCreativeList_QueryVariables;
};



/*
query AdCreativeList_Query(
  $projectId: ID!
  $adId: ID!
  $search: String
) {
  project(id: $projectId) {
    ad(id: $adId) {
      ...AdCreativeList_ad
      id
    }
    id
  }
}

fragment AdCreativeList_ad on Ad {
  id
  creatives(first: 100) {
    edges {
      node {
        id
        title
        adm
        height
        width
        createdAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  selectableCreatives(first: 100, search: $search) {
    edges {
      node {
        id
        title
        adm
        height
        width
        createdAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "adId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v7 = [
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreativeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Creative",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdCreativeList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdCreativeList_ad"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdCreativeList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "creatives",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": "creatives(first:100)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "CreateAdCreativeMutation_creatives",
                "kind": "LinkedHandle",
                "name": "creatives"
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "selectableCreatives",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": [
                  "search"
                ],
                "handle": "connection",
                "key": "DeleteAdCreativeMutation_selectableCreatives",
                "kind": "LinkedHandle",
                "name": "selectableCreatives"
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6875053892c2441df14f1354c71e16e7",
    "id": null,
    "metadata": {},
    "name": "AdCreativeList_Query",
    "operationKind": "query",
    "text": "query AdCreativeList_Query(\n  $projectId: ID!\n  $adId: ID!\n  $search: String\n) {\n  project(id: $projectId) {\n    ad(id: $adId) {\n      ...AdCreativeList_ad\n      id\n    }\n    id\n  }\n}\n\nfragment AdCreativeList_ad on Ad {\n  id\n  creatives(first: 100) {\n    edges {\n      node {\n        id\n        title\n        adm\n        height\n        width\n        createdAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  selectableCreatives(first: 100, search: $search) {\n    edges {\n      node {\n        id\n        title\n        adm\n        height\n        width\n        createdAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0b224bb1ade16bc76a39bd1501ef9453';
export default node;
