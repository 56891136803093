import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdatePromotionInput,
  UpdatePromotionMutation,
} from "~/__relay_artifacts__/UpdatePromotionMutation.graphql";

const mutation = graphql`
  mutation UpdatePromotionMutation($input: UpdatePromotionInput!) {
    updatePromotion(input: $input) {
      promotion {
        id
        title
        ...PromotionEditForm_promotion
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdatePromotionInput) =>
  commitMutation<UpdatePromotionMutation>(environment, {
    mutation,
    variables: { input },
  });

export default commit;
