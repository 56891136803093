import { Grid } from "@material-ui/core";
import { FormikNumberField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  height: number;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  height: yup.number().required(),
});

export const CreativeCopyForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikNumberField
          fullWidth
          required
          name="height"
          label="高さ"
          margin="normal"
        />
      </Grid>
    </Grid>
  </DialogForm>
);
