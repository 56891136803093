/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeletePromotionInput = {
    clientMutationId?: string | null;
    promotionId: string;
};
export type DeletePromotionMutationVariables = {
    input: DeletePromotionInput;
};
export type DeletePromotionMutationResponse = {
    readonly deletePromotion: {
        readonly deletedPromotionId: string;
    } | null;
};
export type DeletePromotionMutation = {
    readonly response: DeletePromotionMutationResponse;
    readonly variables: DeletePromotionMutationVariables;
};



/*
mutation DeletePromotionMutation(
  $input: DeletePromotionInput!
) {
  deletePromotion(input: $input) {
    deletedPromotionId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeletePromotionPayload",
    "kind": "LinkedField",
    "name": "deletePromotion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedPromotionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeletePromotionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeletePromotionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f2c5d290b15ef776775dd1f32449a19",
    "id": null,
    "metadata": {},
    "name": "DeletePromotionMutation",
    "operationKind": "mutation",
    "text": "mutation DeletePromotionMutation(\n  $input: DeletePromotionInput!\n) {\n  deletePromotion(input: $input) {\n    deletedPromotionId\n  }\n}\n"
  }
};
})();
(node as any).hash = '97a9bd81fe05ed0238bd73026c583c5b';
export default node;
