const chunk = <T>(list: T[], size: number): [T[]] =>
  list.reduce(
    (sum, item) => {
      const last = sum[sum.length - 1];
      if (last.length === size) {
        sum.push([item]);
        return sum;
      }
      last.push(item);
      return sum;
    },
    [[]] as [T[]]
  );

const delay = (ms: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

type DelayChunkPromiseOption = {
  size?: number;
  ms?: number;
};

export const delayChunkPromise = <T>(
  list: Promise<T>[],
  options?: DelayChunkPromiseOption
) => {
  const { size, ms } = { size: 20, ms: 50, ...options };
  return Promise.all(
    chunk(list, size).reduce(
      (sum, item) => sum.concat(item, [delay(ms)]),
      [] as Promise<void | T>[]
    )
  );
};
