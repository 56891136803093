/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ThompsonFilteringStrategyCard_thompsonFilteringStrategy = {
    readonly id: string;
    readonly cpa: number;
    readonly cvr: number;
    readonly cpmCorrectionTerm: number;
    readonly ctrCorrectionTerm: number;
    readonly initialDistributionAlpha: number;
    readonly initialDistributionBeta: number;
    readonly " $fragmentRefs": FragmentRefs<"ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy">;
    readonly " $refType": "ThompsonFilteringStrategyCard_thompsonFilteringStrategy";
};
export type ThompsonFilteringStrategyCard_thompsonFilteringStrategy$data = ThompsonFilteringStrategyCard_thompsonFilteringStrategy;
export type ThompsonFilteringStrategyCard_thompsonFilteringStrategy$key = {
    readonly " $data"?: ThompsonFilteringStrategyCard_thompsonFilteringStrategy$data;
    readonly " $fragmentRefs": FragmentRefs<"ThompsonFilteringStrategyCard_thompsonFilteringStrategy">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThompsonFilteringStrategyCard_thompsonFilteringStrategy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cpa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cvr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cpmCorrectionTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctrCorrectionTerm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initialDistributionAlpha",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initialDistributionBeta",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ThompsonFilteringStrategyEditForm_thompsonFilteringStrategy"
    }
  ],
  "type": "ThompsonFilteringStrategy",
  "abstractKey": null
};
(node as any).hash = '732aa9056b39a7d84f83c11fda0d6ac3';
export default node;
