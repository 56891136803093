import { Grid } from "@material-ui/core";
import {
  FormikMultipleSelectField,
  FormikNumberField,
  FormikSelectField,
  FormikSwitchField,
  FormikTextField,
} from "@vrize/vrizead-use";
import { Formik } from "formik";
import * as React from "react";

import {
  AdSlotPlatform,
  AdSlotSspProvider,
  PricingLogic,
} from "~/__relay_artifacts__/AdSlotListTableCard_root.graphql";
import { DialogForm } from "~/components/DialogForm";

export type FormValues = {
  withDeleted: boolean;
  orderField:
    | "NAME"
    | "DOMAIN"
    | "TAG_ID"
    | "BUNDLE"
    | "SSP_PROVIDER"
    | "CREATED_AT"
    | "UPDATED_AT";
  orderDirection: "DESC" | "ASC";
  domain: string | null;
  bundle: string | null;
  width: number | null;
  height: number | null;
  sspProvider: AdSlotSspProvider[];
  platform: AdSlotPlatform[];
  logicType: PricingLogic[];
  adxBillingId: string | null;
};

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

const fieldOptions = [
  { value: "DOMAIN", label: "ドメイン" },
  { value: "TAG_ID", label: "タグID" },
  { value: "BUNDLE", label: "バンドル" },
  { value: "SSP_PROVIDER", label: "プロバイダ" },
  { value: "CREATED_AT", label: "作成日" },
  { value: "UPDATED_AT", label: "更新日" },
];

const directionOptions = [
  { value: "DESC", label: "降順" },
  { value: "ASC", label: "昇順" },
];

const sspProviderOptions = [
  { label: "ADSTIR", value: "ADSTIR" },
  { label: "ADX", value: "ADX" },
  { label: "VRIZEAD", value: "VRIZEAD" },
  { label: "PROFITX", value: "PROFITX" },
  { label: "GENIEE", value: "GENIEE" },
  { label: "FLUCT", value: "FLUCT" },
  { label: "GMO", value: "GMO" },
  { label: "OPENX", value: "OPENX" },
  { label: "SUPERSHIP", value: "SUPERSHIP" },
  { label: "INMOBI", value: "INMOBI" },
  { label: "BIDSWITCH_GMO", value: "BIDSWITCH_GMO" },
  { label: "BIDSWITCH_GENIEE", value: "BIDSWITCH_GENIEE" },
  { label: "BIDSWITCH_SCALEOUT", value: "BIDSWITCH_SCALEOUT" },
  { label: "BIDSWITCH_MICROAD", value: "BIDSWITCH_MICROAD" },
];

const platformOptions = [
  { value: "SITE", label: "Site" },
  { value: "APP", label: "App" },
];

const logicTypeOptions = [
  { value: "MANUAL", label: "手動" },
  { value: "ML", label: "ML" },
  { value: "FLOOR_PLUS", label: "Floor+1" },
];

export const AdSlotFilterForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
}) => (
  <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            name="orderField"
            label="並び替え（フィールド）"
            options={fieldOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            name="orderDirection"
            label="並び替え（方向）"
            options={directionOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField fullWidth name="domain" label="ドメイン" />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField fullWidth name="bundle" label="バンドル" />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField fullWidth name="width" label="幅" />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField fullWidth name="height" label="高さ" />
        </Grid>
        <Grid item xs={6}>
          <FormikMultipleSelectField
            fullWidth
            name="sspProvider"
            label="プロバイダ"
            options={sspProviderOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikMultipleSelectField
            fullWidth
            name="platform"
            label="プラットフォーム"
            options={platformOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField
            fullWidth
            name="adxBillingId"
            label="ADX BILLING ID"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikMultipleSelectField
            fullWidth
            name="logicType"
            label="入札方式"
            options={logicTypeOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSwitchField
            name="withDeleted"
            label="削除済みのデータを含める"
          />
        </Grid>
      </Grid>
    </DialogForm>
  </Formik>
);
