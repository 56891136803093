import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateCampaignAllowIpInput,
  CreateCampaignAllowIpMutation,
} from "~/__relay_artifacts__/CreateCampaignAllowIpMutation.graphql";

const mutation = graphql`
  mutation CreateCampaignAllowIpMutation(
    $connections: [ID!]!
    $edgeTypeName: String!
    $input: CreateCampaignAllowIpInput!
  ) {
    createCampaignAllowIp(input: $input) {
      campaignAllowIp
        @appendNode(connections: $connections, edgeTypeName: $edgeTypeName) {
        id
        ip
      }
    }
  }
`;

export const createCampaignAllowIpMutation = (
  environment: Environment,
  input: CreateCampaignAllowIpInput,
  connectionId: string,
  edgeTypeName: string
) => {
  return commitMutation<CreateCampaignAllowIpMutation>(environment, {
    mutation,
    variables: {
      input,
      connections: [connectionId],
      edgeTypeName: edgeTypeName,
    },
  });
};

export type { CreateCampaignAllowIpInput };
