import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { ClientCreativeList_Query } from "~/__relay_artifacts__/ClientCreativeList_Query.graphql";
import { ClientCreativeListTableCard } from "~/components/ClientCreativeListTableCard";

const query = graphql`
  query ClientCreativeList_Query(
    $projectId: ID!
    $first: Int
    $after: String
    $title: String
  ) {
    project(id: $projectId) {
      ...ClientCreativeListTableCard_project
    }
  }
`;

export const ClientCreativeList: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<ClientCreativeList_Query>(query, {
    projectId,
    first: 100,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project) return <div>not found</div>;
  return <ClientCreativeListTableCard projectRef={props.project} />;
};
