/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CopyCreativeInput = {
    clientMutationId?: string | null;
    creativeId: string;
    height: number;
};
export type CopyCreativeMutationVariables = {
    input: CopyCreativeInput;
};
export type CopyCreativeMutationResponse = {
    readonly copyCreative: {
        readonly creativeEdge: {
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly adm: string | null;
                readonly height: number | null;
                readonly width: number | null;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly sdkVersion: string | null;
                readonly periodTill: string | null;
                readonly periodSince: string | null;
                readonly " $fragmentRefs": FragmentRefs<"CreativeEditForm_creative">;
            } | null;
        } | null;
    } | null;
};
export type CopyCreativeMutation = {
    readonly response: CopyCreativeMutationResponse;
    readonly variables: CopyCreativeMutationVariables;
};



/*
mutation CopyCreativeMutation(
  $input: CopyCreativeInput!
) {
  copyCreative(input: $input) {
    creativeEdge {
      node {
        id
        title
        adm
        height
        width
        createdAt
        updatedAt
        sdkVersion
        periodTill
        periodSince
        ...CreativeEditForm_creative
      }
    }
  }
}

fragment CreativeEditForm_creative on Creative {
  id
  title
  adm
  width
  height
  iurl
  tagList
  note
  periodTill
  periodSince
  metaPosition
  metaOs
  metaAdmVersion
  archived
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adm",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sdkVersion",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodTill",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodSince",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CopyCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyCreativePayload",
        "kind": "LinkedField",
        "name": "copyCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreativeEdge",
            "kind": "LinkedField",
            "name": "creativeEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Creative",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CreativeEditForm_creative"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CopyCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyCreativePayload",
        "kind": "LinkedField",
        "name": "copyCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreativeEdge",
            "kind": "LinkedField",
            "name": "creativeEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Creative",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iurl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagList",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaPosition",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaOs",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaAdmVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3e58f97059da408ce0a72e92417218c",
    "id": null,
    "metadata": {},
    "name": "CopyCreativeMutation",
    "operationKind": "mutation",
    "text": "mutation CopyCreativeMutation(\n  $input: CopyCreativeInput!\n) {\n  copyCreative(input: $input) {\n    creativeEdge {\n      node {\n        id\n        title\n        adm\n        height\n        width\n        createdAt\n        updatedAt\n        sdkVersion\n        periodTill\n        periodSince\n        ...CreativeEditForm_creative\n      }\n    }\n  }\n}\n\nfragment CreativeEditForm_creative on Creative {\n  id\n  title\n  adm\n  width\n  height\n  iurl\n  tagList\n  note\n  periodTill\n  periodSince\n  metaPosition\n  metaOs\n  metaAdmVersion\n  archived\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = '1c059014f31a1d6b32d66740d6f50c08';
export default node;
