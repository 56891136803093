import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteUserMutation } from "~/__relay_artifacts__/DeleteUserMutation.graphql";

const mutation = graphql`
  mutation DeleteUserMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      deletedUserId
    }
  }
`;

const commit = (environment: Environment, id: string) =>
  commitMutation<DeleteUserMutation>(environment, {
    mutation,
    variables: {
      input: { id },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: "client:root",
        connectionKeys: [
          {
            key: "UserListTableCard_users",
          },
        ],
        pathToConnection: ["root", "users"],
        deletedIDFieldName: "deletedUserId",
      },
    ],
  });

export default commit;
