import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateExpenseInput,
  UpdateExpenseMutation,
} from "~/__relay_artifacts__/UpdateExpenseMutation.graphql";

const mutation = graphql`
  mutation UpdateExpenseMutation($input: UpdateExpenseInput!) {
    updateExpense(input: $input) {
      expense {
        id
        date
        rate
        latestVersion
        ...ExpenseEditForm_expense
      }
    }
  }
`;

export const useUpdateExpenseMutationMutation = () => {
  const environment = useRelayEnvironment();

  const updateExpenseMutation = useCallback(
    (input: UpdateExpenseInput) =>
      commitMutation<UpdateExpenseMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateExpenseMutation };
};
