import { Table, Theme } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as React from "react";

type Props = {
  minWidth?: number;
};

const StyledWrapper = styled("div")({
  overflowX: "auto",
});

const StyledTable = styled(Table)<Theme, Props>({
  minWidth: (props) => props.minWidth,
});

export const ListTable: React.FC<Props> = ({ children, minWidth }) => (
  <StyledWrapper>
    <StyledTable minWidth={minWidth} size="small">
      {children}
    </StyledTable>
  </StyledWrapper>
);
