import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdatePlacementInput,
  UpdatePlacementMutation,
} from "~/__relay_artifacts__/UpdatePlacementMutation.graphql";

const mutation = graphql`
  mutation UpdatePlacementMutation($input: UpdatePlacementInput!) {
    updatePlacement(input: $input) {
      placement {
        id
        correctionTerm
        proportion
        latestVersion
      }
    }
  }
`;

export const useUpdatePlacementMutation = () => {
  const environment = useRelayEnvironment();

  const updatePlacementMutation = useCallback(
    (input: UpdatePlacementInput) =>
      commitMutation<UpdatePlacementMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updatePlacementMutation };
};
