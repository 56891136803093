/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReportList_project = {
    readonly id: string;
    readonly report: {
        readonly measures: ReadonlyArray<ReadonlyArray<string>>;
    };
    readonly " $refType": "ReportList_project";
};
export type ReportList_project$data = ReportList_project;
export type ReportList_project$key = {
    readonly " $data"?: ReportList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportList_project">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "dimensions"
    },
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "measures"
    },
    {
      "kind": "RootArgument",
      "name": "orders"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportList_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "dimensions",
          "variableName": "dimensions"
        },
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "measures",
          "variableName": "measures"
        },
        {
          "kind": "Variable",
          "name": "orders",
          "variableName": "orders"
        }
      ],
      "concreteType": "Report",
      "kind": "LinkedField",
      "name": "report",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "measures",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
(node as any).hash = 'fba15bc953c0efec4eca87047e6b8674';
export default node;
