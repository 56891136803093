import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateCampaignTrackerSettingInput,
  UpdateCampaignTrackerSettingMutation,
} from "~/__relay_artifacts__/UpdateCampaignTrackerSettingMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateCampaignTrackerSettingMutation(
    $input: UpdateCampaignTrackerSettingInput!
  ) {
    updateCampaignTrackerSetting(input: $input) {
      campaign {
        id
        tracker {
          id
          title
        }
      }
    }
  }
`;

export const useUpdateCampaignTrackerSettingMutation = () => {
  const environment = useRelayEnvironment();

  const updateCampaignTrackerSettingMutation = useCallback(
    (input: UpdateCampaignTrackerSettingInput) =>
      commitMutation<UpdateCampaignTrackerSettingMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateCampaignTrackerSettingMutation };
};
