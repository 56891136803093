/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type AdDetail_ad = {
    readonly id: string;
    readonly title: string;
    readonly adomain: string | null;
    readonly status: CampaignStatus;
    readonly createdAt: string;
    readonly thompsonFilteringStrategy: {
        readonly " $fragmentRefs": FragmentRefs<"ThompsonFilteringStrategyCard_thompsonFilteringStrategy">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"AdScheduleSetCard_ad">;
    readonly " $refType": "AdDetail_ad";
};
export type AdDetail_ad$data = AdDetail_ad;
export type AdDetail_ad$key = {
    readonly " $data"?: AdDetail_ad$data;
    readonly " $fragmentRefs": FragmentRefs<"AdDetail_ad">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdDetail_ad",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adomain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ThompsonFilteringStrategy",
      "kind": "LinkedField",
      "name": "thompsonFilteringStrategy",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ThompsonFilteringStrategyCard_thompsonFilteringStrategy"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdScheduleSetCard_ad"
    }
  ],
  "type": "Ad",
  "abstractKey": null
};
(node as any).hash = '0842b98bf690a9ca76fb520fb32bc655';
export default node;
