import { graphql } from "react-relay";
import { useCallback } from "reactn";
import commitMutation from "relay-commit-mutation-promise";

import { DeleteCreativeTrackerSettingMutation } from "~/__relay_artifacts__/DeleteCreativeTrackerSettingMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteCreativeTrackerSettingMutation(
    $input: DeleteCreativeTrackerSettingInput!
  ) {
    deleteCreativeTrackerSetting(input: $input) {
      creative {
        id
        tracker {
          id
          title
        }
      }
    }
  }
`;

export const useDeleteCreativeTrackerSettingMutation = () => {
  const environment = useRelayEnvironment();

  const deleteCreativeTrackerSettingMutation = useCallback(
    (creativeId: string) =>
      commitMutation<DeleteCreativeTrackerSettingMutation>(environment, {
        mutation,
        variables: { input: { creativeId } },
      }),
    [environment]
  );

  return { deleteCreativeTrackerSettingMutation };
};
