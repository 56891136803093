import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { AdSlotPlacementList_Query } from "~/__relay_artifacts__/AdSlotPlacementList_Query.graphql";
import { AdSlotPlacementListTableCard } from "~/components/AdSlotPlacementList";
import { PageHeader } from "~/components/PageHeader";

const query = graphql`
  query AdSlotPlacementList_Query(
    $adSlotId: ID!
    $first: Int
    $after: String
    $isDistributedCampaigns: Boolean
  ) {
    adSlot(id: $adSlotId) {
      ...AdSlotPlacementListTableCard_adSlot
    }
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader shouldUseHistoryBack title="プレイスメント一覧" />
    {children}
  </>
);

export const AdSlotPlacementList: React.FC = () => {
  const { adSlotId } = useParams<{ adSlotId: string }>();
  const { props, error } = useQuery<AdSlotPlacementList_Query>(query, {
    adSlotId,
    first: 100,
  });

  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <AdSlotPlacementListTableCard adSlot={props.adSlot} />
    </Wrapper>
  );
};
