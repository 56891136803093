import { ListItem, ListItemText } from "@material-ui/core";
import * as React from "react";
import { graphql } from "react-relay";
import { Link, LinkProps } from "react-router-dom";

import { ProjectListItem_project } from "~/__relay_artifacts__/ProjectListItem_project.graphql";
import { ProjectList_viewer } from "~/__relay_artifacts__/ProjectList_viewer.graphql";
import { useFragment } from "~/lib/relay-hooks";

const ReferensableLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link {...props} ref={ref} />
);

type Project = NonNullable<
  NonNullable<ProjectList_viewer["projects"]["edges"]>[0]
>["node"];

type Props = {
  projectRef: Project;
};

const projectFragment = graphql`
  fragment ProjectListItem_project on Project {
    id
    name
    createdAt
    updatedAt
  }
`;

export const ProjectListItem: React.FC<Props> = ({ projectRef }) => {
  const project = useFragment<ProjectListItem_project>(
    projectFragment,
    projectRef
  );
  const path = `/projects/${project.id}/home`;
  return (
    <ListItem key={project.id} button to={path} component={ReferensableLink}>
      <ListItemText primary={project.name} />
    </ListItem>
  );
};
