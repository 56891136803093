import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  RestoreAdSlotInput,
  RestoreAdSlotMutation,
} from "~/__relay_artifacts__/RestoreAdSlotMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation RestoreAdSlotMutation($input: RestoreAdSlotInput!) {
    restoreAdSlot(input: $input) {
      adSlot {
        deletedAt
      }
    }
  }
`;

export const useRestoreAdSlotMutation = () => {
  const environment = useRelayEnvironment();

  const restoreAdSlotMutation = React.useCallback(
    (input: RestoreAdSlotInput) =>
      commitMutation<RestoreAdSlotMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { restoreAdSlotMutation };
};
