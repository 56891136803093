import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { PricingEditForm_pricing } from "~/__relay_artifacts__/PricingEditForm_pricing.graphql";
import { FormValues, PricingForm, schema } from "~/components/PricingForm";
import { noop } from "~/lib/noop";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updatePricingMutation from "~/mutations/UpdatePricingMutation";

type Props = {
  pricingRef: FragmentRef<PricingEditForm_pricing>;
  onSubmitCompleted?: () => void;
};

const pricingFragment = graphql`
  fragment PricingEditForm_pricing on Pricing {
    id
    logicType
    manualBidPrice
  }
`;

export const PricingEditForm: React.FC<Props> = ({
  pricingRef,
  onSubmitCompleted = noop,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const pricing = useFragment<PricingEditForm_pricing>(
    pricingFragment,
    pricingRef
  );

  const initialValues = React.useMemo(
    () => ({ ...pricing } as FormValues),
    [pricing]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ logicType, manualBidPrice }, { setErrors }) => {
      try {
        const { updatePricing } = await updatePricingMutation(environment, {
          id: pricing.id,
          logicType: logicType,
          manualBidPrice: manualBidPrice,
        });
        if (!updatePricing?.adSlot) throw new Error("assertion failed");
        enqueueSnackbar("入札設定を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        formErrorHandler(err, setErrors);
      }
    },
    [
      environment,
      pricing.id,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <PricingForm />
    </Formik>
  );
};
