import { Grid } from "@material-ui/core";
import { FormikNumberField, FormikTextField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  creativeId: string;
  adomain: string | null;
  height: number | null;
  width: number | null;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  creativeId: yup.string().required(),
  adomain: yup.string().required(),
  height: yup.number().required(),
  width: yup.number().required(),
});

export const AdxForm: React.FC<Props> = () => (
  <DialogForm submitButtonTitle="提出する">
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField
          required
          fullWidth
          name="adomain"
          label="広告ドメイン"
          helperText="this must be a crawlable domain or URL"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField required fullWidth name="height" label="高さ" />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField required fullWidth name="width" label="横幅" />
      </Grid>
    </Grid>
  </DialogForm>
);
