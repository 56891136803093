import { useSnackbar } from "notistack";
import * as React from "react";

type ValidationError = {
  path: string;
  message: string;
};

type ServerError = {
  extensions?: {
    code: string;
    validationErrors?: ValidationError[];
  };
};

export const useValidationErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleValidationError = React.useCallback(
    (error: ServerError | Error) => {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
        return;
      }
      const code = error.extensions?.code;
      const validationErrors = error.extensions?.validationErrors || [];
      validationErrors.forEach((e) => {
        enqueueSnackbar(`${code}: ${e.message}`, { variant: "error" });
      });
    },
    [enqueueSnackbar]
  );

  return handleValidationError;
};
