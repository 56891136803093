/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdatePlacementIsEnabledInput = {
    clientMutationId?: string | null;
    clientVersion?: number | null;
    id: string;
    isEnabled: boolean;
};
export type UpdatePlacementIsEnabledMutationVariables = {
    input: UpdatePlacementIsEnabledInput;
};
export type UpdatePlacementIsEnabledMutationResponse = {
    readonly updatePlacementIsEnabled: {
        readonly placement: {
            readonly id: string;
            readonly isEnabled: boolean;
            readonly latestVersion: number | null;
        } | null;
    } | null;
};
export type UpdatePlacementIsEnabledMutation = {
    readonly response: UpdatePlacementIsEnabledMutationResponse;
    readonly variables: UpdatePlacementIsEnabledMutationVariables;
};



/*
mutation UpdatePlacementIsEnabledMutation(
  $input: UpdatePlacementIsEnabledInput!
) {
  updatePlacementIsEnabled(input: $input) {
    placement {
      id
      isEnabled
      latestVersion
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePlacementIsEnabledPayload",
    "kind": "LinkedField",
    "name": "updatePlacementIsEnabled",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Placement",
        "kind": "LinkedField",
        "name": "placement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latestVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePlacementIsEnabledMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePlacementIsEnabledMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f9e884b50d7a4792b06a6fcd2de77d82",
    "id": null,
    "metadata": {},
    "name": "UpdatePlacementIsEnabledMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePlacementIsEnabledMutation(\n  $input: UpdatePlacementIsEnabledInput!\n) {\n  updatePlacementIsEnabled(input: $input) {\n    placement {\n      id\n      isEnabled\n      latestVersion\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '30a9e1e93b03665f3a32e9ca5c2b9b2f';
export default node;
