import {
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { ConnectionConfig } from "relay-hooks";

import { AdAudits_ad } from "~/__relay_artifacts__/AdAudits_ad.graphql";
import { ListTable } from "~/components/ListTable";
import { TableCell } from "~/components/atoms/TableCell";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { usePagination } from "~/lib/relay-hooks";

type Props = {
  adRef: FragmentRef<AdAudits_ad>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

const adFragment = graphql`
  fragment AdAudits_ad on Ad {
    id
    campaign {
      project {
        id
      }
    }
    audits(first: $first, after: $after)
      @connection(key: "AdAudits_audits", filters: []) {
      edges {
        node {
          id
          changes
          createdAt
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.audits,
  getVariables: ({ id, campaign }: any, { count, cursor }) => ({
    projectId: campaign.project.id,
    adId: id,
    first: count,
    after: cursor,
  }),
  query: graphql`
    query AdAudits_Query(
      $projectId: ID!
      $adId: ID!
      $first: Int
      $after: String
    ) {
      project(id: $projectId) {
        ad(id: $adId) {
          ...AdAudits_ad
        }
      }
    }
  `,
};

export const AdAudits: React.FC<Props> = ({ adRef }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [ad, { hasMore, loadMore }] = usePagination<AdAudits_ad>(
    adFragment,
    adRef
  );
  const totalCount = ad.audits.totalCount;

  const audits = React.useMemo(() => {
    const edges = ad.audits?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [ad.audits, page, perPage]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1">変更履歴</Typography>
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>変更日時</TableCell>
            <TableCell>変更内容</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audits.map((audit) => (
            <TableRow key={audit.id}>
              <TableCell>
                {parseISO(audit.createdAt, "yyyy/MM/dd HH:mm:ss")}
              </TableCell>
              <TableCell>{audit.changes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          hasMore() && loadMore(connectionConfig, perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};
