import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateBlockInput,
  CreateBlockMutation,
} from "~/__relay_artifacts__/CreateBlockMutation.graphql";

const mutation = graphql`
  mutation CreateBlockMutation($input: CreateBlockInput!) {
    createBlock(input: $input) {
      blockEdge {
        node {
          id
          domain
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateBlockInput) =>
  commitMutation<CreateBlockMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: "client:root",
        connectionInfo: [
          {
            key: "BlockList_blocks",
            rangeBehavior: "append",
          },
        ],
        edgeName: "blockEdge",
      },
    ],
  });

export default commit;
