/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateMaintenanceInput = {
    clientMutationId?: string | null;
    message: string;
};
export type CreateMaintenanceMutationVariables = {
    input: CreateMaintenanceInput;
};
export type CreateMaintenanceMutationResponse = {
    readonly createMaintenance: {
        readonly maintenanceEdge: {
            readonly node: {
                readonly id: string;
                readonly message: string;
                readonly createdAt: string;
                readonly updatedAt: string;
            } | null;
        } | null;
    } | null;
};
export type CreateMaintenanceMutation = {
    readonly response: CreateMaintenanceMutationResponse;
    readonly variables: CreateMaintenanceMutationVariables;
};



/*
mutation CreateMaintenanceMutation(
  $input: CreateMaintenanceInput!
) {
  createMaintenance(input: $input) {
    maintenanceEdge {
      node {
        id
        message
        createdAt
        updatedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMaintenancePayload",
    "kind": "LinkedField",
    "name": "createMaintenance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MaintenanceEdge",
        "kind": "LinkedField",
        "name": "maintenanceEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Maintenance",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMaintenanceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMaintenanceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a1e157a735f83ffaeb7da2a8f42f0550",
    "id": null,
    "metadata": {},
    "name": "CreateMaintenanceMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMaintenanceMutation(\n  $input: CreateMaintenanceInput!\n) {\n  createMaintenance(input: $input) {\n    maintenanceEdge {\n      node {\n        id\n        message\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '376fa457d674d6a42ce9061c639254bb';
export default node;
