import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { Link } from "react-router-dom";
import { useDebounce } from "react-use";

import { PlacementList_ad } from "~/__relay_artifacts__/PlacementList_ad.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { PlacementCreateButton } from "~/components/PlacementCreateButton";
import { TruncatedTextWithTooltip } from "~/components/TruncatedTextWithTooltip";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { AdSlotNoteEditForm } from "~/containers/AdSlotNoteEditForm";
import { PlacementSetAddForm } from "~/containers/PlacementSetAddForm";
import { usePlacementFilterCache } from "~/globalState";
import { noop } from "~/lib/noop";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import deletePlacementMutation from "~/mutations/DeletePlacementMutation";
import { useRefreshAdPlacementsMutation } from "~/mutations/RefreshAdPlacementsMutation";

import { AdPlacementBulkUpdateButton } from "./AdPlacementBulkUpdateButton";
import { CorrectionTermField } from "./CorrectionTermField";
import { IsEnabledSwitch } from "./IsEnabledSwitch";
import { PlacementFilterButton } from "./PlacementFilterButton";
import { ProportionField } from "./ProportionField";

type Props = {
  relay: RelayPaginationProp;
  ad: PlacementList_ad;
  projectId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  spacer: {
    flex: "1 1 100%",
  },
}));

const PlacementList: React.FC<Props> = ({ ad, projectId, relay }) => {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [searchText, setSearchText] = React.useState<string | null>(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [formValues, setFormValues] = usePlacementFilterCache(projectId, ad.id);
  const { refreshAdPlacementsMutation } = useRefreshAdPlacementsMutation();
  const totalCount = ad.placements.totalCount;

  const placements = React.useMemo(() => {
    const edges = ad.placements?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return {
        node,
        id: node.id,
        isEnabled: node.isEnabled,
        proportion: node.proportion,
        adSlotId: node.adSlot.id,
        adSlotNote: node.adSlot.note,
        adSlotName: node.adSlot.name,
        adSlotTagId: node.adSlot.tagId ? node.adSlot.tagId.substr(0, 8) : "",
        adSlotSspProvider: node.adSlot.sspProvider,
        adSlotDomain: node.adSlot.domain,
        adSlotBundle: node.adSlot.bundle,
        adSlotPlatform: node.adSlot.platform,
        adSlotWidth: node.adSlot.width,
        adSlotHeight: node.adSlot.height,
        adSlotAdxBillingId: node.adSlot.adxBillingId,
      };
    });
  }, [ad.placements, perPage, page]);

  const handleDeletePlacement = React.useCallback(
    async (placementId: string) => {
      try {
        await deletePlacementMutation(environment, ad.id, placementId);
        enqueueSnackbar("配信候補を削除しました", { variant: "success" });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [environment, ad.id, enqueueSnackbar]
  );

  const handleRefetch = React.useCallback(async () => {
    relay.refetchConnection(perPage, noop, {
      isEnabled: !formValues.showIsEnabledOnly
        ? null
        : formValues.showIsEnabledOnly,
      tagId: searchText,
    });
  }, [perPage, searchText, formValues.showIsEnabledOnly, relay]);

  const handleRefreshPlacements = React.useCallback(async () => {
    try {
      await refreshAdPlacementsMutation({
        adId: ad.id,
      });
      await handleRefetch();
      enqueueSnackbar("配信候補を更新しました", { variant: "success" });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  }, [ad.id, enqueueSnackbar, refreshAdPlacementsMutation, handleRefetch]);

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    const value = e.target.value;
    setSearchText(value !== "" ? value : null);
  }, []);

  useDebounce(handleRefetch, 500, [
    perPage,
    searchText,
    formValues.showIsEnabledOnly,
  ]);

  const placementIds = React.useMemo(
    () => R.pluck("id", placements),
    [placements]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          配信先の一覧
        </Typography>
        <PlacementCreateButton />
        <DialogButton
          title="プレイスメントセットで配信先を追加"
          render={({ close }) => (
            <PlacementSetAddForm
              isEnabled={formValues.showIsEnabledOnly ? true : undefined}
              onSubmitCompleted={close}
            />
          )}
        >
          プレイスメントセット使用
        </DialogButton>
        <ConfirmButton
          key="copy"
          color="inherit"
          confirmTitle="配信候補を更新しますか？"
          onAgree={async (changeDialog) => {
            handleRefreshPlacements();
            changeDialog(false);
          }}
        >
          更新
        </ConfirmButton>
        <div className={classes.spacer} />
        <PlacementFilterButton
          formValues={formValues}
          onSubmit={setFormValues}
        />
      </Toolbar>
      <Toolbar>
        <TextField
          fullWidth
          label="Tag IDで絞り込む"
          value={searchText}
          onChange={handleTextFieldChange}
        />
      </Toolbar>
      <ListTable minWidth={2000}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名称</TableCell>
            <TableCell>
              <AdPlacementBulkUpdateButton
                adId={ad.id}
                tagId={searchText}
                isEnabled={
                  !formValues.showIsEnabledOnly
                    ? null
                    : formValues.showIsEnabledOnly
                }
                fieldKey={"correctionTerm"}
                fieldName={"補正係数"}
                disabled={placementIds.length === 0}
                initialValue={0}
              />
            </TableCell>
            <TableCell>タグID</TableCell>
            <TableCell>
              <AdPlacementBulkUpdateButton
                adId={ad.id}
                tagId={searchText}
                isEnabled={
                  !formValues.showIsEnabledOnly
                    ? null
                    : formValues.showIsEnabledOnly
                }
                fieldKey={"proportion"}
                fieldName={"配信割合"}
                disabled={placementIds.length === 0}
                initialValue={0}
              />
            </TableCell>
            <TableCell>
              <AdPlacementBulkUpdateButton
                adId={ad.id}
                tagId={searchText}
                isEnabled={
                  !formValues.showIsEnabledOnly
                    ? null
                    : formValues.showIsEnabledOnly
                }
                fieldKey="isEnabled"
                fieldName="配信ステータス"
                disabled={placementIds.length === 0}
                initialValue={false}
              />
            </TableCell>
            <TableCell>アクション</TableCell>
            <TableCell>プロバイダ</TableCell>
            <TableCell>ドメイン</TableCell>
            <TableCell>バンドル</TableCell>
            <TableCell>プラットフォーム</TableCell>
            <TableCell>幅/高さ</TableCell>
            <TableCell>ADX BILLING ID</TableCell>
            <TableCell>メモ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placements.map((placement) => (
            <TableRow key={placement.id}>
              <TableCell>{atob(placement.id)}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip tooltip={placement.adSlotName}>
                  <Link to={`/admin/ad_slots/${placement.adSlotId}/placements`}>
                    {placement.adSlotName || "未設定"}
                  </Link>
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>
                <CorrectionTermField placement={placement.node} />
              </TableCell>
              <TableCell>{placement.adSlotTagId}</TableCell>
              <TableCell>
                <ProportionField placement={placement.node} />
              </TableCell>
              <TableCell>
                <IsEnabledSwitch placement={placement.node} />
              </TableCell>
              <TableCell>
                <DialogButton
                  title="配信先メモ入力"
                  minWidth={600}
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <AdSlotNoteEditForm
                      placementId={placement.id}
                      placementRef={placement.node}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  メモ入力
                </DialogButton>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="このプレイスメントを削除しますか？"
                  onAgree={async (changeDialog) => {
                    handleDeletePlacement(placement.id);
                    changeDialog(false);
                  }}
                >
                  削除
                </ConfirmButton>
              </TableCell>
              <TableCell>{placement.adSlotSspProvider}</TableCell>
              <TableCell>{placement.adSlotDomain}</TableCell>
              <TableCell>{placement.adSlotBundle}</TableCell>
              <TableCell>{placement.adSlotPlatform}</TableCell>
              <TableCell>
                {placement.adSlotWidth || "未設定"}/
                {placement.adSlotHeight || "未設定"}
              </TableCell>
              <TableCell>{placement.adSlotAdxBillingId || "未設定"}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip
                  tooltip={placement.adSlotNote || "メモ無し"}
                >
                  {placement.adSlotNote}
                </TruncatedTextWithTooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};

export default createPaginationContainer(
  PlacementList,
  {
    ad: graphql`
      fragment PlacementList_ad on Ad {
        id
        latestVersion
        placements(
          first: $count
          after: $cursor
          isEnabled: $isEnabled
          tagId: $tagId
        ) @connection(key: "PlacementList_placements") {
          edges {
            node {
              id
              correctionTerm
              isEnabled
              proportion
              latestVersion
              adSlot {
                id
                name
                domain
                tagId
                note
                sspProvider
                bundle
                platform
                width
                height
                adxBillingId
              }
              ...AdSlotNoteEditForm_placement
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.ad.placements as any;
    },
    getVariables(props, { count, cursor }) {
      return {
        projectId: props.projectId,
        adId: props.ad.id,
        count,
        cursor,
      };
    },
    query: graphql`
      query PlacementList_Query(
        $projectId: ID!
        $adId: ID!
        $isEnabled: Boolean
        $tagId: String
        $count: Int
        $cursor: String
      ) {
        project(id: $projectId) {
          ad(id: $adId) {
            ...PlacementList_ad
          }
        }
      }
    `,
  }
);
