import { styled } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";

import { AdCategory } from "~/__relay_artifacts__/CampaignEditFormForAdmin_campaign.graphql";
import {
  EditableTextField,
  Props as EditableTextFieldProps,
} from "~/components/EditableTextField";
import { useUpdateCampaignMutation } from "~/mutations/UpdateCampaignMutation";

import { Campaign } from "./types";
import { useValidationErrorHandler } from "./useValidationErrorHandler";

type Props = {
  campaign: Campaign;
};

const StyledEditableTextFieldWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const FeeField: React.FC<Props> = ({ campaign }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleValidationError = useValidationErrorHandler();
  const { updateCampaignMutation } = useUpdateCampaignMutation();

  const handleSubmit = React.useCallback<EditableTextFieldProps["onSubmit"]>(
    async ({ input }, { resetForm }) => {
      try {
        if (input === campaign.fee) return;
        if (typeof input !== "number") throw new Error("assertion error");
        const params = R.pick(
          [
            "id",
            "title",
            "status",
            "bidStrategy",
            "reportingOption",
            "frequencyCapEnabled",
            "goalAmount",
            "periodSince",
          ],
          campaign
        );
        await updateCampaignMutation({
          ...params,
          fee: input,
          clientVersion: campaign.latestVersion,
          category: campaign.categories as AdCategory[],
        });
        enqueueSnackbar("Feeを更新しました。", { variant: "success" });
      } catch (err) {
        handleValidationError(err);
        resetForm();
      }
    },
    [campaign, updateCampaignMutation, enqueueSnackbar, handleValidationError]
  );

  return (
    <StyledEditableTextFieldWrapper>
      <EditableTextField
        type="number"
        precision={2}
        style={{ width: 40 }}
        value={campaign.fee}
        onSubmit={handleSubmit}
      />
    </StyledEditableTextFieldWrapper>
  );
};
