import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { ExcludeScheduleEditForm_excludeSchedule } from "~/__relay_artifacts__/ExcludeScheduleEditForm_excludeSchedule.graphql";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateExcludeScheduleMutation } from "~/mutations/UpdateExcludeScheduleMutation";

import { ExcludeScheduleForm, FormValues, schema } from "./ExcludeScheduleForm";

type Props = {
  excludeScheduleRef: FragmentRef<ExcludeScheduleEditForm_excludeSchedule>;
  onSubmitCompleted: () => void;
};

const excludeScheduleFragment = graphql`
  fragment ExcludeScheduleEditForm_excludeSchedule on ExcludeSchedule {
    id
    name
    since
    till
    latestVersion
  }
`;

export const ExcludeScheduleEditForm: React.FC<Props> = ({
  excludeScheduleRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateExcludeScheduleMutation } = useUpdateExcludeScheduleMutation();
  const excludeSchedule = useFragment<ExcludeScheduleEditForm_excludeSchedule>(
    excludeScheduleFragment,
    excludeScheduleRef
  );

  const initialValues = React.useMemo<FormValues>(
    () => R.pick(["id", "name", "since", "till"], excludeSchedule),
    [excludeSchedule]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateExcludeSchedule } = await updateExcludeScheduleMutation({
          ...values,
          id: excludeSchedule.id,
          clientVersion: excludeSchedule.latestVersion,
        });
        if (!updateExcludeSchedule?.excludeSchedule) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("配信停止設定を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      updateExcludeScheduleMutation,
      excludeSchedule,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <ExcludeScheduleForm />
    </Formik>
  );
};
