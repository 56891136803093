import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeletePlacementMutation } from "~/__relay_artifacts__/DeletePlacementMutation.graphql";

const mutation = graphql`
  mutation DeletePlacementMutation($input: DeletePlacementInput!) {
    deletePlacement(input: $input) {
      deletedPlacementId
    }
  }
`;

const commit = (environment: Environment, adId: string, placementId: string) =>
  commitMutation<DeletePlacementMutation>(environment, {
    mutation,
    variables: {
      input: { placementId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: adId,
        connectionKeys: [
          {
            key: "PlacementList_placements",
          },
        ],
        pathToConnection: ["ad", "placements"],
        deletedIDFieldName: "deletedPlacementId",
      },
    ],
  });

export default commit;
