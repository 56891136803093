import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { useParams } from "react-router";

import {
  AdCategory,
  CampaignEditForm_campaign,
} from "~/__relay_artifacts__/CampaignEditForm_campaign.graphql";
import { CampaignForm, FormValues, schema } from "~/components/CampaignForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateCampaignMutation } from "~/mutations/UpdateCampaignMutation";
import { useUpdateDailyBudgetMutation } from "~/mutations/UpdateDailyBudgetMutation";
import { useUpdateMonthlyBudgetMutation } from "~/mutations/UpdateMonthlyBudgetMutation";

type Props = {
  campaignRef: FragmentRef<CampaignEditForm_campaign>;
  onSubmitCompleted: () => void;
};

const campaignFragment = graphql`
  fragment CampaignEditForm_campaign on Campaign {
    id
    title
    viewTrackingUrl
    clickTrackingUrl
    status
    bidStrategy
    reportingOption
    goalAmount
    note
    adomain
    fee
    feeMin
    frequencyCapEnabled
    frequencyCapAmount
    frequencyCapInterval
    periodSince
    periodTill
    os
    osVersionMin
    isBanditEnabled
    latestVersion
    isAutoRefreshPlacementsEnabled
    isPacingEnabled
    isPricingExplorationEnabled
    isCarryoverEnabled
    dailyBudget {
      id
      date
      baseAmount
    }
    monthlyBudget {
      id
      date
      amount
    }
    costCpi
    categories
  }
`;

type validationError = {
  message: string;
  path: any;
};

export const CampaignEditForm: React.FC<Props> = ({
  campaignRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { id } = useParams<{ id: string }>();
  const { updateCampaignMutation } = useUpdateCampaignMutation();
  const { updateDailyBudgetMutation } = useUpdateDailyBudgetMutation();
  const { updateMonthlyBudgetMutation } = useUpdateMonthlyBudgetMutation();
  const campaign = useFragment<CampaignEditForm_campaign>(
    campaignFragment,
    campaignRef
  );

  const initialValues = React.useMemo(() => {
    return R.assoc(
      "categories",
      campaign.categories.map((e) => e) as [AdCategory],
      R.assoc(
        "dailyBudget",
        campaign.dailyBudget.baseAmount,
        R.assoc(
          "monthlyBudget",
          campaign.monthlyBudget.amount,
          R.omit(["latestVersion", "dailyBudget", "monthlyBudget"], campaign)
        )
      )
    ) as FormValues;
  }, [campaign]);

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { periodSince, dailyBudget, monthlyBudget, categories, ...rest } =
          values;
        if (!periodSince) throw new Error("assertion failed");
        const { updateCampaign } = await updateCampaignMutation({
          id,
          periodSince,
          clientVersion: campaign.latestVersion,
          category: categories,
          ...rest,
        });
        if (!updateCampaign?.campaign) throw new Error("assertion failed");

        const { updateDailyBudget } = await updateDailyBudgetMutation({
          id: campaign.dailyBudget.id,
          date: campaign.dailyBudget.date,
          baseAmount: dailyBudget,
        });

        if (!updateDailyBudget?.dailyBudget)
          throw new Error("assertion failed");

        const { updateMonthlyBudget } = await updateMonthlyBudgetMutation({
          id: campaign.monthlyBudget.id,
          date: campaign.monthlyBudget.date,
          amount: monthlyBudget,
        });

        if (!updateMonthlyBudget?.monthlyBudget)
          throw new Error("assertion failed");

        enqueueSnackbar("キャンペーンを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        const errors = err.extensions.validationErrors as validationError[];
        errors.forEach((e) => {
          if (e.path.attribute === "base") {
            enqueueSnackbar(`${err.extensions.code}: ${e.path.type}`, {
              variant: "error",
            });
          }
        });
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      formErrorHandler,
      id,
      campaign.latestVersion,
      onSubmitCompleted,
      updateCampaignMutation,
      campaign.dailyBudget.id,
      campaign.dailyBudget.date,
      campaign.monthlyBudget.id,
      campaign.monthlyBudget.date,
      updateDailyBudgetMutation,
      updateMonthlyBudgetMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={CampaignForm}
    />
  );
};
