import { Tooltip } from "@material-ui/core";
import {
  Error as ErrorIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as React from "react";

import { IconText } from "~/components/atoms/IconText";

type Props = {
  status: keyof typeof StatusTexts;
  periodSince: string;
  periodTill: string | null;
};

const StatusTexts = {
  ACTIVE: "配信中",
  PENDING: "保留中",
  STOPPED: "停止中",
  "%future added value": "その他",
} as const;

const useStyles = makeStyles({
  iconTextWrapper: {
    display: "block",
  },
});

const StatusIcon: React.FC<Pick<Props, "status">> = ({ status }) => {
  switch (status) {
    case "ACTIVE":
      return (
        <>
          <PlayArrowIcon fontSize="small" /> {StatusTexts[status]}
        </>
      );
    case "PENDING":
      return (
        <>
          <PauseIcon fontSize="small" /> {StatusTexts[status]}
        </>
      );
    case "STOPPED":
      return (
        <>
          <StopIcon fontSize="small" /> {StatusTexts[status]}
        </>
      );
    default:
      return <>UNKNOWN STATUS</>;
  }
};

export const CampaignStatusColumn: React.FC<Props> = ({
  status,
  periodSince,
  periodTill,
}) => {
  const classes = useStyles();

  const isWithinPeriod = React.useMemo(() => {
    if (periodTill === null) {
      return DateFns.isAfter(new Date(), new Date(periodSince));
    }
    return DateFns.isWithinInterval(new Date(), {
      start: new Date(periodSince),
      end: new Date(periodTill),
    });
  }, [periodSince, periodTill]);

  return (
    <>
      <div className={classes.iconTextWrapper}>
        <IconText>
          <StatusIcon status={status} />
        </IconText>
      </div>
      {!isWithinPeriod && (
        <Tooltip
          title={
            <>{!isWithinPeriod && <p>・配信期間外であるため停止中です</p>}</>
          }
        >
          <div className={classes.iconTextWrapper}>
            <IconText>
              <ErrorIcon fontSize="small" /> 配信不可
            </IconText>
          </div>
        </Tooltip>
      )}
    </>
  );
};
