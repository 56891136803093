import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useMount } from "react-use";
import { useQuery } from "relay-hooks";

import { ClientCampaignDailyBudgetList_Query } from "~/__relay_artifacts__/ClientCampaignDailyBudgetList_Query.graphql";
import { ClientDailyBudgetListTableCard } from "~/components/ClientDailyBudgetListTableCard";
import { useDailyBudgetByMonthCache } from "~/globalState";

const query = graphql`
  query ClientCampaignDailyBudgetList_Query(
    $projectId: ID!
    $campaignId: ID!
    $month: ISO8601Date!
  ) {
    project(id: $projectId) {
      campaign(id: $campaignId) {
        ...ClientDailyBudgetListTableCard_campaign
      }
    }
  }
`;

const useCachedVariables = (projectId: string, campaignId: string) => {
  const [cache, setCache] = useDailyBudgetByMonthCache(projectId, campaignId);
  const [variables] = React.useState(() => ({
    month: cache.month || new Date().toISOString(),
  }));

  useMount(() => setCache(variables));

  return { variables };
};

export const ClientCampaignDailyBudgetList: React.FC = () => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { variables } = useCachedVariables(projectId, id);
  const { props, error } = useQuery<ClientCampaignDailyBudgetList_Query>(
    query,
    {
      projectId,
      campaignId: id,
      month: variables.month,
    }
  );

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project) return <div>project not found</div>;
  if (!props.project.campaign) return <div>campaign not found</div>;
  return (
    <ClientDailyBudgetListTableCard campaignRef={props.project.campaign} />
  );
};
