import { styled } from "@material-ui/styles";

export const StyledEditableTextFieldWrapper = styled("div")<
  {},
  { width?: number }
>((props) => ({
  display: "flex",
  alignItems: "center",
  maxWidth: props.width || 40,
}));
