/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectHeader_QueryVariables = {
    projectId: string;
    search?: string | null;
};
export type ProjectHeader_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectHeader_project">;
    } | null;
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectHeader_viewer">;
    };
};
export type ProjectHeader_Query = {
    readonly response: ProjectHeader_QueryResponse;
    readonly variables: ProjectHeader_QueryVariables;
};



/*
query ProjectHeader_Query(
  $projectId: ID!
  $search: String
) {
  project(id: $projectId) {
    ...ProjectHeader_project
    id
  }
  viewer {
    ...ProjectHeader_viewer
    id
  }
}

fragment AccountButton_viewer on User {
  id
  email
}

fragment AdminDrawerMenuList_viewer on User {
  ...AccountButton_viewer
}

fragment ProjectDrawerMenuList_project on Project {
  id
  name
  viewerCanReadCampaign
  viewerCanManageCreative
  viewerCanAdministrate
}

fragment ProjectDrawerMenuList_viewer on User {
  ...AccountButton_viewer
  role
}

fragment ProjectHeader_project on Project {
  ...ProjectSelectionButton_project
  ...ProjectDrawerMenuList_project
}

fragment ProjectHeader_viewer on User {
  id
  ...AccountButton_viewer
  ...AdminDrawerMenuList_viewer
  ...ProjectSelectionButton_viewer
  ...ProjectDrawerMenuList_viewer
}

fragment ProjectSelectionButton_project on Project {
  id
  name
}

fragment ProjectSelectionButton_viewer on User {
  role
  projects(search: $search) {
    edges {
      node {
        id
        name
        viewerCanAdministrate
        updatedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanAdministrate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectHeader_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectHeader_project"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectHeader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectHeader_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanReadCampaign",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanManageCreative",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "search",
                "variableName": "search"
              }
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ce6202a625273299fd4184fd4251422",
    "id": null,
    "metadata": {},
    "name": "ProjectHeader_Query",
    "operationKind": "query",
    "text": "query ProjectHeader_Query(\n  $projectId: ID!\n  $search: String\n) {\n  project(id: $projectId) {\n    ...ProjectHeader_project\n    id\n  }\n  viewer {\n    ...ProjectHeader_viewer\n    id\n  }\n}\n\nfragment AccountButton_viewer on User {\n  id\n  email\n}\n\nfragment AdminDrawerMenuList_viewer on User {\n  ...AccountButton_viewer\n}\n\nfragment ProjectDrawerMenuList_project on Project {\n  id\n  name\n  viewerCanReadCampaign\n  viewerCanManageCreative\n  viewerCanAdministrate\n}\n\nfragment ProjectDrawerMenuList_viewer on User {\n  ...AccountButton_viewer\n  role\n}\n\nfragment ProjectHeader_project on Project {\n  ...ProjectSelectionButton_project\n  ...ProjectDrawerMenuList_project\n}\n\nfragment ProjectHeader_viewer on User {\n  id\n  ...AccountButton_viewer\n  ...AdminDrawerMenuList_viewer\n  ...ProjectSelectionButton_viewer\n  ...ProjectDrawerMenuList_viewer\n}\n\nfragment ProjectSelectionButton_project on Project {\n  id\n  name\n}\n\nfragment ProjectSelectionButton_viewer on User {\n  role\n  projects(search: $search) {\n    edges {\n      node {\n        id\n        name\n        viewerCanAdministrate\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0618b1bbfd58ea07dd6e4ab4e71d0a7f';
export default node;
