import {
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useEffect, useMemo, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";

import { UserListTableCard_root } from "~/__relay_artifacts__/UserListTableCard_root.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { UserCreateForm } from "~/containers/UserCreateForm";
import UserDeleteButton from "~/containers/UserDeleteButton";
import { noop } from "~/lib/noop";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

type Props = {
  relay: RelayPaginationProp;
  root: UserListTableCard_root;
};

const UserListTableCard: FC<Props> = ({ relay, root }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);

  useEffect(() => {
    relay.loadMore(perPage, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const totalCount = root.users && root.users.totalCount;

  const users = useMemo(() => {
    const edges = root.users && root.users.edges;
    if (!edges) throw new Error("assertion failed");
    return edges.slice(page * perPage, page * perPage + perPage).map((edge) => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      const { id, role } = root.viewer;
      const isEditable = role === "ADMIN" && id !== node.id;
      return { ...node, isEditable };
    });
  }, [page, perPage, root.users, root.viewer]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          ユーザー一覧
        </Typography>
        <DialogButton
          title="ユーザー作成"
          render={({ close }) => <UserCreateForm onSubmitCompleted={close} />}
        >
          <Icon>add</Icon>
          <span>作成する</span>
        </DialogButton>
      </Toolbar>
      <ListTable minWidth={1200}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>役割</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>
                {user.isEditable && (
                  <UserDeleteButton userId={user.id}>削除</UserDeleteButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(event) => setPerPage(Number(event.target.value))}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  UserListTableCard,
  {
    root: graphql`
      fragment UserListTableCard_root on Query {
        viewer {
          id
          role
          email
        }
        users(first: $count, after: $cursor, orderBy: $orderBy)
          @connection(key: "UserListTableCard_users", filters: []) {
          edges {
            node {
              id
              email
              role
            }
          }
          totalCount
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.root && props.root.users;
    },
    getVariables(_, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: fragmentVariables.orderBy,
      };
    },
    query: graphql`
      query UserListTableCard_Query(
        $count: Int!
        $cursor: String
        $orderBy: UserOrder
      ) {
        ...UserListTableCard_root
      }
    `,
  }
);
