import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  MoveCampaignInput,
  MoveCampaignMutation,
} from "~/__relay_artifacts__/MoveCampaignMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation MoveCampaignMutation($input: MoveCampaignInput!) {
    moveCampaign(input: $input) {
      campaign {
        id
        title
        status
        createdAt
        updatedAt
        adomain
        ads {
          totalCount
        }
        os
        osVersionMin
        ...CampaignEditForm_campaign
      }
    }
  }
`;

export const useMoveCampaignMutation = () => {
  const environment = useRelayEnvironment();

  const moveCampaignMutation = React.useCallback(
    (input: MoveCampaignInput) =>
      commitMutation<MoveCampaignMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { moveCampaignMutation };
};
