import { Toolbar } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { Home_Query } from "~/__relay_artifacts__/Home_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { LinkButton } from "~/components/atoms/LinkButton";

import { Report } from "./Report";

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    width: "100%",
    overflowX: "scroll",
  },
  toolbar: {
    margin: 0,
    minHeight: 3,
  },
  root: {
    marginTop: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(0),
  },
}));

const query = graphql`
  query Home_Query($projectId: ID!, $first: Int, $after: String) {
    viewer {
      ...MembershipList_viewer
    }
    project(id: $projectId) {
      ...MembershipList_project
      viewerCanReadCampaign
      viewerCanAdministrate
    }
  }
`;

export const Home: React.FC = () => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<Home_Query>(query, {
    projectId,
    first: 100,
  });

  const showReport = React.useMemo(
    () =>
      !!props?.project?.viewerCanAdministrate ||
      !!props?.project?.viewerCanReadCampaign,
    [
      props?.project?.viewerCanAdministrate,
      props?.project?.viewerCanReadCampaign,
    ]
  );

  const isAdvertiser = React.useMemo(
    () => !!props?.project?.viewerCanAdministrate,
    [props?.project?.viewerCanAdministrate]
  );

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  return (
    <>
      <div className={classes.pageHeader}>
        <PageHeader title="ホーム">
          {isAdvertiser ? (
            <LinkButton color="inherit" to={`/projects/${projectId}/edit`}>
              <Icon className={classes.leftIcon}>edit</Icon>
              <span>プロジェクトを編集する</span>
            </LinkButton>
          ) : (
            <></>
          )}
        </PageHeader>
        <Toolbar className={classes.toolbar} />
      </div>
      <div className={classes.root}>{showReport ? <Report /> : <></>}</div>
    </>
  );
};
