import * as DateFns from "date-fns";
import * as R from "ramda";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useMount } from "react-use";
import { useQuery } from "relay-hooks";

import { CampaignDetail_Query } from "~/__relay_artifacts__/CampaignDetail_Query.graphql";
import { AdFilterFormValues } from "~/components/AdListTableCard";
import { CampaignDetail as CampaignDetailComponent } from "~/components/CampaignDetail";
import { useAdFilterCache, useDailyBudgetByMonthCache } from "~/globalState";
import { useQueryString } from "~/lib/useQueryString";

const query = graphql`
  query CampaignDetail_Query(
    $projectId: ID!
    $campaignId: ID!
    $first: Int
    $after: String
    $month: ISO8601Date!
    $orderBy: AdOrder
    $title: String
    $tagId: String
    $status: [CampaignStatus!]
    $scheduleSetId: ID
  ) {
    project(id: $projectId) {
      ...CampaignDetail_project
    }
  }
`;

const useCachedVariables = (projectId: string, campaignId: string) => {
  const { params } = useQueryString<AdFilterFormValues>();
  const [cache, setCache] = useAdFilterCache(projectId, campaignId);
  const [monthCache, setMonthCache] = useDailyBudgetByMonthCache(
    projectId,
    campaignId
  );
  const [variables] = React.useState(() => ({
    orderBy: {
      field: params.field || cache.field,
      direction: params.direction || cache.direction,
    },
    title: params.title || cache.title,
    tagId: params.tagId || cache.tagId,
    status: params.status || cache.status,
    scheduleSetId: params.scheduleSetId || cache.scheduleSetId,
  }));
  const [dailyBudgetsVariables] = React.useState(() => ({
    month: monthCache.month || DateFns.formatISO(new Date()),
  }));
  useMount(() => {
    setCache(
      R.omit(["orderBy"], {
        ...variables,
        field: variables.orderBy.field,
        direction: variables.orderBy.direction,
      })
    );
    setMonthCache(dailyBudgetsVariables);
  });

  return { variables, dailyBudgetsVariables };
};

export const CampaignDetail: React.FC = () => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { variables, dailyBudgetsVariables } = useCachedVariables(
    projectId,
    id
  );
  const { props, error } = useQuery<CampaignDetail_Query>(query, {
    ...variables,
    projectId,
    campaignId: id,
    first: 100,
    month: dailyBudgetsVariables.month,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project) return <div>project not found</div>;
  return <CampaignDetailComponent projectRef={props.project} />;
};
