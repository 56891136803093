/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PlacementSetList_QueryVariables = {};
export type PlacementSetList_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"PlacementSetListTableCard_root">;
};
export type PlacementSetList_Query = {
    readonly response: PlacementSetList_QueryResponse;
    readonly variables: PlacementSetList_QueryVariables;
};



/*
query PlacementSetList_Query {
  ...PlacementSetListTableCard_root
}

fragment PlacementSetEditForm_placementSet on PlacementSet {
  id
  title
  latestVersion
}

fragment PlacementSetListTableCard_root on Query {
  placementSets(first: 100) {
    edges {
      node {
        id
        title
        ...PlacementSetEditForm_placementSet
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlacementSetList_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PlacementSetListTableCard_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PlacementSetList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "PlacementSetConnection",
        "kind": "LinkedField",
        "name": "placementSets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlacementSetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlacementSet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "placementSets(first:100)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "PlacementSetListTableCard_placementSets",
        "kind": "LinkedHandle",
        "name": "placementSets"
      }
    ]
  },
  "params": {
    "cacheID": "b20a2388019fd3b96f846e291365af38",
    "id": null,
    "metadata": {},
    "name": "PlacementSetList_Query",
    "operationKind": "query",
    "text": "query PlacementSetList_Query {\n  ...PlacementSetListTableCard_root\n}\n\nfragment PlacementSetEditForm_placementSet on PlacementSet {\n  id\n  title\n  latestVersion\n}\n\nfragment PlacementSetListTableCard_root on Query {\n  placementSets(first: 100) {\n    edges {\n      node {\n        id\n        title\n        ...PlacementSetEditForm_placementSet\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '75e1c0c41d6c4bc9f377aebec3b9e3ed';
export default node;
