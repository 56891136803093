import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateCampaignInput,
  CreateCampaignMutation,
} from "~/__relay_artifacts__/CreateCampaignMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreateCampaignMutation(
    $connections: [ID!]!
    $input: CreateCampaignInput!
  ) {
    createCampaign(input: $input) {
      campaignEdge @prependEdge(connections: $connections) {
        node {
          id
          title
          status
          createdAt
          updatedAt
          adomain
          ads {
            totalCount
          }
          os
          osVersionMin
          stoppedAds {
            edges {
              node {
                id
                title
              }
            }
          }
          stoppedCreatives {
            edges {
              node {
                id
                title
              }
            }
          }
          stoppedPlacements {
            edges {
              node {
                id
                adSlot {
                  tagId
                }
              }
            }
          }
          categories
          ...CampaignEditForm_campaign
        }
      }
      campaign {
        id
      }
    }
  }
`;

let clientMutationId = 0;

export const useCreateCampaignMutation = () => {
  const environment = useRelayEnvironment();

  const createCampaignMutation = useCallback(
    (input: CreateCampaignInput, connectionId: string) =>
      commitMutation<CreateCampaignMutation>(environment, {
        mutation,
        variables: {
          input: {
            clientMutationId: `${clientMutationId++}`,
            ...input,
          },
          connections: [connectionId],
        },
      }),
    [environment]
  );

  return { createCampaignMutation };
};
