/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotOrderField = "BUNDLE" | "CREATED_AT" | "DOMAIN" | "NAME" | "SSP_PROVIDER" | "TAG_ID" | "UPDATED_AT" | "%future added value";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type PricingLogic = "FLOOR_PLUS" | "MANUAL" | "ML" | "%future added value";
export type AdSlotOrder = {
    direction: OrderDirection;
    field: AdSlotOrderField;
};
export type AdSlotList_QueryVariables = {
    count?: number | null;
    cursor?: string | null;
    withDeleted: boolean;
    orderBy: AdSlotOrder;
    domain?: string | null;
    bundle?: string | null;
    width?: number | null;
    height?: number | null;
    sspProvider: Array<AdSlotSspProvider>;
    platform: Array<AdSlotPlatform>;
    logicType: Array<PricingLogic>;
    adxBillingId?: string | null;
    search?: string | null;
    withArchived?: boolean | null;
};
export type AdSlotList_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AdSlotListTableCard_root">;
};
export type AdSlotList_Query = {
    readonly response: AdSlotList_QueryResponse;
    readonly variables: AdSlotList_QueryVariables;
};



/*
query AdSlotList_Query(
  $count: Int
  $cursor: String
  $withDeleted: Boolean!
  $orderBy: AdSlotOrder!
  $domain: String
  $bundle: String
  $width: Int
  $height: Int
  $sspProvider: [AdSlotSspProvider!]!
  $platform: [AdSlotPlatform!]!
  $logicType: [PricingLogic!]!
  $adxBillingId: String
  $search: String
  $withArchived: Boolean
) {
  ...AdSlotListTableCard_root
}

fragment AdSlotEditForm_adSlot on AdSlot {
  id
  name
  domain
  tagId
  bundle
  note
  width
  height
  sspProvider
  platform
  os
  adxBillingId
  targetWinRate
}

fragment AdSlotListTableCard_root on Query {
  viewer {
    id
    role
    email
  }
  adSlots(first: $count, after: $cursor, withDeleted: $withDeleted, orderBy: $orderBy, domain: $domain, bundle: $bundle, width: $width, height: $height, sspProvider: $sspProvider, platform: $platform, logicType: $logicType, adxBillingId: $adxBillingId, search: $search, withArchived: $withArchived) {
    edges {
      node {
        id
        name
        domain
        tagId
        bundle
        note
        width
        height
        sspProvider
        platform
        bannerFormat
        os
        metadata
        deletedAt
        archived
        pricing {
          id
          logicType
          manualBidPrice
          ...PricingEditForm_pricing
        }
        placements {
          totalCount
        }
        adxBillingId
        targetWinRate
        ...AdSlotEditForm_adSlot
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}

fragment PricingEditForm_pricing on Pricing {
  id
  logicType
  manualBidPrice
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adxBillingId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bundle"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domain"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "height"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "logicType"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sspProvider"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "width"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withDeleted"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "adxBillingId",
    "variableName": "adxBillingId"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "bundle",
    "variableName": "bundle"
  },
  {
    "kind": "Variable",
    "name": "domain",
    "variableName": "domain"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "height",
    "variableName": "height"
  },
  {
    "kind": "Variable",
    "name": "logicType",
    "variableName": "logicType"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "platform",
    "variableName": "platform"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sspProvider",
    "variableName": "sspProvider"
  },
  {
    "kind": "Variable",
    "name": "width",
    "variableName": "width"
  },
  {
    "kind": "Variable",
    "name": "withArchived",
    "variableName": "withArchived"
  },
  {
    "kind": "Variable",
    "name": "withDeleted",
    "variableName": "withDeleted"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdSlotList_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AdSlotListTableCard_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v13/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v11/*: any*/),
      (v5/*: any*/),
      (v10/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdSlotList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "AdSlotConnection",
        "kind": "LinkedField",
        "name": "adSlots",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlotEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdSlot",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bundle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sspProvider",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platform",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bannerFormat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "os",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metadata",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pricing",
                    "kind": "LinkedField",
                    "name": "pricing",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logicType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manualBidPrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementConnection",
                    "kind": "LinkedField",
                    "name": "placements",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adxBillingId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetWinRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "AdSlotListTableCard_adSlots",
        "kind": "LinkedHandle",
        "name": "adSlots"
      }
    ]
  },
  "params": {
    "cacheID": "464a4373dc42fa62b9095ef0a5e2b37f",
    "id": null,
    "metadata": {},
    "name": "AdSlotList_Query",
    "operationKind": "query",
    "text": "query AdSlotList_Query(\n  $count: Int\n  $cursor: String\n  $withDeleted: Boolean!\n  $orderBy: AdSlotOrder!\n  $domain: String\n  $bundle: String\n  $width: Int\n  $height: Int\n  $sspProvider: [AdSlotSspProvider!]!\n  $platform: [AdSlotPlatform!]!\n  $logicType: [PricingLogic!]!\n  $adxBillingId: String\n  $search: String\n  $withArchived: Boolean\n) {\n  ...AdSlotListTableCard_root\n}\n\nfragment AdSlotEditForm_adSlot on AdSlot {\n  id\n  name\n  domain\n  tagId\n  bundle\n  note\n  width\n  height\n  sspProvider\n  platform\n  os\n  adxBillingId\n  targetWinRate\n}\n\nfragment AdSlotListTableCard_root on Query {\n  viewer {\n    id\n    role\n    email\n  }\n  adSlots(first: $count, after: $cursor, withDeleted: $withDeleted, orderBy: $orderBy, domain: $domain, bundle: $bundle, width: $width, height: $height, sspProvider: $sspProvider, platform: $platform, logicType: $logicType, adxBillingId: $adxBillingId, search: $search, withArchived: $withArchived) {\n    edges {\n      node {\n        id\n        name\n        domain\n        tagId\n        bundle\n        note\n        width\n        height\n        sspProvider\n        platform\n        bannerFormat\n        os\n        metadata\n        deletedAt\n        archived\n        pricing {\n          id\n          logicType\n          manualBidPrice\n          ...PricingEditForm_pricing\n        }\n        placements {\n          totalCount\n        }\n        adxBillingId\n        targetWinRate\n        ...AdSlotEditForm_adSlot\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n\nfragment PricingEditForm_pricing on Pricing {\n  id\n  logicType\n  manualBidPrice\n}\n"
  }
};
})();
(node as any).hash = 'be1506fe3da1851b9d9fb0d3316cb046';
export default node;
