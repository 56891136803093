import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { useCreateMaintenanceMutation } from "~/mutations/CreateMaintenanceMutation";

import { FormValues, MaintenanceForm, schema } from "./MaintenanceForm";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues = {
  message: "",
};

export const MaintenanceCreateForm: React.FC<Props> = ({
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { createMaintenanceMutation } = useCreateMaintenanceMutation();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createMaintenance } = await createMaintenanceMutation(values);
        if (!createMaintenance?.maintenanceEdge)
          throw new Error("assertion failed");
        enqueueSnackbar("メッセージを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      createMaintenanceMutation,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => <MaintenanceForm {...formikHelpers} />}
    </Formik>
  );
};
