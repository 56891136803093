import {
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { isEmpty } from "ramda";
import * as React from "react";

import { CreativeLabelItem } from "./CreativeLabelItem";

export type Creative = {
  id: string;
  title: string;
  adm: string | null;
  height: number | null;
  width: number | null;
  createdAt: string;
};

type Props = {
  textFieldValue: string;
  selectedCreatives: Creative[];
  selectableCreatives: Creative[];
  onCreativeAdd: (id: string) => void | Promise<void>;
  onCreativeDelete: (id: string) => void | Promise<void>;
  onTextFieldChange: React.ChangeEventHandler<HTMLInputElement>;
};

const StyledToolbar = styled(Toolbar)<Theme>(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const CreativeAllocationForm: React.FC<Props> = ({
  textFieldValue,
  selectedCreatives,
  selectableCreatives,
  onCreativeAdd,
  onCreativeDelete,
  onTextFieldChange,
}) => (
  <>
    <StyledToolbar>
      <TextField
        fullWidth
        variant="outlined"
        label="絞り込み検索"
        value={textFieldValue}
        onChange={onTextFieldChange}
      />
    </StyledToolbar>
    <DialogContent>
      <List subheader={<ListSubheader disableSticky>選択済み</ListSubheader>}>
        {selectedCreatives.map((creative) => (
          <ListItem key={creative.id}>
            <CreativeLabelItem
              id={creative.id}
              title={creative.title}
              html={creative.adm}
              height={creative.height}
              width={creative.width}
              createdAt={creative.createdAt}
            />
            <ListItemSecondaryAction>
              <Tooltip title="削除する">
                <IconButton
                  aria-label="Delete"
                  onClick={() => onCreativeDelete(creative.id)}
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader disableSticky>
            選択可能なクリエイティブ一覧
          </ListSubheader>
        }
      >
        {isEmpty(selectableCreatives) ? (
          <ListItem>
            <ListItemText primary="選択可能なクリエイティブはありません" />
          </ListItem>
        ) : (
          selectableCreatives.map((creative) => (
            <ListItem key={creative.id}>
              <CreativeLabelItem
                id={creative.id}
                title={creative.title}
                html={creative.adm}
                height={creative.height}
                width={creative.width}
                createdAt={creative.createdAt}
              />
              <ListItemSecondaryAction>
                <Tooltip title="追加する">
                  <IconButton
                    aria-label="Delete"
                    onClick={() => onCreativeAdd(creative.id)}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}
      </List>
    </DialogContent>
  </>
);
