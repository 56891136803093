import {
  Button,
  Checkbox,
  Icon,
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Assignment as AssignmentIcon,
  CancelScheduleSend as CancelScheduleSendIcon,
  FileCopyOutlined as CopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Schedule as ScheduleIcon,
  Stop as StopIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { Link } from "react-router-dom";

import {
  AdListTableCard_campaign,
  CampaignStatus,
} from "~/__relay_artifacts__/AdListTableCard_campaign.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ListTableToolbar } from "~/components/ListTableToolbar";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { TableCell } from "~/components/atoms/TableCell";
import { AdCreateForm } from "~/containers/AdCreateForm";
import { AdEditForm } from "~/containers/AdEditForm";
import { useAdFilterCache } from "~/globalState";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { useQueryString } from "~/lib/useQueryString";
import { delayChunkPromise } from "~/lib/utils";
import copyAdMutation from "~/mutations/CopyAdMutation";
import deleteScheduleSettingMutation from "~/mutations/DeleteScheduleSettingMutation";
import deletedAndUpdateAdMutation from "~/mutations/DeletedAndUpdateAdMutation";
import { useUpdateAdStatusMutation } from "~/mutations/UpdateAdStatusMutation";

import { AdFilterButton } from "./AdFilterButton";
import { FormValues } from "./AdFilterForm";
import { AdStatusColumn } from "./AdStatusColumn";
import { CreativeBulkAllocationForm } from "./CreativeBulkAllocationForm";
import { ScheduleSetBulkForm } from "./ScheduleSetBulkForm";
import { StatusTexts } from "./constants";

type Props = {
  relay: RelayPaginationProp;
  campaign: AdListTableCard_campaign;
  projectId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  spacer: {
    flex: "1 1 100%",
  },
  button: {
    whiteSpace: "nowrap",
    margin: theme.spacing(1),
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
}));

const useAdFilter = (
  projectId: string,
  campaignId: string,
  relay: Props["relay"],
  perPage: number
) => {
  const { params, changeQueryString } = useQueryString<FormValues>();
  const [cache, setCache] = useAdFilterCache(projectId, campaignId);
  const [formValues, setFormValues] = useState<FormValues>({
    ...cache,
    ...params,
  });

  useEffect(() => {
    relay.refetchConnection(perPage, noop, {
      orderBy: {
        direction: formValues.direction,
        field: formValues.field,
      },
      title: formValues.title,
      tagId: formValues.tagId,
      status: formValues.status,
      scheduleSetId: formValues.scheduleSetId,
    });
  }, [
    formValues.direction,
    formValues.field,
    formValues.title,
    formValues.tagId,
    formValues.status,
    formValues.scheduleSetId,
    params,
    perPage,
    relay,
  ]);

  const handleAdFilterSubmit = useCallback(
    (values: FormValues) => {
      setFormValues(values);
      setCache(values);
      changeQueryString(values);
    },
    [changeQueryString, setCache]
  );

  return {
    formValues,
    handleAdFilterSubmit,
  };
};

const CampaignAdTableCard: FC<Props> = ({ campaign, projectId, relay }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { updateAdStatusMutation } = useUpdateAdStatusMutation();
  const { formValues, handleAdFilterSubmit } = useAdFilter(
    projectId,
    campaign.id,
    relay,
    perPage
  );
  const { totalCount } = campaign.ads;

  const ads = useMemo(() => {
    const edges = campaign.ads?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge?.node;
    });
  }, [campaign.ads, page, perPage]);

  const selectedAds = useMemo(
    () => ads.filter((ad) => selectedIds.includes(ad.id)),
    [ads, selectedIds]
  );

  const selectedAdIds = useMemo(
    () => selectedAds.map((ad) => ad.id),
    [selectedAds]
  );

  const scheduleSetOptions = useMemo(() => {
    const options = ads
      .filter((ad) => !!ad.scheduleSet)
      .map((ad) => ({
        value: ad.scheduleSet!.id,
        label: ad.scheduleSet!.title,
      }));
    return [{ value: "", label: "未選択" }, ...options];
  }, [ads]);

  const handleSelectAll = useCallback(() => {
    setSelectedIds(
      selectedIds.length === ads.length ? [] : ads.map((ad) => ad.id)
    );
  }, [ads, selectedIds]);

  const handleSelect = useCallback(
    (id: string) => {
      const next = selectedIds.includes(id)
        ? selectedIds.filter((s) => s !== id)
        : [...new Set([...selectedIds, id])];
      setSelectedIds(next);
    },
    [selectedIds]
  );

  const handleDeleteSelectIds = useCallback(
    async (campaignId: string) => {
      try {
        const mutations = selectedIds.map((id) =>
          deletedAndUpdateAdMutation(relay.environment, id, campaignId)
        );
        await delayChunkPromise(mutations);
        enqueueSnackbar(`広告を削除しました。`, { variant: "success" });
        setSelectedIds([]);
      } catch (_) {
        enqueueSnackbar(`広告を削除できませんでした。`, { variant: "error" });
      }
    },
    [enqueueSnackbar, relay.environment, selectedIds]
  );

  const handleCopy = useCallback(
    async (adId: string) => {
      try {
        await copyAdMutation(relay.environment, campaign.id, adId);
        enqueueSnackbar(`広告をコピーしました。`, { variant: "success" });
      } catch (_) {
        enqueueSnackbar(`広告のコピーに失敗しました。`, { variant: "error" });
      }
    },
    [campaign.id, enqueueSnackbar, relay.environment]
  );

  const handleUpdateStatus = useCallback(
    async (status: CampaignStatus) => {
      try {
        const mutations = selectedAds.map((ad) =>
          updateAdStatusMutation({
            id: ad.id,
            status: status,
            clientVersion: ad.latestVersion,
          })
        );
        await delayChunkPromise(mutations);
        enqueueSnackbar(`ステータスを${StatusTexts[status]}に更新しました。`, {
          variant: "success",
        });
        setSelectedIds([]);
      } catch (_) {
        enqueueSnackbar(`ステータスの更新に失敗しました。`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, selectedAds, updateAdStatusMutation]
  );

  const handleBulkDeleteScheduleSetting = useCallback(async () => {
    try {
      const selectedScheduleSettings = ads
        .filter((ad) => selectedIds.includes(ad.id))
        .map((ad) => ad.scheduleSetting)
        .filter(
          (
            scheduleSetting
          ): scheduleSetting is NonNullable<typeof scheduleSetting> =>
            scheduleSetting != null
        );

      const mutations = selectedScheduleSettings.map((scheduleSetting) =>
        deleteScheduleSettingMutation(relay.environment, scheduleSetting.id)
      );
      await delayChunkPromise(mutations);
      enqueueSnackbar("スケジュールセットを取り外しました", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("スケジュールセットの取り外しに失敗しました", {
        variant: err,
      });
    }
  }, [enqueueSnackbar, ads, selectedIds, relay.environment]);

  useEffect(() => {
    relay.loadMore(perPage, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Paper className={classes.root}>
      <ListTableToolbar highlighted={selectedIds.length > 0}>
        <div className={classes.title}>
          {selectedIds.length > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {selectedIds.length} 個選択
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="inherit">
              広告の一覧
            </Typography>
          )}
        </div>
        {selectedIds.length > 0 ? (
          <>
            <div className={classes.actions}>
              <Tooltip title="配信を保留にする">
                <Button onClick={() => handleUpdateStatus("PENDING")}>
                  <PauseIcon />
                </Button>
              </Tooltip>
              <Tooltip title="配信を開始する">
                <Button onClick={() => handleUpdateStatus("ACTIVE")}>
                  <PlayArrowIcon />
                </Button>
              </Tooltip>
              <Tooltip title="配信を停止する">
                <Button onClick={() => handleUpdateStatus("STOPPED")}>
                  <StopIcon />
                </Button>
              </Tooltip>
              <Tooltip title="クリエイティブ一括割当">
                <DialogButton
                  fullScreen
                  title="クリエイティブ一括割当"
                  minWidth={600}
                  enableAlert={false}
                  render={() => (
                    <CreativeBulkAllocationForm adIds={selectedAdIds} />
                  )}
                >
                  <AssignmentIcon />
                </DialogButton>
              </Tooltip>
              <Tooltip title="スケジュールセット一括割当">
                <DialogButton
                  title="スケジュールセット一括割当"
                  enableAlert={false}
                  render={({ close }) => (
                    <ScheduleSetBulkForm
                      adIds={selectedAdIds}
                      onSubmitCompleted={() => {
                        setSelectedIds([]);
                        close();
                      }}
                    />
                  )}
                >
                  <ScheduleIcon />
                </DialogButton>
              </Tooltip>
              <Tooltip title="スケジュールセット一括取り外し">
                <Button onClick={() => handleBulkDeleteScheduleSetting()}>
                  <CancelScheduleSendIcon />
                </Button>
              </Tooltip>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <ConfirmButton
                key="delete"
                color="inherit"
                confirmTitle="この広告を削除しますか？"
                onAgree={(changeDialog) => {
                  handleDeleteSelectIds(campaign.id);
                  changeDialog(false);
                }}
              >
                <Tooltip title="削除">
                  <DeleteIcon />
                </Tooltip>
              </ConfirmButton>
            </div>
          </>
        ) : (
          <>
            <div className={classes.actions}>
              <DialogButton
                title="広告作成"
                className={classes.button}
                render={({ close }) => (
                  <AdCreateForm onSubmitCompleted={close} />
                )}
              >
                <Icon>add</Icon> 新規作成
              </DialogButton>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <AdFilterButton
                formValues={formValues}
                scheduleSetOptions={scheduleSetOptions}
                onSubmit={handleAdFilterSubmit}
              />
            </div>
          </>
        )}
      </ListTableToolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={ads.length > 0 && ads.length === selectedIds.length}
                onClick={handleSelectAll}
              />
            </TableCell>
            <TableCell>名前</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>配信先</TableCell>
            <TableCell>スケジュールセット</TableCell>
            <TableCell>IDFA無視</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ads.map((ad) => (
            <TableRow
              selected={selectedIds.includes(ad.id)}
              key={ad.id}
              hover
              onClick={() => handleSelect(ad.id)}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={selectedIds.includes(ad.id)} />
              </TableCell>
              <TableCell>
                <Link
                  to={`/projects/${projectId}/campaigns/${campaign.id}/ads/${ad.id}`}
                >
                  {ad.title}
                </Link>
              </TableCell>
              <TableCell>
                <AdStatusColumn status={ad.status} />
              </TableCell>
              <TableCell>{ad.placements.totalCount}</TableCell>
              <TableCell>
                {ad.scheduleSet ? (
                  ad.scheduleSet.title
                ) : (
                  <>
                    <div>割り当てられていないため</div>
                    <div>24時間配信されます</div>
                  </>
                )}
              </TableCell>
              <TableCell>{ad.ignoreIdfa ? "有効" : "無効"}</TableCell>
              <TableCell>{parseISO(ad.createdAt)}</TableCell>
              <TableCell>
                <ConfirmButton
                  key="copy"
                  color="inherit"
                  confirmTitle="コピーしますか？"
                  onAgree={(changeDialog) => {
                    handleCopy(ad.id);
                    changeDialog(false);
                  }}
                >
                  <Tooltip title="コピーする">
                    <CopyIcon />
                  </Tooltip>
                </ConfirmButton>
                <Tooltip title="編集する">
                  <DialogButton
                    title="広告編集"
                    render={({ close }) => (
                      <AdEditForm adRef={ad} onSubmitCompleted={close} />
                    )}
                  >
                    <EditIcon />
                  </DialogButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          setPage(page);
          setSelectedIds([]);
        }}
        onRowsPerPageChange={(event) => {
          setPerPage(Number(event.target.value));
          setSelectedIds([]);
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  CampaignAdTableCard,
  {
    campaign: graphql`
      fragment AdListTableCard_campaign on Campaign {
        id
        ads(
          first: $first
          after: $after
          orderBy: $orderBy
          title: $title
          tagId: $tagId
          status: $status
          scheduleSetId: $scheduleSetId
        ) @connection(key: "AdListTableCard_ads", filters: []) {
          totalCount
          edges {
            node {
              id
              title
              status
              createdAt
              ignoreIdfa
              width
              height
              sspProvider
              platform
              scheduleSetting {
                id
              }
              latestVersion
              scheduleSet {
                id
                title
              }
              placements {
                totalCount
              }
              ...AdEditForm_ad
            }
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.campaign.ads as any;
    },
    getVariables(
      { projectId, campaign },
      { count, cursor },
      { orderBy, title, status, scheduleSetId }
    ) {
      return {
        projectId,
        orderBy,
        title,
        status,
        scheduleSetId,
        campaignId: campaign.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query AdListTableCard_Query(
        $projectId: ID!
        $campaignId: ID!
        $first: Int
        $after: String
        $orderBy: AdOrder
        $title: String
        $tagId: String
        $status: [CampaignStatus!]
        $scheduleSetId: ID
      ) {
        project(id: $projectId) {
          campaign(id: $campaignId) {
            ...AdListTableCard_campaign
          }
        }
      }
    `,
  }
);
