import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import {
  FormValues,
  MonthlyBudgetForm,
  schema,
} from "~/components/MonthlyBudgetForm";
import { useCreateMonthlyBudgetMutation } from "~/mutations/CreateMonthlyBudgetMutation";

type Props = {
  campaignId: string;
  onSubmitCompleted: () => void;
};

const initialValues = {
  amount: 0,
  date: new Date().toISOString(),
};

const MonthlyBudgetCreateForm: React.FC<Props> = ({
  campaignId,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createMonthlyBudgetMutation } = useCreateMonthlyBudgetMutation();
  const formErrorHandler = useFormErrorHandler();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createMonthlyBudget } = await createMonthlyBudgetMutation({
          campaignId,
          date: values.date,
          amount: values.amount,
        });
        if (!createMonthlyBudget?.monthlyBudgetEdge) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("月予算スケジュールを追加しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      campaignId,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
      createMonthlyBudgetMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => <MonthlyBudgetForm {...formikHelpers} />}
    </Formik>
  );
};

export default MonthlyBudgetCreateForm;
