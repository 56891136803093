import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  SubmitAdxCreativeInput,
  SubmitAdxCreativeMutation,
} from "~/__relay_artifacts__/SubmitAdxCreativeMutation.graphql";

const mutation = graphql`
  mutation SubmitAdxCreativeMutation($input: SubmitAdxCreativeInput!) {
    submitAdxCreative(input: $input) {
      creativeEdge {
        node {
          id
          width
          height
          adxStatus
          adxSubmittedAt
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: SubmitAdxCreativeInput) =>
  commitMutation<SubmitAdxCreativeMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
