/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdatePlacementInput = {
    clientMutationId?: string | null;
    clientVersion?: number | null;
    id: string;
    proportion?: number | null;
    correctionTerm?: number | null;
};
export type UpdatePlacementMutationVariables = {
    input: UpdatePlacementInput;
};
export type UpdatePlacementMutationResponse = {
    readonly updatePlacement: {
        readonly placement: {
            readonly id: string;
            readonly correctionTerm: number;
            readonly proportion: number;
            readonly latestVersion: number | null;
        } | null;
    } | null;
};
export type UpdatePlacementMutation = {
    readonly response: UpdatePlacementMutationResponse;
    readonly variables: UpdatePlacementMutationVariables;
};



/*
mutation UpdatePlacementMutation(
  $input: UpdatePlacementInput!
) {
  updatePlacement(input: $input) {
    placement {
      id
      correctionTerm
      proportion
      latestVersion
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePlacementPayload",
    "kind": "LinkedField",
    "name": "updatePlacement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Placement",
        "kind": "LinkedField",
        "name": "placement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "correctionTerm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proportion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latestVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePlacementMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePlacementMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7cee3cf9e61a27c881a50a70dcaa7361",
    "id": null,
    "metadata": {},
    "name": "UpdatePlacementMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePlacementMutation(\n  $input: UpdatePlacementInput!\n) {\n  updatePlacement(input: $input) {\n    placement {\n      id\n      correctionTerm\n      proportion\n      latestVersion\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '20202dcb91cf7ab2d3b208966d52df79';
export default node;
