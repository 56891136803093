/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignDebugSettingCard_campaign = {
    readonly " $fragmentRefs": FragmentRefs<"CampaignAllowIpListCard_campaign">;
    readonly " $refType": "CampaignDebugSettingCard_campaign";
};
export type CampaignDebugSettingCard_campaign$data = CampaignDebugSettingCard_campaign;
export type CampaignDebugSettingCard_campaign$key = {
    readonly " $data"?: CampaignDebugSettingCard_campaign$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignDebugSettingCard_campaign">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignDebugSettingCard_campaign",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignAllowIpListCard_campaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
(node as any).hash = '0731e3723b1d6423a61aae626e733a89';
export default node;
