import { Grid } from "@material-ui/core";
import { FormikTimeField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  since: string | null;
  till: string | null;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  since: yup.string().required(),
  till: yup.string().required(),
});

export const ScheduleForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTimeField name="since" label="開始時間" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormikTimeField name="till" label="終了時間" fullWidth />
      </Grid>
    </Grid>
  </DialogForm>
);
