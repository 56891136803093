import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import { AdForm, FormValues, schema } from "~/components/AdForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createAdMutation from "~/mutations/CreateAdMutation";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  title: "",
  adomain: null,
  isRotatable: true,
  ignoreIdfa: true,
  width: null,
  height: null,
  sspProvider: "ALL",
  platform: "ALL",
};

export const AdCreateForm: React.FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const environment = useRelayEnvironment();
  const formErrorHandler = useFormErrorHandler();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createAd } = await createAdMutation(
          environment,
          { campaignId: id, ...values },
          { connectionName: "AdListTableCard_ads", parentId: id }
        );
        if (!createAd?.adEdge?.node) throw new Error("assertion failed");
        enqueueSnackbar("広告を作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [enqueueSnackbar, environment, formErrorHandler, id, onSubmitCompleted]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AdForm />
    </Formik>
  );
};
