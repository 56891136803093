/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateInvoiceInput = {
    clientMutationId?: string | null;
    id: string;
    title?: string | null;
    invoiceNumber?: number | null;
    periodSince: string;
    periodTill: string;
    billingDate?: string | null;
    dueDate?: string | null;
    invoiceLines?: Array<InvoiceLineInput> | null;
};
export type InvoiceLineInput = {
    description: string;
    unitPrice: string;
    quantity: number;
};
export type UpdateInvoiceMutationVariables = {
    input: UpdateInvoiceInput;
};
export type UpdateInvoiceMutationResponse = {
    readonly updateInvoice: {
        readonly invoice: {
            readonly id: string;
            readonly freeeInvoiceId: string;
            readonly title: string | null;
            readonly periodSince: string | null;
            readonly periodTill: string | null;
            readonly invoiceNumber: number | null;
            readonly billingDate: string | null;
            readonly dueDate: string | null;
            readonly " $fragmentRefs": FragmentRefs<"InvoiceEditForm_invoice">;
        } | null;
    } | null;
};
export type UpdateInvoiceMutation = {
    readonly response: UpdateInvoiceMutationResponse;
    readonly variables: UpdateInvoiceMutationVariables;
};



/*
mutation UpdateInvoiceMutation(
  $input: UpdateInvoiceInput!
) {
  updateInvoice(input: $input) {
    invoice {
      id
      freeeInvoiceId
      title
      periodSince
      periodTill
      invoiceNumber
      billingDate
      dueDate
      ...InvoiceEditForm_invoice
    }
  }
}

fragment InvoiceEditForm_invoice on Invoice {
  id
  title
  billingDate
  invoiceNumber
  dueDate
  periodSince
  periodTill
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freeeInvoiceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodSince",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodTill",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateInvoicePayload",
        "kind": "LinkedField",
        "name": "updateInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invoice",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InvoiceEditForm_invoice"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateInvoicePayload",
        "kind": "LinkedField",
        "name": "updateInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invoice",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b93c85a89f3e77df5a7b581c487a946",
    "id": null,
    "metadata": {},
    "name": "UpdateInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateInvoiceMutation(\n  $input: UpdateInvoiceInput!\n) {\n  updateInvoice(input: $input) {\n    invoice {\n      id\n      freeeInvoiceId\n      title\n      periodSince\n      periodTill\n      invoiceNumber\n      billingDate\n      dueDate\n      ...InvoiceEditForm_invoice\n    }\n  }\n}\n\nfragment InvoiceEditForm_invoice on Invoice {\n  id\n  title\n  billingDate\n  invoiceNumber\n  dueDate\n  periodSince\n  periodTill\n}\n"
  }
};
})();
(node as any).hash = 'db3f5b4123c6558b74b35072d4bc83dd';
export default node;
