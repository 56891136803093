import { Grid } from "@material-ui/core";
import { FormikNumberField, FormikSelectField } from "@vrize/vrizead-use";
import { useFormikContext } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  logicType: "MANUAL" | "ML" | "FLOOR_PLUS";
  manualBidPrice: number | null;
};

export const schema = yup.object({
  logicType: yup.string().required(),
  manualBidPrice: yup.number().nullable(),
});

const logicTypeOptions = [
  { value: "MANUAL", label: "手動" },
  { value: "ML", label: "ML" },
  { value: "FLOOR_PLUS", label: "Floor+1" },
];

export const PricingForm: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikSelectField
            required
            fullWidth
            name="logicType"
            label="入札方式"
            options={logicTypeOptions}
          />
        </Grid>
        {values.logicType === "MANUAL" && (
          <Grid item xs={12}>
            <FormikNumberField
              required
              fullWidth
              name="manualBidPrice"
              label="手動入札価格"
              margin="normal"
            />
          </Grid>
        )}
      </Grid>
    </DialogForm>
  );
};
