/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignRestrictionListCard_campaign = {
    readonly " $fragmentRefs": FragmentRefs<"CampaignDomainRestrictionListCard_campaign" | "CampaignDenyTagIdListCard_campaign">;
    readonly " $refType": "CampaignRestrictionListCard_campaign";
};
export type CampaignRestrictionListCard_campaign$data = CampaignRestrictionListCard_campaign;
export type CampaignRestrictionListCard_campaign$key = {
    readonly " $data"?: CampaignRestrictionListCard_campaign$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignRestrictionListCard_campaign">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignRestrictionListCard_campaign",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignDomainRestrictionListCard_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignDenyTagIdListCard_campaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
(node as any).hash = '7fb20226b512cb5695c66c000f121140';
export default node;
