/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteDailyBudgetInput = {
    clientMutationId?: string | null;
    dailyBudgetId: string;
};
export type DeleteDailyBudgetMutationVariables = {
    input: DeleteDailyBudgetInput;
};
export type DeleteDailyBudgetMutationResponse = {
    readonly deleteDailyBudget: {
        readonly deletedDailyBudgetId: string;
    } | null;
};
export type DeleteDailyBudgetMutation = {
    readonly response: DeleteDailyBudgetMutationResponse;
    readonly variables: DeleteDailyBudgetMutationVariables;
};



/*
mutation DeleteDailyBudgetMutation(
  $input: DeleteDailyBudgetInput!
) {
  deleteDailyBudget(input: $input) {
    deletedDailyBudgetId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDailyBudgetPayload",
    "kind": "LinkedField",
    "name": "deleteDailyBudget",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedDailyBudgetId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDailyBudgetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDailyBudgetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d364c509b903376636e0c75f3c3f8fa8",
    "id": null,
    "metadata": {},
    "name": "DeleteDailyBudgetMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDailyBudgetMutation(\n  $input: DeleteDailyBudgetInput!\n) {\n  deleteDailyBudget(input: $input) {\n    deletedDailyBudgetId\n  }\n}\n"
  }
};
})();
(node as any).hash = '7d6f27f5f2c032d7338b496b5bd4de01';
export default node;
