import { useSnackbar } from "notistack";
import * as React from "react";

import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { modernEnvironment } from "~/lib/Environment";
import deleteMembershipMutation from "~/mutations/DeleteMembershipMutation";

type Props = {
  membershipId: string;
  projectId: string;
};

export const MembershipDeleteButton: React.FC<Props> = ({
  children,
  membershipId,
  projectId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = React.useCallback(async () => {
    try {
      const { deleteMembership } = await deleteMembershipMutation(
        modernEnvironment,
        membershipId,
        {
          type: "RANGE_DELETE",
          parentID: projectId,
          connectionKeys: [
            {
              key: "MembershipList_memberships",
            },
          ],
          pathToConnection: ["project", "memberships"],
          deletedIDFieldName: "deletedMembershipId",
        }
      );

      const id = deleteMembership && deleteMembership.deletedMembershipId;
      if (!id) throw new Error("assertion failed");

      enqueueSnackbar(`メンバーを削除しました。`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [enqueueSnackbar, membershipId, projectId]);

  return (
    <ConfirmButton
      variant="outlined"
      color="inherit"
      confirmTitle="メンバーを削除しますか？"
      confirmContent=""
      onAgree={(changeDialog) => {
        onDelete().then(() => changeDialog(false));
      }}
    >
      {children}
    </ConfirmButton>
  );
};
