import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@material-ui/core";
import { FormikDateField, FormikNumberField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

export type FormValues = {
  amount: number;
  date: string;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  date: yup.string().required(),
  amount: yup.number().required(),
});

export const MonthlyBudgetForm: React.FC<Props> = ({ isSubmitting }) => (
  <Form>
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormikDateField required fullWidth name="date" label="日付" />
          </Grid>
          <Grid item xs={12}>
            <FormikNumberField
              required
              fullWidth
              name="amount"
              label="予算"
              margin="normal"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
        >
          保存する
        </Button>
      </CardActions>
    </Card>
  </Form>
);
