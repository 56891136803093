import { Grid } from "@material-ui/core";
import { FormikNumberField } from "@vrize/vrizead-use";
import { Formik } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "~/components/DialogForm";

export type FormValues = {
  targetValue: number;
};

const schema = yup.object({
  targetValue: yup.number().required(),
});

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  fieldName: string;
};

export const PlacementBulkUpdateNumberForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  fieldName,
}) => (
  <Formik<FormValues>
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={schema}
  >
    <DialogForm>
      <Grid container spacing={3}>
        <FormikNumberField
          fullWidth
          required
          precision={2}
          margin="normal"
          name="targetValue"
          label={fieldName}
        />
      </Grid>
    </DialogForm>
  </Formik>
);
