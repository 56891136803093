type Tag = {
  open: string;
  close: string;
};

const tag: Tag = {
  open: "<v-viewable",
  close: "</v-viewable>",
};

export const validateTag = (value: string | undefined): boolean => {
  if (value?.indexOf(tag.open) === -1 || value?.indexOf(tag.close) === -1) {
    return false;
  } else {
    return true;
  }
};
