import { FC, useMemo } from "react";

import { parseISO } from "~/lib/parseISO";

type Props = {
  since: string | null;
  till: string | null;
};

export const Period: FC<Props> = ({ since, till }) => {
  const { formattedSince, formattedTill } = useMemo(
    () => ({
      formattedSince: since ? parseISO(since) : "未設定",
      formattedTill: till ? parseISO(till) : "未設定",
    }),
    [since, till]
  );

  return (
    <>
      {formattedSince}
      <span> ~ </span>
      {formattedTill}
    </>
  );
};
