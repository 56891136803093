import {
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";

import { CampaignAudits_campaign } from "~/__relay_artifacts__/CampaignAudits_campaign.graphql";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";

import { ListTable } from "./ListTable";
import { TableCell } from "./atoms/TableCell";

type Props = {
  relay: RelayPaginationProp;
  campaign: CampaignAudits_campaign;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

const CampaignAudits: React.FC<Props> = ({ campaign, relay }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const totalCount = campaign.audits.totalCount;

  const audits = React.useMemo(() => {
    const edges = campaign.audits?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [campaign.audits, page, perPage]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1">変更履歴</Typography>
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>変更日時</TableCell>
            <TableCell>変更内容</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audits.map((audit) => (
            <TableRow key={audit.id}>
              <TableCell>
                {parseISO(audit.createdAt, "yyyy/MM/dd HH:mm:ss")}
              </TableCell>
              <TableCell>{audit.changes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};

export default createPaginationContainer(
  CampaignAudits,
  {
    campaign: graphql`
      fragment CampaignAudits_campaign on Campaign {
        id
        project {
          id
        }
        audits(first: $first, after: $after)
          @connection(key: "CampaignAudits_audits", filters: []) {
          edges {
            node {
              id
              changes
              createdAt
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.campaign.audits as any;
    },
    getVariables(props, { count, cursor }) {
      return {
        projectId: props.campaign.project.id,
        campaignId: props.campaign.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query CampaignAudits_Query(
        $projectId: ID!
        $campaignId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          campaign(id: $campaignId) {
            ...CampaignAudits_campaign
          }
        }
      }
    `,
  }
);
