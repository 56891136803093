import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { useParams } from "react-router";

import { MaintenanceEditForm_maintenance } from "~/__relay_artifacts__/MaintenanceEditForm_maintenance.graphql";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateMaintenanceMutation } from "~/mutations/UpdateMaintenanceMutation";

import { FormValues, MaintenanceForm, schema } from "./MaintenanceForm";

type Props = {
  maintenanceRef: FragmentRef<MaintenanceEditForm_maintenance>;
  onSubmitCompleted: () => void;
};

const maintenanceFragment = graphql`
  fragment MaintenanceEditForm_maintenance on Maintenance {
    id
    message
  }
`;

export const MaintenanceEditForm: React.FC<Props> = ({
  maintenanceRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { id } = useParams<{ id: string }>();
  const { updateMaintenanceMutation } = useUpdateMaintenanceMutation();
  const maintenance = useFragment<MaintenanceEditForm_maintenance>(
    maintenanceFragment,
    maintenanceRef
  );

  const initialValues = React.useMemo<FormValues>(
    () => maintenance,
    [maintenance]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateMaintenance } = await updateMaintenanceMutation({
          id,
          ...values,
        });
        if (!updateMaintenance?.maintenance)
          throw new Error("assertion failed");
        enqueueSnackbar("メンテナンス設定を更新しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      formErrorHandler,
      id,
      onSubmitCompleted,
      updateMaintenanceMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => <MaintenanceForm {...formikHelpers} />}
    </Formik>
  );
};
