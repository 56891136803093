import { Formik, FormikConfig } from "formik";
import * as React from "react";

import {
  FormValues,
  PlacementForm,
  validationSchema,
} from "~/components/PlacementForm";

export type Props = {
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  correctionTerm: 1,
  proportion: 1,
};

export const PlacementCreateForm: React.FC<Props> = ({
  onSubmit,
  onSubmitCompleted,
}) => {
  const handleSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (...args) => {
      await onSubmit(...args);
      onSubmitCompleted();
    },
    [onSubmit, onSubmitCompleted]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <PlacementForm />
    </Formik>
  );
};
