import { Box } from "@material-ui/core";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { PromotionDetail_Query } from "~/__relay_artifacts__/PromotionDetail_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { useTabs } from "~/lib/useTabs";

import { PromotionCampaignListTableCard } from "./PromotionCampaignListTableCard";

type PageContentProps = {
  project: PromotionDetail_Query["response"]["project"];
};

const tabs = [{ id: "campaigns", label: "キャンペーン" }] as const;

const PageContent: React.FC<PageContentProps> = ({ project }) => {
  const { projectId } = useParams<{
    id: string;
    projectId: string;
  }>();
  const { currentTabName, tabsElement } = useTabs(tabs);
  const promotion = React.useMemo(() => {
    if (!project?.promotion) throw new Error("assertion error");
    return project.promotion;
  }, [project]);

  return (
    <>
      <PageHeader
        title={
          <Box>
            {promotion.title}
            <Box ml={1} display="inline" color="text.hint">
              #{window.atob(promotion.id)}
            </Box>
          </Box>
        }
        backTo={`/projects/${projectId}/promotions`}
        tabs={tabsElement}
      >
        <Box flexGrow={1} />
      </PageHeader>
      {currentTabName === "campaigns" && (
        <PromotionCampaignListTableCard
          promotionRef={promotion}
          projectId={projectId}
        />
      )}
    </>
  );
};

const query = graphql`
  query PromotionDetail_Query($projectId: ID!, $promotionId: ID!) {
    project(id: $projectId) {
      promotion(id: $promotionId) {
        id
        title
        ...PromotionEditForm_promotion
        ...PromotionCampaignListTableCard_promotion
      }
    }
  }
`;

export const PromotionDetail: React.FC = () => {
  const { projectId, id } = useParams<{ id: string; projectId: string }>();
  const { props, error } = useQuery<PromotionDetail_Query>(query, {
    projectId,
    promotionId: id,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  return <PageContent project={props.project} />;
};
