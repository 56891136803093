import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { PlacementSettingList_Query } from "~/__relay_artifacts__/PlacementSettingList_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import PlacementSettingListTableCard from "~/components/PlacementSettingListTableCard/PlacementSettingListTableCard";

type Props = {
  title?: string;
};

const query = graphql`
  query PlacementSettingList_Query(
    $placementSetId: ID!
    $first: Int
    $after: String
  ) {
    placementSet(id: $placementSetId) {
      title
      ...PlacementSettingListTableCard_placementSet
    }
  }
`;

const Wrapper: React.FC<Props> = ({ title, children }) => (
  <>
    <PageHeader title={`${title}のAdSlot一覧`} backTo="/admin/placement_sets" />
    {children}
  </>
);

export const PlacementSettingList: React.FC = () => {
  const { placementSetId } = useParams<{ placementSetId: string }>();
  const { props, error } = useQuery<PlacementSettingList_Query>(query, {
    placementSetId,
    first: 100,
  });

  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  if (!props.placementSet) return <div>not found</div>;
  return (
    <Wrapper title={props.placementSet.title}>
      <PlacementSettingListTableCard placementSet={props.placementSet} />
    </Wrapper>
  );
};
