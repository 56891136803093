import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql } from "react-relay";
import { Link } from "react-router-dom";

import { PlacementSetListTableCard_root } from "~/__relay_artifacts__/PlacementSetListTableCard_root.graphql";
import { PlacementSetList_Query } from "~/__relay_artifacts__/PlacementSetList_Query.graphql";
import { PlacementSetCreateForm } from "~/containers/PlacementSetCreateForm";
import { PlacementSetEditForm } from "~/containers/PlacementSetEditForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import deletePlacementSetMutation from "~/mutations/DeletePlacementSetMutation";

import { DialogButton } from "./DialogButton";
import { ListTable } from "./ListTable";
import { ConfirmButton } from "./atoms/ConfirmButton";

type Props = {
  placementSetRef: NonNullable<PlacementSetList_Query["response"]>;
};

const fragment = graphql`
  fragment PlacementSetListTableCard_root on Query {
    placementSets(first: 100)
      @connection(key: "PlacementSetListTableCard_placementSets") {
      edges {
        node {
          id
          title
          ...PlacementSetEditForm_placementSet
        }
      }
      totalCount
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 200,
  },
}));

export const PlacementSetListTableCard: FC<Props> = ({ placementSetRef }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const fragments = useFragment<PlacementSetListTableCard_root>(
    fragment,
    placementSetRef
  );
  const totalCount = fragments.placementSets.totalCount;

  const placementSets = useMemo(() => {
    const edges = fragments.placementSets?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [fragments.placementSets, page, perPage]);

  const onDeleteClick = useCallback(
    async (placementSetId: string) => {
      try {
        const { deletePlacementSet } = await deletePlacementSetMutation(
          environment,
          placementSetId
        );
        const deletedPlacementSetId =
          deletePlacementSet && deletePlacementSet.deletedPlacementSetId;
        if (!deletedPlacementSetId) throw new Error("assertion failed");
        enqueueSnackbar("プレイスメントセットを削除しました", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, environment]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          プレイスメントセット一覧
        </Typography>
        <DialogButton
          title="プレイスメントセット作成"
          render={({ close }) => (
            <PlacementSetCreateForm onSubmitCompleted={close} />
          )}
        >
          作成
        </DialogButton>
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placementSets.map((placementSet) => (
            <TableRow key={placementSet.id}>
              <TableCell>{atob(placementSet.id)}</TableCell>
              <TableCell>
                <Link
                  to={`placement_sets/${placementSet.id}/placement_settings`}
                >
                  {placementSet.title}
                </Link>
              </TableCell>
              <TableCell>
                <DialogButton
                  title="プレイスメントセット編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <PlacementSetEditForm
                      placementSetRef={placementSet}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="このプレイスメントセットを削除しますか？"
                  onAgree={() => onDeleteClick(placementSet.id)}
                >
                  削除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(event) => setPerPage(Number(event.target.value))}
      />
    </Paper>
  );
};
