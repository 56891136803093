import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as React from "react";
import { CSVLink } from "react-csv";

import { ReportList_project } from "~/__relay_artifacts__/ReportList_project.graphql";

import { ReportAggregate } from "./ReportAggregate";
import { TableCell } from "./atoms/TableCell";

type Props = {
  measureFields: string[];
  project: ReportList_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  reportAggregate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  aggregateTitle: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
  },
}));

const cellStyles: React.CSSProperties = {
  wordWrap: "break-word",
  whiteSpace: "normal",
};

const measureLabels: Record<string, string> = {
  DATE: "日付",
  CLICK_COUNT: "クリック数",
  TOTAL_INSTALL_COUNT: "インストール数",
  WIN_COUNT: "インプレッション数",
  MEDIA_WIN_COUNT: "インプレッション数",
  GROSS_BUDGET_CONSUMPTION: "COST",
  NET_BUDGET_CONSUMPTION: "収益",
  NET_CPC: "CPC",
  GROSS_CPC: "CPC",
  NET_CPI: "CPI",
  GROSS_CPI: "CPI",
};

const toObject = (project: ReportList_project, measures: string[]) =>
  project.report.measures.map((measure) => {
    const map: Record<string, any> = {};
    measure.forEach((value, i) => {
      const field = measures[i];
      map[field] = value;
    });
    return map;
  });

const trimNotation = (
  measure: Record<string, any>,
  isCsv: boolean
): Record<string, any> => {
  const result: Record<string, any> = {};
  Object.keys(measure).forEach((key) => {
    const label = measureLabels[key] || key;
    switch (key) {
      case "WIN_COUNT":
      case "MEDIA_WIN_COUNT":
      case "CLICK_COUNT":
      case "TOTAL_INSTALL_COUNT":
        result[label] = parseInt(measure[key] || 0, 10).toLocaleString();
        break;
      case "CTR":
      case "CVR":
        result[label] = parseFloat(measure[key] || 0).toFixed(2) + "%";
        break;
      case "NET_CPC":
      case "NET_CPI":
      case "NET_BUDGET_CONSUMPTION":
      case "GROSS_CPC":
      case "GROSS_CPI":
      case "GROSS_BUDGET_CONSUMPTION":
        if (isCsv) {
          result[label] = parseInt(measure[key] || 0, 10).toLocaleString();
        } else {
          result[label] =
            parseInt(measure[key] || 0, 10).toLocaleString() + "円";
        }
        break;
      default:
        result[label] = measure[key];
        break;
    }
  });
  return result;
};

export const ReportList: React.FC<Props> = ({ project, measureFields }) => {
  const classes = useStyles();
  const measures = project.report.measures;
  const measureMap = toObject(project, measureFields);
  const trimMeasureMap = measureMap.map((v) => trimNotation(v, false));
  const trimMeasureMapCsv = measureMap.map((v) => trimNotation(v, true));

  return measures.length === 0 ? (
    <div className={classes.reportAggregate}>データがありません</div>
  ) : (
    <>
      <div className={classes.reportAggregate}>
        <ReportAggregate measures={measureMap} />
      </div>
      <CSVLink
        filename={`3d-ad-report-${DateFns.format(new Date(), "M/d/yyyy")}.csv`}
        data={trimMeasureMapCsv}
      >
        CSVをダウンロード
      </CSVLink>
      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              {measureFields.map((field, i) => (
                <TableCell key={i} size="small" style={cellStyles}>
                  {measureLabels[field] || field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {trimMeasureMap.map((measure, i) => (
              <TableRow key={i}>
                {Object.keys(measure).map((key) => (
                  <TableCell
                    style={cellStyles}
                    size="small"
                    key={`${i}-${key}`}
                  >
                    {measure[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};
