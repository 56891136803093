import { Button, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FormikSelectField, useFormErrorHandler } from "@vrize/vrizead-use";
import { Form, Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";

import { useCreateCreativeTrackerSettingMutation } from "~/mutations/CreateCreativeTrackerSettingMutation";
import { useDeleteCreativeTrackerSettingMutation } from "~/mutations/DeleteCreativeTrackerSettingMutation";
import { useUpdateCreativeTrackerSettingMutation } from "~/mutations/UpdateCreativeTrackerSettingMutation";

type FormValues = {
  trackerId: string;
};

type Props = {
  creativeId: string;
  initialTrackerId: FormValues["trackerId"];
  trackerOptions: { value: string; label: string }[];
};

const StyledGridContainer = styled(Grid)({
  width: "100%",
  padding: "8px 16px",
});

export const CreativeTrackerSelectForm: FC<Props> = ({
  creativeId,
  initialTrackerId,
  trackerOptions,
}) => {
  const formErrorHandler = useFormErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { createCreativeTrackerSettingMutation } =
    useCreateCreativeTrackerSettingMutation();
  const { updateCreativeTrackerSettingMutation } =
    useUpdateCreativeTrackerSettingMutation();
  const { deleteCreativeTrackerSettingMutation } =
    useDeleteCreativeTrackerSettingMutation();

  const initialValues = useMemo(
    () => ({ trackerId: initialTrackerId }),
    [initialTrackerId]
  );

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ trackerId }, { setErrors }) => {
      const label = "トラッカー設定";
      try {
        if (initialValues.trackerId !== "" && trackerId === "") {
          const { deleteCreativeTrackerSetting } =
            await deleteCreativeTrackerSettingMutation(creativeId);
          const tracker = deleteCreativeTrackerSetting?.creative?.tracker;
          if (tracker) throw new Error("assertion failed");
          enqueueSnackbar(`${label}を削除しました`, { variant: "success" });
        } else if (initialValues.trackerId === "" && trackerId !== "") {
          const { createCreativeTrackerSetting } =
            await createCreativeTrackerSettingMutation({
              creativeId,
              trackerId,
            });
          const tracker = createCreativeTrackerSetting?.creative?.tracker;
          if (!tracker) throw new Error("assertion failed");
          enqueueSnackbar(`${label}を作成しました`, { variant: "success" });
        } else {
          // update
          const { updateCreativeTrackerSetting } =
            await updateCreativeTrackerSettingMutation({
              creativeId,
              trackerId,
            });
          const tracker = updateCreativeTrackerSetting?.creative?.tracker;
          if (!tracker) throw new Error("assertion failed");
          enqueueSnackbar(`${label}を更新しました`, { variant: "success" });
        }
      } catch (error) {
        formErrorHandler(error, setErrors);
      }
    },
    [
      creativeId,
      enqueueSnackbar,
      formErrorHandler,
      initialValues.trackerId,
      createCreativeTrackerSettingMutation,
      deleteCreativeTrackerSettingMutation,
      updateCreativeTrackerSettingMutation,
    ]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Form>
          <StyledGridContainer container spacing={3}>
            <Grid item xs={10}>
              <FormikSelectField
                fullWidth
                name="trackerId"
                label="選択中のトラッカー"
                options={trackerOptions}
              />
              {/* ここに警告メッセージを追加 */}
              {dirty && (
                <p style={{ color: "red" }}>
                  まだトラッカー変更は保存されていません
                </p>
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!dirty}
              >
                保存
              </Button>
            </Grid>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};
