import {
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import logo from "~/3dad_logo.png";
import { AdminHeader_viewer } from "~/__relay_artifacts__/AdminHeader_viewer.graphql";
import { AdminDrawerMenuList } from "~/components/DrawerMenuList";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  viewerRef: FragmentRef<AdminHeader_viewer>;
};

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: theme.zIndex.appBar - 1,
    width: DRAWER_WIDTH,
  },
  logo: {
    width: 124,
  },
  logoWrapper: {
    width: DRAWER_WIDTH,
  },
  toolbar: theme.mixins.toolbar,
}));

const viewerFragment = graphql`
  fragment AdminHeader_viewer on User {
    id
    ...AccountButton_viewer
    ...AdminDrawerMenuList_viewer
    ...ProjectSelectionButton_viewer
    ...ProjectDrawerMenuList_viewer
  }
`;

export const AdminHeader: React.FC<Props> = ({ viewerRef }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const viewer = useFragment<AdminHeader_viewer>(viewerFragment, viewerRef);

  const handleToggle = React.useCallback(() => {
    setDrawerOpen((s) => !s);
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Hidden smUp implementation="css">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.logoWrapper}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClick={handleToggle}
          onClose={handleToggle}
        >
          <div className={classes.toolbar} />
          <AdminDrawerMenuList viewerRef={viewer} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          open
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
        >
          <div className={classes.toolbar} />
          <AdminDrawerMenuList viewerRef={viewer} />
        </Drawer>
      </Hidden>
    </div>
  );
};
