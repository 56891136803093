import { useFormErrorHandler } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import {
  ExpenseUpsertForm as ExpenseUpsertFormComponent,
  FormValues,
  schema,
} from "~/components/ExpenseUpsertForm";
import { noop } from "~/lib/noop";
import { useUpsertExpenseMutation } from "~/mutations/UpsertExpenseMutation";

type Props = {
  campaignId: string;
  campaignTitle: string;
  selectableBeforeDate: boolean;
  onSubmitCompleted?: () => void;
};

export const ExpenseUpsertForm: React.FC<Props> = ({
  campaignId,
  campaignTitle,
  selectableBeforeDate,
  onSubmitCompleted = noop,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { upsertExpenseMutation } = useUpsertExpenseMutation();

  const initialValues = React.useMemo(
    () => ({
      date: new Date(),
      rate: null,
      maxAssignableExpenseRate: 10,
    }),
    []
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ date, rate, maxAssignableExpenseRate }, { setErrors }) => {
      try {
        if (
          maxAssignableExpenseRate &&
          rate &&
          maxAssignableExpenseRate < rate &&
          !window.confirm(
            `追加費用の上限(${maxAssignableExpenseRate})を超えています。本当に更新しますか？`
          )
        ) {
          return;
        }
        const { upsertExpense } = await upsertExpenseMutation({
          campaignId,
          date: DateFns.formatISO(date, { representation: "date" }),
          rate,
        });
        if (!upsertExpense?.expense) throw new Error("assertion failed");
        enqueueSnackbar("追加費用を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      upsertExpenseMutation,
      campaignId,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => (
        <ExpenseUpsertFormComponent
          {...formikHelpers}
          campaignId={campaignId}
          campaignTitle={campaignTitle}
          selectableBeforeDate={selectableBeforeDate}
        />
      )}
    </Formik>
  );
};
