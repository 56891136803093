import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";
import { useDebounce } from "use-debounce";

import { CreativeBulkAllocationForm_Query } from "~/__relay_artifacts__/CreativeBulkAllocationForm_Query.graphql";
import {
  Creative,
  CreativeAllocationForm,
} from "~/components/CreativeAllocationForm";
import { containsInsensitive } from "~/lib/containsInsensitive";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import { delayChunkPromise } from "~/lib/utils";
import createAdCreativeMutation from "~/mutations/CreateAdCreativeMutation";
import deleteAdCreativeMutation from "~/mutations/DeleteAdCreativeMutation";

type Props = {
  adIds: string[];
};

const query = graphql`
  query CreativeBulkAllocationForm_Query($projectId: ID!, $adIds: [ID!]!) {
    project(id: $projectId) {
      ads(ids: $adIds) {
        id
        creatives(first: 100)
          @connection(key: "CreateAdCreativeMutation_creatives") {
          edges {
            node {
              id
              title
              adm
              height
              width
              createdAt
            }
          }
        }
        selectableCreatives(first: 100)
          @connection(key: "DeleteAdCreativeMutation_selectableCreatives") {
          edges {
            node {
              id
              title
              adm
              height
              width
              createdAt
            }
          }
        }
      }
    }
  }
`;

const uniqByCreativeId = R.pipe(
  R.uniqBy((c: Creative) => c.id),
  R.sort(R.descend(R.prop("createdAt")))
);

export const CreativeBulkAllocationForm: React.FC<Props> = ({ adIds }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [searchText, setSearchText] = React.useState("");
  const { props, retry } = useQuery<CreativeBulkAllocationForm_Query>(query, {
    projectId,
    adIds,
  });

  const creativesByAdId = React.useMemo(() => {
    const ads = props?.project?.ads || [];
    return R.mergeAll(
      ads.map((ad) => {
        const edges = ad.creatives.edges;
        const adCreatives = edges!.map((edge) => edge!.node!);
        return { [ad.id]: adCreatives };
      })
    );
  }, [props]);

  const allCreatives = React.useMemo(() => {
    const creatives = R.values(creativesByAdId).flat();
    return uniqByCreativeId(creatives);
  }, [creativesByAdId]);

  const allSelectableCreatives = React.useMemo(() => {
    const ads = props?.project?.ads || [];
    const selectableCreatives = ads
      .map((ad) => {
        const edges = ad.selectableCreatives.edges;
        return edges!.map((edge) => edge!.node!);
      })
      .flat();
    return uniqByCreativeId(selectableCreatives);
  }, [props]);

  const [filteredSelectableCreatives] = useDebounce(
    allSelectableCreatives.filter(
      (selectableCreative) =>
        containsInsensitive(searchText, atob(selectableCreative.id)) ||
        containsInsensitive(searchText, selectableCreative.title)
    ),
    250
  );

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => setSearchText(e.target.value), []);

  const handleCreativesAdd = React.useCallback(
    async (creativeId: string) => {
      try {
        const mutations = adIds
          .filter((adId) => {
            const adCreativeIds = creativesByAdId[adId].map((c) => c.id);
            return !adCreativeIds.includes(creativeId);
          })
          .map((adId) =>
            createAdCreativeMutation(environment, creativeId, adId)
          );
        await delayChunkPromise(mutations);
        enqueueSnackbar("配信クリエイティブを追加しました", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [adIds, creativesByAdId, enqueueSnackbar, environment]
  );

  const handleCreativesDelete = React.useCallback(
    async (creativeId: string) => {
      try {
        const mutations = adIds
          .filter((adId) => {
            const adCreativeIds = creativesByAdId[adId].map((c) => c.id);
            return adCreativeIds.includes(creativeId);
          })
          .map((adId) =>
            deleteAdCreativeMutation({
              environment,
              adId,
              creativeId,
              options: {
                parentId: adId,
                connectionName: "CreativeBulkAllocationForm_creatives",
              },
            })
          );
        await delayChunkPromise(mutations);
        enqueueSnackbar("配信クリエイティブを削除しました", {
          variant: "success",
        });
        retry();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [adIds, creativesByAdId, enqueueSnackbar, environment, retry]
  );

  return (
    <CreativeAllocationForm
      textFieldValue={searchText}
      selectedCreatives={allCreatives}
      selectableCreatives={filteredSelectableCreatives}
      onCreativeAdd={handleCreativesAdd}
      onCreativeDelete={handleCreativesDelete}
      onTextFieldChange={handleTextFieldChange}
    />
  );
};
