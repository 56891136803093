import { Grid } from "@material-ui/core";
import {
  FormikMultipleSelectField,
  FormikSelectField,
  FormikTextField,
} from "@vrize/vrizead-use";
import { Formik } from "formik";
import * as React from "react";

import { DialogForm } from "~/components/DialogForm";

type Status = "ACTIVE" | "PENDING" | "STOPPED";

export type FormValues = {
  direction: "DESC" | "ASC";
  field: "CREATED_AT" | "TITLE";
  title: string | null;
  tagId: string | null;
  status: Status[];
  scheduleSetId: string | null;
};

type Props = {
  initialValues: FormValues;
  scheduleSetOptions: { value: string; label: string }[];
  onSubmit: (values: FormValues) => void;
};

const fieldOptions = [
  { value: "TITLE", label: "タイトル" },
  { value: "CREATED_AT", label: "作成日" },
];

const directionOptions = [
  { value: "DESC", label: "降順" },
  { value: "ASC", label: "昇順" },
];

const statusOptions = [
  { value: "ACTIVE", label: "配信中" },
  { value: "PENDING", label: "保留中" },
  { value: "STOPPED", label: "停止中" },
];

export const AdFilterForm: React.FC<Props> = ({
  initialValues,
  scheduleSetOptions,
  onSubmit,
}) => (
  <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            name="field"
            label="並び替え（フィールド）"
            options={fieldOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            name="direction"
            label="並び替え（方向）"
            options={directionOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            fullWidth
            name="title"
            label="絞り込み（タイトル）"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField fullWidth name="tagId" label="絞り込み（Tag ID）" />
        </Grid>
        <Grid item xs={12}>
          <FormikMultipleSelectField
            fullWidth
            name="status"
            label="絞り込み（ステータス）"
            options={statusOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSelectField
            fullWidth
            name="scheduleSetId"
            label="絞り込み（スケジュールセット）"
            options={scheduleSetOptions}
          />
        </Grid>
      </Grid>
    </DialogForm>
  </Formik>
);
