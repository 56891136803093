/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateAdScheduleSetInput = {
    clientMutationId?: string | null;
    id: string;
    scheduleSetId: string;
    clientVersion?: number | null;
};
export type UpdateAdScheduleSetMutationVariables = {
    input: UpdateAdScheduleSetInput;
};
export type UpdateAdScheduleSetMutationResponse = {
    readonly updateAdScheduleSet: {
        readonly ad: {
            readonly " $fragmentRefs": FragmentRefs<"AdScheduleSetCard_ad">;
        } | null;
    } | null;
};
export type UpdateAdScheduleSetMutation = {
    readonly response: UpdateAdScheduleSetMutationResponse;
    readonly variables: UpdateAdScheduleSetMutationVariables;
};



/*
mutation UpdateAdScheduleSetMutation(
  $input: UpdateAdScheduleSetInput!
) {
  updateAdScheduleSet(input: $input) {
    ad {
      ...AdScheduleSetCard_ad
      id
    }
  }
}

fragment AdScheduleSetCard_ad on Ad {
  ...AdScheduleSetSelectButton_ad
}

fragment AdScheduleSetSelectButton_ad on Ad {
  id
  latestVersion
  scheduleSetting {
    id
    scheduleSet {
      id
      title
      createdAt
    }
  }
  selectableScheduleSets {
    edges {
      node {
        id
        title
        createdAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdAt",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdScheduleSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdScheduleSetPayload",
        "kind": "LinkedField",
        "name": "updateAdScheduleSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdScheduleSetCard_ad"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdScheduleSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdScheduleSetPayload",
        "kind": "LinkedField",
        "name": "updateAdScheduleSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latestVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleSetting",
                "kind": "LinkedField",
                "name": "scheduleSetting",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleSet",
                    "kind": "LinkedField",
                    "name": "scheduleSet",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleSetConnection",
                "kind": "LinkedField",
                "name": "selectableScheduleSets",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed6d7ecc099e16c0a6751a458cb3169b",
    "id": null,
    "metadata": {},
    "name": "UpdateAdScheduleSetMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdScheduleSetMutation(\n  $input: UpdateAdScheduleSetInput!\n) {\n  updateAdScheduleSet(input: $input) {\n    ad {\n      ...AdScheduleSetCard_ad\n      id\n    }\n  }\n}\n\nfragment AdScheduleSetCard_ad on Ad {\n  ...AdScheduleSetSelectButton_ad\n}\n\nfragment AdScheduleSetSelectButton_ad on Ad {\n  id\n  latestVersion\n  scheduleSetting {\n    id\n    scheduleSet {\n      id\n      title\n      createdAt\n    }\n  }\n  selectableScheduleSets {\n    edges {\n      node {\n        id\n        title\n        createdAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd143366f4e93384eb17f2d29669eb27a';
export default node;
