import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateMessageInput,
  CreateMessageMutation,
} from "~/__relay_artifacts__/CreateMessageMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreateMessageMutation($input: CreateMessageInput!) {
    createMessage(input: $input) {
      messageEdge {
        node {
          id
          body
        }
      }
    }
  }
`;

export const useCreateMessageMutation = () => {
  const environment = useRelayEnvironment();

  const createMessageMutation = React.useCallback(
    (input: CreateMessageInput) =>
      commitMutation<CreateMessageMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: "client:root",
            edgeName: "messageEdge",
            connectionInfo: [
              {
                key: "MessageListTableCard_messages",
                rangeBehavior: "prepend",
              },
            ],
          },
        ],
      }),
    [environment]
  );

  return { createMessageMutation };
};
