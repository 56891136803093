import { FormikSelectField, useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";
import * as yup from "yup";

import { ScheduleSetBulkForm_Query } from "~/__relay_artifacts__/ScheduleSetBulkForm_Query.graphql";
import { DialogForm } from "~/components/DialogForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import { delayChunkPromise } from "~/lib/utils";
import deleteScheduleSettingMutation from "~/mutations/DeleteScheduleSettingMutation";
import { useUpdateAdScheduleSetMutation } from "~/mutations/UpdateAdScheduleSetMutation";

type FormValues = yup.Asserts<typeof schema>;

type Props = {
  adIds: string[];
  onSubmitCompleted: () => void;
};

const schema = yup.object({
  scheduleSetId: yup.string().required(),
});

const query = graphql`
  query ScheduleSetBulkForm_Query($projectId: ID!, $adIds: [ID!]!) {
    project(id: $projectId) {
      ads(ids: $adIds) {
        id
        latestVersion
        scheduleSetting {
          id
          scheduleSet {
            id
            title
            createdAt
          }
        }
        selectableScheduleSets {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  }
`;

const useScheduleSet = ({ adIds, onSubmitCompleted }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { projectId } = useParams<{ projectId: string }>();
  const environment = useRelayEnvironment();
  const { updateAdScheduleSetMutation } = useUpdateAdScheduleSetMutation();
  const { props } = useQuery<ScheduleSetBulkForm_Query>(query, {
    projectId,
    adIds,
  });

  const ads = React.useMemo(() => props?.project?.ads || [], [props]);

  const scheduleSettingIdByAdId = React.useMemo(
    () =>
      R.mergeAll(
        ads.map(({ id, scheduleSetting }) => ({
          [id]: scheduleSetting?.id,
        }))
      ),
    [ads]
  );

  const selectableScheduleSets = React.useMemo(() => {
    const scheduleSets = ads
      .map(({ selectableScheduleSets }) => {
        const scheduleSetEdges =
          selectableScheduleSets && selectableScheduleSets.edges;
        if (!scheduleSetEdges) throw new Error("assertion failed");
        return scheduleSetEdges.map((edge) => {
          const node = edge && edge.node;
          if (!node) throw new Error("assertion failed");
          return node;
        });
      })
      .flat();
    return R.uniqBy((s: typeof scheduleSets[0]) => s.id)(scheduleSets);
  }, [ads]);

  const selectableScheduleSetOptions = React.useMemo(
    () => [
      { value: "", label: "none" },
      ...selectableScheduleSets.map((item) => ({
        value: item.id,
        label: item.title,
      })),
    ],
    [selectableScheduleSets]
  );

  const initialValues = React.useMemo(() => {
    const scheduleSetIds = ads
      .filter(({ scheduleSetting }) => !!scheduleSetting)
      .map(({ scheduleSetting }) => scheduleSetting!.scheduleSet.id);
    return {
      scheduleSetId:
        R.uniq(scheduleSetIds).length === 1 ? scheduleSetIds[0] : "",
    };
  }, [ads]);

  const handleSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ scheduleSetId }, { setErrors }) => {
      try {
        scheduleSetId === ""
          ? await delayChunkPromise(
              ads
                .filter((ad) => !!scheduleSettingIdByAdId[ad.id])
                .map((ad) =>
                  deleteScheduleSettingMutation(
                    environment,
                    scheduleSettingIdByAdId[ad.id]!
                  )
                )
            )
          : await delayChunkPromise(
              ads.map((ad) =>
                updateAdScheduleSetMutation({
                  id: ad.id,
                  scheduleSetId,
                  clientVersion: ad.latestVersion,
                })
              )
            );
        enqueueSnackbar("広告のスケジュールセットを更新しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      ads,
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
      scheduleSettingIdByAdId,
      updateAdScheduleSetMutation,
    ]
  );

  return {
    selectableScheduleSetOptions,
    initialValues,
    handleSubmit,
  };
};

export const ScheduleSetBulkForm: React.FC<Props> = (props) => {
  const { selectableScheduleSetOptions, initialValues, handleSubmit } =
    useScheduleSet(props);
  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <DialogForm submitButtonTitle="更新する">
        <FormikSelectField
          fullWidth
          name="scheduleSetId"
          label="候補"
          options={selectableScheduleSetOptions}
        />
      </DialogForm>
    </Formik>
  );
};
