/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CampaignRoot_QueryVariables = {
    projectId: string;
    campaignId: string;
};
export type CampaignRoot_QueryResponse = {
    readonly project: {
        readonly campaign: {
            readonly id: string;
        } | null;
    } | null;
};
export type CampaignRoot_Query = {
    readonly response: CampaignRoot_QueryResponse;
    readonly variables: CampaignRoot_QueryVariables;
};



/*
query CampaignRoot_Query(
  $projectId: ID!
  $campaignId: ID!
) {
  project(id: $projectId) {
    campaign(id: $campaignId) {
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "campaignId"
    }
  ],
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8dc322d187a7ea2249d0851c7a29ce89",
    "id": null,
    "metadata": {},
    "name": "CampaignRoot_Query",
    "operationKind": "query",
    "text": "query CampaignRoot_Query(\n  $projectId: ID!\n  $campaignId: ID!\n) {\n  project(id: $projectId) {\n    campaign(id: $campaignId) {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '8d0a2529d40ae9eecdd374365b081dc6';
export default node;
