import { Tab, Tabs, TabsProps } from "@material-ui/core";
import * as React from "react";
import { T } from "ts-toolbelt";

import { useQueryString } from "./useQueryString";

type TabType = {
  id: string;
  label: string;
};

type Options = {
  withUrl?: boolean;
  tabsProps?: Partial<TabsProps>;
};

export const useTabs = <TabsType extends readonly TabType[]>(
  tabs: TabsType,
  { withUrl = true, tabsProps = {} }: Options = {}
) => {
  const {
    params: { tab },
    changeQueryString,
  } = useQueryString<{ tab?: string }>();
  const [tabState, setTabState] = React.useState(
    tabs.find((t) => t.id === tab) ? tab : tabs[0]?.id
  );

  React.useEffect(() => {
    if (tabs.length === 0) return;
    if (tabState) return;
    if (!tabs[0]?.id) return;
    const newTab = tabs[0].id;
    setTabState(newTab);
    withUrl && changeQueryString({ tab: newTab });
  }, [changeQueryString, tabState, tabs, withUrl]);

  const handleTabsChange = React.useCallback(
    (_, value: number) => {
      const newTab = tabs[value].id;
      setTabState(newTab);
      withUrl && changeQueryString({ tab: newTab });
    },
    [changeQueryString, tabs, withUrl]
  );

  const currentTabIndex = React.useMemo(
    () => tabs.findIndex((t) => t.id === tabState),
    [tabState, tabs]
  );

  const tabsElement = React.useMemo(
    () => (
      <Tabs
        {...tabsProps}
        variant={tabsProps.variant || "scrollable"}
        value={currentTabIndex}
        onChange={handleTabsChange}
      >
        {tabs.map(({ id, label }) => (
          <Tab key={id} label={label} />
        ))}
      </Tabs>
    ),
    [currentTabIndex, handleTabsChange, tabs, tabsProps]
  );

  return {
    currentTabName: tabState as T.UnionOf<TabsType>["id"],
    tabsElement,
  };
};
