import { FC, useMemo } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { DialogButton } from "~/components/DialogButton";
import { usePrettier } from "~/lib/usePrettier";

type Props = {
  html: string | null;
};

export const HtmlPreviewButton: FC<Props> = ({ html }) => {
  const { formatHtml } = usePrettier();

  const formattedHtml = useMemo(() => {
    if (!html) return null;
    try {
      return formatHtml(html || "");
    } catch {
      return "不正なHTMLです" as string;
    }
  }, [formatHtml, html]);

  return (
    <DialogButton
      minWidth={600}
      title="HTMLプレビュー"
      variant="outlined"
      disabled={!html}
      render={() => (
        <SyntaxHighlighter
          language="html"
          style={atomOneDark}
          customStyle={{ width: 600, fontSize: 14 }}
        >
          {formattedHtml}
        </SyntaxHighlighter>
      )}
    >
      HTML表示
    </DialogButton>
  );
};
