import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteExpenseInput,
  DeleteExpenseMutation,
} from "~/__relay_artifacts__/DeleteExpenseMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteExpenseMutation($input: DeleteExpenseInput!) {
    deleteExpense(input: $input) {
      deletedExpenseId
    }
  }
`;

export const useDeleteExpenseMutation = () => {
  const environment = useRelayEnvironment();

  const deleteExpenseMutation = React.useCallback(
    (input: DeleteExpenseInput, campaignId: string) =>
      commitMutation<DeleteExpenseMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: campaignId,
            connectionKeys: [{ key: "ExpenseListTableCard_expenses" }],
            pathToConnection: ["campaign", "expenses"],
            deletedIDFieldName: "deletedExpenseId",
          },
        ],
      }),
    [environment]
  );

  return { deleteExpenseMutation };
};
