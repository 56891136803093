/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateScheduleSetInput = {
    clientMutationId?: string | null;
    clientVersion?: number | null;
    id: string;
    title: string;
};
export type UpdateScheduleSetMutationVariables = {
    input: UpdateScheduleSetInput;
};
export type UpdateScheduleSetMutationResponse = {
    readonly updateScheduleSet: {
        readonly scheduleSet: {
            readonly id: string;
            readonly title: string;
            readonly createdAt: string;
        } | null;
    } | null;
};
export type UpdateScheduleSetMutation = {
    readonly response: UpdateScheduleSetMutationResponse;
    readonly variables: UpdateScheduleSetMutationVariables;
};



/*
mutation UpdateScheduleSetMutation(
  $input: UpdateScheduleSetInput!
) {
  updateScheduleSet(input: $input) {
    scheduleSet {
      id
      title
      createdAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateScheduleSetPayload",
    "kind": "LinkedField",
    "name": "updateScheduleSet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleSet",
        "kind": "LinkedField",
        "name": "scheduleSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateScheduleSetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateScheduleSetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd07e821638e7340e56cc5f4bec3a205",
    "id": null,
    "metadata": {},
    "name": "UpdateScheduleSetMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateScheduleSetMutation(\n  $input: UpdateScheduleSetInput!\n) {\n  updateScheduleSet(input: $input) {\n    scheduleSet {\n      id\n      title\n      createdAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '42783dd67b856c32b0536e193336f599';
export default node;
