import { Link as MaterialLink } from "@material-ui/core";
import * as React from "react";

type Props = {
  sdkVersion: string | null;
};

const SDK_CHANGE_LOG_URL =
  "https://github.com/vrize/vrizead-web-sdk/blob/develop/CHANGELOG.md";

export const CreativeSdkVersionLinks: React.FC<Props> = ({ sdkVersion }) => (
  <>
    {sdkVersion && sdkVersion !== "" ? (
      sdkVersion.split(",").map((version) => (
        <div>
          <MaterialLink target="_blank" href={SDK_CHANGE_LOG_URL}>
            {version}
          </MaterialLink>
        </div>
      ))
    ) : (
      <>ー</>
    )}
  </>
);
