import { Grid } from "@material-ui/core";
import { FormikNumberField } from "@vrize/vrizead-use";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "~/components/DialogForm";

export type FormValues = yup.Asserts<typeof validationSchema>;

type Props = {};

export const validationSchema = yup.object({
  proportion: yup.number(),
  correctionTerm: yup.number(),
});

export const PlacementForm: React.FC<Props> = () => {
  return (
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikNumberField
            fullWidth
            required
            name="proportion"
            label="配信割合"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikNumberField
            fullWidth
            required
            precision={2}
            name="correctionTerm"
            label="FairPriceの計算に使用される補正係数です"
            margin="normal"
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
};
