/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectSelectFormContainerForCopyCampaign_QueryVariables = {
    search?: string | null;
};
export type ProjectSelectFormContainerForCopyCampaign_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectSelectFormForCopyCampaign_viewer">;
    };
};
export type ProjectSelectFormContainerForCopyCampaign_Query = {
    readonly response: ProjectSelectFormContainerForCopyCampaign_QueryResponse;
    readonly variables: ProjectSelectFormContainerForCopyCampaign_QueryVariables;
};



/*
query ProjectSelectFormContainerForCopyCampaign_Query(
  $search: String
) {
  viewer {
    ...ProjectSelectFormForCopyCampaign_viewer
    id
  }
}

fragment ProjectSelectFormForCopyCampaign_viewer on User {
  projects(first: 2147483647, search: $search) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSelectFormContainerForCopyCampaign_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectSelectFormForCopyCampaign_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectSelectFormContainerForCopyCampaign_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 2147483647
              },
              {
                "kind": "Variable",
                "name": "search",
                "variableName": "search"
              }
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8cab3025a2ce3e21160f0871da1ea3f",
    "id": null,
    "metadata": {},
    "name": "ProjectSelectFormContainerForCopyCampaign_Query",
    "operationKind": "query",
    "text": "query ProjectSelectFormContainerForCopyCampaign_Query(\n  $search: String\n) {\n  viewer {\n    ...ProjectSelectFormForCopyCampaign_viewer\n    id\n  }\n}\n\nfragment ProjectSelectFormForCopyCampaign_viewer on User {\n  projects(first: 2147483647, search: $search) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c932666eab2e7e2f073c8f0cdedc2579';
export default node;
