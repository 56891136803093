import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteCampaignInput,
  DeleteCampaignMutation,
} from "~/__relay_artifacts__/DeleteCampaignMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteCampaignMutation($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      deletedCampaignId
    }
  }
`;

export const useDeleteCampaignMutation = () => {
  const environment = useRelayEnvironment();

  const deleteCampaignMutation = useCallback(
    (input: DeleteCampaignInput) =>
      commitMutation<DeleteCampaignMutation>(environment, {
        mutation,
        variables: {
          input,
        },
      }),
    [environment]
  );

  return { deleteCampaignMutation };
};
