import {
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";
import * as React from "react";
import { graphql } from "react-relay";
import { useLocation } from "react-router";

import { AdminDrawerMenuList_viewer } from "~/__relay_artifacts__/AdminDrawerMenuList_viewer.graphql";
import { AdminHeader_viewer } from "~/__relay_artifacts__/AdminHeader_viewer.graphql";
import { AccountButton } from "~/components/AccountButton";
import { LogoutButton } from "~/components/LogoutButton";
import { LinkListItem } from "~/components/atoms/LinkListItem";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  viewerRef: AdminHeader_viewer;
};

const viewerFragment = graphql`
  fragment AdminDrawerMenuList_viewer on User {
    ...AccountButton_viewer
  }
`;

export const AdminDrawerMenuList: React.FC<Props> = ({ viewerRef }) => {
  const { pathname } = useLocation();
  const viewer = useFragment<AdminDrawerMenuList_viewer>(
    viewerFragment,
    viewerRef
  );

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <List>
        <AccountButton viewerRef={viewer} />
        <Divider />
        <LinkListItem
          button
          to="/admin/users"
          selected={pathname.startsWith("/admin/users")}
        >
          <ListItemText primary="ユーザー管理" />
        </LinkListItem>
        <LinkListItem
          button
          to="/admin/ad_slots"
          selected={pathname.startsWith("/admin/ad_slots")}
        >
          <ListItemText primary="配信枠管理" />
        </LinkListItem>
        <LinkListItem
          button
          to="/admin/placement_sets"
          selected={pathname.startsWith("/admin/placement_sets")}
        >
          <ListItemText primary="プレイスメントセット管理" />
        </LinkListItem>
        <LinkListItem
          button
          to="/admin/blocks"
          selected={pathname.startsWith("/admin/blocks")}
        >
          <ListItemText primary="ブロックリスト" />
        </LinkListItem>
        <LinkListItem
          button
          to="/admin/deny_tag_ids"
          selected={pathname.startsWith("/admin/deny_tag_ids")}
        >
          <ListItemText primary="配信禁止タグリスト" />
        </LinkListItem>
        <LinkListItem
          button
          to="/admin/campaigns"
          selected={pathname.startsWith("/admin/campaigns")}
        >
          <ListItemText primary="キャンペーン管理" />
        </LinkListItem>
        <LinkListItem
          button
          to="/admin/messages"
          selected={pathname.startsWith("/admin/messages")}
        >
          <ListItemText primary="メッセージ管理" />
        </LinkListItem>
        <LinkListItem
          button
          to="/admin/maintenances"
          selected={pathname.startsWith("/admin/maintenances")}
        >
          <ListItemText primary="メンテナンス管理" />
        </LinkListItem>
      </List>
      <Box flexGrow={1} />
      <List>
        <LinkListItem button to="/">
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="プロジェクト" />
        </LinkListItem>
        <LogoutButton />
      </List>
    </Box>
  );
};
