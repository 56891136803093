import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { CreativeDetail_Query } from "~/__relay_artifacts__/CreativeDetail_Query.graphql";
import { CreativeDetail as CreativeDetailComponent } from "~/components/CreativeDetail";

const query = graphql`
  query CreativeDetail_Query($projectId: ID!, $creativeId: ID!) {
    project(id: $projectId) {
      creative(id: $creativeId) {
        ...CreativeDetail_creative
      }
    }
  }
`;

export const CreativeDetail: React.FC = () => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { props, error } = useQuery<CreativeDetail_Query>(query, {
    projectId,
    creativeId: id,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project?.creative) return <div>not found</div>;
  return <CreativeDetailComponent creativeRef={props.project.creative} />;
};
