import { Chip, Dialog } from "@material-ui/core";
import ChipInput, { Props as ChipInputProps } from "material-ui-chip-input";
import { FC, ReactNode, useCallback, useState } from "react";

type RenderProps = {
  close: Function;
  index?: number;
};

type Props = {
  children: (_: RenderProps) => ReactNode;
  label: string;
  value: ChipInputProps["value"];
  onDelete: (index: number) => void;
};

type RenderChipItemOptions = {
  children: Props["children"];
  focussedChipIndex: number | null;
  setFocussedChipIndex: (_: number | null) => void;
};

const renderChipItem =
  ({
    children,
    focussedChipIndex,
    setFocussedChipIndex,
  }: RenderChipItemOptions): ChipInputProps["chipRenderer"] =>
  ({ text, handleDelete, className }, i) =>
    (
      <>
        <Chip
          key={i}
          className={className}
          onClick={(e) => {
            e.stopPropagation();
            setFocussedChipIndex(i);
          }}
          onDelete={handleDelete}
          label={text}
        />
        <Dialog
          open={focussedChipIndex === i}
          onClick={(e) => e.stopPropagation()}
          onClose={() => setFocussedChipIndex(null)}
        >
          {children({ close: () => setFocussedChipIndex(null), index: i })}
        </Dialog>
      </>
    );

export const DialogChipInput: FC<Props> = ({
  children,
  label,
  value,
  onDelete,
}) => {
  const [open, setOpen] = useState(false);
  const [focussedChipIndex, setFocussedChipIndex] = useState<number | null>(
    null
  );

  const handleChipClick = useCallback(() => {
    setOpen(true);
    return true;
  }, []);

  const handleChipDelete = useCallback<NonNullable<ChipInputProps["onDelete"]>>(
    (_, i) => onDelete(i),
    [onDelete]
  );

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <ChipInput
        fullWidth
        margin="normal"
        label={label}
        value={value}
        onClick={handleChipClick}
        onDelete={handleChipDelete}
        chipRenderer={renderChipItem({
          children,
          focussedChipIndex,
          setFocussedChipIndex,
        })}
      />
      <Dialog open={open} onClose={handleClose}>
        {children({ close: handleClose })}
      </Dialog>
    </>
  );
};
