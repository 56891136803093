import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  AddPlacementSetInput,
  AddPlacementSetMutation,
} from "~/__relay_artifacts__/AddPlacementSetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation AddPlacementSetMutation(
    $input: AddPlacementSetInput!
    $isEnabled: Boolean
    $tagId: String
    $count: Int
    $cursor: String
  ) {
    addPlacementSet(input: $input) {
      ad {
        id
        title
        ...PlacementList_ad
      }
    }
  }
`;

export const useAddPlacementSetMutation = (isEnabled: boolean | undefined) => {
  const environment = useRelayEnvironment();

  const addPlacementSetMutation = React.useCallback(
    (input: AddPlacementSetInput) =>
      commitMutation<AddPlacementSetMutation>(environment, {
        mutation,
        variables: {
          input,
          isEnabled,
          count: 100,
        },
      }),
    [environment, isEnabled]
  );

  return { addPlacementSetMutation };
};
