/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpsertMonthlyBudgetInput = {
    clientMutationId?: string | null;
    campaignId: string;
    date: string;
    amount: number;
    clientVersion?: number | null;
};
export type UpsertMonthlyBudgetMutationVariables = {
    input: UpsertMonthlyBudgetInput;
    first?: number | null;
    after?: string | null;
};
export type UpsertMonthlyBudgetMutationResponse = {
    readonly upsertMonthlyBudget: {
        readonly campaign: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"MonthlyBudgetListTableCard_campaign">;
        } | null;
    } | null;
};
export type UpsertMonthlyBudgetMutation = {
    readonly response: UpsertMonthlyBudgetMutationResponse;
    readonly variables: UpsertMonthlyBudgetMutationVariables;
};



/*
mutation UpsertMonthlyBudgetMutation(
  $input: UpsertMonthlyBudgetInput!
  $first: Int
  $after: String
) {
  upsertMonthlyBudget(input: $input) {
    campaign {
      id
      ...MonthlyBudgetListTableCard_campaign
    }
  }
}

fragment MonthlyBudgetEditForm_monthlyBudget on MonthlyBudget {
  id
  date
  amount
  latestVersion
}

fragment MonthlyBudgetListTableCard_campaign on Campaign {
  id
  project {
    id
  }
  monthlyBudgets(first: $first, after: $after) {
    edges {
      node {
        id
        date
        amount
        createdAt
        updatedAt
        ...MonthlyBudgetEditForm_monthlyBudget
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertMonthlyBudgetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UpsertMonthlyBudgetPayload",
        "kind": "LinkedField",
        "name": "upsertMonthlyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MonthlyBudgetListTableCard_campaign"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpsertMonthlyBudgetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UpsertMonthlyBudgetPayload",
        "kind": "LinkedField",
        "name": "upsertMonthlyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "MonthlyBudgetConnection",
                "kind": "LinkedField",
                "name": "monthlyBudgets",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MonthlyBudgetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MonthlyBudget",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestVersion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MonthlyBudgetListTableCard_monthlyBudgets",
                "kind": "LinkedHandle",
                "name": "monthlyBudgets"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3056e958d3fefb0a9c40e8f1d41bdb3",
    "id": null,
    "metadata": {},
    "name": "UpsertMonthlyBudgetMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertMonthlyBudgetMutation(\n  $input: UpsertMonthlyBudgetInput!\n  $first: Int\n  $after: String\n) {\n  upsertMonthlyBudget(input: $input) {\n    campaign {\n      id\n      ...MonthlyBudgetListTableCard_campaign\n    }\n  }\n}\n\nfragment MonthlyBudgetEditForm_monthlyBudget on MonthlyBudget {\n  id\n  date\n  amount\n  latestVersion\n}\n\nfragment MonthlyBudgetListTableCard_campaign on Campaign {\n  id\n  project {\n    id\n  }\n  monthlyBudgets(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        date\n        amount\n        createdAt\n        updatedAt\n        ...MonthlyBudgetEditForm_monthlyBudget\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7301d97d0a95f5946fb821b0fe4323f7';
export default node;
