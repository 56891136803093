import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  DetachPromotionInput,
  DetachPromotionMutation,
} from "~/__relay_artifacts__/DetachPromotionMutation.graphql";

const mutation = graphql`
  mutation DetachPromotionMutation($input: DetachPromotionInput!) {
    detachPromotion(input: $input) {
      campaign {
        id
      }
    }
  }
`;

const commit = (environment: Environment, input: DetachPromotionInput) =>
  commitMutation<DetachPromotionMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
