/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdxCreativeStatus = "APPROVED" | "CONDITIONALLY_APPROVED" | "DISAPPROVED" | "NOT_CHECKED" | "PENDING_REVIEW" | "UNKNOWN" | "%future added value";
export type CreativeDetail_creative = {
    readonly id: string;
    readonly title: string;
    readonly adm: string | null;
    readonly height: number | null;
    readonly width: number | null;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly sdkVersion: string | null;
    readonly note: string | null;
    readonly adxStatus: AdxCreativeStatus | null;
    readonly adxSubmittedAt: string | null;
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"CreativeSetting_project">;
    };
    readonly " $fragmentRefs": FragmentRefs<"CreativeEditForm_creative">;
    readonly " $refType": "CreativeDetail_creative";
};
export type CreativeDetail_creative$data = CreativeDetail_creative;
export type CreativeDetail_creative$key = {
    readonly " $data"?: CreativeDetail_creative$data;
    readonly " $fragmentRefs": FragmentRefs<"CreativeDetail_creative">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreativeDetail_creative",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sdkVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adxStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adxSubmittedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreativeSetting_project"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreativeEditForm_creative"
    }
  ],
  "type": "Creative",
  "abstractKey": null
};
(node as any).hash = 'd41afd25afa1def261e724010806d89f';
export default node;
