import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteScheduleSetMutation } from "~/__relay_artifacts__/DeleteScheduleSetMutation.graphql";

const mutation = graphql`
  mutation DeleteScheduleSetMutation($input: DeleteScheduleSetInput!) {
    deleteScheduleSet(input: $input) {
      deletedScheduleSetId
    }
  }
`;

const commit = (
  environment: Environment,
  scheduleSetId: string,
  projectId: string
) =>
  commitMutation<DeleteScheduleSetMutation>(environment, {
    mutation,
    variables: {
      input: { scheduleSetId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: projectId,
        connectionKeys: [
          {
            key: "ScheduleSetList_scheduleSets",
          },
        ],
        pathToConnection: ["project", "scheduleSets"],
        deletedIDFieldName: "deletedScheduleSetId",
      },
    ],
  });

export default commit;
