import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import {
  ConnectionHandler,
  Environment,
  RecordSourceSelectorProxy,
} from "relay-runtime";

import { DeletedAndUpdateAdMutation } from "~/__relay_artifacts__/DeletedAndUpdateAdMutation.graphql";

const mutation = graphql`
  mutation DeletedAndUpdateAdMutation($input: DeleteAdInput!) {
    deleteAd(input: $input) {
      deletedAdId
    }
  }
`;

const sharedUpdater = (
  store: RecordSourceSelectorProxy,
  campaignId: string,
  deletedId: string
) => {
  const proxy = store.get(campaignId);
  if (!proxy) {
    return;
  }
  const conn = ConnectionHandler.getConnection(proxy, "AdListTableCard_ads");
  if (!conn) {
    return;
  }
  ConnectionHandler.deleteNode(conn, deletedId);
};

const commit = (environment: Environment, adId: string, campaignId: string) =>
  commitMutation<DeletedAndUpdateAdMutation>(environment, {
    mutation,
    variables: {
      input: { adId },
    },
    updater: (store) => {
      const payload = store.getRootField("deleteAd");
      if (!payload) {
        return;
      }
      const deletedId = payload.getValue("deletedAdId") as string;
      campaignId && sharedUpdater(store, campaignId, deletedId);
    },

    optimisticUpdater: (store) => {
      campaignId && sharedUpdater(store, campaignId, adId);
    },
  });

export default commit;
