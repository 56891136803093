import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";

import { AdCategory } from "~/__relay_artifacts__/UpdateCampaignMutation.graphql";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { useUpdateCampaignMutation } from "~/mutations/UpdateCampaignMutation";

import { Campaign } from "./types";
import { useValidationErrorHandler } from "./useValidationErrorHandler";

type Props = {
  campaign: Campaign;
  onSubmitCompleted: () => void;
};

export const ArchiveButton: React.FC<Props> = ({
  campaign,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleValidationError = useValidationErrorHandler();
  const { updateCampaignMutation } = useUpdateCampaignMutation();
  const mode = campaign.archived ? "復元" : "アーカイブ";

  const handleArchive = React.useCallback(async () => {
    try {
      const params = R.pick(
        [
          "id",
          "title",
          "status",
          "bidStrategy",
          "reportingOption",
          "goalAmount",
          "frequencyCapEnabled",
          "periodSince",
        ],
        campaign
      );
      await updateCampaignMutation({
        ...params,
        category: campaign.categories as AdCategory[],
        archived: !campaign.archived,
        clientVersion: campaign.latestVersion,
      });
      onSubmitCompleted();
      enqueueSnackbar(`${mode}しました。`, { variant: "success" });
    } catch (err) {
      handleValidationError(err);
    }
  }, [
    campaign,
    updateCampaignMutation,
    onSubmitCompleted,
    enqueueSnackbar,
    handleValidationError,
    mode,
  ]);

  return (
    <ConfirmButton
      variant="outlined"
      color={mode === "アーカイブ" ? "secondary" : "primary"}
      confirmTitle={`このキャンペーンを${mode}しますか？`}
      onAgree={async (changeDialog) => {
        await handleArchive();
        changeDialog(false);
      }}
    >
      {mode}
    </ConfirmButton>
  );
};
