import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { AdSlotForm, FormValues, schema } from "~/components/AdSlotForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createAdSlotMutation from "~/mutations/CreateAdSlotMutation";

type Props = {
  onSubmitCompleted: () => void;
  connectionId: string;
};

const initialValues: FormValues = {
  name: null,
  domain: "",
  tagId: "",
  bundle: "",
  note: null,
  width: null,
  height: null,
  sspProvider: "ADSTIR",
  platform: null,
  os: null,
  adxBillingId: null,
  targetWinRate: 0.7,
};

export const AdSlotCreateForm: React.FC<Props> = ({
  onSubmitCompleted,
  connectionId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createAdSlot } = await createAdSlotMutation(
          environment,
          values,
          connectionId
        );
        if (!createAdSlot?.adSlotEdge) throw new Error("assertion failed");
        enqueueSnackbar("広告枠を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      environment,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
      connectionId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={AdSlotForm}
    />
  );
};
