import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

type Props = ListItemProps & LinkProps;

const ReferensableLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link {...props} ref={ref} />
);

export const LinkListItem = (props: Props) => (
  // @ts-expect-error: FIXME: correct ListItem props
  <ListItem component={ReferensableLink} {...props} />
);
