import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { MessageList_Query } from "~/__relay_artifacts__/MessageList_Query.graphql";
import { PageHeader } from "~/components/PageHeader";

import { MessageListTableCard } from "./MessageListTableCard";

const query = graphql`
  query MessageList_Query($first: Int, $after: String) {
    ...MessageListTableCard_root
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader shouldUseHistoryBack title="メッセージ管理" />
    {children}
  </>
);

export const MessageList: React.FC = () => {
  const { props, error } = useQuery<MessageList_Query>(query);
  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <MessageListTableCard rootRef={props} />
    </Wrapper>
  );
};
