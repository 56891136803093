import {
  DialogContent,
  List,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import * as R from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { ProjectHeader_project } from "~/__relay_artifacts__/ProjectHeader_project.graphql";
import { ProjectSelectionButton_Query } from "~/__relay_artifacts__/ProjectSelectionButton_Query.graphql";
import { ProjectSelectionButton_project } from "~/__relay_artifacts__/ProjectSelectionButton_project.graphql";
import { ProjectSelectionButton_viewer } from "~/__relay_artifacts__/ProjectSelectionButton_viewer.graphql";
import { useFragment, useRefetch } from "~/lib/relay-hooks";

import { DialogButton } from "./DialogButton";
import { LinkButton } from "./atoms/LinkButton";
import { LinkListItem } from "./atoms/LinkListItem";

type Props = {
  projectRef: ProjectHeader_project;
  viewerRef: FragmentRef<ProjectSelectionButton_viewer>;
};

const projectFragment = graphql`
  fragment ProjectSelectionButton_project on Project {
    id
    name
  }
`;

const viewerFragment = graphql`
  fragment ProjectSelectionButton_viewer on User {
    role
    projects(search: $search) {
      edges {
        node {
          id
          name
          viewerCanAdministrate
          updatedAt
        }
      }
    }
  }
`;

const query = graphql`
  query ProjectSelectionButton_Query($search: String) {
    viewer {
      ...ProjectSelectionButton_viewer
    }
  }
`;

const StyledDialogContent = styled(DialogContent)({
  overflow: "visible",
  minHeight: 500,
});

const useRefetchViewer = (viewerRef: Props["viewerRef"]) => {
  const [viewer, refetch] = useRefetch<ProjectSelectionButton_viewer>(
    viewerFragment,
    viewerRef
  );

  const refetchViewer = React.useCallback(
    (search: string) => {
      refetch<ProjectSelectionButton_Query>(query, { search });
    },
    [refetch]
  );

  return { viewer, refetchViewer };
};

export const ProjectSelectionButton: React.FC<Props> = ({
  projectRef,
  viewerRef,
}) => {
  const project = useFragment<ProjectSelectionButton_project>(
    projectFragment,
    projectRef
  );
  const { viewer, refetchViewer } = useRefetchViewer(viewerRef);

  const projects = React.useMemo(() => {
    const nodes = (viewer.projects.edges || []).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge?.node;
    });
    return R.sort<typeof nodes[0]>(R.descend(R.prop("updatedAt")))(nodes);
  }, [viewer.projects.edges]);

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      refetchViewer(e.target.value);
    },
    [refetchViewer]
  );

  return (
    <DialogButton
      title="プロジェクトを変更する"
      minWidth={500}
      color="inherit"
      enableAlert={false}
      render={() => (
        <StyledDialogContent>
          <TextField
            fullWidth
            label="絞り込み検索"
            onChange={handleTextFieldChange}
          />
          <List>
            {R.isEmpty(projects) ? (
              <p>条件に一致するプロジェクトが見つかりません</p>
            ) : (
              projects.map((projectNode) => (
                <LinkListItem
                  button
                  key={projectNode.id}
                  to={`/projects/${projectNode.id}/home`}
                >
                  <ListItemText primary={projectNode.name} />
                </LinkListItem>
              ))
            )}
          </List>
          {viewer.role === "ADMIN" && (
            <LinkButton color="primary" to="/admin/projects/create">
              新規プロジェクト作成
            </LinkButton>
          )}
        </StyledDialogContent>
      )}
    >
      <Typography color="inherit">{project.name}</Typography>
      <ArrowDropDownIcon />
    </DialogButton>
  );
};
