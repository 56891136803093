import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import accounting from "accounting";
import { isNil } from "ramda";
import { FC, useMemo } from "react";
import { FragmentRef, graphql } from "relay-runtime";

import { CampaignSetting_project } from "~/__relay_artifacts__/CampaignSetting_project.graphql";
import { parseISO } from "~/lib/parseISO";
import { useFragment } from "~/lib/relay-hooks";

import { CampaignTrackerSelectForm } from "./CampaignTrackerSelectForm";

type Props = {
  projectRef: FragmentRef<CampaignSetting_project>;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(3),
  },
}));

const projectFragment = graphql`
  fragment CampaignSetting_project on Project {
    trackers {
      edges {
        node {
          id
          title
          associationType
        }
      }
    }
    campaign(id: $campaignId) {
      id
      title
      status
      bidStrategy
      reportingOption
      monthlyBudget {
        amount
      }
      dailyBudget {
        baseAmount
      }
      createdAt
      adomain
      tracker {
        id
        title
        associationType
      }
    }
  }
`;

export const CampaignSetting: FC<Props> = ({ projectRef }) => {
  const classes = useStyles();
  const project = useFragment<CampaignSetting_project>(
    projectFragment,
    projectRef
  );

  const campaign = useMemo(() => {
    if (!project.campaign) throw new Error("assertion failed");
    return project.campaign;
  }, [project.campaign]);

  const trackerOptions = useMemo(() => {
    const edges = project.trackers.edges || [];
    const filteredNodes = edges
      .filter(
        (edge) =>
          edge?.node?.associationType === "both" ||
          edge?.node?.associationType === "campaign"
      )
      .map((edge) => {
        if (edge?.node?.id == null || edge?.node?.title == null) {
          throw new Error("assertion error");
        }
        return {
          value: edge.node.id,
          label: edge.node.title,
        };
      });
    return [{ value: "", label: "未選択" }, ...filteredNodes];
  }, [project.trackers.edges]);

  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">基本設定</Typography>
        </Toolbar>
        <List>
          <ListItem>
            <ListItemText primary="名前" secondary={campaign.title} />
          </ListItem>
          <ListItem>
            <ListItemText primary="状態" secondary={campaign.status} />
          </ListItem>
          <ListItem>
            <ListItemText primary="入札戦略" secondary={campaign.bidStrategy} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="レポートオプション"
              secondary={campaign.reportingOption}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="作成日"
              secondary={parseISO(campaign.createdAt)}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="広告ドメイン" secondary={campaign.adomain} />
          </ListItem>
        </List>
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">予算設定</Typography>
        </Toolbar>
        <List>
          <ListItem>
            <ListItemText
              primary="月予算"
              secondary={
                !isNil(campaign.monthlyBudget.amount) &&
                accounting.formatMoney(campaign.monthlyBudget.amount)
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="日予算"
              secondary={accounting.formatMoney(
                campaign.dailyBudget.baseAmount
              )}
            />
          </ListItem>
        </List>
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">トラッカー設定</Typography>
        </Toolbar>
        <List>
          <CampaignTrackerSelectForm
            campaignId={campaign.id}
            initialTrackerId={campaign.tracker?.id || ""}
            trackerOptions={trackerOptions}
          />
        </List>
      </Paper>
    </>
  );
};
