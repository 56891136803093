import { Tooltip } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as React from "react";

import { useMobile } from "~/lib/useMobile";

type Props = {
  tooltip?: string | null;
};

const StyledWrapper = styled("div")<{}, { isMobile: boolean }>((props) => ({
  maxWidth: props.isMobile ? 90 : 150,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  wordBreak: "break-all",
}));

export const TruncatedTextWithTooltip: React.FC<Props> = ({
  children,
  tooltip,
}) => {
  const { isMobile } = useMobile();
  return (
    <Tooltip title={tooltip || ""} disableHoverListener={!tooltip}>
      <StyledWrapper isMobile={isMobile}>{children}</StyledWrapper>
    </Tooltip>
  );
};
