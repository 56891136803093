import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FormikDateField, FormikMultipleSelectField } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Form, useFormikContext } from "formik";
import * as React from "react";
import * as yup from "yup";

import { CampaignReportFormContainer_project } from "~/__relay_artifacts__/CampaignReportFormContainer_project.graphql";
import { ReportList } from "~/containers/ReportList";

import { measures } from "./constants";

export type FormValues = {
  periodTill: string;
  periodSince: string;
  campaignIds: string[];
  promotionIds: string[];
};

type Props = {
  isFormCached: boolean;
  project: CampaignReportFormContainer_project;
  periodSinceMinDate: string;
  periodTillMaxDate: string;
};

export const validationSchema = yup.object({
  periodSince: yup.string().required(),
  periodTill: yup.string().required(),
  campaignIds: yup.array().of(yup.string()).required(),
  promotionIds: yup.array().of(yup.string()).required(),
  os: yup.array().required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  formControl: {
    minWidth: 150,
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "220px",
  },
}));

const osOptions = [
  { value: "IOS", label: "iOS" },
  { value: "ANDROID", label: "Android" },
];

export const CampaignReportForm: React.FC<Props> = ({
  isFormCached,
  project,
  periodSinceMinDate,
  periodTillMaxDate,
}) => {
  const classes = useStyles();
  const [haveBeenSubmitted, setHaveBeenSubmitted] = React.useState(false);
  const { values, setFieldValue, isSubmitting, status } =
    useFormikContext<FormValues>();

  // MEMO: メンテの場合、error boundaryにcatchしてもらう
  if (!!status) throw new Error(status.message);

  React.useEffect(() => {
    if (!isSubmitting) return;
    setHaveBeenSubmitted(true);
  }, [isSubmitting]);

  React.useEffect(() => {
    const since = new Date(values.periodSince);
    const till = new Date(values.periodTill);
    if (since > till) setFieldValue("periodTill", since.toISOString());
  }, [setFieldValue, values.periodSince, values.periodTill]);

  const maxDate = React.useMemo(() => {
    const since = new Date(values.periodSince);
    const tillMax = new Date(periodTillMaxDate);
    if (since < DateFns.subMonths(tillMax, 1)) {
      // MEMO: timezoneが異なる場合の対応として月の最終日の時間帯を0時台に修正する
      const date = DateFns.subHours(DateFns.endOfMonth(since), 23);
      setFieldValue("periodTill", date.toISOString());
      return date;
    } else {
      return tillMax;
    }
  }, [periodTillMaxDate, setFieldValue, values.periodSince]);

  const campaignNodes = React.useMemo(() => {
    const campaignEdges = project.campaigns && project.campaigns.edges;
    if (!campaignEdges) throw new Error("assertion failed");
    return campaignEdges.map((campaignEdge) => {
      const node = campaignEdge && campaignEdge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [project.campaigns]);

  const campaignIdsOptions = React.useMemo(
    () =>
      campaignNodes
        .filter((node) => node.promotion == null)
        .map((campaignNode) => ({
          value: campaignNode.id,
          label: campaignNode.title,
        })),
    [campaignNodes]
  );

  const promotionNodes = React.useMemo(() => {
    const promotionEdges = project.promotions && project.promotions.edges;
    if (!promotionEdges) throw new Error("assertion failed");
    return promotionEdges.map((promotionEdge) => {
      const node = promotionEdge && promotionEdge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [project.promotions]);

  const promotionIdsOptions = React.useMemo(
    () =>
      promotionNodes.map((promotionNodes) => ({
        value: promotionNodes.id,
        label: promotionNodes.title,
      })),
    [promotionNodes]
  );

  return (
    <div className={classes.root}>
      <Form>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormikMultipleSelectField
                  fullWidth
                  name="campaignIds"
                  label="キャンペーンを選択"
                  options={campaignIdsOptions}
                  formControlClassName={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikMultipleSelectField
                  fullWidth
                  name="promotionIds"
                  label="プロモーションを選択"
                  options={promotionIdsOptions}
                  formControlClassName={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDateField
                  fullWidth
                  name="periodSince"
                  label="開始日"
                  format="M月 d日"
                  minDate={periodSinceMinDate}
                  maxDate={periodTillMaxDate}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDateField
                  fullWidth
                  name="periodTill"
                  label="終了日"
                  format="M月 d日"
                  minDate={values.periodSince}
                  maxDate={maxDate}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikMultipleSelectField
                  fullWidth
                  name="os"
                  label="OSを選択"
                  options={osOptions}
                  formControlClassName={classes.formControl}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              表示
            </Button>
          </CardActions>
        </Card>
      </Form>
      {isSubmitting ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress variant="indeterminate" size="60px" />
        </div>
      ) : (
        <ReportList
          // NOTE: Do not disply the list if form isn't cached and has never been submitted
          shouldDisplay={isFormCached || haveBeenSubmitted}
          projectRef={project}
          measureFields={measures.map((m) => m.value)}
        />
      )}
    </div>
  );
};
