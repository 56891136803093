import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreatePricingInput,
  CreatePricingMutation,
} from "~/__relay_artifacts__/CreatePricingMutation.graphql";

const mutation = graphql`
  mutation CreatePricingMutation($input: CreatePricingInput!) {
    createPricing(input: $input) {
      adSlot {
        id
        pricing {
          id
          logicType
          manualBidPrice
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreatePricingInput) =>
  commitMutation<CreatePricingMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
