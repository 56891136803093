import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteAdMutation } from "~/__relay_artifacts__/DeleteAdMutation.graphql";

const mutation = graphql`
  mutation DeleteAdMutation($input: DeleteAdInput!) {
    deleteAd(input: $input) {
      deletedAdId
    }
  }
`;

const commit = (environment: Environment, adId: string) =>
  commitMutation<DeleteAdMutation>(environment, {
    mutation,
    variables: {
      input: { adId },
    },
  });

export default commit;
