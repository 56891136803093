import {
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import * as DateFns from "date-fns";
import * as React from "react";

import { DialogButton } from "~/components/DialogButton";

import { DailyBudgetProratedSimurationPreview } from "./DailyBudgetProrateSimulationPreview";

type Props = {
  campaignClientVersion: number | null;
  selectedMonth: Date;
  onSubmitCompleted: () => void;
};

export const DailyBudgetProratedForm: React.FC<Props> = ({
  campaignClientVersion,
  selectedMonth,
  onSubmitCompleted,
}) => {
  const [remainingMonthlyBudget, setRemainingMonthlyBudget] = React.useState(0);
  const [periodSince, setPeriodSince] = React.useState(
    DateFns.addDays(new Date(), 1)
  );
  const [periodTill, setPeriodTill] = React.useState(
    DateFns.lastDayOfMonth(new Date())
  );

  return (
    <DialogContent title="算出する">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DatePicker
            required
            fullWidth
            name="periodSince"
            label="開始日"
            value={periodSince}
            maxDate={periodTill}
            onChange={(e) => e && setPeriodSince(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            required
            fullWidth
            name="periodTill"
            label="開始日"
            value={periodTill}
            minDate={periodSince}
            onChange={(e) => e && setPeriodTill(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="remainingMonthlyBudget"
            label="残予算"
            type="number"
            value={remainingMonthlyBudget === 0 ? null : remainingMonthlyBudget}
            inputProps={{ min: "0" }}
            onChange={(e) => {
              setRemainingMonthlyBudget(Number(e.target.value));
            }}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <DialogButton
          fullWidth
          color="primary"
          variant="contained"
          title="算出結果"
          render={({ close }) => (
            <DailyBudgetProratedSimurationPreview
              campaignClientVersion={campaignClientVersion}
              periodSince={periodSince}
              periodTill={periodTill}
              remainingMonthlyBudget={remainingMonthlyBudget}
              selectedMonth={selectedMonth}
              onSubmitCompleted={() => {
                close();
                onSubmitCompleted();
              }}
            />
          )}
        >
          算出する
        </DialogButton>
      </DialogActions>
    </DialogContent>
  );
};
