import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  MarkMessageAsReadInput,
  MarkMessageAsReadMutation,
} from "~/__relay_artifacts__/MarkMessageAsReadMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation MarkMessageAsReadMutation($input: MarkMessageAsReadInput!) {
    markMessageAsRead(input: $input) {
      deletedMessageId
    }
  }
`;

export const useMarkMessageAsReadMutation = (userId: string) => {
  const environment = useRelayEnvironment();

  const markMessageAsReadMutation = React.useCallback(
    (input: MarkMessageAsReadInput) =>
      commitMutation<MarkMessageAsReadMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: userId,
            connectionKeys: [{ key: "NotifyMessages_unreadMessages" }],
            pathToConnection: ["user", "unreadMessages"],
            deletedIDFieldName: "deletedMessageId",
          },
        ],
      }),
    [environment, userId]
  );

  return { markMessageAsReadMutation };
};
