import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateMonthlyBudgetInput,
  UpdateMonthlyBudgetMutation,
} from "~/__relay_artifacts__/UpdateMonthlyBudgetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateMonthlyBudgetMutation($input: UpdateMonthlyBudgetInput!) {
    updateMonthlyBudget(input: $input) {
      monthlyBudget {
        id
        date
        amount
        createdAt
        updatedAt
        latestVersion
      }
    }
  }
`;

export const useUpdateMonthlyBudgetMutation = () => {
  const environment = useRelayEnvironment();

  const updateMonthlyBudgetMutation = React.useCallback(
    (input: UpdateMonthlyBudgetInput) =>
      commitMutation<UpdateMonthlyBudgetMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateMonthlyBudgetMutation };
};
