/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRole = "ADMIN" | "NORMAL" | "%future added value";
export type MembershipList_viewer = {
    readonly id: string;
    readonly email: string;
    readonly role: UserRole;
    readonly " $refType": "MembershipList_viewer";
};
export type MembershipList_viewer$data = MembershipList_viewer;
export type MembershipList_viewer$key = {
    readonly " $data"?: MembershipList_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"MembershipList_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipList_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'a76445b90080e13ac604e87d684e7281';
export default node;
