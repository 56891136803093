/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type InvoiceEditForm_invoice = {
    readonly id: string;
    readonly title: string | null;
    readonly billingDate: string | null;
    readonly invoiceNumber: number | null;
    readonly dueDate: string | null;
    readonly periodSince: string | null;
    readonly periodTill: string | null;
    readonly " $refType": "InvoiceEditForm_invoice";
};
export type InvoiceEditForm_invoice$data = InvoiceEditForm_invoice;
export type InvoiceEditForm_invoice$key = {
    readonly " $data"?: InvoiceEditForm_invoice$data;
    readonly " $fragmentRefs": FragmentRefs<"InvoiceEditForm_invoice">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceEditForm_invoice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodTill",
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};
(node as any).hash = '6068b084fa78f1b8eb35a1ce10508d40';
export default node;
