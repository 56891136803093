/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RestoreAdSlotInput = {
    clientMutationId?: string | null;
    adSlotId: string;
};
export type RestoreAdSlotMutationVariables = {
    input: RestoreAdSlotInput;
};
export type RestoreAdSlotMutationResponse = {
    readonly restoreAdSlot: {
        readonly adSlot: {
            readonly deletedAt: string | null;
        } | null;
    } | null;
};
export type RestoreAdSlotMutation = {
    readonly response: RestoreAdSlotMutationResponse;
    readonly variables: RestoreAdSlotMutationVariables;
};



/*
mutation RestoreAdSlotMutation(
  $input: RestoreAdSlotInput!
) {
  restoreAdSlot(input: $input) {
    adSlot {
      deletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RestoreAdSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RestoreAdSlotPayload",
        "kind": "LinkedField",
        "name": "restoreAdSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlot",
            "kind": "LinkedField",
            "name": "adSlot",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RestoreAdSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RestoreAdSlotPayload",
        "kind": "LinkedField",
        "name": "restoreAdSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlot",
            "kind": "LinkedField",
            "name": "adSlot",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "936cf24e43363452a33cff6b841fc02c",
    "id": null,
    "metadata": {},
    "name": "RestoreAdSlotMutation",
    "operationKind": "mutation",
    "text": "mutation RestoreAdSlotMutation(\n  $input: RestoreAdSlotInput!\n) {\n  restoreAdSlot(input: $input) {\n    adSlot {\n      deletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '227ed84033348cf1a60fcbd2718717eb';
export default node;
