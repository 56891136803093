import { Grid } from "@material-ui/core";
import { FormikSelectField, FormikTextField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  email: string;
  role: "ADMIN" | "ADVERTISER" | "GUEST";
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  email: yup.string().required(),
  role: yup.string().oneOf(["ADMIN", "ADVERTISER", "GUEST"]).required(),
});

const roleOptions = [
  { value: "", label: "未選択" },
  { value: "ADMIN", label: "管理者" },
  { value: "ADVERTISER", label: "広告主" },
  { value: "GUEST", label: "ゲスト" },
];

export const MembershipForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField fullWidth name="email" label="メールアドレス" />
      </Grid>
      <Grid item xs={12}>
        <FormikSelectField
          required
          fullWidth
          name="role"
          label="役割"
          options={roleOptions}
        />
      </Grid>
    </Grid>
  </DialogForm>
);
