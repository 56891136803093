import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateScheduleSetInput,
  UpdateScheduleSetMutation,
} from "~/__relay_artifacts__/UpdateScheduleSetMutation.graphql";

const mutation = graphql`
  mutation UpdateScheduleSetMutation($input: UpdateScheduleSetInput!) {
    updateScheduleSet(input: $input) {
      scheduleSet {
        id
        title
        createdAt
      }
    }
  }
`;

export const useUpdateScheduleSetMutation = () => {
  const environment = useRelayEnvironment();

  const updateScheduleSetMutation = useCallback(
    (input: UpdateScheduleSetInput) =>
      commitMutation<UpdateScheduleSetMutation>(environment, {
        mutation,
        variables: {
          input,
        },
      }),
    [environment]
  );

  return { updateScheduleSetMutation };
};
