/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateExpenseInput = {
    clientMutationId?: string | null;
    id: string;
    date: string;
    rate: number;
    clientVersion?: number | null;
};
export type UpdateExpenseMutationVariables = {
    input: UpdateExpenseInput;
};
export type UpdateExpenseMutationResponse = {
    readonly updateExpense: {
        readonly expense: {
            readonly id: string;
            readonly date: string;
            readonly rate: number;
            readonly latestVersion: number | null;
            readonly " $fragmentRefs": FragmentRefs<"ExpenseEditForm_expense">;
        } | null;
    } | null;
};
export type UpdateExpenseMutation = {
    readonly response: UpdateExpenseMutationResponse;
    readonly variables: UpdateExpenseMutationVariables;
};



/*
mutation UpdateExpenseMutation(
  $input: UpdateExpenseInput!
) {
  updateExpense(input: $input) {
    expense {
      id
      date
      rate
      latestVersion
      ...ExpenseEditForm_expense
    }
  }
}

fragment ExpenseEditForm_expense on Expense {
  id
  date
  rate
  campaign {
    id
  }
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestVersion",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateExpenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExpensePayload",
        "kind": "LinkedField",
        "name": "updateExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Expense",
            "kind": "LinkedField",
            "name": "expense",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExpenseEditForm_expense"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateExpenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExpensePayload",
        "kind": "LinkedField",
        "name": "updateExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Expense",
            "kind": "LinkedField",
            "name": "expense",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "campaign",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b4cb73fb71f952f4c2dd10ba1beefae",
    "id": null,
    "metadata": {},
    "name": "UpdateExpenseMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateExpenseMutation(\n  $input: UpdateExpenseInput!\n) {\n  updateExpense(input: $input) {\n    expense {\n      id\n      date\n      rate\n      latestVersion\n      ...ExpenseEditForm_expense\n    }\n  }\n}\n\nfragment ExpenseEditForm_expense on Expense {\n  id\n  date\n  rate\n  campaign {\n    id\n  }\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = '6ed1b18e9dda906e6028fd64a2a365b9';
export default node;
