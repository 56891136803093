import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
} from "~/__relay_artifacts__/UpdateInvoiceMutation.graphql";

const mutation = graphql`
  mutation UpdateInvoiceMutation($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      invoice {
        id
        freeeInvoiceId
        title
        periodSince
        periodTill
        invoiceNumber
        billingDate
        dueDate
        ...InvoiceEditForm_invoice
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdateInvoiceInput) =>
  commitMutation<UpdateInvoiceMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
