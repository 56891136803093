import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  DeletePricingInput,
  DeletePricingMutation,
} from "~/__relay_artifacts__/DeletePricingMutation.graphql";

const mutation = graphql`
  mutation DeletePricingMutation($input: DeletePricingInput!) {
    deletePricing(input: $input) {
      deletedPricingId
    }
  }
`;

const commit = (environment: Environment, input: DeletePricingInput) =>
  commitMutation<DeletePricingMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "NODE_DELETE",
        deletedIDFieldName: "deletedPricingId",
      },
    ],
  });

export default commit;
