/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreativeListTableCard_QueryVariables = {
    projectId: string;
    first?: number | null;
    after?: string | null;
    withArchived?: boolean | null;
    title?: string | null;
};
export type CreativeListTableCard_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"CreativeListTableCard_project">;
    } | null;
};
export type CreativeListTableCard_Query = {
    readonly response: CreativeListTableCard_QueryResponse;
    readonly variables: CreativeListTableCard_QueryVariables;
};



/*
query CreativeListTableCard_Query(
  $projectId: ID!
  $first: Int
  $after: String
  $withArchived: Boolean
  $title: String
) {
  project(id: $projectId) {
    ...CreativeListTableCard_project
    id
  }
}

fragment CreativeEditForm_creative on Creative {
  id
  title
  adm
  width
  height
  iurl
  tagList
  note
  periodTill
  periodSince
  metaPosition
  metaOs
  metaAdmVersion
  archived
  latestVersion
}

fragment CreativeListTableCard_project on Project {
  id
  creatives(first: $first, after: $after, withArchived: $withArchived, title: $title) {
    totalCount
    edges {
      node {
        id
        title
        adm
        height
        width
        createdAt
        updatedAt
        sdkVersion
        periodSince
        periodTill
        metaOs
        metaPosition
        metaAdmVersion
        archived
        note
        latestVersion
        tagList
        ...CreativeEditForm_creative
        ...CreativeNoteEditForm_creative
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment CreativeNoteEditForm_creative on Creative {
  id
  title
  adm
  width
  height
  iurl
  tagList
  note
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "title",
    "variableName": "title"
  },
  {
    "kind": "Variable",
    "name": "withArchived",
    "variableName": "withArchived"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativeListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreativeListTableCard_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreativeListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "CreativeConnection",
            "kind": "LinkedField",
            "name": "creatives",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CreativeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Creative",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "adm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sdkVersion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodSince",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodTill",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metaOs",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metaPosition",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metaAdmVersion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "archived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "note",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "latestVersion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tagList",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iurl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [
              "withArchived",
              "title"
            ],
            "handle": "connection",
            "key": "CreativeListTableCard_creatives",
            "kind": "LinkedHandle",
            "name": "creatives"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3510c9364e13be812a77cb6e8a9c06a",
    "id": null,
    "metadata": {},
    "name": "CreativeListTableCard_Query",
    "operationKind": "query",
    "text": "query CreativeListTableCard_Query(\n  $projectId: ID!\n  $first: Int\n  $after: String\n  $withArchived: Boolean\n  $title: String\n) {\n  project(id: $projectId) {\n    ...CreativeListTableCard_project\n    id\n  }\n}\n\nfragment CreativeEditForm_creative on Creative {\n  id\n  title\n  adm\n  width\n  height\n  iurl\n  tagList\n  note\n  periodTill\n  periodSince\n  metaPosition\n  metaOs\n  metaAdmVersion\n  archived\n  latestVersion\n}\n\nfragment CreativeListTableCard_project on Project {\n  id\n  creatives(first: $first, after: $after, withArchived: $withArchived, title: $title) {\n    totalCount\n    edges {\n      node {\n        id\n        title\n        adm\n        height\n        width\n        createdAt\n        updatedAt\n        sdkVersion\n        periodSince\n        periodTill\n        metaOs\n        metaPosition\n        metaAdmVersion\n        archived\n        note\n        latestVersion\n        tagList\n        ...CreativeEditForm_creative\n        ...CreativeNoteEditForm_creative\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CreativeNoteEditForm_creative on Creative {\n  id\n  title\n  adm\n  width\n  height\n  iurl\n  tagList\n  note\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = '5930a43ff4e7ee35f8c02957a8397df3';
export default node;
