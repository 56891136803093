import { Card, CardContent, CardHeader, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import * as React from "react";
import { useHistory } from "react-router";

import { FormValues, ProjectForm, schema } from "~/components/ProjectForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createProjectMutation from "~/mutations/CreateProjectMutation";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  card: {
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  cardAction: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

const initialValues = { name: "", freeePartnerId: null };

export const ProjectCreate: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const environment = useRelayEnvironment();
  const formErrorHandler = useFormErrorHandler();

  const handleSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createProject } = await createProjectMutation(
          environment,
          values
        );
        if (!createProject?.project) throw new Error("assertion failed");
        history.push(`/projects/${createProject.project.id}`);
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [environment, formErrorHandler, history]
  );

  return (
    <main className={classes.main}>
      <Card className={classes.card}>
        <CardHeader title="プロジェクト作成" />
        <CardContent>
          <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={ProjectForm}
          />
        </CardContent>
      </Card>
    </main>
  );
};
