import { Theme, Toolbar } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { styled } from "@material-ui/styles";
import * as React from "react";

type Props = {
  highlighted: boolean;
};

const StyledToolbar = styled(Toolbar)<Theme, Props>(
  ({ theme: { palette }, highlighted }) => ({
    overflow: "scroll",
    ...(highlighted
      ? {
          color:
            palette.type === "light"
              ? palette.secondary.main
              : palette.text.primary,
          backgroundColor:
            palette.type === "light"
              ? lighten(palette.secondary.light, 0.85)
              : palette.secondary.dark,
        }
      : undefined),
  })
);

export const ListTableToolbar: React.FC<Props> = ({
  children,
  highlighted,
}) => <StyledToolbar highlighted={highlighted}>{children}</StyledToolbar>;
