export const StatusTexts = {
  ACTIVE: "配信中",
  PENDING: "保留中",
  STOPPED: "停止中",
  "%future added value": "その他",
} as const;

export const filterInitialValues = {
  field: "CREATED_AT",
  direction: "DESC",
  title: null,
  tagId: null,
  os: null,
  status: null,
  scheduleSetId: null,
} as const;
