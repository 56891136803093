/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PlacementList_QueryVariables = {
    projectId: string;
    adId: string;
    isEnabled?: boolean | null;
    tagId?: string | null;
    count?: number | null;
    cursor?: string | null;
};
export type PlacementList_QueryResponse = {
    readonly project: {
        readonly ad: {
            readonly " $fragmentRefs": FragmentRefs<"PlacementList_ad">;
        } | null;
    } | null;
};
export type PlacementList_Query = {
    readonly response: PlacementList_QueryResponse;
    readonly variables: PlacementList_QueryVariables;
};



/*
query PlacementList_Query(
  $projectId: ID!
  $adId: ID!
  $isEnabled: Boolean
  $tagId: String
  $count: Int
  $cursor: String
) {
  project(id: $projectId) {
    ad(id: $adId) {
      ...PlacementList_ad
      id
    }
    id
  }
}

fragment AdSlotNoteEditForm_placement on Placement {
  adSlot {
    note
    id
  }
}

fragment PlacementList_ad on Ad {
  id
  latestVersion
  placements(first: $count, after: $cursor, isEnabled: $isEnabled, tagId: $tagId) {
    edges {
      node {
        id
        correctionTerm
        isEnabled
        proportion
        latestVersion
        adSlot {
          id
          name
          domain
          tagId
          note
          sspProvider
          bundle
          platform
          width
          height
          adxBillingId
        }
        ...AdSlotNoteEditForm_placement
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagId"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "adId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestVersion",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "isEnabled",
    "variableName": "isEnabled"
  },
  {
    "kind": "Variable",
    "name": "tagId",
    "variableName": "tagId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlacementList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PlacementList_ad"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlacementList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "PlacementConnection",
                "kind": "LinkedField",
                "name": "placements",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Placement",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "correctionTerm",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isEnabled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "proportion",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdSlot",
                            "kind": "LinkedField",
                            "name": "adSlot",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "domain",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tagId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "note",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sspProvider",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bundle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "platform",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "height",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "adxBillingId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "isEnabled",
                  "tagId"
                ],
                "handle": "connection",
                "key": "PlacementList_placements",
                "kind": "LinkedHandle",
                "name": "placements"
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d5685bdc5fde744e83090083f4c61ac",
    "id": null,
    "metadata": {},
    "name": "PlacementList_Query",
    "operationKind": "query",
    "text": "query PlacementList_Query(\n  $projectId: ID!\n  $adId: ID!\n  $isEnabled: Boolean\n  $tagId: String\n  $count: Int\n  $cursor: String\n) {\n  project(id: $projectId) {\n    ad(id: $adId) {\n      ...PlacementList_ad\n      id\n    }\n    id\n  }\n}\n\nfragment AdSlotNoteEditForm_placement on Placement {\n  adSlot {\n    note\n    id\n  }\n}\n\nfragment PlacementList_ad on Ad {\n  id\n  latestVersion\n  placements(first: $count, after: $cursor, isEnabled: $isEnabled, tagId: $tagId) {\n    edges {\n      node {\n        id\n        correctionTerm\n        isEnabled\n        proportion\n        latestVersion\n        adSlot {\n          id\n          name\n          domain\n          tagId\n          note\n          sspProvider\n          bundle\n          platform\n          width\n          height\n          adxBillingId\n        }\n        ...AdSlotNoteEditForm_placement\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '034732e86222a2322a07002774e12900';
export default node;
