/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeletePricingInput = {
    clientMutationId?: string | null;
    pricingId: string;
};
export type DeletePricingMutationVariables = {
    input: DeletePricingInput;
};
export type DeletePricingMutationResponse = {
    readonly deletePricing: {
        readonly deletedPricingId: string;
    } | null;
};
export type DeletePricingMutation = {
    readonly response: DeletePricingMutationResponse;
    readonly variables: DeletePricingMutationVariables;
};



/*
mutation DeletePricingMutation(
  $input: DeletePricingInput!
) {
  deletePricing(input: $input) {
    deletedPricingId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeletePricingPayload",
    "kind": "LinkedField",
    "name": "deletePricing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedPricingId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeletePricingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeletePricingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f3591f38dd013d498bfed6513b5cca18",
    "id": null,
    "metadata": {},
    "name": "DeletePricingMutation",
    "operationKind": "mutation",
    "text": "mutation DeletePricingMutation(\n  $input: DeletePricingInput!\n) {\n  deletePricing(input: $input) {\n    deletedPricingId\n  }\n}\n"
  }
};
})();
(node as any).hash = '067fbe2d806e5f04a3b46b4fda727fa4';
export default node;
