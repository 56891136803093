import { Button, ButtonProps } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import {
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";

import { FormAlertDialog } from "./FormAlertDialog";
import {
  ResponsiveDialog,
  Props as ResponsiveDialogProps,
} from "./ResponsiveDialog";

type RenderProps = {
  open: () => void;
  close: () => void;
};

export type Props = {
  title?: string;
  render: (props: RenderProps) => ReactNode;
  toolbarContent?: ResponsiveDialogProps["toolbarContent"];
  minWidth?: number;
  fullScreen?: boolean;
  enableAlert?: boolean;
  withMargin?: boolean;
} & ButtonProps;

const StyledButton = styled(Button)({
  whiteSpace: "nowrap",
  margin: 3,
});

export const DialogButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      title,
      render,
      toolbarContent,
      minWidth,
      fullScreen = false,
      enableAlert = true,
      withMargin = true,
      ...buttonProps
    },
    ref
  ) => {
    const [dialogOpen, openDialog, closeDialog] = useOpenState();
    const [alertOpen, openAlert, closeAlert] = useOpenState();
    const [isTouched, setIsTouched] = useState(false);

    // NOTE: reset isTouched state if dialog is opened or closed
    useEffect(() => {
      setIsTouched(false);
    }, [dialogOpen]);

    const handleButtonClick = useCallback<MouseEventHandler>(
      (event) => {
        event.stopPropagation();
        openDialog();
      },
      [openDialog]
    );

    const handleDialogClick = useCallback<MouseEventHandler>((event) => {
      event.stopPropagation();
      setIsTouched(true);
    }, []);

    const handleKeyPress = useCallback<KeyboardEventHandler>((event) => {
      event.stopPropagation();
      setIsTouched(true);
    }, []);

    const handleDialogClose = useCallback<MouseEventHandler>(
      (event) => {
        event.stopPropagation();
        isTouched && enableAlert ? openAlert() : closeDialog();
      },
      [closeDialog, enableAlert, isTouched, openAlert]
    );

    const handleExecuteClick = useCallback<MouseEventHandler>(
      (event) => {
        event.stopPropagation();
        closeAlert();
        closeDialog();
      },
      [closeAlert, closeDialog]
    );

    return (
      <>
        <StyledButton {...buttonProps} ref={ref} onClick={handleButtonClick}>
          {children}
        </StyledButton>
        <ResponsiveDialog
          title={title}
          minWidth={minWidth}
          open={dialogOpen}
          toolbarContent={toolbarContent}
          fullScreen={fullScreen}
          withMargin={withMargin}
          onClose={handleDialogClose}
          onClick={handleDialogClick}
          onKeyPress={handleKeyPress}
        >
          {render({ close: closeDialog, open: openDialog })}
          <FormAlertDialog
            open={alertOpen}
            onClose={closeAlert}
            onExecute={handleExecuteClick}
          />
        </ResponsiveDialog>
      </>
    );
  }
);
