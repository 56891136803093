import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { CreateAdCreativeMutation } from "~/__relay_artifacts__/CreateAdCreativeMutation.graphql";
import { sharedDeleteUpdater } from "~/lib/mutationUtils";

const mutation = graphql`
  mutation CreateAdCreativeMutation($input: CreateAdCreativeInput!) {
    createAdCreative(input: $input) {
      creativeEdge {
        node {
          id
          title
        }
      }
    }
  }
`;

const commit = (environment: Environment, creativeId: string, adId: string) =>
  commitMutation<CreateAdCreativeMutation>(environment, {
    mutation,
    variables: {
      input: {
        creativeId,
        adId,
      },
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: adId,
        connectionInfo: [
          {
            key: "CreateAdCreativeMutation_creatives",
            rangeBehavior: "append",
          },
        ],
        edgeName: "creativeEdge",
      },
    ],
    updater: (store) => {
      sharedDeleteUpdater(creativeId, store, {
        parentId: adId,
        connectionName: "DeleteAdCreativeMutation_selectableCreatives",
      });
    },
  });

export default commit;
