import { Button, DialogContent, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { ProjectSelectFormForCopyCampaign_Query } from "~/__relay_artifacts__/ProjectSelectFormForCopyCampaign_Query.graphql";
import { ProjectSelectFormForCopyCampaign_viewer } from "~/__relay_artifacts__/ProjectSelectFormForCopyCampaign_viewer.graphql";
import { useRefetch } from "~/lib/relay-hooks";
import { useMoveCampaignMutation } from "~/mutations/MoveCampaignMutation";

type Props = {
  campaignId: string;
  viewerRef: FragmentRef<ProjectSelectFormForCopyCampaign_viewer>;
  onSubmitCompleted: () => void;
};

const query = graphql`
  query ProjectSelectFormForCopyCampaign_Query($search: String) {
    viewer {
      ...ProjectSelectFormForCopyCampaign_viewer
    }
  }
`;

const fragment = graphql`
  fragment ProjectSelectFormForCopyCampaign_viewer on User {
    projects(
      first: 2147483647 # max GraphQLInt
      search: $search
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const useRefetchViewer = (viewerRef: Props["viewerRef"]) => {
  const [viewer, refetch] = useRefetch<ProjectSelectFormForCopyCampaign_viewer>(
    fragment,
    viewerRef
  );

  const refetchViewer = React.useCallback(
    (search: string) => {
      refetch<ProjectSelectFormForCopyCampaign_Query>(query, { search });
    },
    [refetch]
  );

  return { viewer, refetchViewer };
};

export const ProjectSelectFormForCopyCamapign: React.FC<Props> = ({
  campaignId,
  viewerRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { moveCampaignMutation } = useMoveCampaignMutation();

  const [projectId, setProjectId] = React.useState<string>("");
  const { viewer, refetchViewer } = useRefetchViewer(viewerRef);

  const projects = React.useMemo(() => {
    return (viewer.projects.edges || []).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [viewer.projects.edges]);

  const options = React.useMemo(() => {
    return {
      options: projects,
      getOptionLabel: (option: {
        readonly id: string;
        readonly name: string;
      }) => option.name,
    };
  }, [projects]);

  const onSubmit = React.useCallback(async () => {
    try {
      const { moveCampaign } = await moveCampaignMutation({
        projectId,
        campaignId,
      });
      if (!moveCampaign?.campaign) throw new Error("assertion failed");
      enqueueSnackbar("選択したプロジェクトにキャンペーンが作成されました", {
        variant: "success",
      });
      onSubmitCompleted();
    } catch (err) {
      enqueueSnackbar(
        "選択したプロジェクトへのキャンペーンの作成が失敗しました",
        { variant: "error" }
      );
    }
  }, [
    projectId,
    campaignId,
    onSubmitCompleted,
    enqueueSnackbar,
    moveCampaignMutation,
  ]);

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      refetchViewer(e.target.value);
    },
    [refetchViewer]
  );

  return (
    <DialogContent>
      <>
        <Autocomplete
          {...options}
          onChange={(_, value) => {
            setProjectId(value?.id || "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="絞り込み検索"
              onChange={handleTextFieldChange}
            />
          )}
        />
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          {"保存する"}
        </Button>
      </>
    </DialogContent>
  );
};
