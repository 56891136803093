import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { useRelayEnvironment } from "relay-hooks";

import {
  BulkUpdateAdSlotPlacementInput,
  BulkUpdateAdSlotPlacementMutation,
} from "~/__relay_artifacts__/BulkUpdateAdSlotPlacementMutation.graphql";

const mutation = graphql`
  mutation BulkUpdateAdSlotPlacementMutation(
    $input: BulkUpdateAdSlotPlacementInput!
    $first: Int
    $after: String
    $isDistributedCampaigns: Boolean
  ) {
    bulkUpdateAdSlotPlacement(input: $input) {
      adSlot {
        id
        ...AdSlotPlacementListTableCard_adSlot
      }
    }
  }
`;

export const useBulkUpdateAdSlotPlacementMutation = () => {
  const environment = useRelayEnvironment();

  const bulkUpdateAdSlotPlacementMutation = useCallback(
    (input: BulkUpdateAdSlotPlacementInput, isDistributedCampaigns: boolean) =>
      commitMutation<BulkUpdateAdSlotPlacementMutation>(environment, {
        mutation,
        variables: {
          input,
          first: 100,
          isDistributedCampaigns,
        },
      }),
    [environment]
  );

  return { bulkUpdateAdSlotPlacementMutation };
};
