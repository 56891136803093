import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { ConnectionHandler } from "relay-runtime";

import {
  CreatePlacementInput,
  CreatePlacementMutation,
} from "~/__relay_artifacts__/CreatePlacementMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreatePlacementMutation($input: CreatePlacementInput!) {
    createPlacement(input: $input) {
      placementEdge {
        node {
          id
          correctionTerm
          proportion
          isEnabled
          adSlot {
            id
            name
            domain
            tagId
            sspProvider
          }
          ad {
            id
            title
            campaign {
              id
              title
              project {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const useCreatePlacementMutation = () => {
  const environment = useRelayEnvironment();

  const createPlacementMutation = React.useCallback(
    (input: CreatePlacementInput) =>
      commitMutation<CreatePlacementMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.adId,
            edgeName: "placementEdge",
            connectionInfo: [
              { key: "PlacementList_placements", rangeBehavior: "append" },
              {
                key: "AdSlotPlacementList_placements",
                rangeBehavior: "append",
              },
            ],
          },
        ],
        updater: (store) => {
          const payload = store.getRootField("createPlacement");
          if (!payload) return;

          const placementEdge = payload.getLinkedRecord("placementEdge");
          if (!placementEdge) return;

          const node = placementEdge.getLinkedRecord("node");
          if (!node) return;

          const adProxy = store.get(input.adId);
          if (!adProxy) return;

          const adUnitsConn = ConnectionHandler.getConnection(
            adProxy,
            "PlacementList_selectableAdSlots"
          );
          if (!adUnitsConn) return;

          ConnectionHandler.deleteNode(adUnitsConn, input.adSlotId);
        },
      }),
    [environment]
  );

  return { createPlacementMutation };
};
