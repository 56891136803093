/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TrackerProvider = "ADJUST" | "APPSFLYER" | "SINGULAR" | "VRIZE" | "%future added value";
export type CreateTrackerInput = {
    clientMutationId?: string | null;
    projectId: string;
    title: string;
    provider: TrackerProvider;
    parameters: string;
    adjustEventCallbackKey?: string | null;
    associationType: string;
};
export type CreateTrackerMutationVariables = {
    input: CreateTrackerInput;
};
export type CreateTrackerMutationResponse = {
    readonly createTracker: {
        readonly trackerEdge: {
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly provider: TrackerProvider;
                readonly parameters: string | null;
                readonly associationType: string;
                readonly createdAt: string;
                readonly updatedAt: string;
            } | null;
        } | null;
    } | null;
};
export type CreateTrackerMutation = {
    readonly response: CreateTrackerMutationResponse;
    readonly variables: CreateTrackerMutationVariables;
};



/*
mutation CreateTrackerMutation(
  $input: CreateTrackerInput!
) {
  createTracker(input: $input) {
    trackerEdge {
      node {
        id
        title
        provider
        parameters
        associationType
        createdAt
        updatedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTrackerPayload",
    "kind": "LinkedField",
    "name": "createTracker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TrackerEdge",
        "kind": "LinkedField",
        "name": "trackerEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tracker",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "provider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parameters",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "associationType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTrackerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTrackerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6eb2adba122f1e2500056f4516761ad8",
    "id": null,
    "metadata": {},
    "name": "CreateTrackerMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTrackerMutation(\n  $input: CreateTrackerInput!\n) {\n  createTracker(input: $input) {\n    trackerEdge {\n      node {\n        id\n        title\n        provider\n        parameters\n        associationType\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1fbdc9c740e6840ce6130542b3692e19';
export default node;
