import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";
import { useHistory } from "react-router";

import { ProjectEditForm_project } from "~/__relay_artifacts__/ProjectEditForm_project.graphql";
import { ProjectEdit_Query } from "~/__relay_artifacts__/ProjectEdit_Query.graphql";
import { FormValues, ProjectForm, schema } from "~/components/ProjectForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updateProjectMutation from "~/mutations/UpdateProjectMutation";

type Props = {
  projectRef: ProjectEdit_Query["response"]["project"];
};

const projectFragment = graphql`
  fragment ProjectEditForm_project on Project {
    name
    freeePartnerId
    id
  }
`;

export const ProjectEditForm: React.FC<Props> = ({ projectRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const history = useHistory();
  const environment = useRelayEnvironment();
  const { id, name, freeePartnerId } = useFragment<ProjectEditForm_project>(
    projectFragment,
    projectRef
  );

  const initialValues = React.useMemo(
    () => ({ name, freeePartnerId }),
    [name, freeePartnerId]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateProject } = await updateProjectMutation(environment, {
          id,
          name: values.name,
          freeePartnerId: values.freeePartnerId,
        });

        const _project = updateProject && updateProject.project;
        if (!_project) throw new Error("assertion failed");

        enqueueSnackbar("プロジェクトを更新しました", { variant: "success" });
        history.push(`/projects/${id}/home`);
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [enqueueSnackbar, environment, formErrorHandler, history, id]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={ProjectForm}
    />
  );
};
