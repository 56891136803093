import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { useParams } from "react-router";

import { DialogForm } from "~/components/DialogForm";
import { ListTable } from "~/components/ListTable";
import { delayChunkPromise } from "~/lib/utils";
import { useUpsertDailyBudgetMutation } from "~/mutations/UpsertDailyBudgetMutation";

export type FormValues = {
  date: string;
  amount: number;
}[];

type Props = {
  campaignClientVersion: number | null;
  periodSince: Date;
  periodTill: Date;
  remainingMonthlyBudget: number;
  selectedMonth: Date;
  onSubmitCompleted: () => void;
};

export const DailyBudgetProratedSimurationPreview: React.FC<Props> = ({
  campaignClientVersion,
  periodSince,
  periodTill,
  remainingMonthlyBudget,
  selectedMonth,
  onSubmitCompleted,
}) => {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { upsertDailyBudgetMutation } = useUpsertDailyBudgetMutation();

  const dailyBudgets = React.useMemo(() => {
    const dates = DateFns.eachDayOfInterval({
      start: DateFns.addDays(periodSince, 1),
      end: DateFns.addDays(periodTill, 1),
    });
    const amount = Math.round(remainingMonthlyBudget / dates.length);
    return dates.map((date) => {
      return { date: date.toISOString(), amount: amount };
    });
  }, [periodSince, periodTill, remainingMonthlyBudget]);

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values) => {
      try {
        const mutations = values.map((input) =>
          upsertDailyBudgetMutation(
            {
              date: input.date,
              baseAmount: input.amount,
              campaignId: id,
              clientVersion: campaignClientVersion,
            },
            selectedMonth
          )
        );
        await delayChunkPromise(mutations);
        enqueueSnackbar("日予算を登録しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [
      campaignClientVersion,
      enqueueSnackbar,
      id,
      onSubmitCompleted,
      selectedMonth,
      upsertDailyBudgetMutation,
    ]
  );

  return (
    <Formik<FormValues> initialValues={dailyBudgets} onSubmit={onSubmit}>
      <DialogForm submitButtonTitle="登録する">
        <Grid container spacing={3}>
          <ListTable>
            <TableHead>
              <TableRow>
                <TableCell>日付</TableCell>
                <TableCell>予算</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dailyBudgets.map((budget, index) => (
                <TableRow key={index}>
                  <TableCell>{R.slice(0, 10, budget.date)}</TableCell>
                  <TableCell>{budget.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ListTable>
        </Grid>
      </DialogForm>
    </Formik>
  );
};
