import { makeStyles } from "@material-ui/styles";
import * as React from "react";

export type Props = {
  html: string;
  height: number;
  width: number;
  playing: boolean;
};

const STYLE_TAG = '<style type="text/css">body{margin:0}</style>';

const useStyles = makeStyles({
  preview: {
    border: "solid 1px #000000",
  },
});

export const CreativePreview: React.FC<Props> = ({
  html,
  height,
  width,
  playing,
}) => {
  const classes = useStyles();
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  React.useEffect(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) return;
    // NOTE: get the `document` object on iframe `Window`
    const iframeDocument = iframeRef.current.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(html + STYLE_TAG);
    iframeDocument.close();
  }, [html, playing]);

  return (
    <div style={{ height, width }}>
      {playing && (
        <iframe
          title="preview"
          sandbox="allow-scripts allow-same-origin"
          className={classes.preview}
          ref={iframeRef}
          height={height}
          width={width}
        />
      )}
    </div>
  );
};
