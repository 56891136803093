/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpsertDailyBudgetInput = {
    clientMutationId?: string | null;
    campaignId: string;
    date: string;
    baseAmount: number;
    clientVersion?: number | null;
};
export type UpsertDailyBudgetMutationVariables = {
    input: UpsertDailyBudgetInput;
    month: string;
};
export type UpsertDailyBudgetMutationResponse = {
    readonly upsertDailyBudget: {
        readonly campaign: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"DailyBudgetListDataSheet_campaign">;
        } | null;
    } | null;
};
export type UpsertDailyBudgetMutation = {
    readonly response: UpsertDailyBudgetMutationResponse;
    readonly variables: UpsertDailyBudgetMutationVariables;
};



/*
mutation UpsertDailyBudgetMutation(
  $input: UpsertDailyBudgetInput!
  $month: ISO8601Date!
) {
  upsertDailyBudget(input: $input) {
    campaign {
      id
      ...DailyBudgetListDataSheet_campaign
    }
  }
}

fragment DailyBudgetListDataSheet_campaign on Campaign {
  id
  latestVersion
  dailyBudgets(first: 100, month: $month) {
    edges {
      node {
        id
        date
        amount
        baseAmount
        createdAt
        updatedAt
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "month"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Variable",
    "name": "month",
    "variableName": "month"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertDailyBudgetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertDailyBudgetPayload",
        "kind": "LinkedField",
        "name": "upsertDailyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DailyBudgetListDataSheet_campaign"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertDailyBudgetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertDailyBudgetPayload",
        "kind": "LinkedField",
        "name": "upsertDailyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latestVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "DailyBudgetConnection",
                "kind": "LinkedField",
                "name": "dailyBudgets",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DailyBudgetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DailyBudget",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "baseAmount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "month"
                ],
                "handle": "connection",
                "key": "DailyBudgetListDataSheet_dailyBudgets",
                "kind": "LinkedHandle",
                "name": "dailyBudgets"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b30f78e9c047996f9312192f677f434",
    "id": null,
    "metadata": {},
    "name": "UpsertDailyBudgetMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertDailyBudgetMutation(\n  $input: UpsertDailyBudgetInput!\n  $month: ISO8601Date!\n) {\n  upsertDailyBudget(input: $input) {\n    campaign {\n      id\n      ...DailyBudgetListDataSheet_campaign\n    }\n  }\n}\n\nfragment DailyBudgetListDataSheet_campaign on Campaign {\n  id\n  latestVersion\n  dailyBudgets(first: 100, month: $month) {\n    edges {\n      node {\n        id\n        date\n        amount\n        baseAmount\n        createdAt\n        updatedAt\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '044f9468435d547df8aafb5c72f4ebd6';
export default node;
