import { useFormErrorHandler } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import { FormValues, InvoiceForm, schema } from "~/components/InvoiceForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createInvoiceMutation from "~/mutations/CreateInvoiceMutation";

type Props = {
  onSubmitCompleted: () => void;
};

export const InvoiceCreateForm: React.FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();
  const environment = useRelayEnvironment();
  const formErrorHandler = useFormErrorHandler();
  const { currentDate, periodSince, periodTill, dueDate } =
    React.useMemo(() => {
      const currentDate = new Date();
      const lastMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return {
        currentDate: currentDate.toISOString(),
        periodSince: DateFns.startOfMonth(lastMonth).toISOString(),
        periodTill: DateFns.lastDayOfMonth(lastMonth).toISOString(),
        dueDate: DateFns.lastDayOfMonth(
          DateFns.addMonths(currentDate, 1)
        ).toISOString(),
      };
    }, []);

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: "3D AD配信費用",
      periodSince,
      periodTill,
      billingDate: currentDate,
      dueDate,
      invoiceLines: [],
    }),
    [currentDate, periodSince, periodTill, dueDate]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        await createInvoiceMutation(environment, {
          projectId,
          title: values.title,
          billingDate: values.billingDate,
          dueDate: values.dueDate,
          periodSince: values.periodSince,
          periodTill: values.periodTill,
          invoiceLines: values.invoiceLines,
        });
        enqueueSnackbar("請求書を作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      projectId,
      environment,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => <InvoiceForm {...formikHelpers} />}
    </Formik>
  );
};
