import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateProjectInput,
  CreateProjectMutation,
} from "~/__relay_artifacts__/CreateProjectMutation.graphql";

const mutation = graphql`
  mutation CreateProjectMutation($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
        name
        freeePartnerId
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateProjectInput) =>
  commitMutation<CreateProjectMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
