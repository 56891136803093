import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdatePlacementSetInput,
  UpdatePlacementSetMutation,
} from "~/__relay_artifacts__/UpdatePlacementSetMutation.graphql";

const mutation = graphql`
  mutation UpdatePlacementSetMutation($input: UpdatePlacementSetInput!) {
    updatePlacementSet(input: $input) {
      placementSet {
        id
        title
        latestVersion
      }
    }
  }
`;

export const useUpdatePlacementSetMutation = () => {
  const environment = useRelayEnvironment();

  const updatePlacementSetMutation = useCallback(
    (input: UpdatePlacementSetInput) =>
      commitMutation<UpdatePlacementSetMutation>(environment, {
        mutation,
        variables: {
          input,
        },
      }),
    [environment]
  );

  return { updatePlacementSetMutation };
};
