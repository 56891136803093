import {
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { ConnectionConfig } from "relay-hooks";

import { MaintenanceListTableCard_root } from "~/__relay_artifacts__/MaintenanceListTableCard_root.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { TableCell } from "~/components/atoms/TableCell";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { usePagination } from "~/lib/relay-hooks";
import { useDeleteMaintenanceMutation } from "~/mutations/DeleteMaintenanceMutation";

import { MaintenanceCreateForm, MaintenanceEditForm } from "./MaintenanceForm";

type Props = {
  rootRef: FragmentRef<MaintenanceListTableCard_root>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 100,
  },
}));

const rootFragment = graphql`
  fragment MaintenanceListTableCard_root on Query {
    maintenances(first: $first, after: $after)
      @connection(key: "MaintenanceListTableCard_maintenances") {
      edges {
        node {
          id
          message
          createdAt
          updatedAt
          ...MaintenanceEditForm_maintenance
        }
      }
      totalCount
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.root.Maintenances,
  getVariables: (_, { count, cursor }) => ({ first: count, after: cursor }),
  query: graphql`
    query MaintenanceListTableCard_Query($first: Int, $after: String) {
      ...MaintenanceListTableCard_root
    }
  `,
};

export const MaintenanceListTableCard: React.FC<Props> = ({ rootRef }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [root, { hasMore, loadMore }] =
    usePagination<MaintenanceListTableCard_root>(rootFragment, rootRef);
  const { deleteMaintenanceMutation } = useDeleteMaintenanceMutation();
  const totalCount = root.maintenances.totalCount;

  const maintenances = React.useMemo(() => {
    const edges = root.maintenances.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [page, perPage, root.maintenances.edges]);

  const handleDeleteClick = React.useCallback(
    async (maintenanceId: string) => {
      try {
        const { deleteMaintenance } = await deleteMaintenanceMutation(
          maintenanceId,
          {
            type: "RANGE_DELETE",
            parentID: "client:root",
            connectionKeys: [{ key: "MaintenanceListTableCard_maintenances" }],
            pathToConnection: ["root", "Maintenances"],
            deletedIDFieldName: "deletedMaintenanceId",
          }
        );
        const deletedMaintenanceId = deleteMaintenance?.deletedMaintenanceId;
        if (!deletedMaintenanceId) throw new Error("assertion failed");
        enqueueSnackbar(`メンテナンス設定を削除しました。`, {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [deleteMaintenanceMutation, enqueueSnackbar]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          メッセージ管理
        </Typography>
        <DialogButton
          title="作成"
          render={({ close }) => (
            <MaintenanceCreateForm onSubmitCompleted={close} />
          )}
        >
          作成
        </DialogButton>
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>本文</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>更新日</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {maintenances.map((maintenance) => (
            <TableRow key={maintenance.id}>
              <TableCell>{atob(maintenance.id)}</TableCell>
              <TableCell>
                <DialogButton
                  minWidth={600}
                  title="メンテナンスメッセージ"
                  variant="outlined"
                  render={() => <>{maintenance.message}</>}
                >
                  メッセージ表示
                </DialogButton>
              </TableCell>
              <TableCell>{parseISO(maintenance.createdAt)}</TableCell>
              <TableCell>{parseISO(maintenance.updatedAt)}</TableCell>
              <TableCell>
                <DialogButton
                  variant="outlined"
                  color="primary"
                  title="メンテナンス設定編集"
                  render={({ close }) => (
                    <MaintenanceEditForm
                      maintenanceRef={maintenance}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="このメンテナンス設定を削除しますか？"
                  onAgree={async (changeDialog) => {
                    handleDeleteClick(maintenance.id);
                    changeDialog(false);
                  }}
                >
                  削除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          hasMore() && loadMore(connectionConfig, perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(e) => setPerPage(parseInt(e.target.value))}
      />
    </Paper>
  );
};
