import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateCampaignDomainRestrictionInput,
  CreateCampaignDomainRestrictionMutation,
} from "~/__relay_artifacts__/CreateCampaignDomainRestrictionMutation.graphql";

const mutation = graphql`
  mutation CreateCampaignDomainRestrictionMutation(
    $connections: [ID!]!
    $input: CreateCampaignDomainRestrictionInput!
  ) {
    createCampaignDomainRestriction(input: $input) {
      campaignDomainRestrictionEdge @appendEdge(connections: $connections) {
        node {
          id
          domain
          status
        }
      }
    }
  }
`;

export const createCampaignDomainRestrictionMutation = (
  environment: Environment,
  input: CreateCampaignDomainRestrictionInput,
  connectionId: string
) => {
  return commitMutation<CreateCampaignDomainRestrictionMutation>(environment, {
    mutation,
    variables: {
      input,
      connections: [connectionId],
    },
  });
};

export type { CreateCampaignDomainRestrictionInput };
