import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router-dom";
import { useQuery } from "relay-hooks";

import { CampaignList_Query } from "~/__relay_artifacts__/CampaignList_Query.graphql";
import { CampaignList as CampaignListComponent } from "~/components/CampaignList";

const query = graphql`
  query CampaignList_Query(
    $projectId: ID!
    $first: Int
    $trackerFirst: Int
    $after: String
    $withArchived: Boolean
    $title: String
  ) {
    project(id: $projectId) {
      ...CampaignList_project
    }
  }
`;

export const CampaignList: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<CampaignList_Query>(query, {
    projectId,
    trackerFirst: 200,
    withArchived: false,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  if (!props.project) return <div>not found</div>;
  return <CampaignListComponent projectRef={props.project} />;
};
