/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClientDailyBudgetListTableCard_campaign = {
    readonly id: string;
    readonly dailyBudgets: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly date: string;
                readonly baseAmount: number;
                readonly " $fragmentRefs": FragmentRefs<"DailyBudgetEditForm_dailyBudget">;
            } | null;
        } | null> | null;
        readonly totalCount: number;
    };
    readonly " $refType": "ClientDailyBudgetListTableCard_campaign";
};
export type ClientDailyBudgetListTableCard_campaign$data = ClientDailyBudgetListTableCard_campaign;
export type ClientDailyBudgetListTableCard_campaign$key = {
    readonly " $data"?: ClientDailyBudgetListTableCard_campaign$data;
    readonly " $fragmentRefs": FragmentRefs<"ClientDailyBudgetListTableCard_campaign">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "month"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "dailyBudgets"
        ]
      }
    ]
  },
  "name": "ClientDailyBudgetListTableCard_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "dailyBudgets",
      "args": [
        {
          "kind": "Variable",
          "name": "month",
          "variableName": "month"
        }
      ],
      "concreteType": "DailyBudgetConnection",
      "kind": "LinkedField",
      "name": "__DailyBudgetListTableCard_dailyBudgets_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DailyBudgetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DailyBudget",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "baseAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DailyBudgetEditForm_dailyBudget"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();
(node as any).hash = '31ea83d1e93debe85ddb939ebc993e3c';
export default node;
