import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateExpenseInput,
  CreateExpenseMutation,
} from "~/__relay_artifacts__/CreateExpenseMutation.graphql";
import { ConnectionUpdaterOptions } from "~/lib/mutationUtils";

const mutation = graphql`
  mutation CreateExpenseMutation($input: CreateExpenseInput!) {
    createExpense(input: $input) {
      expenseEdge {
        node {
          id
          date
          rate
          ...ExpenseEditForm_expense
        }
      }
    }
  }
`;

// TODO: do not use type assertion
const configs = (options?: ConnectionUpdaterOptions): any => {
  return !!options
    ? [
        {
          type: "RANGE_ADD",
          parentID: options.parentId,
          connectionInfo: [
            {
              key: options.connectionName,
              rangeBehavior: "append",
            },
          ],
          edgeName: "expenseEdge",
        },
      ]
    : undefined;
};

const commit = (
  environment: Environment,
  input: CreateExpenseInput,
  options?: ConnectionUpdaterOptions
) =>
  commitMutation<CreateExpenseMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: configs(options),
  });

export default commit;
