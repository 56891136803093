/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectSelectionButton_QueryVariables = {
    search?: string | null;
};
export type ProjectSelectionButton_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectSelectionButton_viewer">;
    };
};
export type ProjectSelectionButton_Query = {
    readonly response: ProjectSelectionButton_QueryResponse;
    readonly variables: ProjectSelectionButton_QueryVariables;
};



/*
query ProjectSelectionButton_Query(
  $search: String
) {
  viewer {
    ...ProjectSelectionButton_viewer
    id
  }
}

fragment ProjectSelectionButton_viewer on User {
  role
  projects(search: $search) {
    edges {
      node {
        id
        name
        viewerCanAdministrate
        updatedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSelectionButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectSelectionButton_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectSelectionButton_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "search",
                "variableName": "search"
              }
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerCanAdministrate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61c46ec0bb0527277d6e2fecd20831e5",
    "id": null,
    "metadata": {},
    "name": "ProjectSelectionButton_Query",
    "operationKind": "query",
    "text": "query ProjectSelectionButton_Query(\n  $search: String\n) {\n  viewer {\n    ...ProjectSelectionButton_viewer\n    id\n  }\n}\n\nfragment ProjectSelectionButton_viewer on User {\n  role\n  projects(search: $search) {\n    edges {\n      node {\n        id\n        name\n        viewerCanAdministrate\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '38385d77a733d798471267766a088524';
export default node;
