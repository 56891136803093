/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateProjectInput = {
    clientMutationId?: string | null;
    id: string;
    name: string;
    freeePartnerId?: string | null;
};
export type UpdateProjectMutationVariables = {
    input: UpdateProjectInput;
};
export type UpdateProjectMutationResponse = {
    readonly updateProject: {
        readonly project: {
            readonly id: string;
            readonly name: string;
            readonly freeePartnerId: string | null;
        } | null;
    } | null;
};
export type UpdateProjectMutation = {
    readonly response: UpdateProjectMutationResponse;
    readonly variables: UpdateProjectMutationVariables;
};



/*
mutation UpdateProjectMutation(
  $input: UpdateProjectInput!
) {
  updateProject(input: $input) {
    project {
      id
      name
      freeePartnerId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProjectPayload",
    "kind": "LinkedField",
    "name": "updateProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "freeePartnerId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d72b026181f2cc6587187ae9eb3d138b",
    "id": null,
    "metadata": {},
    "name": "UpdateProjectMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProjectMutation(\n  $input: UpdateProjectInput!\n) {\n  updateProject(input: $input) {\n    project {\n      id\n      name\n      freeePartnerId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8f62a12c09f0f0e5b80518bb968943b6';
export default node;
