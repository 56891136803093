import { Tooltip } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as DateFns from "date-fns";
import { FC, useMemo } from "react";

import { parseISO } from "~/lib/parseISO";

type Props = {
  updatedAtISOString: string;
};

const StyledText = styled("span")<{}, { hasNotBeenUpdatedRecently: boolean }>(
  (props) =>
    props.hasNotBeenUpdatedRecently
      ? {
          color: "red",
          fontWeight: "bold",
        }
      : {}
);

export const UpdatedAtText: FC<Props> = ({ updatedAtISOString }) => {
  const hasNotBeenUpdatedRecently = useMemo(() => {
    const threeMonthsAgo = DateFns.sub(new Date(), { months: 3 });
    const updatedDate = new Date(updatedAtISOString);
    return DateFns.isBefore(updatedDate, threeMonthsAgo);
  }, [updatedAtISOString]);

  return (
    <Tooltip
      title="更新されてから3ヶ月以上経過しています"
      disableHoverListener={!hasNotBeenUpdatedRecently}
    >
      <StyledText hasNotBeenUpdatedRecently={hasNotBeenUpdatedRecently}>
        {parseISO(updatedAtISOString)}
      </StyledText>
    </Tooltip>
  );
};
