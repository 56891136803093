import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";
import { useMemo } from "reactn";

import { TrackerList_project } from "~/__relay_artifacts__/TrackerList_project.graphql";
import { useUpdateTrackerMutation } from "~/mutations/UpdateTrackerMutation";

import { FormValues, TrackerForm, schema } from "./TrackerForm";

type Tracker = NonNullable<
  NonNullable<
    NonNullable<NonNullable<TrackerList_project["trackers"]>["edges"]>
  >[0]
>["node"];

type Props = {
  tracker: NonNullable<Tracker>;
  onSubmitCompleted: () => void;
};

export const TrackerEditForm: FC<Props> = ({ tracker, onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateTrackerMutation } = useUpdateTrackerMutation();

  const initialValues = useMemo(() => {
    const parameters = tracker.parameters
      ? JSON.parse(tracker.parameters)
      : null;
    const parametersArray = parameters
      ? Object.keys(parameters).map((key) => ({
          key,
          value: parameters[key] as string,
        }))
      : [];
    return {
      title: tracker.title,
      provider: tracker.provider as FormValues["provider"],
      parameters: parametersArray,
      adjustEventCallbackKey: tracker.adjustEventCallbackKey,
      associationType: tracker.associationType,
    };
  }, [tracker]);

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const parameters = values.parameters.reduce(
          (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
          {}
        );
        const parametersJson = JSON.stringify(parameters);
        const { updateTracker } = await updateTrackerMutation({
          ...values,
          parameters: parametersJson,
          trackerId: tracker.id,
        });
        if (!updateTracker) throw new Error("assertion failed");
        enqueueSnackbar("トラッカーを編集しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar("トラッカーの編集に失敗しました", { variant: "error" });
        formErrorHandler(err, setErrors);
      }
    },
    [
      updateTrackerMutation,
      tracker.id,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <TrackerForm />
    </Formik>
  );
};
