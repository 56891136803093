/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExcludeScheduleListTableCard_QueryVariables = {
    projectId: string;
    campaignId: string;
    first?: number | null;
    after?: string | null;
};
export type ExcludeScheduleListTableCard_QueryResponse = {
    readonly project: {
        readonly campaign: {
            readonly " $fragmentRefs": FragmentRefs<"ExcludeScheduleListTableCard_campaign">;
        } | null;
    } | null;
};
export type ExcludeScheduleListTableCard_Query = {
    readonly response: ExcludeScheduleListTableCard_QueryResponse;
    readonly variables: ExcludeScheduleListTableCard_QueryVariables;
};



/*
query ExcludeScheduleListTableCard_Query(
  $projectId: ID!
  $campaignId: ID!
  $first: Int
  $after: String
) {
  project(id: $projectId) {
    campaign(id: $campaignId) {
      ...ExcludeScheduleListTableCard_campaign
      id
    }
    id
  }
}

fragment ExcludeScheduleEditForm_excludeSchedule on ExcludeSchedule {
  id
  name
  since
  till
  latestVersion
}

fragment ExcludeScheduleListTableCard_campaign on Campaign {
  id
  project {
    id
  }
  excludeSchedules(first: $first, after: $after) {
    edges {
      node {
        id
        name
        since
        till
        ...ExcludeScheduleEditForm_excludeSchedule
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExcludeScheduleListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExcludeScheduleListTableCard_campaign"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExcludeScheduleListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ExcludeScheduleConnection",
                "kind": "LinkedField",
                "name": "excludeSchedules",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExcludeScheduleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExcludeSchedule",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "since",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "till",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestVersion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "ExcludeScheduleListTableCard_excludeSchedules",
                "kind": "LinkedHandle",
                "name": "excludeSchedules"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23f0da0f1cc8eaa595d79ffc41186cd5",
    "id": null,
    "metadata": {},
    "name": "ExcludeScheduleListTableCard_Query",
    "operationKind": "query",
    "text": "query ExcludeScheduleListTableCard_Query(\n  $projectId: ID!\n  $campaignId: ID!\n  $first: Int\n  $after: String\n) {\n  project(id: $projectId) {\n    campaign(id: $campaignId) {\n      ...ExcludeScheduleListTableCard_campaign\n      id\n    }\n    id\n  }\n}\n\nfragment ExcludeScheduleEditForm_excludeSchedule on ExcludeSchedule {\n  id\n  name\n  since\n  till\n  latestVersion\n}\n\nfragment ExcludeScheduleListTableCard_campaign on Campaign {\n  id\n  project {\n    id\n  }\n  excludeSchedules(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        name\n        since\n        till\n        ...ExcludeScheduleEditForm_excludeSchedule\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6bdf32dd605b503f8550cc8456ad015e';
export default node;
