import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { CreativeEditForm_creative } from "~/__relay_artifacts__/CreativeEditForm_creative.graphql";
import { CreativeForm, FormValues, schema } from "~/components/CreativeForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateCreativeMutation } from "~/mutations/UpdateCreativeMutation";

type Props = {
  creativeRef: FragmentRef<CreativeEditForm_creative>;
  onSubmitCompleted: () => void;
};

const creativeFragment = graphql`
  fragment CreativeEditForm_creative on Creative {
    id
    title
    adm
    width
    height
    iurl
    tagList
    note
    periodTill
    periodSince
    metaPosition
    metaOs
    metaAdmVersion
    archived
    latestVersion
  }
`;

export const CreativeEditForm: React.FC<Props> = ({
  creativeRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateCreativeMutation } = useUpdateCreativeMutation();
  const creative = useFragment<CreativeEditForm_creative>(
    creativeFragment,
    creativeRef
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      if (!values.adm || !values.title) throw new Error("assertion failed.");
      try {
        const { updateCreative } = await updateCreativeMutation({
          id: creative.id,
          clientVersion: creative.latestVersion,
          ...values,
        });
        if (!updateCreative?.creative) throw new Error("assertion failed");
        enqueueSnackbar("クリエイティブを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      creative,
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      updateCreativeMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={
        {
          title: creative.title,
          adm: creative.adm,
          width: creative.width,
          height: creative.height,
          iurl: creative.iurl,
          viewableValidationEnabled: true,
          tagList: creative.tagList || [],
          note: creative.note,
          periodSince: creative.periodSince,
          periodTill: creative.periodTill,
          metaAdmVersion: creative.metaAdmVersion,
          metaOs: creative.metaOs,
          metaPosition: creative.metaPosition,
        } as FormValues
      }
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={CreativeForm}
    />
  );
};
