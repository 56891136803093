import { useSnackbar } from "notistack";
import { pick } from "ramda";
import * as React from "react";

import {
  EditableTextField,
  Props as EditableTextFieldProps,
} from "~/components/EditableTextField";
import { useUpdatePlacementMutation } from "~/mutations/UpdatePlacementMutation";

import { StyledEditableTextFieldWrapper } from "./styles";
import { PartialPlacement } from "./types";

type Props = {
  placement: PartialPlacement;
};

export const CorrectionTermField: React.FC<Props> = ({ placement }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updatePlacementMutation } = useUpdatePlacementMutation();

  const handleSubmit = React.useCallback<EditableTextFieldProps["onSubmit"]>(
    async ({ input }, { resetForm }) => {
      try {
        if (input === placement.correctionTerm) return;
        if (typeof input !== "number") throw new Error("assertion error");
        await updatePlacementMutation({
          ...pick(["id", "correctionTerm", "proportion"], placement),
          clientVersion: placement.latestVersion,
          correctionTerm: input,
        });
        enqueueSnackbar("補正係数を更新しました。", {
          variant: "success",
        });
      } catch (_) {
        enqueueSnackbar("補正係数の更新に失敗しました。", {
          variant: "error",
        });
        resetForm();
      }
    },
    [enqueueSnackbar, placement, updatePlacementMutation]
  );

  return (
    <StyledEditableTextFieldWrapper>
      <EditableTextField
        type="number"
        precision={2}
        value={placement.correctionTerm}
        onSubmit={handleSubmit}
      />
    </StyledEditableTextFieldWrapper>
  );
};
