import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  FormikChipTextField,
  FormikDateTimeField,
  FormikNumberField,
  FormikSelectField,
  FormikSwitchField,
  FormikTextField,
  useOpenState,
} from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import { FC, useCallback, useState } from "react";
import * as yup from "yup";

import { validateTag } from "~/lib/validateTag";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  title: string;
  adm: string;
  metaAdmVersion?: string;
  width?: number;
  height?: number;
  iurl?: string;
  viewableValidationEnabled: boolean;
  tagList: string[];
  note: string | null;
  periodSince?: string;
  periodTill?: string;
  metaOs?: "IOS" | "ANDROID" | "ALL";
  metaPosition?: "OVERLAY" | "INLINE";
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  title: yup.string().required(),
  width: yup.number().required(),
  height: yup.number().required(),
  adm: yup
    .string()
    .required()
    .when("viewableValidationEnabled", {
      is: true,
      then: yup
        .string()
        .test("impression-tag-check", "v-viewableタグがありません", (value) =>
          validateTag(value)
        ),
    }),
  periodSince: yup.string().nullable(),
  periodTill: yup.string().nullable(),
  metaAdmVersion: yup
    .string()
    .matches(
      /^[0-9]+\.[0-9]+\.[0-9]+$/,
      "MAJOR.MINOR.PATCHの形式で入力してください"
    )
    .required(),
  metaOs: yup.string().oneOf(["IOS", "ANDROID", "ALL"]).required(),
  metaPosition: yup.string().oneOf(["OVERLAY", "INLINE"]).required(),
});

const admClickUrls = (adm: string) => {
  const doc = new DOMParser().parseFromString(adm, "text/html");
  const allElements = doc.querySelectorAll("*");
  return (
    Array.prototype.slice.call(allElements).filter((v) => {
      return !!(v as HTMLElement).localName.match(/^v-.*-click-through$/g);
    }) as [HTMLElement]
  ).map((v) => v.getAttribute("url"));
};

const admViewUrls = (adm: string) => {
  const doc = new DOMParser().parseFromString(adm, "text/html");
  const allElements = doc.querySelectorAll("*");
  return (
    Array.prototype.slice.call(allElements).filter((v) => {
      return !!(v as HTMLElement).localName.match(/^v-.*-tracking-timer$/g);
    }) as [HTMLElement]
  ).map((v) => v.getAttribute("url"));
};

const isChangedAdmUrl = (previous: string, current: string): boolean => {
  const isSameClickUrl =
    JSON.stringify(admClickUrls(previous)) ===
    JSON.stringify(admClickUrls(current));
  const isSameViewUrl =
    JSON.stringify(admViewUrls(previous)) ===
    JSON.stringify(admViewUrls(current));
  return !(isSameClickUrl && isSameViewUrl);
};

const ClickAndViewIsSameUrl = (adm: string): boolean => {
  const clickUrls = admClickUrls(adm);
  const viewUrls = admViewUrls(adm);
  if (clickUrls.length === 0 || viewUrls.length === 0) return false;
  return JSON.stringify(clickUrls) === JSON.stringify(viewUrls);
};

const osOptions = [
  { value: "", label: "未選択" },
  { value: "IOS", label: "IOS" },
  { value: "ANDROID", label: "ANDROID" },
  { value: "ALL", label: "ALL" },
];

const positionOptions = [
  { value: "", label: "未選択" },
  { value: "OVERLAY", label: "OVERLAY" },
  { value: "INLINE", label: "INLINE" },
];

const useStyles = makeStyles(() => ({
  clearButton: {
    padding: "0",
  },
}));

export const CreativeForm: FC<Props> = ({
  values,
  handleSubmit,
  setFieldValue,
}) => {
  const classes = useStyles();
  const [dialogOpen, openDialog, closeDialog] = useOpenState();
  const [previousAdm] = useState(values.adm || "");

  const handleDialogFormSubmit = useCallback(() => {
    if (previousAdm !== "" && isChangedAdmUrl(previousAdm, values.adm || "")) {
      return openDialog();
    }
    handleSubmit();
  }, [handleSubmit, openDialog, previousAdm, values.adm]);

  const handleClearPeriodSince = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setFieldValue("periodSince", null);
    },
    [setFieldValue]
  );

  const handleClearPeriodTill = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setFieldValue("periodTill", null);
    },
    [setFieldValue]
  );

  return (
    <DialogForm onSubmit={handleDialogFormSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikTextField
            required
            fullWidth
            name="title"
            label="タイトル"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField
            fullWidth
            required
            name="width"
            label="横幅"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField
            fullWidth
            required
            name="height"
            label="高さ"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            fullWidth
            name="iurl"
            label="OpenRTBの審査用画像"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            multiline
            required
            fullWidth
            name="adm"
            label="WebCompoent ADM"
            margin="normal"
            rows={10}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            fullWidth
            name="metaAdmVersion"
            label="クリエイティブのバージョン"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSelectField
            required
            fullWidth
            name="metaOs"
            label="OS"
            options={osOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSelectField
            required
            fullWidth
            name="metaPosition"
            label="Position"
            options={positionOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateTimeField
            required
            fullWidth
            name="periodSince"
            label="開始日"
            minDate={new Date()}
            InputProps={{
              endAdornment: (
                <IconButton
                  className={classes.clearButton}
                  onClick={handleClearPeriodSince}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateTimeField
            required
            fullWidth
            name="periodTill"
            label="終了日"
            minDate={values.periodSince ? values.periodSince : new Date()}
            InputProps={{
              endAdornment: (
                <IconButton
                  className={classes.clearButton}
                  onClick={handleClearPeriodTill}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSwitchField
            name="viewableValidationEnabled"
            label="viewableバリデーションをOFFにする"
            checked={!values.viewableValidationEnabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikChipTextField
            fullWidth
            name="tagList"
            label="タグ"
            blurBehavior="add"
            newChipKeys={["Tab"]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {ClickAndViewIsSameUrl(values.adm || "") && (
          <Typography color="primary">
            設定されているViewThrough/ClickThgouhのURLが同一ですが確認ずみですか？
          </Typography>
        )}
      </Grid>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={closeDialog}
      >
        <DialogTitle id="alert-dialog-title">
          {"ClickThrouh/ViewThrouhのURLが変更されています"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ClickThrouhまたはViewThrouhのURLが以前のものから変更されています。
            URLの変更は意図したものですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            キャンセル
          </Button>
          <Button autoFocus color="primary" onClick={() => handleSubmit()}>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </DialogForm>
  );
};
