/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ScheduleEditForm_schedule = {
    readonly id: string;
    readonly since: string | null;
    readonly till: string | null;
    readonly latestVersion: number | null;
    readonly " $refType": "ScheduleEditForm_schedule";
};
export type ScheduleEditForm_schedule$data = ScheduleEditForm_schedule;
export type ScheduleEditForm_schedule$key = {
    readonly " $data"?: ScheduleEditForm_schedule$data;
    readonly " $fragmentRefs": FragmentRefs<"ScheduleEditForm_schedule">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleEditForm_schedule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "since",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "till",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "Schedule",
  "abstractKey": null
};
(node as any).hash = 'c02d0801d13bb8ceef3a20878ef9612e';
export default node;
