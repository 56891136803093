/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignListTableCard_QueryVariables = {
    projectId: string;
    first?: number | null;
    after?: string | null;
};
export type CampaignListTableCard_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"CampaignListTableCard_project">;
    } | null;
};
export type CampaignListTableCard_Query = {
    readonly response: CampaignListTableCard_QueryResponse;
    readonly variables: CampaignListTableCard_QueryVariables;
};



/*
query CampaignListTableCard_Query(
  $projectId: ID!
  $first: Int
  $after: String
) {
  project(id: $projectId) {
    ...CampaignListTableCard_project
    id
  }
}

fragment CampaignEditForm_campaign on Campaign {
  id
  title
  viewTrackingUrl
  clickTrackingUrl
  status
  bidStrategy
  reportingOption
  goalAmount
  note
  adomain
  fee
  feeMin
  frequencyCapEnabled
  frequencyCapAmount
  frequencyCapInterval
  periodSince
  periodTill
  os
  osVersionMin
  isBanditEnabled
  latestVersion
  isAutoRefreshPlacementsEnabled
  isPacingEnabled
  isPricingExplorationEnabled
  isCarryoverEnabled
  dailyBudget {
    id
    date
    baseAmount
  }
  monthlyBudget {
    id
    date
    amount
  }
  costCpi
  categories
}

fragment CampaignListTableCard_project on Project {
  id
  campaigns(first: $first, after: $after) {
    edges {
      node {
        ...CampaignEditForm_campaign
        id
        title
        status
        bidStrategy
        reportingOption
        createdAt
        updatedAt
        adomain
        periodSince
        periodTill
        os
        osVersionMin
        latestVersion
        ads {
          totalCount
        }
        stoppedAds {
          edges {
            node {
              id
              title
            }
          }
        }
        stoppedCreatives {
          edges {
            node {
              id
              title
            }
          }
        }
        stoppedPlacements {
          edges {
            node {
              id
              adSlot {
                tagId
                id
              }
            }
          }
        }
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CampaignListTableCard_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CampaignConnection",
            "kind": "LinkedField",
            "name": "campaigns",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CampaignEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewTrackingUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clickTrackingUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bidStrategy",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reportingOption",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "goalAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "note",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "adomain",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fee",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "feeMin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "frequencyCapEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "frequencyCapAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "frequencyCapInterval",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodSince",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodTill",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "os",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "osVersionMin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBanditEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "latestVersion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isAutoRefreshPlacementsEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPacingEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPricingExplorationEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCarryoverEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DailyBudget",
                        "kind": "LinkedField",
                        "name": "dailyBudget",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "baseAmount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MonthlyBudget",
                        "kind": "LinkedField",
                        "name": "monthlyBudget",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "costCpi",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categories",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdConnection",
                        "kind": "LinkedField",
                        "name": "ads",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdConnection",
                        "kind": "LinkedField",
                        "name": "stoppedAds",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Ad",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreativeConnection",
                        "kind": "LinkedField",
                        "name": "stoppedCreatives",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreativeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Creative",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlacementConnection",
                        "kind": "LinkedField",
                        "name": "stoppedPlacements",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlacementEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Placement",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AdSlot",
                                    "kind": "LinkedField",
                                    "name": "adSlot",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "tagId",
                                        "storageKey": null
                                      },
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "CampaignListTableCard_campaigns",
            "kind": "LinkedHandle",
            "name": "campaigns"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "531f5113a93090a55c0cf0dc1b32eea1",
    "id": null,
    "metadata": {},
    "name": "CampaignListTableCard_Query",
    "operationKind": "query",
    "text": "query CampaignListTableCard_Query(\n  $projectId: ID!\n  $first: Int\n  $after: String\n) {\n  project(id: $projectId) {\n    ...CampaignListTableCard_project\n    id\n  }\n}\n\nfragment CampaignEditForm_campaign on Campaign {\n  id\n  title\n  viewTrackingUrl\n  clickTrackingUrl\n  status\n  bidStrategy\n  reportingOption\n  goalAmount\n  note\n  adomain\n  fee\n  feeMin\n  frequencyCapEnabled\n  frequencyCapAmount\n  frequencyCapInterval\n  periodSince\n  periodTill\n  os\n  osVersionMin\n  isBanditEnabled\n  latestVersion\n  isAutoRefreshPlacementsEnabled\n  isPacingEnabled\n  isPricingExplorationEnabled\n  isCarryoverEnabled\n  dailyBudget {\n    id\n    date\n    baseAmount\n  }\n  monthlyBudget {\n    id\n    date\n    amount\n  }\n  costCpi\n  categories\n}\n\nfragment CampaignListTableCard_project on Project {\n  id\n  campaigns(first: $first, after: $after) {\n    edges {\n      node {\n        ...CampaignEditForm_campaign\n        id\n        title\n        status\n        bidStrategy\n        reportingOption\n        createdAt\n        updatedAt\n        adomain\n        periodSince\n        periodTill\n        os\n        osVersionMin\n        latestVersion\n        ads {\n          totalCount\n        }\n        stoppedAds {\n          edges {\n            node {\n              id\n              title\n            }\n          }\n        }\n        stoppedCreatives {\n          edges {\n            node {\n              id\n              title\n            }\n          }\n        }\n        stoppedPlacements {\n          edges {\n            node {\n              id\n              adSlot {\n                tagId\n                id\n              }\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e19aabfc279b186b725bd1861f107a03';
export default node;
