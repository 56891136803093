/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ScheduleSetEditForm_scheduleSet = {
    readonly id: string;
    readonly title: string;
    readonly latestVersion: number | null;
    readonly " $refType": "ScheduleSetEditForm_scheduleSet";
};
export type ScheduleSetEditForm_scheduleSet$data = ScheduleSetEditForm_scheduleSet;
export type ScheduleSetEditForm_scheduleSet$key = {
    readonly " $data"?: ScheduleSetEditForm_scheduleSet$data;
    readonly " $fragmentRefs": FragmentRefs<"ScheduleSetEditForm_scheduleSet">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleSetEditForm_scheduleSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "ScheduleSet",
  "abstractKey": null
};
(node as any).hash = '102e5b24ea992a11567c2f86bcff4d83';
export default node;
