import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { ProjectEdit_Query } from "~/__relay_artifacts__/ProjectEdit_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { ProjectEditForm } from "~/containers/ProjectEditForm";

const query = graphql`
  query ProjectEdit_Query($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectEditForm_project
    }
  }
`;

export const ProjectEdit: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<ProjectEdit_Query>(query, { projectId });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  if (!props.project) return <div>not found</div>;
  return (
    <>
      <PageHeader title="プロジェクト編集" />
      <ProjectEditForm projectRef={props.project} />
    </>
  );
};
