/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdxSubmitForm_creative = {
    readonly id: string;
    readonly width: number | null;
    readonly height: number | null;
    readonly " $refType": "AdxSubmitForm_creative";
};
export type AdxSubmitForm_creative$data = AdxSubmitForm_creative;
export type AdxSubmitForm_creative$key = {
    readonly " $data"?: AdxSubmitForm_creative$data;
    readonly " $fragmentRefs": FragmentRefs<"AdxSubmitForm_creative">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdxSubmitForm_creative",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    }
  ],
  "type": "Creative",
  "abstractKey": null
};
(node as any).hash = 'b0546c24844f3cd4c82838f879ca3f16';
export default node;
