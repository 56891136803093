import { useFormErrorHandler } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import { CampaignForm, FormValues, schema } from "~/components/CampaignForm";
import { useCreateCampaignMutation } from "~/mutations/CreateCampaignMutation";
import { useUpsertDailyBudgetMutation } from "~/mutations/UpsertDailyBudgetMutation";
import { useUpsertMonthlyBudgetMutation } from "~/mutations/UpsertMonthlyBudgetMutation";

type Props = {
  onSubmitCompleted: () => void;
  connectionId: string;
};

const initialValues: FormValues = {
  title: "",
  categories: ["VIDEO_COMPUTER_GAMES"],
  viewTrackingUrl: null,
  clickTrackingUrl: null,
  fee: 0,
  feeMin: 1.2,
  adomain: "",
  status: "PENDING",
  bidStrategy: "MANUAL",
  reportingOption: "CPM",
  note: null,
  frequencyCapEnabled: false,
  periodSince: undefined,
  periodTill: null,
  goalAmount: 0,
  osVersionMin: "",
  isBanditEnabled: false,
  isAutoRefreshPlacementsEnabled: false,
  isPacingEnabled: true,
  isPricingExplorationEnabled: false,
  isCarryoverEnabled: true,
  dailyBudget: 0,
  monthlyBudget: 0,
  costCpi: null,
};

export const CampaignCreateForm: React.FC<Props> = ({
  onSubmitCompleted,
  connectionId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { projectId } = useParams<{ projectId: string }>();
  const { createCampaignMutation } = useCreateCampaignMutation();
  const { upsertDailyBudgetMutation } = useUpsertDailyBudgetMutation();
  const { upsertMonthlyBudgetMutation } = useUpsertMonthlyBudgetMutation();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      const { periodSince, dailyBudget, monthlyBudget, categories, ...rest } =
        values;
      if (!periodSince) throw new Error("assertion failed");
      try {
        const { createCampaign } = await createCampaignMutation(
          {
            projectId,
            periodSince,
            category: categories,
            ...rest,
          },
          connectionId
        );
        if (!createCampaign?.campaignEdge) throw new Error("assertion failed");

        const date = new Date();
        const isoDate = DateFns.formatISO(date);

        const { upsertDailyBudget } = await upsertDailyBudgetMutation(
          {
            campaignId: createCampaign.campaign.id,
            date: isoDate,
            baseAmount: dailyBudget,
          },
          date
        );

        if (!upsertDailyBudget?.campaign) throw new Error("assertion failed");

        const { upsertMonthlyBudget } = await upsertMonthlyBudgetMutation({
          campaignId: createCampaign.campaign.id,
          date: isoDate,
          amount: monthlyBudget,
        });

        if (!upsertMonthlyBudget?.campaign) throw new Error("assertion failed");

        enqueueSnackbar("キャンペーンを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      projectId,
      createCampaignMutation,
      upsertDailyBudgetMutation,
      upsertMonthlyBudgetMutation,
      connectionId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={CampaignForm}
    />
  );
};
