import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";

import { AdSlotNoteEditForm_placement } from "~/__relay_artifacts__/AdSlotNoteEditForm_placement.graphql";
import { PlacementList_ad } from "~/__relay_artifacts__/PlacementList_ad.graphql";
import {
  AdSlotNoteForm,
  FormValues,
  schema,
} from "~/components/AdSlotNoteForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updateAdSlotNoteMutation from "~/mutations/UpdateAdSlotNoteMutation";

type PlacementRef = NonNullable<
  NonNullable<PlacementList_ad["placements"]["edges"]>[0]
>["node"];

type Props = {
  placementId: string;
  placementRef: PlacementRef;
  onSubmitCompleted: () => void;
};

const placementFragment = graphql`
  fragment AdSlotNoteEditForm_placement on Placement {
    adSlot {
      note
    }
  }
`;

export const AdSlotNoteEditForm: React.FC<Props> = ({
  placementId,
  placementRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const { adSlot } = useFragment<AdSlotNoteEditForm_placement>(
    placementFragment,
    placementRef
  );

  const initialValues = React.useMemo(
    () => ({ note: adSlot.note }),
    [adSlot.note]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        if (!values.note) throw new Error("assertion failed");
        const { updateAdSlotNote } = await updateAdSlotNoteMutation(
          environment,
          {
            placementId,
            note: values.note,
          }
        );
        if (!updateAdSlotNote?.placement) throw new Error("assertion failed");
        enqueueSnackbar("配信先メモを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
      placementId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={AdSlotNoteForm}
    />
  );
};
