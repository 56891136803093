import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  ArchiveCreativeInput,
  ArchiveCreativeMutation,
} from "~/__relay_artifacts__/ArchiveCreativeMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation ArchiveCreativeMutation($input: ArchiveCreativeInput!) {
    archiveCreative(input: $input) {
      creative {
        id
        title
        adm
        height
        width
        archived
        latestVersion
        periodSince
        periodTill
      }
    }
  }
`;

export const useArchiveCreativeMutation = () => {
  const environment = useRelayEnvironment();

  const archiveCreativeMutation = React.useCallback(
    (input: ArchiveCreativeInput) =>
      commitMutation<ArchiveCreativeMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { archiveCreativeMutation };
};
