import {
  Icon,
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as dateFns from "date-fns";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FragmentRef, graphql } from "react-relay";
import { ConnectionConfig } from "relay-hooks";

import { ExpenseListTableCard_campaign } from "~/__relay_artifacts__/ExpenseListTableCard_campaign.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ListTableToolbar } from "~/components/ListTableToolbar";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { TableCell } from "~/components/atoms/TableCell";
import { ExpenseCreateForm } from "~/containers/ExpenseCreateForm";
import { ExpenseEditForm } from "~/containers/ExpenseEditForm";
import { noop } from "~/lib/noop";
import { usePagination } from "~/lib/relay-hooks";
import { useDeleteExpenseMutation } from "~/mutations/DeleteExpenseMutation";

type Props = {
  campaignRef: FragmentRef<ExpenseListTableCard_campaign>;
  projectId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  button: {
    whiteSpace: "nowrap",
    margin: theme.spacing(1),
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
}));

const campaignFragment = graphql`
  fragment ExpenseListTableCard_campaign on Campaign {
    id
    project {
      id
    }
    expenses(first: $first, after: $after)
      @connection(key: "ExpenseListTableCard_expenses", filters: []) {
      totalCount
      edges {
        node {
          id
          date
          rate
          ...ExpenseEditForm_expense
        }
      }
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.expenses,
  getVariables: ({ id, project }: any, { count, cursor }) => ({
    projectId: project.id,
    campaignId: id,
    first: count,
    after: cursor,
  }),
  query: graphql`
    query ExpenseListTableCard_Query(
      $projectId: ID!
      $campaignId: ID!
      $first: Int
      $after: String
    ) {
      project(id: $projectId) {
        campaign(id: $campaignId) {
          ...ExpenseListTableCard_campaign
        }
      }
    }
  `,
};

export const ExpenseListTableCard: FC<Props> = ({ campaignRef }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [perPage, setPerPage] = useState(100);
  const [campaign, { hasMore, loadMore }] =
    usePagination<ExpenseListTableCard_campaign>(campaignFragment, campaignRef);
  const { totalCount } = campaign.expenses;
  const { deleteExpenseMutation } = useDeleteExpenseMutation();

  /* eslint-disable react-hooks/exhaustive-deps */
  const currentDateTime = new Date();
  const limitDateTime = dateFns.getTime(
    new Date(
      dateFns.getYear(currentDateTime),
      dateFns.getMonth(currentDateTime),
      dateFns.getDate(currentDateTime),
      9
    )
  );
  /* eslint-enable react-hooks/exhaustive-deps  */

  const expenses = useMemo(() => {
    const edges = campaign.expenses?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      const expenseDate = dateFns.parseISO(edge?.node.date);
      return {
        ...edge?.node,
        editable:
          dateFns.isSameYear(expenseDate, currentDateTime) &&
          dateFns.isSameMonth(expenseDate, currentDateTime) &&
          (dateFns.isSameDay(expenseDate, currentDateTime) ||
            (dateFns.differenceInDays(currentDateTime, expenseDate) === 1 &&
              dateFns.getTime(currentDateTime) < limitDateTime)),
      };
    });
  }, [campaign.expenses, page, perPage, currentDateTime, limitDateTime]);

  const handleDeleteClick = useCallback(
    async (expenseId: string) => {
      try {
        const { deleteExpense } = await deleteExpenseMutation(
          {
            expenseId,
          },
          campaign.id
        );
        if (!deleteExpense?.deletedExpenseId) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("Expenseを削除しました", { variant: "success" });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [deleteExpenseMutation, enqueueSnackbar, campaign.id]
  );

  useEffect(() => {
    loadMore(connectionConfig, perPage, noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Paper className={classes.root}>
      <ListTableToolbar highlighted={false}>
        <div className={classes.title}>
          <Typography variant="subtitle1" color="inherit">
            追加費用
          </Typography>
        </div>
        <div className={classes.actions}>
          <DialogButton
            title="作成"
            className={classes.button}
            render={({ close }) => (
              <ExpenseCreateForm
                campaignId={campaign.id}
                onSubmitCompleted={close}
                selectableBeforeDate={
                  dateFns.getTime(currentDateTime) > limitDateTime
                }
              />
            )}
          >
            <Icon>add</Icon> 新規作成
          </DialogButton>
        </div>
      </ListTableToolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>追加手数料(率)</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {expenses.map((expense) => (
            <TableRow key={expense.id}>
              <TableCell>{expense.date}</TableCell>
              <TableCell>{expense.rate}</TableCell>
              <TableCell>
                <Tooltip title="編集する">
                  <DialogButton
                    title="編集"
                    disabled={!expense.editable}
                    render={({ close }) => (
                      <ExpenseEditForm
                        expenseRef={expense}
                        onSubmitCompleted={close}
                        selectableBeforeDate={false}
                      />
                    )}
                  >
                    <EditIcon />
                  </DialogButton>
                </Tooltip>
                <ConfirmButton
                  variant="outlined"
                  disabled={!expense.editable}
                  color="secondary"
                  confirmTitle="このExpenseを削除しますか？"
                  onAgree={async (changeDialog) => {
                    await handleDeleteClick(expense.id);
                    changeDialog(false);
                  }}
                >
                  削除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          hasMore() && loadMore(connectionConfig, perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};
