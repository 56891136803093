/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PlacementSettingList_QueryVariables = {
    placementSetId: string;
    first?: number | null;
    after?: string | null;
};
export type PlacementSettingList_QueryResponse = {
    readonly placementSet: {
        readonly title: string;
        readonly " $fragmentRefs": FragmentRefs<"PlacementSettingListTableCard_placementSet">;
    };
};
export type PlacementSettingList_Query = {
    readonly response: PlacementSettingList_QueryResponse;
    readonly variables: PlacementSettingList_QueryVariables;
};



/*
query PlacementSettingList_Query(
  $placementSetId: ID!
  $first: Int
  $after: String
) {
  placementSet(id: $placementSetId) {
    title
    ...PlacementSettingListTableCard_placementSet
    id
  }
}

fragment PlacementSettingListTableCard_placementSet on PlacementSet {
  id
  placementSettings(first: $first, after: $after) {
    edges {
      node {
        id
        adSlot {
          id
          name
          domain
          tagId
          bundle
          note
          width
          height
          sspProvider
          platform
          bannerFormat
          deletedAt
          adxBillingId
          targetWinRate
        }
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "placementSetId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "placementSetId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlacementSettingList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PlacementSet",
        "kind": "LinkedField",
        "name": "placementSet",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlacementSettingListTableCard_placementSet"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlacementSettingList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PlacementSet",
        "kind": "LinkedField",
        "name": "placementSet",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "PlacementSettingConnection",
            "kind": "LinkedField",
            "name": "placementSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlacementSettingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementSetting",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdSlot",
                        "kind": "LinkedField",
                        "name": "adSlot",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domain",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tagId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bundle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "note",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "width",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "height",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sspProvider",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "platform",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bannerFormat",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deletedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "adxBillingId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "targetWinRate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "PlacementSettingListTableCard_placementSettings",
            "kind": "LinkedHandle",
            "name": "placementSettings"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65b6bd1730c6908790bd4300e5a99fcd",
    "id": null,
    "metadata": {},
    "name": "PlacementSettingList_Query",
    "operationKind": "query",
    "text": "query PlacementSettingList_Query(\n  $placementSetId: ID!\n  $first: Int\n  $after: String\n) {\n  placementSet(id: $placementSetId) {\n    title\n    ...PlacementSettingListTableCard_placementSet\n    id\n  }\n}\n\nfragment PlacementSettingListTableCard_placementSet on PlacementSet {\n  id\n  placementSettings(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        adSlot {\n          id\n          name\n          domain\n          tagId\n          bundle\n          note\n          width\n          height\n          sspProvider\n          platform\n          bannerFormat\n          deletedAt\n          adxBillingId\n          targetWinRate\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '39ceeae5afd91e829d61febcfc690e47';
export default node;
