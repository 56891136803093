import { styled } from "@material-ui/styles";
import * as React from "react";

const StyledWrapper = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
  justifyContent: "center",
});

const StyledLabel = styled("div")({
  width: "100%",
  display: "inherit",
  alignItems: "inherit",
  justifyContent: "inherit",
});

export const IconText: React.FC = ({ children }) => (
  <StyledWrapper>
    <StyledLabel>{children}</StyledLabel>
  </StyledWrapper>
);
