import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { CreativeSetting_project } from "~/__relay_artifacts__/CreativeSetting_project.graphql";
import { DialogButton } from "~/components/DialogButton";
import { AdxSubmitForm } from "~/containers/AdxSubmitForm";
import { parseISO } from "~/lib/parseISO";
import { useFragment } from "~/lib/relay-hooks";

import { CreativeTrackerSelectForm } from "./CreativeTrackerSelectForm";

type Props = {
  projectRef: FragmentRef<CreativeSetting_project>;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(3),
  },
}));

const projectFragment = graphql`
  fragment CreativeSetting_project on Project {
    trackers {
      edges {
        node {
          id
          title
          associationType
        }
      }
    }
    creative(id: $creativeId) {
      id
      title
      createdAt
      adxStatus
      adxSubmittedAt
      tracker {
        id
        title
        associationType
      }
      ...AdxSubmitForm_creative
    }
  }
`;

export const CreativeSetting: FC<Props> = ({ projectRef }) => {
  const classes = useStyles();
  const project = useFragment<CreativeSetting_project>(
    projectFragment,
    projectRef
  );

  const creative = useMemo(() => {
    if (!project.creative) throw new Error("assertion error");
    return project.creative;
  }, [project.creative]);

  const trackerOptions = useMemo(() => {
    const edges = project.trackers.edges || [];
    const filteredNodes = edges
      .filter(
        (edge) =>
          edge?.node?.associationType === "both" ||
          edge?.node?.associationType === "creative"
      )
      .map((edge) => {
        if (edge?.node?.id == null || edge?.node?.title == null) {
          throw new Error("assertion error");
        }
        return {
          value: edge.node.id,
          label: edge.node.title,
        };
      });
    return [{ value: "", label: "未選択" }, ...filteredNodes];
  }, [project.trackers.edges]);

  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">基本設定</Typography>
        </Toolbar>
        <List>
          <ListItem>
            <ListItemText primary="名前" secondary={creative.title} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="作成日"
              secondary={parseISO(creative.createdAt)}
            />
          </ListItem>
        </List>
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">Adx設定</Typography>
          <DialogButton
            title="Adxへのクリエイティブ提出"
            color="inherit"
            render={({ close }) => (
              <AdxSubmitForm creativeRef={creative} onSubmitCompleted={close} />
            )}
          >
            提出する
          </DialogButton>
        </Toolbar>
        {creative.adxStatus && creative.adxSubmittedAt ? (
          <List>
            <ListItem>
              <ListItemText primary="提出状況" secondary={creative.adxStatus} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="提出日"
                secondary={parseISO(
                  creative.adxSubmittedAt,
                  "yyyy/MM/dd HH:mm:ss"
                )}
              />
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem>
              <ListItemText primary="提出状況" secondary={"未提出"} />
            </ListItem>
          </List>
        )}
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">トラッカー設定</Typography>
        </Toolbar>
        <List>
          <CreativeTrackerSelectForm
            creativeId={creative.id}
            initialTrackerId={creative.tracker?.id || ""}
            trackerOptions={trackerOptions}
          />
        </List>
      </Paper>
    </>
  );
};
