import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FormikTextField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "~/components/DialogForm";

export type FormValues = yup.Asserts<typeof schema>;

export const schema = yup.object({
  message: yup.string().required(),
});

type Props = FormikProps<FormValues>;

const StyledWrapper = styled("div")({
  minWidth: 500,
});

export const MaintenanceForm: React.FC<Props> = () => (
  <DialogForm>
    <StyledWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikTextField
            fullWidth
            required
            multiline
            rows={5}
            rowsMax={10}
            margin="normal"
            name="message"
            label="メッセージ本文"
          />
        </Grid>
      </Grid>
    </StyledWrapper>
  </DialogForm>
);
