import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteExcludeScheduleInput,
  DeleteExcludeScheduleMutation,
} from "~/__relay_artifacts__/DeleteExcludeScheduleMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteExcludeScheduleMutation($input: DeleteExcludeScheduleInput!) {
    deleteExcludeSchedule(input: $input) {
      deletedExcludeScheduleId
    }
  }
`;

export const useDeleteExcludeScheduleMutation = (campaignId: string) => {
  const environment = useRelayEnvironment();

  const deleteExcludeScheduleMutation = React.useCallback(
    (input: DeleteExcludeScheduleInput) =>
      commitMutation<DeleteExcludeScheduleMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: campaignId,
            connectionKeys: [
              { key: "ExcludeScheduleListTableCard_excludeSchedules" },
            ],
            pathToConnection: ["campaign", "excludeSchedules"],
            deletedIDFieldName: "deletedExcludeScheduleId",
          },
        ],
      }),
    [campaignId, environment]
  );

  return { deleteExcludeScheduleMutation };
};
