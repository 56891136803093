/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type InvoiceForm_QueryVariables = {
    projectId: string;
    periodSince: string;
    periodTill: string;
};
export type InvoiceForm_QueryResponse = {
    readonly project: {
        readonly invoiceLines: {
            readonly measures: ReadonlyArray<ReadonlyArray<string>>;
        };
    } | null;
};
export type InvoiceForm_Query = {
    readonly response: InvoiceForm_QueryResponse;
    readonly variables: InvoiceForm_QueryVariables;
};



/*
query InvoiceForm_Query(
  $projectId: ID!
  $periodSince: DateTime!
  $periodTill: DateTime!
) {
  project(id: $projectId) {
    invoiceLines(periodSince: $periodSince, periodTill: $periodTill) {
      measures
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodSince"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodTill"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "periodSince",
      "variableName": "periodSince"
    },
    {
      "kind": "Variable",
      "name": "periodTill",
      "variableName": "periodTill"
    }
  ],
  "concreteType": "Report",
  "kind": "LinkedField",
  "name": "invoiceLines",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measures",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "InvoiceForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32cf68459836a85bcd8d63a81bcf0cbd",
    "id": null,
    "metadata": {},
    "name": "InvoiceForm_Query",
    "operationKind": "query",
    "text": "query InvoiceForm_Query(\n  $projectId: ID!\n  $periodSince: DateTime!\n  $periodTill: DateTime!\n) {\n  project(id: $projectId) {\n    invoiceLines(periodSince: $periodSince, periodTill: $periodTill) {\n      measures\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '3a985779c13d9da951f2b692bd19ebc2';
export default node;
