/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ScheduleSetBulkForm_QueryVariables = {
    projectId: string;
    adIds: Array<string>;
};
export type ScheduleSetBulkForm_QueryResponse = {
    readonly project: {
        readonly ads: ReadonlyArray<{
            readonly id: string;
            readonly latestVersion: number | null;
            readonly scheduleSetting: {
                readonly id: string;
                readonly scheduleSet: {
                    readonly id: string;
                    readonly title: string;
                    readonly createdAt: string;
                };
            } | null;
            readonly selectableScheduleSets: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly title: string;
                    } | null;
                } | null> | null;
            } | null;
        }> | null;
    } | null;
};
export type ScheduleSetBulkForm_Query = {
    readonly response: ScheduleSetBulkForm_QueryResponse;
    readonly variables: ScheduleSetBulkForm_QueryVariables;
};



/*
query ScheduleSetBulkForm_Query(
  $projectId: ID!
  $adIds: [ID!]!
) {
  project(id: $projectId) {
    ads(ids: $adIds) {
      id
      latestVersion
      scheduleSetting {
        id
        scheduleSet {
          id
          title
          createdAt
        }
      }
      selectableScheduleSets {
        edges {
          node {
            id
            title
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "ids",
      "variableName": "adIds"
    }
  ],
  "concreteType": "Ad",
  "kind": "LinkedField",
  "name": "ads",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleSetting",
      "kind": "LinkedField",
      "name": "scheduleSetting",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ScheduleSet",
          "kind": "LinkedField",
          "name": "scheduleSet",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleSetConnection",
      "kind": "LinkedField",
      "name": "selectableScheduleSets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScheduleSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ScheduleSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleSetBulkForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScheduleSetBulkForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be4b177385383293008ff45e242531b1",
    "id": null,
    "metadata": {},
    "name": "ScheduleSetBulkForm_Query",
    "operationKind": "query",
    "text": "query ScheduleSetBulkForm_Query(\n  $projectId: ID!\n  $adIds: [ID!]!\n) {\n  project(id: $projectId) {\n    ads(ids: $adIds) {\n      id\n      latestVersion\n      scheduleSetting {\n        id\n        scheduleSet {\n          id\n          title\n          createdAt\n        }\n      }\n      selectableScheduleSets {\n        edges {\n          node {\n            id\n            title\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'db11e0dfa15de80c423ec46994229452';
export default node;
