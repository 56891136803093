import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { Report_Query } from "~/__relay_artifacts__/Report_Query.graphql";

import { CampaignReportFormContainer } from "./CampaignReportFormContainer";
import { NotifyMessages } from "./NotifyMessages";

const query = graphql`
  query Report_Query(
    $projectId: ID!
    $measures: [MeasureInput!]!
    $dimensions: [DimensionInput!]!
    $filter: FilterInput!
    $orders: [OrderInput!]
    $withArchived: Boolean
  ) {
    viewer {
      role
      ...NotifyMessages_user
    }
    project(id: $projectId) {
      ...CampaignReportFormContainer_project
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    margin: theme.spacing(3),
    color: "#FF0000",
  },
}));

export const Report: React.FC = () => {
  const classes = useStyles();
  const currentISOString = React.useMemo(() => new Date().toISOString(), []);
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<Report_Query>(query, {
    projectId,
    measures: [],
    dimensions: [],
    filter: {
      periodTill: currentISOString,
      periodSince: currentISOString,
      campaignIds: [],
    },
    orders: [],
    withArchived: true,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  if (!props.project) return <div>not found</div>;
  return (
    <>
      <NotifyMessages userRef={props.viewer} />
      <Typography component="div" variant="body2" className={classes.message}>
        <Box>AM11:00に前日のレポートが更新されます。</Box>
      </Typography>
      <CampaignReportFormContainer
        projectRef={props.project}
        userRole={props.viewer.role}
      />
    </>
  );
};
