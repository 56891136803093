import { Divider, List } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { PromotionAttachButton_Query } from "~/__relay_artifacts__/PromotionAttachButton_Query.graphql";
import { SearchDialogButton } from "~/components/SearchDialogButton";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import attachPromotionMutation from "~/mutations/AttachPromotionMutation";

import { CampaignListItem } from "./CampaignListItem";

type Props = {
  promotionId: string;
  onSubmitCompleted?: () => void;
};

const query = graphql`
  query PromotionAttachButton_Query($projectId: ID!) {
    project(id: $projectId) {
      campaigns(first: 150) {
        edges {
          node {
            id
            title
            promotion {
              id
            }
          }
        }
      }
    }
  }
`;

const StyledList = styled(List)({
  overflowX: "scroll",
});

export const PromotionAttachButton: React.FC<Props> = ({
  promotionId,
  onSubmitCompleted,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const environment = useRelayEnvironment();
  const { props } = useQuery<PromotionAttachButton_Query>(query, {
    projectId,
  });
  const { enqueueSnackbar } = useSnackbar();
  const handleAttachCampaign = React.useCallback(
    async (campaignId: string, promotionId: string) => {
      try {
        await attachPromotionMutation(environment, {
          campaignId,
          promotionId,
        });
        enqueueSnackbar("プロモーションに追加しました", { variant: "success" });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [environment, enqueueSnackbar]
  );

  const campaigns = React.useMemo(() => {
    if (!props) return [];
    if (!props.project) return [];
    const edges = props.project.campaigns.edges || [];
    return edges
      .filter((edge) => edge?.node?.promotion == null)
      .map((edge) => {
        if (!edge?.node) throw Error("assertion error");
        return edge.node;
      });
  }, [props]);

  return (
    <SearchDialogButton
      title="対象キャンペーンを追加"
      renderDialogContent={({ close }) => (
        <StyledList>
          {campaigns.length !== 0 ? (
            campaigns.map((campaign) => (
              <div key={campaign.id}>
                <CampaignListItem
                  id={campaign.id}
                  title={campaign.title}
                  onAddClick={(id) => {
                    handleAttachCampaign(id, promotionId).then(() => {
                      onSubmitCompleted?.();
                      close();
                    });
                  }}
                />
                <Divider />
              </div>
            ))
          ) : (
            <div style={{ textAlign: "center", verticalAlign: "center" }}>
              検索条件に一致する配信先が存在しません
            </div>
          )}
        </StyledList>
      )}
      onSubmit={() => {}}
    >
      追加
    </SearchDialogButton>
  );
};
