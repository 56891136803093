import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeletePromotionMutation } from "~/__relay_artifacts__/DeletePromotionMutation.graphql";

const mutation = graphql`
  mutation DeletePromotionMutation($input: DeletePromotionInput!) {
    deletePromotion(input: $input) {
      deletedPromotionId
    }
  }
`;

const commit = (
  environment: Environment,
  promotionId: string,
  projectId: string
) =>
  commitMutation<DeletePromotionMutation>(environment, {
    mutation,
    variables: {
      input: { promotionId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: projectId,
        connectionKeys: [{ key: "PromotionListTableCard_promotions" }],
        pathToConnection: ["project", "promotions"],
        deletedIDFieldName: "deletedPromotionId",
      },
    ],
  });

export default commit;
