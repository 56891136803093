import "firebase/auth";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { PowerSettingsNew as PowerSettingsNewIcon } from "@material-ui/icons";
import firebase from "firebase/app";
import * as React from "react";
import { useAsyncFn } from "react-use";

import { clearPersistedReactnCaches } from "~/globalState";

export const LogoutButton: React.FC = () => {
  const [state, logout] = useAsyncFn(async () => {
    if (!firebase.auth) throw new Error("firebase.auth is not set");
    return firebase
      .auth()
      .signOut()
      .then(() => {
        clearPersistedReactnCaches();
        window.location.replace(`/signin`);
      });
  });

  return (
    <ListItem button color="inherit" onClick={logout} disabled={state.loading}>
      <ListItemIcon>
        <PowerSettingsNewIcon />
      </ListItemIcon>
      <ListItemText primary="ログアウト" />
    </ListItem>
  );
};
