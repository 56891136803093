import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as React from "react";

type Props = {
  adSlotId: string;
  adSlotName: string;
  sspProvider: string;
  tagId: string;
  platform: string;
  bundle: string;
  adxBillingId: string;
  width: string;
  height: string;
  selected?: boolean;
  onAddClick: (adSlotId: Props["adSlotId"]) => void;
};

const StyledListItem = styled(ListItem)({
  minWidth: 700,
});

const StyledAddButton = styled(Button)({
  whiteSpace: "nowrap",
  margin: 3,
});

export const AdSlotListItem: React.FC<Props> = ({
  adSlotId,
  adSlotName,
  sspProvider,
  tagId,
  platform,
  bundle,
  adxBillingId,
  width,
  height,
  selected = false,
  onAddClick,
}) => {
  const handleButtonClick = React.useCallback(() => {
    onAddClick(adSlotId);
  }, [adSlotId, onAddClick]);

  return (
    // @ts-expect-error
    <StyledListItem key={adSlotId} selected={selected}>
      <ListItemText>
        <Typography>{adSlotName}</Typography>
        <Typography variant="body2" color="textSecondary">
          SSP PROVIDER : {sspProvider}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          TAG ID : {tagId}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          PLATFORM : {platform}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          BUNDLE : {bundle}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          SIZE(W/H) : {width}/{height}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          ADX BILLING ID : {adxBillingId}
        </Typography>
      </ListItemText>
      <Box display="flex" flexDirection="column" width={100}>
        <StyledAddButton
          variant="outlined"
          color="primary"
          disabled={selected}
          onClick={handleButtonClick}
        >
          {selected ? "選択済み" : "追加"}
        </StyledAddButton>
      </Box>
    </StyledListItem>
  );
};
