import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import {
  AdCategory,
  CampaignEditFormForAdmin_campaign,
} from "~/__relay_artifacts__/CampaignEditFormForAdmin_campaign.graphql";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateCampaignMutation } from "~/mutations/UpdateCampaignMutation";
import { useUpdateDailyBudgetMutation } from "~/mutations/UpdateDailyBudgetMutation";
import { useUpdateMonthlyBudgetMutation } from "~/mutations/UpdateMonthlyBudgetMutation";

import {
  CampaignFormForAdmin,
  FormValues,
  schema,
} from "./CampaignFormForAdmin";

type Props = {
  campaignRef: FragmentRef<CampaignEditFormForAdmin_campaign>;
  onSubmitCompleted: () => void;
};

const campaignFragment = graphql`
  fragment CampaignEditFormForAdmin_campaign on Campaign {
    id
    title
    status
    bidStrategy
    reportingOption
    goalAmount
    frequencyCapEnabled
    periodSince
    periodTill
    isBanditEnabled
    latestVersion
    dailyBudget {
      id
      date
      baseAmount
    }
    monthlyBudget {
      id
      date
      amount
    }
    categories
  }
`;

export const CampaignEditFormForAdmin: React.FC<Props> = ({
  campaignRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateCampaignMutation } = useUpdateCampaignMutation();
  const { updateDailyBudgetMutation } = useUpdateDailyBudgetMutation();
  const { updateMonthlyBudgetMutation } = useUpdateMonthlyBudgetMutation();

  const campaign = useFragment<CampaignEditFormForAdmin_campaign>(
    campaignFragment,
    campaignRef
  );

  const initialValues = {
    periodSince: campaign.periodSince,
    periodTill: campaign.periodTill,
    monthlyBudgetAmount: campaign.monthlyBudget.amount,
    dailyBudgetAmount: campaign.dailyBudget.baseAmount,
  };

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (
      { dailyBudgetAmount, monthlyBudgetAmount, ...values },
      { setErrors }
    ) => {
      try {
        const { updateCampaign } = await updateCampaignMutation({
          ...R.pick(
            [
              "id",
              "title",
              "status",
              "bidStrategy",
              "reportingOption",
              "goalAmount",
              "frequencyCapEnabled",
              "periodSince",
            ],
            campaign
          ),
          clientVersion: campaign.latestVersion,
          category: campaign.categories as AdCategory[],
          ...values,
        });
        const { updateDailyBudget } = await updateDailyBudgetMutation({
          id: campaign.dailyBudget.id,
          date: campaign.dailyBudget.date,
          baseAmount: dailyBudgetAmount,
        });
        const { updateMonthlyBudget } = await updateMonthlyBudgetMutation({
          id: campaign.monthlyBudget.id,
          date: campaign.monthlyBudget.date,
          amount: monthlyBudgetAmount,
        });
        if (
          !updateCampaign?.campaign &&
          !updateDailyBudget?.dailyBudget &&
          !updateMonthlyBudget?.monthlyBudget
        )
          throw new Error("assertion failed");
        enqueueSnackbar("キャンペーンを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      campaign,
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      updateCampaignMutation,
      updateDailyBudgetMutation,
      updateMonthlyBudgetMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <CampaignFormForAdmin />
    </Formik>
  );
};
