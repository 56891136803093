/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PlacementSetEditForm_placementSet = {
    readonly id: string;
    readonly title: string;
    readonly latestVersion: number | null;
    readonly " $refType": "PlacementSetEditForm_placementSet";
};
export type PlacementSetEditForm_placementSet$data = PlacementSetEditForm_placementSet;
export type PlacementSetEditForm_placementSet$key = {
    readonly " $data"?: PlacementSetEditForm_placementSet$data;
    readonly " $fragmentRefs": FragmentRefs<"PlacementSetEditForm_placementSet">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlacementSetEditForm_placementSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "PlacementSet",
  "abstractKey": null
};
(node as any).hash = '51446a7295f042e2dc48ec4c14611db9';
export default node;
