import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreatePromotionInput,
  CreatePromotionMutation,
} from "~/__relay_artifacts__/CreatePromotionMutation.graphql";

const mutation = graphql`
  mutation CreatePromotionMutation(
    $input: CreatePromotionInput!
    $connections: [ID!]!
  ) {
    createPromotion(input: $input) {
      promotion
        @appendNode(connections: $connections, edgeTypeName: "PromotionEdge") {
        id
        title
      }
    }
  }
`;

const commit = (
  environment: Environment,
  input: CreatePromotionInput,
  connectionId: string
) =>
  commitMutation<CreatePromotionMutation>(environment, {
    mutation,
    variables: {
      input,
      connections: [connectionId],
    },
  });

export default commit;
