import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { MaintenanceList_Query } from "~/__relay_artifacts__/MaintenanceList_Query.graphql";
import { PageHeader } from "~/components/PageHeader";

import { MaintenanceListTableCard } from "./MaintenanceListTableCard";

const query = graphql`
  query MaintenanceList_Query($first: Int, $after: String) {
    ...MaintenanceListTableCard_root
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader shouldUseHistoryBack title="メンテナンス設定" />
    {children}
  </>
);

export const MaintenanceList: React.FC = () => {
  const { props, error } = useQuery<MaintenanceList_Query>(query);
  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <MaintenanceListTableCard rootRef={props} />
    </Wrapper>
  );
};
