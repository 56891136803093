import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { AdSlotEditForm_adSlot } from "~/__relay_artifacts__/AdSlotEditForm_adSlot.graphql";
import { AdSlotForm, FormValues, schema } from "~/components/AdSlotForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updateAdSlotMutation from "~/mutations/UpdateAdSlotMutation";

type Props = {
  adSlotRef: FragmentRef<AdSlotEditForm_adSlot>;
  onSubmitCompleted: () => void;
};

const adSlotFragment = graphql`
  fragment AdSlotEditForm_adSlot on AdSlot {
    id
    name
    domain
    tagId
    bundle
    note
    width
    height
    sspProvider
    platform
    os
    adxBillingId
    targetWinRate
  }
`;

export const AdSlotEditForm: React.FC<Props> = ({
  adSlotRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const adSlot = useFragment<AdSlotEditForm_adSlot>(adSlotFragment, adSlotRef);
  const { id } = adSlot;

  const initialValues = React.useMemo(
    () => ({ ...adSlot } as FormValues),
    [adSlot]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateAdSlot } = await updateAdSlotMutation(environment, {
          id,
          ...values,
        });
        const adSlot = updateAdSlot && updateAdSlot.adSlot;
        if (!adSlot) throw new Error("assertion failed");
        enqueueSnackbar("広告枠を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [environment, id, enqueueSnackbar, onSubmitCompleted, formErrorHandler]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={AdSlotForm}
    />
  );
};
