/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateDailyBudgetInput = {
    clientMutationId?: string | null;
    baseAmount: number;
    clientVersion?: number | null;
    date: string;
    id: string;
};
export type UpdateDailyBudgetMutationVariables = {
    input: UpdateDailyBudgetInput;
};
export type UpdateDailyBudgetMutationResponse = {
    readonly updateDailyBudget: {
        readonly dailyBudget: {
            readonly id: string;
            readonly date: string;
            readonly baseAmount: number;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly latestVersion: number | null;
        } | null;
    } | null;
};
export type UpdateDailyBudgetMutation = {
    readonly response: UpdateDailyBudgetMutationResponse;
    readonly variables: UpdateDailyBudgetMutationVariables;
};



/*
mutation UpdateDailyBudgetMutation(
  $input: UpdateDailyBudgetInput!
) {
  updateDailyBudget(input: $input) {
    dailyBudget {
      id
      date
      baseAmount
      createdAt
      updatedAt
      latestVersion
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDailyBudgetPayload",
    "kind": "LinkedField",
    "name": "updateDailyBudget",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DailyBudget",
        "kind": "LinkedField",
        "name": "dailyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "baseAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latestVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDailyBudgetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDailyBudgetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fe256c5a4bdb3af2aade2f864190a659",
    "id": null,
    "metadata": {},
    "name": "UpdateDailyBudgetMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDailyBudgetMutation(\n  $input: UpdateDailyBudgetInput!\n) {\n  updateDailyBudget(input: $input) {\n    dailyBudget {\n      id\n      date\n      baseAmount\n      createdAt\n      updatedAt\n      latestVersion\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5aec0f4e202155d2e14541d1ec6a0710';
export default node;
