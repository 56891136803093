/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdOrderField = "CREATED_AT" | "GOAL_AMOUNT" | "STATUS" | "TITLE" | "%future added value";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type AdOrder = {
    direction: OrderDirection;
    field: AdOrderField;
};
export type AdListTableCard_QueryVariables = {
    projectId: string;
    campaignId: string;
    first?: number | null;
    after?: string | null;
    orderBy?: AdOrder | null;
    title?: string | null;
    tagId?: string | null;
    status?: Array<CampaignStatus> | null;
    scheduleSetId?: string | null;
};
export type AdListTableCard_QueryResponse = {
    readonly project: {
        readonly campaign: {
            readonly " $fragmentRefs": FragmentRefs<"AdListTableCard_campaign">;
        } | null;
    } | null;
};
export type AdListTableCard_Query = {
    readonly response: AdListTableCard_QueryResponse;
    readonly variables: AdListTableCard_QueryVariables;
};



/*
query AdListTableCard_Query(
  $projectId: ID!
  $campaignId: ID!
  $first: Int
  $after: String
  $orderBy: AdOrder
  $title: String
  $tagId: String
  $status: [CampaignStatus!]
  $scheduleSetId: ID
) {
  project(id: $projectId) {
    campaign(id: $campaignId) {
      ...AdListTableCard_campaign
      id
    }
    id
  }
}

fragment AdEditForm_ad on Ad {
  id
  title
  adomain
  categories
  isRotatable
  ignoreIdfa
  width
  height
  sspProvider
  platform
  latestVersion
}

fragment AdListTableCard_campaign on Campaign {
  id
  ads(first: $first, after: $after, orderBy: $orderBy, title: $title, tagId: $tagId, status: $status, scheduleSetId: $scheduleSetId) {
    totalCount
    edges {
      node {
        id
        title
        status
        createdAt
        ignoreIdfa
        width
        height
        sspProvider
        platform
        scheduleSetting {
          id
        }
        latestVersion
        scheduleSet {
          id
          title
        }
        placements {
          totalCount
        }
        ...AdEditForm_ad
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scheduleSetId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "scheduleSetId",
    "variableName": "scheduleSetId"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  },
  {
    "kind": "Variable",
    "name": "tagId",
    "variableName": "tagId"
  },
  {
    "kind": "Variable",
    "name": "title",
    "variableName": "title"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdListTableCard_campaign"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "AdConnection",
                "kind": "LinkedField",
                "name": "ads",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Ad",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ignoreIdfa",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "width",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "height",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sspProvider",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "platform",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleSetting",
                            "kind": "LinkedField",
                            "name": "scheduleSetting",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestVersion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleSet",
                            "kind": "LinkedField",
                            "name": "scheduleSet",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlacementConnection",
                            "kind": "LinkedField",
                            "name": "placements",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "adomain",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "categories",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isRotatable",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "AdListTableCard_ads",
                "kind": "LinkedHandle",
                "name": "ads"
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "568897428b849afef8c7d5a39f086768",
    "id": null,
    "metadata": {},
    "name": "AdListTableCard_Query",
    "operationKind": "query",
    "text": "query AdListTableCard_Query(\n  $projectId: ID!\n  $campaignId: ID!\n  $first: Int\n  $after: String\n  $orderBy: AdOrder\n  $title: String\n  $tagId: String\n  $status: [CampaignStatus!]\n  $scheduleSetId: ID\n) {\n  project(id: $projectId) {\n    campaign(id: $campaignId) {\n      ...AdListTableCard_campaign\n      id\n    }\n    id\n  }\n}\n\nfragment AdEditForm_ad on Ad {\n  id\n  title\n  adomain\n  categories\n  isRotatable\n  ignoreIdfa\n  width\n  height\n  sspProvider\n  platform\n  latestVersion\n}\n\nfragment AdListTableCard_campaign on Campaign {\n  id\n  ads(first: $first, after: $after, orderBy: $orderBy, title: $title, tagId: $tagId, status: $status, scheduleSetId: $scheduleSetId) {\n    totalCount\n    edges {\n      node {\n        id\n        title\n        status\n        createdAt\n        ignoreIdfa\n        width\n        height\n        sspProvider\n        platform\n        scheduleSetting {\n          id\n        }\n        latestVersion\n        scheduleSet {\n          id\n          title\n        }\n        placements {\n          totalCount\n        }\n        ...AdEditForm_ad\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '22c98c0baf33329e1c9219fcec9436f5';
export default node;
