import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { DenyTagIdList_Query } from "~/__relay_artifacts__/DenyTagIdList_Query.graphql";
import { DenyTagIdList as DenyTagIdListTableCard } from "~/components/DenyTagIdList";
import { PageHeader } from "~/components/PageHeader";

const query = graphql`
  query DenyTagIdList_Query {
    ...DenyTagIdList_root
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader title="配信禁止タグリスト" backTo="/" />
    {children}
  </>
);

export const DenyTagIdList: React.FC = () => {
  const { props, error } = useQuery<DenyTagIdList_Query>(query, {});

  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <DenyTagIdListTableCard denyTagIdRef={props} />
    </Wrapper>
  );
};
