/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateMonthlyBudgetInput = {
    clientMutationId?: string | null;
    id: string;
    date: string;
    amount: number;
    clientVersion?: number | null;
};
export type UpdateMonthlyBudgetMutationVariables = {
    input: UpdateMonthlyBudgetInput;
};
export type UpdateMonthlyBudgetMutationResponse = {
    readonly updateMonthlyBudget: {
        readonly monthlyBudget: {
            readonly id: string;
            readonly date: string;
            readonly amount: number;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly latestVersion: number | null;
        } | null;
    } | null;
};
export type UpdateMonthlyBudgetMutation = {
    readonly response: UpdateMonthlyBudgetMutationResponse;
    readonly variables: UpdateMonthlyBudgetMutationVariables;
};



/*
mutation UpdateMonthlyBudgetMutation(
  $input: UpdateMonthlyBudgetInput!
) {
  updateMonthlyBudget(input: $input) {
    monthlyBudget {
      id
      date
      amount
      createdAt
      updatedAt
      latestVersion
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMonthlyBudgetPayload",
    "kind": "LinkedField",
    "name": "updateMonthlyBudget",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MonthlyBudget",
        "kind": "LinkedField",
        "name": "monthlyBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latestVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMonthlyBudgetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMonthlyBudgetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f9fdb4c31560edc69f9ef9e77f495ea5",
    "id": null,
    "metadata": {},
    "name": "UpdateMonthlyBudgetMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMonthlyBudgetMutation(\n  $input: UpdateMonthlyBudgetInput!\n) {\n  updateMonthlyBudget(input: $input) {\n    monthlyBudget {\n      id\n      date\n      amount\n      createdAt\n      updatedAt\n      latestVersion\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a59da8c57880ece3a0baf9b30b2abe1e';
export default node;
