import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";

import { AdListTableCard_campaign } from "~/__relay_artifacts__/AdListTableCard_campaign.graphql";
import { IconText } from "~/components/atoms/IconText";

import { StatusTexts } from "./constants";

type Ads = NonNullable<AdListTableCard_campaign["ads"]>["edges"];
type Ad = NonNullable<NonNullable<Ads>[0]>["node"];
type Status = NonNullable<Ad>["status"];

type Props = {
  status: Status;
};

const useStyles = makeStyles({
  iconTextWrapper: {
    display: "block",
  },
});

const StatusIcon: React.FC<Pick<Props, "status">> = ({ status }) => {
  switch (status) {
    case "ACTIVE":
      return (
        <>
          <PlayArrowIcon fontSize="small" /> {StatusTexts[status]}
        </>
      );
    case "PENDING":
      return (
        <>
          <PauseIcon fontSize="small" /> {StatusTexts[status]}
        </>
      );
    case "STOPPED":
      return (
        <>
          <StopIcon fontSize="small" /> {StatusTexts[status]}
        </>
      );
    default:
      return <>UNKNOWN STATUS</>;
  }
};

export const AdStatusColumn: React.FC<Props> = ({ status }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconTextWrapper}>
      <IconText>
        <StatusIcon status={status} />
      </IconText>
    </div>
  );
};
