import * as React from "react";
import { useGlobal } from "reactn";

import {
  AdFilterFormValues,
  adListTableCardFilterInitialValues,
} from "~/components/AdListTableCard";
import {
  AdSlotPlacementFilterFormValues,
  adSlotPlacementFilterInitialValues,
} from "~/components/AdSlotPlacementList";
import {
  DailyBudgetByMonth,
  initialDisplayMonth,
} from "~/components/DailyBudgetListDataSheet";
import {
  PlacementAdSlotFilterFormValues,
  placementAdSlotFilterInitialValues,
} from "~/components/PlacementCreateButton";
import {
  PlacementFilterFormValues,
  placementFilterInitialValues,
} from "~/components/PlacementList";
import {
  PlacementSettingAdSlotFilterFormValues,
  placementSettingAdSlotFilterInitialValues,
} from "~/components/PlacementSettingListTableCard";

export const useAdFilterCache = (projectId: string, campaignId: string) => {
  const [caches, setCaches] = useGlobal("adFilterCache");

  const cache = React.useMemo<AdFilterFormValues>(
    // @ts-expect-error
    () => caches[projectId + campaignId] || adListTableCardFilterInitialValues,
    [caches, campaignId, projectId]
  );

  const setCache = React.useCallback(
    (newCache: AdFilterFormValues) => {
      setCaches({
        ...caches,
        [projectId + campaignId]: newCache,
      });
    },
    [caches, campaignId, projectId, setCaches]
  );

  return [cache, setCache] as const;
};

export const usePlacementAdSlotFilterCache = (
  projectId: string,
  adId: string
) => {
  const [caches, setCaches] = useGlobal("placementAdSlotFilterCache");

  const cache = React.useMemo<PlacementAdSlotFilterFormValues>(
    () => caches[projectId + adId] || placementAdSlotFilterInitialValues,
    [adId, caches, projectId]
  );

  const setCache = React.useCallback(
    (newCache: PlacementAdSlotFilterFormValues) => {
      setCaches({
        ...caches,
        [projectId + adId]: newCache,
      });
    },
    [adId, caches, projectId, setCaches]
  );

  return [cache, setCache] as const;
};

export const usePlacementFilterCache = (projectId: string, adId: string) => {
  const [caches, setCaches] = useGlobal("placementFilterCache");

  const cache = React.useMemo<PlacementFilterFormValues>(
    () => caches[projectId + adId] || placementFilterInitialValues,
    [adId, caches, projectId]
  );

  const setCache = React.useCallback(
    (newCache: PlacementFilterFormValues) => {
      setCaches({
        ...caches,
        [projectId + adId]: newCache,
      });
    },
    [adId, caches, projectId, setCaches]
  );

  return [cache, setCache] as const;
};

export const useAdSlotPlacementFilterCache = (adSlotId: string) => {
  const [caches, setCaches] = useGlobal("adSlotPlacementFilterCache");

  const cache = React.useMemo<AdSlotPlacementFilterFormValues>(
    () => caches[adSlotId] || adSlotPlacementFilterInitialValues,
    [adSlotId, caches]
  );

  const setCache = React.useCallback(
    (newCache: AdSlotPlacementFilterFormValues) => {
      setCaches({
        ...caches,
        [adSlotId]: newCache,
      });
    },
    [adSlotId, caches, setCaches]
  );

  return [cache, setCache] as const;
};

export const usePlacementSettingAdSlotFilterCache = (
  placementSetId: string
) => {
  const [caches, setCaches] = useGlobal("placementSettingAdSlotFilterCache");

  const cache = React.useMemo<PlacementSettingAdSlotFilterFormValues>(
    () => caches[placementSetId] || placementSettingAdSlotFilterInitialValues,
    [caches, placementSetId]
  );

  const setCache = React.useCallback(
    (newCache: PlacementSettingAdSlotFilterFormValues) => {
      setCaches({
        ...caches,
        [placementSetId]: newCache,
      });
    },
    [caches, placementSetId, setCaches]
  );

  return [cache, setCache] as const;
};

export const useDailyBudgetByMonthCache = (
  projectId: string,
  campaignId: string
) => {
  const [caches, setCaches] = useGlobal("dailyBudgetByMonthCache");

  const cache = React.useMemo<DailyBudgetByMonth>(
    () => caches[projectId + campaignId] || initialDisplayMonth,
    [caches, campaignId, projectId]
  );

  const setCache = React.useCallback(
    (newCache: DailyBudgetByMonth) => {
      setCaches({
        ...caches,
        [projectId + campaignId]: newCache,
      });
    },
    [caches, campaignId, projectId, setCaches]
  );

  return [cache, setCache] as const;
};
