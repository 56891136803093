import {
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useMemo, useState } from "react";
import { ConnectionConfig, FragmentRef, graphql } from "react-relay";

import { TrackerList_project } from "~/__relay_artifacts__/TrackerList_project.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { usePagination } from "~/lib/relay-hooks";

import { TrackerCreateForm, TrackerEditForm } from "./TrackerForms";

type Props = {
  projectRef: FragmentRef<TrackerList_project>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

const projectFragment = graphql`
  fragment TrackerList_project on Project {
    id
    trackers(first: $trackerFirst, after: $after)
      @connection(key: "TrackerList_trackers", filters: []) {
      totalCount
      edges {
        node {
          id
          title
          provider
          parameters
          adjustEventCallbackKey
          associationType
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.project.trackers as any,
  getVariables: (props: any, { count, cursor }) => ({
    projectId: props.project.id,
    trackerFirst: count,
    after: cursor,
  }),
  query: graphql`
    query TrackerList_Query(
      $projectId: ID!
      $trackerFirst: Int
      $after: String
    ) {
      project(id: $projectId) {
        ...TrackerList_project
      }
    }
  `,
};

export const TrackerList: FC<Props> = ({ projectRef }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(200);
  const [project, { hasMore, loadMore }] = usePagination<TrackerList_project>(
    projectFragment,
    projectRef
  );

  const trackers = useMemo(() => {
    const edges = project.trackers?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [page, perPage, project.trackers?.edges]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          トラッカー一覧
        </Typography>
        <DialogButton
          title="トラッカ一作成"
          render={({ close }) => (
            <TrackerCreateForm onSubmitCompleted={close} />
          )}
        >
          <Icon>add</Icon> 新規作成
        </DialogButton>
      </Toolbar>
      <ListTable minWidth={900}>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>タイトル</TableCell>
            <TableCell>プロバイダ</TableCell>
            <TableCell>パラメータ</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>更新日</TableCell>
            <TableCell>紐付け対象</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trackers.map((tracker) => (
            <TableRow key={tracker.id}>
              <TableCell>{atob(tracker.id)}</TableCell>
              <TableCell>{tracker.title}</TableCell>
              <TableCell>{tracker.provider}</TableCell>
              <TableCell>{JSON.stringify(tracker.parameters)}</TableCell>
              <TableCell>{parseISO(tracker.createdAt)}</TableCell>
              <TableCell>{parseISO(tracker.updatedAt)}</TableCell>
              <TableCell>{tracker.associationType}</TableCell>
              <TableCell>
                <DialogButton
                  title="トラッカー編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <TrackerEditForm
                      tracker={tracker}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={project.trackers.totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          hasMore() && loadMore(connectionConfig, perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};
