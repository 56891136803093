import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as R from "ramda";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { Link, useParams } from "react-router-dom";
import { useDebounce } from "react-use";

import { AdSlotPlacementListTableCard_adSlot } from "~/__relay_artifacts__/AdSlotPlacementListTableCard_adSlot.graphql";
import { ListTable } from "~/components/ListTable";
import {
  CorrectionTermField,
  IsEnabledSwitch,
  ProportionField,
} from "~/components/PlacementList";
import { TruncatedTextWithTooltip } from "~/components/TruncatedTextWithTooltip";
import { useAdSlotPlacementFilterCache } from "~/globalState/hooks";
import { noop } from "~/lib/noop";

import { AdSlotPlacementBulkUpdateButton } from "./AdSlotPlacementBulkUpdateButton";
import { PlacementBulkCreateButton } from "./PlacementBulkCreateButton";
import { PlacementFilterButton } from "./PlacementFilterButton";

type Props = {
  relay: RelayPaginationProp;
  adSlot: AdSlotPlacementListTableCard_adSlot;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  spacer: {
    flex: "1 1 100%",
  },
}));

const AdSlotPlacementListTableCard: React.FC<Props> = ({ relay, adSlot }) => {
  const classes = useStyles();
  const { adSlotId } = useParams<{ adSlotId: string }>();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [formValues, setFormValues] = useAdSlotPlacementFilterCache(adSlotId);
  const totalCount = adSlot.placements.totalCount;

  const placements = React.useMemo(() => {
    const edges = adSlot.placements?.edges || [];
    const nodes = edges
      .slice(page * perPage, page * perPage + perPage)
      .map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return {
          node,
          id: node.id,
          isEnabled: node.isEnabled,
          correctionTerm: node.correctionTerm,
          proportion: node.proportion,
          adSlotSspProvider: adSlot.sspProvider,
          campaignName: node.ad.campaign.title || "未設定",
          campaignId: node.ad.campaign.id,
          campaignUrl: `/projects/${node.ad.campaign.project.id}/campaigns/${node.ad.campaign.id}`,
          adId: node.ad.id,
          adName: node.ad.title || "未設定",
          adUrl: `/projects/${node.ad.campaign.project.id}/campaigns/${node.ad.campaign.id}/ads/${node.ad.id}`,
        };
      });
    return R.reverse(R.sortBy(R.prop("campaignId"), nodes));
  }, [adSlot, page, perPage]);

  const placementIds = React.useMemo(
    () => R.pluck("id", placements),
    [placements]
  );

  const usedAdIds = React.useMemo(
    () => R.uniq(placements.map((placement) => placement.adId)),
    [placements]
  );

  const refetchPlacements = React.useCallback(() => {
    relay.refetchConnection(perPage, noop, {
      isDistributedCampaigns: formValues.showIsDistributedCampaignsOnly,
    });
  }, [formValues.showIsDistributedCampaignsOnly, perPage, relay]);

  useDebounce(refetchPlacements, 500, [
    perPage,
    formValues.showIsDistributedCampaignsOnly,
  ]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          プレイスメントの一覧
        </Typography>
        <PlacementBulkCreateButton
          usedAdIds={usedAdIds}
          onSubmitCompleted={refetchPlacements}
        />
        <div className={classes.spacer} />
        <PlacementFilterButton
          formValues={formValues}
          onSubmit={setFormValues}
          handleChange={refetchPlacements}
        />
      </Toolbar>
      <ListTable minWidth={1600}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>キャンペーン名</TableCell>
            <TableCell>キャンペーンID</TableCell>
            <TableCell>広告名</TableCell>
            <TableCell>
              <AdSlotPlacementBulkUpdateButton
                adSlotId={adSlotId}
                fieldKey={"correctionTerm"}
                fieldName={"補正係数"}
                disabled={placementIds.length === 0}
                isDistributionCampaigns={
                  formValues.showIsDistributedCampaignsOnly
                }
                initialValue={0}
              />
            </TableCell>
            <TableCell>
              <AdSlotPlacementBulkUpdateButton
                adSlotId={adSlotId}
                fieldKey={"proportion"}
                fieldName={"配信割合"}
                disabled={placementIds.length === 0}
                isDistributionCampaigns={
                  formValues.showIsDistributedCampaignsOnly
                }
                initialValue={0}
              />
            </TableCell>
            <TableCell>
              <AdSlotPlacementBulkUpdateButton
                adSlotId={adSlotId}
                fieldKey={"isEnabled"}
                fieldName={"配信ステータス"}
                disabled={placementIds.length === 0}
                isDistributionCampaigns={
                  formValues.showIsDistributedCampaignsOnly
                }
                initialValue={false}
              />
            </TableCell>
            <TableCell>タグID</TableCell>
            <TableCell>プロバイダ</TableCell>
            <TableCell>プラットフォーム</TableCell>
            <TableCell>バンドル</TableCell>
            <TableCell>幅/高さ</TableCell>
            <TableCell>ADX BILLING ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placements.map((placement) => (
            <TableRow key={placement.id}>
              <TableCell>{atob(placement.id)}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip tooltip={placement.campaignName}>
                  <Link to={placement.campaignUrl}>
                    {placement.campaignName}
                  </Link>
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>{atob(placement.campaignId)}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip tooltip={placement.adName}>
                  <Link to={placement.adUrl}>{placement.adName}</Link>
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>
                <CorrectionTermField placement={placement.node} />
              </TableCell>
              <TableCell>
                <ProportionField placement={placement.node} />
              </TableCell>
              <TableCell>
                <IsEnabledSwitch placement={placement.node} />
              </TableCell>
              <TableCell>
                <TruncatedTextWithTooltip tooltip={adSlot.tagId}>
                  {adSlot.tagId}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>{adSlot.sspProvider}</TableCell>
              <TableCell>{adSlot.platform}</TableCell>
              <TableCell>{adSlot.bundle}</TableCell>
              <TableCell>
                {adSlot.width || "未設定"}/{adSlot.height || "未設定"}
              </TableCell>
              <TableCell>{adSlot.adxBillingId || "未設定"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value));
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  AdSlotPlacementListTableCard,
  {
    adSlot: graphql`
      fragment AdSlotPlacementListTableCard_adSlot on AdSlot {
        id
        name
        tagId
        sspProvider
        width
        height
        platform
        bundle
        adxBillingId
        placements(
          first: $first
          after: $after
          isDistributedCampaigns: $isDistributedCampaigns
        ) @connection(key: "AdSlotPlacementListTableCard_placements") {
          edges {
            node {
              id
              isEnabled
              correctionTerm
              proportion
              latestVersion
              ad {
                id
                title
                campaign {
                  id
                  title
                  project {
                    id
                  }
                }
              }
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.adSlot.placements as any;
    },
    getVariables(props, { count, cursor }) {
      return {
        adSlotId: props.adSlot.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query AdSlotPlacementListTableCard_Query(
        $adSlotId: ID!
        $first: Int
        $after: String
        $isDistributedCampaigns: Boolean
      ) {
        adSlot(id: $adSlotId) {
          ...AdSlotPlacementListTableCard_adSlot
        }
      }
    `,
  }
);
