import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateExcludeScheduleInput,
  CreateExcludeScheduleMutation,
} from "~/__relay_artifacts__/CreateExcludeScheduleMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreateExcludeScheduleMutation($input: CreateExcludeScheduleInput!) {
    createExcludeSchedule(input: $input) {
      excludeScheduleEdge {
        node {
          id
          name
          since
          till
        }
      }
    }
  }
`;

export const useCreateExcludeScheduleMutation = () => {
  const environment = useRelayEnvironment();

  const createExcludeScheduleMutation = React.useCallback(
    (input: CreateExcludeScheduleInput) =>
      commitMutation<CreateExcludeScheduleMutation>(environment, {
        mutation,
        variables: {
          input,
        },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: input.campaignId,
            connectionInfo: [
              {
                key: "ExcludeScheduleListTableCard_excludeSchedules",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "excludeScheduleEdge",
          },
        ],
      }),
    [environment]
  );

  return { createExcludeScheduleMutation };
};
