/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateMaintenanceInput = {
    clientMutationId?: string | null;
    id: string;
    message: string;
};
export type UpdateMaintenanceMutationVariables = {
    input: UpdateMaintenanceInput;
};
export type UpdateMaintenanceMutationResponse = {
    readonly updateMaintenance: {
        readonly maintenance: {
            readonly id: string;
            readonly message: string;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly " $fragmentRefs": FragmentRefs<"MaintenanceEditForm_maintenance">;
        } | null;
    } | null;
};
export type UpdateMaintenanceMutation = {
    readonly response: UpdateMaintenanceMutationResponse;
    readonly variables: UpdateMaintenanceMutationVariables;
};



/*
mutation UpdateMaintenanceMutation(
  $input: UpdateMaintenanceInput!
) {
  updateMaintenance(input: $input) {
    maintenance {
      id
      message
      createdAt
      updatedAt
      ...MaintenanceEditForm_maintenance
    }
  }
}

fragment MaintenanceEditForm_maintenance on Maintenance {
  id
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMaintenanceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMaintenancePayload",
        "kind": "LinkedField",
        "name": "updateMaintenance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Maintenance",
            "kind": "LinkedField",
            "name": "maintenance",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MaintenanceEditForm_maintenance"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMaintenanceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMaintenancePayload",
        "kind": "LinkedField",
        "name": "updateMaintenance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Maintenance",
            "kind": "LinkedField",
            "name": "maintenance",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b25c75e0b45c44514fa726df28cd5ddf",
    "id": null,
    "metadata": {},
    "name": "UpdateMaintenanceMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMaintenanceMutation(\n  $input: UpdateMaintenanceInput!\n) {\n  updateMaintenance(input: $input) {\n    maintenance {\n      id\n      message\n      createdAt\n      updatedAt\n      ...MaintenanceEditForm_maintenance\n    }\n  }\n}\n\nfragment MaintenanceEditForm_maintenance on Maintenance {\n  id\n  message\n}\n"
  }
};
})();
(node as any).hash = '197914e4cd49e811f254e3946e62282b';
export default node;
