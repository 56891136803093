import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import {
  FormValues,
  PlacementForm,
  validationSchema,
} from "~/components/PlacementForm";
import { useCreatePlacementMutation } from "~/mutations/CreatePlacementMutation";

type Props = {
  adSlotId: string;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  correctionTerm: 1,
  proportion: 1,
};

export const PlacementCreateForm: React.FC<Props> = ({
  adSlotId,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { id } = useParams<{ id: string }>();
  const { createPlacementMutation } = useCreatePlacementMutation();

  const handleSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        await createPlacementMutation({
          adId: id,
          adSlotId: adSlotId,
          correctionTerm: values.correctionTerm,
          proportion: values.proportion,
        });
        enqueueSnackbar("配信候補を追加しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      adSlotId,
      createPlacementMutation,
      enqueueSnackbar,
      formErrorHandler,
      id,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <PlacementForm />
    </Formik>
  );
};
