import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdatePricingInput,
  UpdatePricingMutation,
} from "~/__relay_artifacts__/UpdatePricingMutation.graphql";

const mutation = graphql`
  mutation UpdatePricingMutation($input: UpdatePricingInput!) {
    updatePricing(input: $input) {
      adSlot {
        id
        pricing {
          id
          logicType
          manualBidPrice
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdatePricingInput) =>
  commitMutation<UpdatePricingMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
