import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateScheduleInput,
  CreateScheduleMutation,
} from "~/__relay_artifacts__/CreateScheduleMutation.graphql";

const mutation = graphql`
  mutation CreateScheduleMutation($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      scheduleEdge {
        node {
          id
          since
          till
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateScheduleInput) =>
  commitMutation<CreateScheduleMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.scheduleSetId,
        connectionInfo: [
          {
            key: "ScheduleList_schedules",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "scheduleEdge",
      },
    ],
  });

export default commit;
