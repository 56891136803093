/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type PlacementBulkCreateButton_QueryVariables = {
    id: string;
    os?: Os | null;
    width?: number | null;
    height?: number | null;
    first?: number | null;
    after?: string | null;
};
export type PlacementBulkCreateButton_QueryResponse = {
    readonly adSlot: {
        readonly id: string;
        readonly sspProvider: AdSlotSspProvider;
    };
    readonly " $fragmentRefs": FragmentRefs<"CampaignAdList_root">;
};
export type PlacementBulkCreateButton_Query = {
    readonly response: PlacementBulkCreateButton_QueryResponse;
    readonly variables: PlacementBulkCreateButton_QueryVariables;
};



/*
query PlacementBulkCreateButton_Query(
  $id: ID!
  $os: Os
  $width: Int
  $height: Int
  $first: Int
  $after: String
) {
  adSlot(id: $id) {
    id
    sspProvider
  }
  ...CampaignAdList_root
}

fragment CampaignAdList_root on Query {
  selectableAds(first: $first, after: $after, os: $os, width: $width, height: $height) {
    edges {
      node {
        id
        title
        createdAt
        updatedAt
        status
        campaign {
          id
          title
        }
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "height"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "os"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "width"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "AdSlot",
  "kind": "LinkedField",
  "name": "adSlot",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sspProvider",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "height",
    "variableName": "height"
  },
  {
    "kind": "Variable",
    "name": "os",
    "variableName": "os"
  },
  {
    "kind": "Variable",
    "name": "width",
    "variableName": "width"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlacementBulkCreateButton_Query",
    "selections": [
      (v7/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CampaignAdList_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlacementBulkCreateButton_Query",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "AdConnection",
        "kind": "LinkedField",
        "name": "selectableAds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Ad",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CampaignAdList_selectableAds",
        "kind": "LinkedHandle",
        "name": "selectableAds"
      }
    ]
  },
  "params": {
    "cacheID": "516cf058013394452c4d9c76665a005f",
    "id": null,
    "metadata": {},
    "name": "PlacementBulkCreateButton_Query",
    "operationKind": "query",
    "text": "query PlacementBulkCreateButton_Query(\n  $id: ID!\n  $os: Os\n  $width: Int\n  $height: Int\n  $first: Int\n  $after: String\n) {\n  adSlot(id: $id) {\n    id\n    sspProvider\n  }\n  ...CampaignAdList_root\n}\n\nfragment CampaignAdList_root on Query {\n  selectableAds(first: $first, after: $after, os: $os, width: $width, height: $height) {\n    edges {\n      node {\n        id\n        title\n        createdAt\n        updatedAt\n        status\n        campaign {\n          id\n          title\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1f35355c529f352ef10bdae712230940';
export default node;
