import * as React from "react";
import { graphql } from "react-relay";
import { Redirect, useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { ProjectRoot_Query } from "~/__relay_artifacts__/ProjectRoot_Query.graphql";

const query = graphql`
  query ProjectRoot_Query($projectId: ID!) {
    project(id: $projectId) {
      id
    }
  }
`;

const useProject = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<ProjectRoot_Query>(query, { projectId });

  return {
    isLoading: !props,
    project: props && props.project,
    error: error,
  };
};

export const ProjectRoot: React.FC = ({ children }) => {
  const { isLoading, project, error } = useProject();
  if (error) throw new Error(error.message);
  if (isLoading) return <>Loading...</>;
  if (!project) return <Redirect to="/" />;
  return <>{children}</>;
};
