import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { useParams } from "react-router-dom";
import { useDebounce } from "react-use";

import { AdCreativeList_Query } from "~/__relay_artifacts__/AdCreativeList_Query.graphql";
import { AdCreativeList_ad } from "~/__relay_artifacts__/AdCreativeList_ad.graphql";
import { CreativeAllocationForm } from "~/components/CreativeAllocationForm";
import { DialogButton } from "~/components/DialogButton";
import { useRefetch, useRelayEnvironment } from "~/lib/relay-hooks";
import createAdCreativeMutation from "~/mutations/CreateAdCreativeMutation";
import deleteAdCreativeMutation from "~/mutations/DeleteAdCreativeMutation";

type Props = {
  adRef: FragmentRef<AdCreativeList_ad>;
};

const adFragment = graphql`
  fragment AdCreativeList_ad on Ad {
    id
    creatives(first: 100)
      @connection(key: "CreateAdCreativeMutation_creatives") {
      edges {
        node {
          id
          title
          adm
          height
          width
          createdAt
        }
      }
    }
    selectableCreatives(first: 100, search: $search)
      @connection(key: "DeleteAdCreativeMutation_selectableCreatives") {
      edges {
        node {
          id
          title
          adm
          height
          width
          createdAt
        }
      }
    }
  }
`;

const query = graphql`
  query AdCreativeList_Query($projectId: ID!, $adId: ID!, $search: String) {
    project(id: $projectId) {
      ad(id: $adId) {
        ...AdCreativeList_ad
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

type ValidationError = {
  message: string;
  path: string;
};

const useSelectableCreatives = (adRef: Props["adRef"], projectId: string) => {
  const [ad, refetch] = useRefetch<AdCreativeList_ad>(adFragment, adRef);

  const refetchSelectableCreatives = React.useCallback(
    (search: string) => {
      const variables = {
        projectId,
        adId: ad.id,
        search,
      };
      refetch<AdCreativeList_Query>(query, variables);
    },
    [projectId, ad.id, refetch]
  );

  return { ad, refetchSelectableCreatives };
};

const sortByCreatedAt = R.sort(R.descend(R.prop("createdAt")));

export const AdCreativeList: React.FC<Props> = ({ adRef }) => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [searchText, setSearchText] = React.useState("");
  const { ad, refetchSelectableCreatives } = useSelectableCreatives(
    adRef,
    projectId
  );

  const selectedCreatives = React.useMemo(() => {
    const edges = ad.creatives?.edges || [];
    const creatives = edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
    return sortByCreatedAt(creatives) as typeof creatives;
  }, [ad.creatives]);

  const selectableCreatives = React.useMemo(() => {
    const edges = ad.selectableCreatives?.edges || [];
    const creatives = edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
    return sortByCreatedAt(creatives) as typeof creatives;
  }, [ad.selectableCreatives]);

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setSearchText(e.target.value);
  }, []);

  const handleCreativeAdd = React.useCallback(
    async (creativeId: string) => {
      try {
        await createAdCreativeMutation(environment, creativeId, ad.id);
        enqueueSnackbar("配信クリエイティブを追加しました", {
          variant: "success",
        });
      } catch (err) {
        const errors = err.extensions.validationErrors as ValidationError[];
        errors.forEach((e) =>
          enqueueSnackbar(`${err.extensions.code}: ${e.message}`, {
            variant: "error",
          })
        );
      }
    },
    [ad.id, enqueueSnackbar, environment]
  );

  const handleCreativeDelete = React.useCallback(
    async (creativeId: string) => {
      try {
        await deleteAdCreativeMutation({
          environment,
          adId: ad.id,
          creativeId,
          options: {
            parentId: ad.id,
            connectionName: "CreateAdCreativeMutation_creatives",
          },
        });
        enqueueSnackbar("配信クリエイティブを削除しました", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [ad.id, enqueueSnackbar, environment]
  );

  useDebounce(() => refetchSelectableCreatives(searchText), 500, [searchText]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          選択対象のクリエイティブ
        </Typography>
        <DialogButton
          fullScreen
          title="クリエイティブを追加"
          minWidth={600}
          enableAlert={false}
          render={() => (
            <CreativeAllocationForm
              textFieldValue={searchText}
              selectedCreatives={selectedCreatives}
              selectableCreatives={selectableCreatives}
              onCreativeAdd={handleCreativeAdd}
              onCreativeDelete={handleCreativeDelete}
              onTextFieldChange={handleTextFieldChange}
            />
          )}
        >
          編集
        </DialogButton>
      </Toolbar>
      <List>
        {selectedCreatives.map(({ id, title }) => (
          <ListItem key={id}>
            <Chip
              variant="outlined"
              size="small"
              avatar={<Avatar>ID</Avatar>}
              label={atob(id)}
              className={classes.chip}
            />
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
