import { Grid } from "@material-ui/core";
import { FormikDateTimeField, FormikTextField } from "@vrize/vrizead-use";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "~/components/DialogForm";

export type FormValues = {
  name: string;
  since: string;
  till: string;
};

export const schema = yup.object({
  name: yup.string().required(),
  since: yup.string().required(),
  till: yup.string().required(),
});

export const ExcludeScheduleForm: React.FC = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField name="name" label="名前" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormikDateTimeField name="since" label="開始時間" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormikDateTimeField name="till" label="終了時間" fullWidth />
      </Grid>
    </Grid>
  </DialogForm>
);
