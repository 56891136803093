/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateExcludeScheduleInput = {
    clientMutationId?: string | null;
    clientVersion?: number | null;
    id: string;
    name: string;
    since: string;
    till: string;
};
export type UpdateExcludeScheduleMutationVariables = {
    input: UpdateExcludeScheduleInput;
};
export type UpdateExcludeScheduleMutationResponse = {
    readonly updateExcludeSchedule: {
        readonly excludeSchedule: {
            readonly id: string;
            readonly name: string;
            readonly since: string;
            readonly till: string;
            readonly latestVersion: number | null;
        } | null;
    } | null;
};
export type UpdateExcludeScheduleMutation = {
    readonly response: UpdateExcludeScheduleMutationResponse;
    readonly variables: UpdateExcludeScheduleMutationVariables;
};



/*
mutation UpdateExcludeScheduleMutation(
  $input: UpdateExcludeScheduleInput!
) {
  updateExcludeSchedule(input: $input) {
    excludeSchedule {
      id
      name
      since
      till
      latestVersion
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateExcludeSchedulePayload",
    "kind": "LinkedField",
    "name": "updateExcludeSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExcludeSchedule",
        "kind": "LinkedField",
        "name": "excludeSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "since",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "till",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latestVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateExcludeScheduleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateExcludeScheduleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01481e62384ed481ab3b0060f7839797",
    "id": null,
    "metadata": {},
    "name": "UpdateExcludeScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateExcludeScheduleMutation(\n  $input: UpdateExcludeScheduleInput!\n) {\n  updateExcludeSchedule(input: $input) {\n    excludeSchedule {\n      id\n      name\n      since\n      till\n      latestVersion\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9d802aabda2581756ef34f506bd95d9e';
export default node;
