import {
  Link,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useMemo, useState } from "react";
import { graphql } from "react-relay";

import { InvoiceListTableCard_project } from "~/__relay_artifacts__/InvoiceListTableCard_project.graphql";
import { InvoiceList_Query } from "~/__relay_artifacts__/InvoiceList_Query.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { Period } from "~/components/Period";
import { InvoiceCreateForm } from "~/containers/InvoiceCreateForm";
import { InvoiceEditForm } from "~/containers/InvoiceEditForm";
import { parseISO } from "~/lib/parseISO";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  projectRef: InvoiceList_Query["response"]["project"];
};

const fragment = graphql`
  fragment InvoiceListTableCard_project on Project {
    invoices(first: 100) @connection(key: "InvoiceListTableCard_invoices") {
      edges {
        node {
          id
          freeeInvoiceId
          title
          periodSince
          periodTill
          invoiceNumber
          billingDate
          dueDate
          ...InvoiceEditForm_invoice
        }
      }
      totalCount
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 200,
  },
}));

const freeeInvoiceUrl = "https://secure.freee.co.jp/invoice/reports/invoices";
const freeeInvoicePDFUrl =
  "https://invoice.secure.freee.co.jp/api/p/reports/invoices";

export const InvoiceListTableCard: FC<Props> = ({ projectRef }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const project = useFragment<InvoiceListTableCard_project>(
    fragment,
    projectRef
  );
  const totalCount = project.invoices.totalCount;

  const invoices = useMemo(() => {
    const edges = project.invoices.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [project.invoices, page, perPage]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          請求書一覧
        </Typography>
        <DialogButton
          title="請求書作成"
          render={({ close }) => (
            <InvoiceCreateForm onSubmitCompleted={close} />
          )}
        >
          作成
        </DialogButton>
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>PDF</TableCell>
            <TableCell>期間</TableCell>
            <TableCell>請求番号</TableCell>
            <TableCell>請求日</TableCell>
            <TableCell>お支払い期限</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id}>
              <TableCell>{atob(invoice.id)}</TableCell>
              <TableCell>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.location.href = `${freeeInvoiceUrl}/${invoice.freeeInvoiceId}`;
                  }}
                >
                  {invoice.title}
                </Link>
              </TableCell>
              <TableCell>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    const pdfUrl = `${freeeInvoicePDFUrl}/${invoice.freeeInvoiceId}/pdf`;
                    window.open(pdfUrl, "_blank");
                  }}
                >
                  PDF
                </Link>
              </TableCell>
              <TableCell>
                <Period since={invoice.periodSince} till={invoice.periodTill} />
              </TableCell>
              <TableCell>
                {invoice.invoiceNumber
                  ? String(invoice.invoiceNumber)
                  : "未設定"}
              </TableCell>
              <TableCell>
                {invoice.billingDate ? parseISO(invoice.billingDate) : "未設定"}
              </TableCell>
              <TableCell>
                {invoice.dueDate ? parseISO(invoice.dueDate) : "未設定"}
              </TableCell>
              <TableCell>
                <DialogButton
                  title="請求書編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <InvoiceEditForm
                      invoiceRef={invoice}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(event) => setPerPage(Number(event.target.value))}
      />
    </Paper>
  );
};
