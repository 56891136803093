import {
  Button,
  Checkbox,
  Icon,
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  FileCopyOutlined as CopyIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";

import {
  CampaignListTableCard_project,
  CampaignStatus,
} from "~/__relay_artifacts__/CampaignListTableCard_project.graphql";
import { CampaignStatusColumn } from "~/components/CampaignStatusColumn";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ListTableToolbar } from "~/components/ListTableToolbar";
import { Period } from "~/components/Period";
import { UpdatedAtText } from "~/components/UpdatedAtText";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { TableCell } from "~/components/atoms/TableCell";
import { CampaignCreateForm } from "~/containers/CampaignCreateForm";
import { CampaignEditForm } from "~/containers/CampaignEditForm";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { delayChunkPromise } from "~/lib/utils";
import copyCampaignMutation from "~/mutations/CopyCampaignMutation";
import { useUpdateCampaignStatusMutation } from "~/mutations/UpdateCampaignStatusMutation";

import { CampaignTitle } from "./CampaignTitle";
import { ProjectSelectFormContainerForCopyCamapign } from "./ProjectSelectFormContainerForCopyCampaign";
import { StatusTexts } from "./constants";

type Props = {
  relay: RelayPaginationProp;
  project: CampaignListTableCard_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  loadButton: {
    marginTop: 5,
    marginBottom: 5,
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const CampaignListTableCard: FC<Props> = ({ relay, project }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(200);
  const { updateCampaignStatusMutation } = useUpdateCampaignStatusMutation();
  const totalCount = project.campaigns.totalCount;

  const campaigns = useMemo(() => {
    const edges = project.campaigns?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [page, perPage, project.campaigns]);

  const selectedCampaigns = useMemo(
    () => campaigns.filter((campaign) => selectedIds.includes(campaign.id)),
    [campaigns, selectedIds]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedIds(
      selectedIds.length === campaigns.length
        ? []
        : campaigns.map((campaign) => campaign.id)
    );
  }, [campaigns, selectedIds.length]);

  const handleSelect = useCallback(
    (id: string) => {
      const next = selectedIds.includes(id)
        ? selectedIds.filter((s) => s !== id)
        : [...new Set([...selectedIds, id])];
      setSelectedIds(next);
    },
    [selectedIds]
  );

  const handleUpdateStatus = useCallback(
    async (status: CampaignStatus) => {
      try {
        const mutations = selectedCampaigns.map((campaign) =>
          updateCampaignStatusMutation({
            id: campaign.id,
            status: status,
            clientVersion: campaign.latestVersion,
          })
        );
        await delayChunkPromise(mutations);
        enqueueSnackbar(`ステータスを${StatusTexts[status]}に更新しました。`, {
          variant: "success",
        });
      } catch (_) {
        enqueueSnackbar(`ステータスの更新に失敗しました。`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, selectedCampaigns, updateCampaignStatusMutation]
  );

  const handleCopy = useCallback(
    async (campaignId: string) => {
      try {
        await copyCampaignMutation(relay.environment, project.id, campaignId);
        enqueueSnackbar(`キャンペーンをコピーしました。`, {
          variant: "success",
        });
      } catch (_) {
        enqueueSnackbar(`キャンペーンのコピーに失敗しました。`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, project.id, relay.environment]
  );

  const connectionId = project.campaigns.__id;

  return (
    <Paper className={classes.root}>
      <ListTableToolbar highlighted={selectedIds.length > 0}>
        {selectedIds.length > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {selectedIds.length} 個選択
          </Typography>
        ) : (
          <Typography variant="subtitle1" color="inherit">
            キャンペーン一覧
          </Typography>
        )}
        <DialogButton
          title="キャンペーン作成"
          render={({ close }) => (
            <CampaignCreateForm
              onSubmitCompleted={close}
              connectionId={connectionId}
            />
          )}
        >
          <Icon className={classes.leftIcon}>add</Icon>
          <span>作成する</span>
        </DialogButton>
        {selectedIds.length > 0 && (
          <div className={classes.actions}>
            <Tooltip title="配信を保留にする">
              <Button onClick={() => handleUpdateStatus("PENDING")}>
                <PauseIcon />
              </Button>
            </Tooltip>
            <Tooltip title="配信を開始する">
              <Button onClick={() => handleUpdateStatus("ACTIVE")}>
                <PlayArrowIcon />
              </Button>
            </Tooltip>
            <Tooltip title="配信を停止する">
              <Button onClick={() => handleUpdateStatus("STOPPED")}>
                <StopIcon />
              </Button>
            </Tooltip>
          </div>
        )}
      </ListTableToolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={
                  campaigns.length > 0 &&
                  campaigns.length === selectedIds.length
                }
                onClick={handleSelectAll}
              />
            </TableCell>
            <TableCell>ID</TableCell>
            <TableCell>タイトル</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>配信プラットフォーム</TableCell>
            <TableCell>OSの最低バージョン</TableCell>
            <TableCell>配信期間</TableCell>
            <TableCell>入札戦略</TableCell>
            <TableCell>レポートオプション</TableCell>
            <TableCell>広告</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>更新日</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow
              selected={selectedIds.includes(campaign.id)}
              key={campaign.id}
              hover
              onClick={() => handleSelect(campaign.id)}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={selectedIds.includes(campaign.id)} />
              </TableCell>
              <TableCell>{atob(campaign.id)}</TableCell>
              <TableCell>
                <CampaignTitle campaign={campaign} />
              </TableCell>
              <TableCell>
                <CampaignStatusColumn
                  status={campaign.status}
                  periodSince={campaign.periodSince}
                  periodTill={campaign.periodTill}
                />
              </TableCell>
              <TableCell>{campaign.os || "未設定"}</TableCell>
              <TableCell>{campaign.osVersionMin || "未設定"}</TableCell>
              <TableCell>
                <Period
                  since={campaign.periodSince}
                  till={campaign.periodTill}
                />
              </TableCell>
              <TableCell>{campaign.bidStrategy}</TableCell>
              <TableCell>{campaign.reportingOption}</TableCell>
              <TableCell>{campaign.ads.totalCount}件</TableCell>
              <TableCell>{parseISO(campaign.createdAt)}</TableCell>
              <TableCell>
                <UpdatedAtText updatedAtISOString={campaign.updatedAt} />
              </TableCell>
              <TableCell>
                <ConfirmButton
                  key="copy"
                  color="inherit"
                  confirmTitle="コピーしますか？"
                  onAgree={(changeDialog) => {
                    handleCopy(campaign.id);
                    changeDialog(false);
                  }}
                >
                  <Tooltip title="コピーする">
                    <CopyIcon />
                  </Tooltip>
                </ConfirmButton>
                <DialogButton
                  title="キャンペーン編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <CampaignEditForm
                      campaignRef={campaign}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <ProjectSelectFormContainerForCopyCamapign
                  campaign={campaign}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100, 200]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, noop);
          setPage(page);
          setSelectedIds([]);
        }}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value));
          setSelectedIds([]);
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  CampaignListTableCard,
  {
    project: graphql`
      fragment CampaignListTableCard_project on Project {
        id
        campaigns(first: $first, after: $after)
          @connection(key: "CampaignListTableCard_campaigns", filters: []) {
          __id
          edges {
            node {
              ...CampaignEditForm_campaign
              id
              title
              status
              bidStrategy
              reportingOption
              createdAt
              updatedAt
              adomain
              periodSince
              periodTill
              os
              osVersionMin
              latestVersion
              ads {
                totalCount
              }
              stoppedAds {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
              stoppedCreatives {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
              stoppedPlacements {
                edges {
                  node {
                    id
                    adSlot {
                      tagId
                    }
                  }
                }
              }
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps: (props) => props.project.campaigns as any,
    getVariables: (props, { count, cursor }) => ({
      projectId: props.project.id,
      first: count,
      after: cursor,
    }),
    query: graphql`
      query CampaignListTableCard_Query(
        $projectId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          ...CampaignListTableCard_project
        }
      }
    `,
  }
);
