import { Card, CardContent, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";

type Props = {
  measures: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
}));

const measureLabel = (field: string) => {
  switch (field) {
    case "WIN_COUNT":
    case "MEDIA_WIN_COUNT":
      return "IMP";
    case "CLICK_COUNT":
      return "CLICK";
    case "TOTAL_INSTALL_COUNT":
      return "INSTALL";
    case "GROSS_BUDGET_CONSUMPTION":
      return "COST";
    case "NET_BUDGET_CONSUMPTION":
      return "収益";
    case "NET_CPC":
    case "GROSS_CPC":
      return "CPC";
    case "NET_CPI":
    case "GROSS_CPI":
      return "CPI";
    default:
      return field;
  }
};

const sumMeasure = (measures: any) => {
  return measures
    .map((obj: any) => {
      const result: Record<string, any> = {};

      Object.keys(obj).forEach((key: any) => {
        switch (key) {
          case "WIN_COUNT":
          case "MEDIA_WIN_COUNT":
          case "CLICK_COUNT":
          case "TOTAL_INSTALL_COUNT":
          case "CVR":
          case "CTR":
          case "NET_CPC":
          case "NET_CPI":
          case "GROSS_CPC":
          case "GROSS_CPI":
          case "NET_BUDGET_CONSUMPTION":
          case "GROSS_BUDGET_CONSUMPTION":
            result[key] = Number(obj[key]);
            break;
          default:
            result[key] = 0;
        }
      });
      return result;
    })
    .reduce((sum: any, obj: any) => {
      const result: Record<string, any> = {};
      Object.keys(obj).forEach((key) => {
        result[key] = (sum[key] || 0) + obj[key];
      });
      return result;
    }, {});
};

export const ReportAggregate: React.FC<Props> = ({ measures }) => {
  const classes = useStyles();
  const measure = sumMeasure(measures);
  return (
    <Grid container className={classes.root} spacing={2}>
      {Object.keys(measure).map((key) => {
        let value = measure[key];
        switch (key) {
          case "WIN_COUNT":
          case "MEDIA_WIN_COUNT":
          case "CLICK_COUNT":
          case "TOTAL_INSTALL_COUNT":
            value = parseInt(value || 0, 10).toLocaleString();
            break;
          case "CTR":
            value =
              ((100 * measure.CLICK_COUNT) / measure.WIN_COUNT).toFixed(2) +
              "%";
            break;
          case "CVR":
            value =
              (
                (100 * measure.TOTAL_INSTALL_COUNT) /
                measure.CLICK_COUNT
              ).toFixed(2) + "%";
            break;
          case "NET_CPC":
          case "NET_CPI":
            value =
              "¥ " +
              (measure.NET_BUDGET_CONSUMPTION / measure.TOTAL_INSTALL_COUNT)
                .toFixed()
                .toLocaleString();
            break;
          case "GROSS_CPC":
          case "GROSS_CPI":
            value =
              "¥ " +
              (measure.GROSS_BUDGET_CONSUMPTION / measure.TOTAL_INSTALL_COUNT)
                .toFixed()
                .toLocaleString();
            break;
          case "NET_BUDGET_CONSUMPTION":
          case "GROSS_BUDGET_CONSUMPTION":
            value = "¥ " + parseInt(value || 0, 10).toLocaleString();
            break;
          default:
            return <React.Fragment key={key} />;
        }

        return (
          <Grid key={key} item xs={6} md={2}>
            <Card>
              <CardContent className={classes.card}>
                <Typography component="p">{measureLabel(key)}</Typography>
                <Typography variant="h6">{value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
