/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdCategory = "COMIC_BOOKS" | "DRAWING_SKETCHING" | "VIDEO_COMPUTER_GAMES" | "%future added value";
export type BidStrategy = "MANUAL" | "ML" | "%future added value";
export type CampaignReportingOption = "CPI" | "CPM" | "SIMEJI" | "%future added value";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type FrequencyCapInterval = "DAY" | "FIFTEEN_MINUTES" | "FIVE_MINUTES" | "HOUR" | "MINUTE" | "MONTH" | "NONE" | "SIX_HOURS" | "TWELVE_HOURS" | "TWO_HOURS" | "WEEK" | "YEAR" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type CreateCampaignInput = {
    clientMutationId?: string | null;
    frequencyCapAmount?: number | null;
    frequencyCapInterval?: FrequencyCapInterval | null;
    frequencyCapEnabled: boolean;
    projectId: string;
    title: string;
    viewTrackingUrl?: string | null;
    clickTrackingUrl?: string | null;
    periodSince: string;
    periodTill?: string | null;
    status?: CampaignStatus | null;
    adomain?: string | null;
    fee?: number | null;
    feeMin: number;
    goalAmount: number;
    bidStrategy: BidStrategy;
    reportingOption: CampaignReportingOption;
    note?: string | null;
    os?: Os | null;
    osVersionMin?: string | null;
    isBanditEnabled?: boolean | null;
    isAutoRefreshPlacementsEnabled?: boolean | null;
    isPacingEnabled?: boolean | null;
    isPricingExplorationEnabled?: boolean | null;
    isCarryoverEnabled?: boolean | null;
    costCpi?: number | null;
    category?: Array<AdCategory> | null;
};
export type CreateCampaignMutationVariables = {
    connections: Array<string>;
    input: CreateCampaignInput;
};
export type CreateCampaignMutationResponse = {
    readonly createCampaign: {
        readonly campaignEdge: {
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly status: CampaignStatus;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly adomain: string | null;
                readonly ads: {
                    readonly totalCount: number;
                };
                readonly os: Os | null;
                readonly osVersionMin: string | null;
                readonly stoppedAds: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    } | null> | null;
                };
                readonly stoppedCreatives: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly title: string;
                        } | null;
                    } | null> | null;
                };
                readonly stoppedPlacements: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly adSlot: {
                                readonly tagId: string | null;
                            };
                        } | null;
                    } | null> | null;
                };
                readonly categories: ReadonlyArray<AdCategory>;
                readonly " $fragmentRefs": FragmentRefs<"CampaignEditForm_campaign">;
            } | null;
        } | null;
        readonly campaign: {
            readonly id: string;
        };
    } | null;
};
export type CreateCampaignMutation = {
    readonly response: CreateCampaignMutationResponse;
    readonly variables: CreateCampaignMutationVariables;
};



/*
mutation CreateCampaignMutation(
  $input: CreateCampaignInput!
) {
  createCampaign(input: $input) {
    campaignEdge {
      node {
        id
        title
        status
        createdAt
        updatedAt
        adomain
        ads {
          totalCount
        }
        os
        osVersionMin
        stoppedAds {
          edges {
            node {
              id
              title
            }
          }
        }
        stoppedCreatives {
          edges {
            node {
              id
              title
            }
          }
        }
        stoppedPlacements {
          edges {
            node {
              id
              adSlot {
                tagId
                id
              }
            }
          }
        }
        categories
        ...CampaignEditForm_campaign
      }
    }
    campaign {
      id
    }
  }
}

fragment CampaignEditForm_campaign on Campaign {
  id
  title
  viewTrackingUrl
  clickTrackingUrl
  status
  bidStrategy
  reportingOption
  goalAmount
  note
  adomain
  fee
  feeMin
  frequencyCapEnabled
  frequencyCapAmount
  frequencyCapInterval
  periodSince
  periodTill
  os
  osVersionMin
  isBanditEnabled
  latestVersion
  isAutoRefreshPlacementsEnabled
  isPacingEnabled
  isPricingExplorationEnabled
  isCarryoverEnabled
  dailyBudget {
    id
    date
    baseAmount
  }
  monthlyBudget {
    id
    date
    amount
  }
  costCpi
  categories
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adomain",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AdConnection",
  "kind": "LinkedField",
  "name": "ads",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "os",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "osVersionMin",
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "AdConnection",
  "kind": "LinkedField",
  "name": "stoppedAds",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Ad",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "CreativeConnection",
  "kind": "LinkedField",
  "name": "stoppedCreatives",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CreativeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Creative",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categories",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignPayload",
        "kind": "LinkedField",
        "name": "createCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignEdge",
            "kind": "LinkedField",
            "name": "campaignEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementConnection",
                    "kind": "LinkedField",
                    "name": "stoppedPlacements",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlacementEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Placement",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AdSlot",
                                "kind": "LinkedField",
                                "name": "adSlot",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CampaignEditForm_campaign"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignPayload",
        "kind": "LinkedField",
        "name": "createCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignEdge",
            "kind": "LinkedField",
            "name": "campaignEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementConnection",
                    "kind": "LinkedField",
                    "name": "stoppedPlacements",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlacementEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Placement",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AdSlot",
                                "kind": "LinkedField",
                                "name": "adSlot",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewTrackingUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clickTrackingUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bidStrategy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportingOption",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goalAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fee",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feeMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequencyCapEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequencyCapAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequencyCapInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodSince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodTill",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBanditEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAutoRefreshPlacementsEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPacingEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPricingExplorationEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCarryoverEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DailyBudget",
                    "kind": "LinkedField",
                    "name": "dailyBudget",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baseAmount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MonthlyBudget",
                    "kind": "LinkedField",
                    "name": "monthlyBudget",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "costCpi",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "campaignEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e05dd38dd47c6384356aa54951040a6",
    "id": null,
    "metadata": {},
    "name": "CreateCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCampaignMutation(\n  $input: CreateCampaignInput!\n) {\n  createCampaign(input: $input) {\n    campaignEdge {\n      node {\n        id\n        title\n        status\n        createdAt\n        updatedAt\n        adomain\n        ads {\n          totalCount\n        }\n        os\n        osVersionMin\n        stoppedAds {\n          edges {\n            node {\n              id\n              title\n            }\n          }\n        }\n        stoppedCreatives {\n          edges {\n            node {\n              id\n              title\n            }\n          }\n        }\n        stoppedPlacements {\n          edges {\n            node {\n              id\n              adSlot {\n                tagId\n                id\n              }\n            }\n          }\n        }\n        categories\n        ...CampaignEditForm_campaign\n      }\n    }\n    campaign {\n      id\n    }\n  }\n}\n\nfragment CampaignEditForm_campaign on Campaign {\n  id\n  title\n  viewTrackingUrl\n  clickTrackingUrl\n  status\n  bidStrategy\n  reportingOption\n  goalAmount\n  note\n  adomain\n  fee\n  feeMin\n  frequencyCapEnabled\n  frequencyCapAmount\n  frequencyCapInterval\n  periodSince\n  periodTill\n  os\n  osVersionMin\n  isBanditEnabled\n  latestVersion\n  isAutoRefreshPlacementsEnabled\n  isPacingEnabled\n  isPricingExplorationEnabled\n  isCarryoverEnabled\n  dailyBudget {\n    id\n    date\n    baseAmount\n  }\n  monthlyBudget {\n    id\n    date\n    amount\n  }\n  costCpi\n  categories\n}\n"
  }
};
})();
(node as any).hash = '1ec56658ebb809f1e2f4c261c401ede6';
export default node;
