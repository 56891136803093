import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateDailyBudgetInput,
  UpdateDailyBudgetMutation,
} from "~/__relay_artifacts__/UpdateDailyBudgetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateDailyBudgetMutation($input: UpdateDailyBudgetInput!) {
    updateDailyBudget(input: $input) {
      dailyBudget {
        id
        date
        baseAmount
        createdAt
        updatedAt
        latestVersion
      }
    }
  }
`;

export const useUpdateDailyBudgetMutation = () => {
  const environment = useRelayEnvironment();

  const updateDailyBudgetMutation = React.useCallback(
    (input: UpdateDailyBudgetInput) =>
      commitMutation<UpdateDailyBudgetMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateDailyBudgetMutation };
};
