import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateCampaignInput,
  UpdateCampaignMutation,
} from "~/__relay_artifacts__/UpdateCampaignMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateCampaignMutation($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      campaign {
        id
        title
        status
        createdAt
        adomain
        frequencyCapEnabled
        frequencyCapAmount
        frequencyCapInterval
        archived
        os
        osVersionMin
        latestVersion
        categories
        ...CampaignEditForm_campaign
      }
    }
  }
`;

export const useUpdateCampaignMutation = () => {
  const environment = useRelayEnvironment();

  const updateCampaignMutation = React.useCallback(
    (input: UpdateCampaignInput) =>
      commitMutation<UpdateCampaignMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateCampaignMutation };
};
