import { styled } from "@material-ui/styles";
import { FormikChipTextField } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { CreativeListTableCard_project } from "~/__relay_artifacts__/CreativeListTableCard_project.graphql";
import { useUpdateCreativeMutation } from "~/mutations/UpdateCreativeMutation";

type Creative = NonNullable<
  NonNullable<
    NonNullable<CreativeListTableCard_project["creatives"]["edges"]>[0]
  >["node"]
>;

type Props = {
  creative: Creative;
};

type FormValues = {
  tagList: string[];
};

const StyledWrapper = styled("div")<{}>(() => ({
  display: "flex",
  alignItems: "center",
  minWidth: 300,
}));

export const TagListField: React.FC<Props> = ({ creative }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateCreativeMutation } = useUpdateCreativeMutation();

  const handleSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ tagList }, { resetForm }) => {
      try {
        const { updateCreative } = await updateCreativeMutation({
          id: creative.id,
          tagList,
          clientVersion: creative.latestVersion,
          viewableValidationEnabled: false,
        });
        if (!updateCreative?.creative) throw new Error("assertion failed");
        enqueueSnackbar("タグを更新しました。", { variant: "success" });
      } catch (_) {
        enqueueSnackbar("タグの更新に失敗しました。", { variant: "error" });
        resetForm();
      }
    },
    [enqueueSnackbar, creative, updateCreativeMutation]
  );

  return (
    <StyledWrapper>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{ tagList: creative.tagList || [] } as FormValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <FormikChipTextField
              name="tagList"
              blurBehavior="add"
              newChipKeys={["Tab"]}
              onChange={() => handleSubmit()}
            />
          </>
        )}
      </Formik>
    </StyledWrapper>
  );
};
