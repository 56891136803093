import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { FormValues, ScheduleForm, schema } from "~/components/ScheduleForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createScheduleMutation from "~/mutations/CreateScheduleMutation";

type Props = {
  scheduleSetId: string;
  onSubmitCompleted: () => void;
};

const initialValues = {
  since: null,
  till: null,
};

export const ScheduleCreateForm: React.FC<Props> = ({
  scheduleSetId,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ since, till, ...values }, { setErrors }) => {
      try {
        if (!since || !till) throw new Error("assertion error");
        const { createSchedule } = await createScheduleMutation(environment, {
          scheduleSetId,
          since,
          till,
          ...values,
        });
        if (!createSchedule?.scheduleEdge) throw new Error("assertion failed");
        enqueueSnackbar("スケジュールを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
      scheduleSetId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={ScheduleForm}
    />
  );
};
