import { Grid } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = yup.Asserts<typeof schema>;

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  title: yup.string().required(),
});

export const PromotionForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField name="title" label="Title" margin="normal" fullWidth />
      </Grid>
    </Grid>
  </DialogForm>
);
