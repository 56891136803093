import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateAdSlotNoteInput,
  UpdateAdSlotNoteMutation,
} from "~/__relay_artifacts__/UpdateAdSlotNoteMutation.graphql";

const mutation = graphql`
  mutation UpdateAdSlotNoteMutation($input: UpdateAdSlotNoteInput!) {
    updateAdSlotNote(input: $input) {
      placement {
        adSlot {
          note
        }
        ...AdSlotNoteEditForm_placement
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdateAdSlotNoteInput) =>
  commitMutation<UpdateAdSlotNoteMutation>(environment, {
    mutation,
    variables: { input },
  });

export default commit;
