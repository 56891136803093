import { Box, Icon, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";
import { useHistory, useParams } from "react-router";

import { CampaignDetail_Query } from "~/__relay_artifacts__/CampaignDetail_Query.graphql";
import { CampaignDetail_project } from "~/__relay_artifacts__/CampaignDetail_project.graphql";
import { AdListTableCard } from "~/components/AdListTableCard";
import CampaignAudits from "~/components/CampaignAudits";
import { DailyBudgetListDataSheet } from "~/components/DailyBudgetListDataSheet/DailyBudgetListDataSheet";
import { DialogButton } from "~/components/DialogButton";
import { ExcludeScheduleListTableCard } from "~/components/ExcludeScheduleListTableCard";
import { ExpenseListTableCard } from "~/components/ExpenseListTableCard";
import { MonthlyBudgetListTableCard } from "~/components/MonthlyBudgetListTableCard";
import { PageHeader } from "~/components/PageHeader";
import { DeleteButton } from "~/components/atoms/DeleteButton";
import { CampaignEditForm } from "~/containers/CampaignEditForm";
import { useFragment } from "~/lib/relay-hooks";
import { useTabs } from "~/lib/useTabs";
import { useDeleteCampaignMutation } from "~/mutations/DeleteCampaignMutation";

import { CampaignDebugSettingCard } from "./CampaignDebugSettingCard";
import { CampaignRestrictionListCard } from "./CampaignRestrictionListCard";
import { CampaignSetting } from "./CampaignSetting";

type Props = {
  projectRef: CampaignDetail_Query["response"]["project"];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  pageHeader: {
    width: "100%",
    overflowX: "scroll",
  },
}));

const tabs = [
  { id: "ads", label: "広告" },
  { id: "expenses", label: "追加費用" },
  { id: "daily_budgets", label: "日予算" },
  { id: "monthly_budgets", label: "月予算" },
  { id: "exclude_schedules", label: "停止期間設定" },
  { id: "campaign_restrictions", label: "配信可否" },
  { id: "settings", label: "設定" },
  { id: "campaign_audits", label: "変更履歴" },
  { id: "developer_debug", label: "開発者機能" },
] as const;

const projectFragment = graphql`
  fragment CampaignDetail_project on Project {
    ...CampaignSetting_project
    campaign(id: $campaignId) {
      id
      title
      ...CampaignEditForm_campaign
      ...AdListTableCard_campaign
      ...ExpenseListTableCard_campaign
      ...CampaignAudits_campaign
      ...ExcludeScheduleListTableCard_campaign
      ...DailyBudgetListDataSheet_campaign
      ...MonthlyBudgetListTableCard_campaign
      ...CampaignRestrictionListCard_campaign
      ...CampaignDebugSettingCard_campaign
    }
  }
`;

export const CampaignDetail: FC<Props> = ({ projectRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const { projectId } = useParams<{ id: string; projectId: string }>();
  const { currentTabName, tabsElement } = useTabs(tabs);
  const { deleteCampaignMutation } = useDeleteCampaignMutation();
  const project = useFragment<CampaignDetail_project>(
    projectFragment,
    projectRef
  );

  const campaign = useMemo(() => {
    if (!project.campaign) throw new Error("assertion failed");
    return project.campaign;
  }, [project.campaign]);

  const handleDeleteClick = useCallback(async () => {
    await deleteCampaignMutation({ campaignId: campaign.id });
    history.push(`/projects/${projectId}/campaigns`);
  }, [campaign.id, deleteCampaignMutation, history, projectId]);

  return (
    <>
      <div className={classes.pageHeader}>
        <PageHeader
          title={
            <Box>
              {campaign.title}
              <Box ml={1} display="inline" color="text.hint">
                #{window.atob(campaign.id)}
              </Box>
            </Box>
          }
          backTo={`/projects/${projectId}/campaigns`}
          tabs={tabsElement}
          breadcrumbs={[
            {
              title: "キャンペーン一覧",
              link: `/projects/${projectId}/campaigns`,
            },
            {
              title: "キャンペーン詳細",
              link: `/projects/${projectId}/campaigns/${campaign.id}`,
            },
          ]}
        >
          <DialogButton
            title="キャンペーン編集"
            variant="outlined"
            color="primary"
            render={({ close }) => (
              <CampaignEditForm
                campaignRef={campaign}
                onSubmitCompleted={close}
              />
            )}
          >
            <Icon>edit</Icon> 編集
          </DialogButton>
          <DeleteButton
            variant="text"
            key="delete"
            color="inherit"
            keyword="キャンペーン"
            confirmValue={campaign.title}
            onAgree={handleDeleteClick}
          >
            <Icon>delete</Icon> 削除
          </DeleteButton>
        </PageHeader>
      </div>
      <div className={classes.root}>
        {currentTabName === "ads" && (
          <AdListTableCard campaign={campaign} projectId={projectId} />
        )}
        {currentTabName === "expenses" && (
          <ExpenseListTableCard campaignRef={campaign} projectId={projectId} />
        )}
        {currentTabName === "daily_budgets" && (
          <DailyBudgetListDataSheet campaignRef={campaign} />
        )}
        {currentTabName === "monthly_budgets" && (
          <MonthlyBudgetListTableCard campaignRef={campaign} />
        )}
        {currentTabName === "exclude_schedules" && (
          <ExcludeScheduleListTableCard campaignRef={campaign} />
        )}
        {currentTabName === "campaign_restrictions" && (
          <CampaignRestrictionListCard campaignRef={campaign} />
        )}
        {currentTabName === "developer_debug" && (
          <CampaignDebugSettingCard campaignRef={campaign} />
        )}
        {currentTabName === "settings" && (
          <CampaignSetting projectRef={project} />
        )}
        {currentTabName === "campaign_audits" && (
          <CampaignAudits campaign={campaign} />
        )}
      </div>
    </>
  );
};
