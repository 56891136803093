import { Icon, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC } from "react";
import { FragmentRef, graphql } from "react-relay";
import { useParams } from "react-router";

import { CreativeDetail_creative } from "~/__relay_artifacts__/CreativeDetail_creative.graphql";
import { DialogButton } from "~/components/DialogButton";
import { PageHeader } from "~/components/PageHeader";
import { CreativeEditForm } from "~/containers/CreativeEditForm";
import { useFragment } from "~/lib/relay-hooks";
import { useTabs } from "~/lib/useTabs";

import { CreativeSetting } from "./CreativeSetting";

type Props = {
  creativeRef: FragmentRef<CreativeDetail_creative>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const tabs = [{ id: "settings", label: "設定" }] as const;

const creativeFragment = graphql`
  fragment CreativeDetail_creative on Creative {
    id
    title
    adm
    height
    width
    createdAt
    updatedAt
    sdkVersion
    note
    adxStatus
    adxSubmittedAt
    project {
      ...CreativeSetting_project
    }
    ...CreativeEditForm_creative
  }
`;

export const CreativeDetail: FC<Props> = ({ creativeRef }) => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const { currentTabName, tabsElement } = useTabs(tabs);
  const creative = useFragment<CreativeDetail_creative>(
    creativeFragment,
    creativeRef
  );

  return (
    <>
      <PageHeader
        title={creative.title}
        backTo={`/projects/${projectId}/campaigns`}
        tabs={tabsElement}
        breadcrumbs={[
          {
            title: "キャンペーン一覧",
            link: `/projects/${projectId}/campaigns`,
          },
          {
            title: "クリエイティブ詳細",
            link: `/projects/${projectId}/creatives/${creative.id}`,
          },
        ]}
      >
        <DialogButton
          title="クリエイティブ(WebComponent版)の編集"
          variant="text"
          color="default"
          render={({ close }) => (
            <CreativeEditForm
              creativeRef={creative}
              onSubmitCompleted={close}
            />
          )}
        >
          <Icon className={classes.leftIcon}>edit</Icon>
          編集
        </DialogButton>
      </PageHeader>
      <div className={classes.root}>
        {currentTabName === "settings" && (
          <CreativeSetting projectRef={creative.project} />
        )}
      </div>
    </>
  );
};
