import { makeStyles } from "@material-ui/styles";
import { FC } from "react";

import { CreativePreviewButton } from "~/components/CreativePreviewButton";
import { HtmlPreviewButton } from "~/components/HtmlPreviewButton";

type Props = {
  adm: string | null;
  height: number | null;
  width: number | null;
};

const useStyles = makeStyles({
  previews: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export const CreativeAdmPreviewButtons: FC<Props> = ({
  adm,
  height,
  width,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.previews}>
      <HtmlPreviewButton html={adm} />
      <CreativePreviewButton
        html={adm || ""}
        height={height || 100}
        width={width || 200}
        disabled={!adm}
      />
    </div>
  );
};
