import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { CampaignListTableCard_project } from "~/__relay_artifacts__/CampaignListTableCard_project.graphql";
import { ProjectSelectFormContainerForCopyCampaign_Query } from "~/__relay_artifacts__/ProjectSelectFormContainerForCopyCampaign_Query.graphql";
import { DialogButton } from "~/components/DialogButton";

import { ProjectSelectFormForCopyCamapign } from "./ProjectSelectFormForCopyCampaign";

type Props = {
  campaign: NonNullable<
    NonNullable<
      NonNullable<CampaignListTableCard_project["campaigns"]["edges"]>[0]
    >["node"]
  >;
};

const query = graphql`
  query ProjectSelectFormContainerForCopyCampaign_Query($search: String) {
    viewer {
      ...ProjectSelectFormForCopyCampaign_viewer
    }
  }
`;

export const ProjectSelectFormContainerForCopyCamapign: React.FC<Props> = ({
  campaign,
}) => {
  const { props, error } =
    useQuery<ProjectSelectFormContainerForCopyCampaign_Query>(query, {});

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading</div>;
  return props.viewer ? (
    <DialogButton
      title="作成先のプロジェクト選択"
      variant="outlined"
      color="primary"
      render={({ close }) => (
        <ProjectSelectFormForCopyCamapign
          campaignId={campaign.id}
          viewerRef={props.viewer}
          onSubmitCompleted={close}
        />
      )}
    >
      テンプレートとして利用
    </DialogButton>
  ) : (
    <div>not found</div>
  );
};
