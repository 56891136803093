/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteMaintenanceInput = {
    clientMutationId?: string | null;
    maintenanceId: string;
};
export type DeleteMaintenanceMutationVariables = {
    input: DeleteMaintenanceInput;
};
export type DeleteMaintenanceMutationResponse = {
    readonly deleteMaintenance: {
        readonly deletedMaintenanceId: string;
    } | null;
};
export type DeleteMaintenanceMutation = {
    readonly response: DeleteMaintenanceMutationResponse;
    readonly variables: DeleteMaintenanceMutationVariables;
};



/*
mutation DeleteMaintenanceMutation(
  $input: DeleteMaintenanceInput!
) {
  deleteMaintenance(input: $input) {
    deletedMaintenanceId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteMaintenancePayload",
    "kind": "LinkedField",
    "name": "deleteMaintenance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedMaintenanceId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMaintenanceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMaintenanceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3b34b7deca0d5368493735331fcea4af",
    "id": null,
    "metadata": {},
    "name": "DeleteMaintenanceMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteMaintenanceMutation(\n  $input: DeleteMaintenanceInput!\n) {\n  deleteMaintenance(input: $input) {\n    deletedMaintenanceId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b43471a3b759f2be3f805390076a6312';
export default node;
