/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateThompsonFilteringStrategyInput = {
    clientMutationId?: string | null;
    id: string;
    cpa: number;
    cvr: number;
    cpmCorrectionTerm: number;
    ctrCorrectionTerm: number;
    initialDistributionAlpha: number;
    initialDistributionBeta: number;
};
export type UpdateThompsonFilteringStrategyMutationVariables = {
    input: UpdateThompsonFilteringStrategyInput;
};
export type UpdateThompsonFilteringStrategyMutationResponse = {
    readonly updateThompsonFilteringStrategy: {
        readonly thompsonFilteringStrategy: {
            readonly id: string;
            readonly cpa: number;
            readonly cvr: number;
            readonly cpmCorrectionTerm: number;
            readonly ctrCorrectionTerm: number;
            readonly initialDistributionAlpha: number;
            readonly initialDistributionBeta: number;
        } | null;
    } | null;
};
export type UpdateThompsonFilteringStrategyMutation = {
    readonly response: UpdateThompsonFilteringStrategyMutationResponse;
    readonly variables: UpdateThompsonFilteringStrategyMutationVariables;
};



/*
mutation UpdateThompsonFilteringStrategyMutation(
  $input: UpdateThompsonFilteringStrategyInput!
) {
  updateThompsonFilteringStrategy(input: $input) {
    thompsonFilteringStrategy {
      id
      cpa
      cvr
      cpmCorrectionTerm
      ctrCorrectionTerm
      initialDistributionAlpha
      initialDistributionBeta
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateThompsonFilteringStrategyPayload",
    "kind": "LinkedField",
    "name": "updateThompsonFilteringStrategy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ThompsonFilteringStrategy",
        "kind": "LinkedField",
        "name": "thompsonFilteringStrategy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cpa",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cvr",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cpmCorrectionTerm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctrCorrectionTerm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialDistributionAlpha",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialDistributionBeta",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateThompsonFilteringStrategyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateThompsonFilteringStrategyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "03ec957c2ac654a7ce59371d2ed57c97",
    "id": null,
    "metadata": {},
    "name": "UpdateThompsonFilteringStrategyMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateThompsonFilteringStrategyMutation(\n  $input: UpdateThompsonFilteringStrategyInput!\n) {\n  updateThompsonFilteringStrategy(input: $input) {\n    thompsonFilteringStrategy {\n      id\n      cpa\n      cvr\n      cpmCorrectionTerm\n      ctrCorrectionTerm\n      initialDistributionAlpha\n      initialDistributionBeta\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8713fda3f59e7e71391a2e1546330d01';
export default node;
