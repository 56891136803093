/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateExpenseInput = {
    clientMutationId?: string | null;
    campaignId: string;
    date: string;
    rate: number;
};
export type CreateExpenseMutationVariables = {
    input: CreateExpenseInput;
};
export type CreateExpenseMutationResponse = {
    readonly createExpense: {
        readonly expenseEdge: {
            readonly node: {
                readonly id: string;
                readonly date: string;
                readonly rate: number;
                readonly " $fragmentRefs": FragmentRefs<"ExpenseEditForm_expense">;
            } | null;
        } | null;
    } | null;
};
export type CreateExpenseMutation = {
    readonly response: CreateExpenseMutationResponse;
    readonly variables: CreateExpenseMutationVariables;
};



/*
mutation CreateExpenseMutation(
  $input: CreateExpenseInput!
) {
  createExpense(input: $input) {
    expenseEdge {
      node {
        id
        date
        rate
        ...ExpenseEditForm_expense
      }
    }
  }
}

fragment ExpenseEditForm_expense on Expense {
  id
  date
  rate
  campaign {
    id
  }
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateExpenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateExpensePayload",
        "kind": "LinkedField",
        "name": "createExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseEdge",
            "kind": "LinkedField",
            "name": "expenseEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Expense",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ExpenseEditForm_expense"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateExpenseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateExpensePayload",
        "kind": "LinkedField",
        "name": "createExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseEdge",
            "kind": "LinkedField",
            "name": "expenseEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Expense",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b6e5fbe2d94be4dd4009896c0740408",
    "id": null,
    "metadata": {},
    "name": "CreateExpenseMutation",
    "operationKind": "mutation",
    "text": "mutation CreateExpenseMutation(\n  $input: CreateExpenseInput!\n) {\n  createExpense(input: $input) {\n    expenseEdge {\n      node {\n        id\n        date\n        rate\n        ...ExpenseEditForm_expense\n      }\n    }\n  }\n}\n\nfragment ExpenseEditForm_expense on Expense {\n  id\n  date\n  rate\n  campaign {\n    id\n  }\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = '3f73199c2bd5ca19f41ba415a1d50114';
export default node;
