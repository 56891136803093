/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AttachPromotionInput = {
    clientMutationId?: string | null;
    campaignId: string;
    promotionId: string;
};
export type AttachPromotionMutationVariables = {
    input: AttachPromotionInput;
};
export type AttachPromotionMutationResponse = {
    readonly attachPromotion: {
        readonly campaign: {
            readonly id: string;
            readonly promotion: {
                readonly id: string;
                readonly title: string;
            } | null;
        } | null;
    } | null;
};
export type AttachPromotionMutation = {
    readonly response: AttachPromotionMutationResponse;
    readonly variables: AttachPromotionMutationVariables;
};



/*
mutation AttachPromotionMutation(
  $input: AttachPromotionInput!
) {
  attachPromotion(input: $input) {
    campaign {
      id
      promotion {
        id
        title
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AttachPromotionPayload",
    "kind": "LinkedField",
    "name": "attachPromotion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Promotion",
            "kind": "LinkedField",
            "name": "promotion",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttachPromotionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttachPromotionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "120dde892bb5b66e8d529033ca38a2bc",
    "id": null,
    "metadata": {},
    "name": "AttachPromotionMutation",
    "operationKind": "mutation",
    "text": "mutation AttachPromotionMutation(\n  $input: AttachPromotionInput!\n) {\n  attachPromotion(input: $input) {\n    campaign {\n      id\n      promotion {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5d05e62b512ace86fe6363b4b27b11da';
export default node;
