import { Grid, Typography } from "@material-ui/core";
import { FormikDateField, FormikNumberField } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { FormikProps } from "formik";
import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";
import * as yup from "yup";

import { ExpenseForm_Query } from "~/__relay_artifacts__/ExpenseForm_Query.graphql";

import { DialogForm } from "./DialogForm";

export type FormValues = yup.Asserts<typeof schema>;

type Props = {
  campaignId: string;
  selectableBeforeDate: boolean;
} & FormikProps<FormValues>;

export const schema = yup.object({
  date: yup.date().required(),
  rate: yup.number().required(),
  maxAssignableExpenseRate: yup.number().nullable(),
});

const query = graphql`
  query ExpenseForm_Query($campaignId: ID!, $date: ISO8601Date!) {
    campaign(id: $campaignId) {
      maxAssignableExpenseRate(date: $date)
    }
  }
`;

export const ExpenseForm: React.FC<Props> = ({
  campaignId,
  selectableBeforeDate,
  values,
}) => {
  const currentDateTime = new Date();
  const { props } = useQuery<ExpenseForm_Query>(query, {
    campaignId,
    date: new Date(values.date).toISOString(),
  });

  const maxAssignableExpenseRate = React.useMemo(() => {
    if (!props) return null;
    if (!props.campaign) return null;
    values.maxAssignableExpenseRate = props.campaign.maxAssignableExpenseRate;
    return props.campaign.maxAssignableExpenseRate;
  }, [props, values]);

  return (
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {selectableBeforeDate ? (
            <FormikDateField
              required
              fullWidth
              name="date"
              label="日付"
              minDate={DateFns.startOfYesterday()}
              maxDate={currentDateTime}
            />
          ) : (
            <FormikDateField
              required
              fullWidth
              readOnly
              name="date"
              label="日付"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormikNumberField
            required
            fullWidth
            name="rate"
            label="追加手数料(率)"
            margin="normal"
            precision={2}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">設定可能な最大手数料(率)</Typography>
          <Typography>
            {maxAssignableExpenseRate
              ? maxAssignableExpenseRate
              : "算出できませんでした"}
          </Typography>
        </Grid>
      </Grid>
    </DialogForm>
  );
};
