import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  AttachPromotionInput,
  AttachPromotionMutation,
} from "~/__relay_artifacts__/AttachPromotionMutation.graphql";

const mutation = graphql`
  mutation AttachPromotionMutation($input: AttachPromotionInput!) {
    attachPromotion(input: $input) {
      campaign {
        id
        promotion {
          id
          title
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: AttachPromotionInput) =>
  commitMutation<AttachPromotionMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
