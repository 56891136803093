/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExpenseEditForm_expense = {
    readonly id: string;
    readonly date: string;
    readonly rate: number;
    readonly campaign: {
        readonly id: string;
    };
    readonly latestVersion: number | null;
    readonly " $refType": "ExpenseEditForm_expense";
};
export type ExpenseEditForm_expense$data = ExpenseEditForm_expense;
export type ExpenseEditForm_expense$key = {
    readonly " $data"?: ExpenseEditForm_expense$data;
    readonly " $fragmentRefs": FragmentRefs<"ExpenseEditForm_expense">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExpenseEditForm_expense",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "Expense",
  "abstractKey": null
};
})();
(node as any).hash = '921249968702b0d0ddec04e3621aa948';
export default node;
