/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdminHeader_QueryVariables = {
    search?: string | null;
};
export type AdminHeader_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"AdminHeader_viewer">;
    };
};
export type AdminHeader_Query = {
    readonly response: AdminHeader_QueryResponse;
    readonly variables: AdminHeader_QueryVariables;
};



/*
query AdminHeader_Query(
  $search: String
) {
  viewer {
    ...AdminHeader_viewer
    id
  }
}

fragment AccountButton_viewer on User {
  id
  email
}

fragment AdminDrawerMenuList_viewer on User {
  ...AccountButton_viewer
}

fragment AdminHeader_viewer on User {
  id
  ...AccountButton_viewer
  ...AdminDrawerMenuList_viewer
  ...ProjectSelectionButton_viewer
  ...ProjectDrawerMenuList_viewer
}

fragment ProjectDrawerMenuList_viewer on User {
  ...AccountButton_viewer
  role
}

fragment ProjectSelectionButton_viewer on User {
  role
  projects(search: $search) {
    edges {
      node {
        id
        name
        viewerCanAdministrate
        updatedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminHeader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminHeader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminHeader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "search",
                "variableName": "search"
              }
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerCanAdministrate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a385014f46d08a3d49452925becc5a5",
    "id": null,
    "metadata": {},
    "name": "AdminHeader_Query",
    "operationKind": "query",
    "text": "query AdminHeader_Query(\n  $search: String\n) {\n  viewer {\n    ...AdminHeader_viewer\n    id\n  }\n}\n\nfragment AccountButton_viewer on User {\n  id\n  email\n}\n\nfragment AdminDrawerMenuList_viewer on User {\n  ...AccountButton_viewer\n}\n\nfragment AdminHeader_viewer on User {\n  id\n  ...AccountButton_viewer\n  ...AdminDrawerMenuList_viewer\n  ...ProjectSelectionButton_viewer\n  ...ProjectDrawerMenuList_viewer\n}\n\nfragment ProjectDrawerMenuList_viewer on User {\n  ...AccountButton_viewer\n  role\n}\n\nfragment ProjectSelectionButton_viewer on User {\n  role\n  projects(search: $search) {\n    edges {\n      node {\n        id\n        name\n        viewerCanAdministrate\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1bd5e167c923e6390153fccabcd8a296';
export default node;
