import * as React from "react";
import useScript from "react-script-hook";

const PRETTIER_VERSION = "2.0.5";
const PRETTIER_STANDALONE_SRC = `https://unpkg.com/prettier@${PRETTIER_VERSION}/standalone.js`;
const PRETTIER_PARSER_HTML_SRC = `https://unpkg.com/prettier@${PRETTIER_VERSION}/parser-html.js`;

declare global {
  // NOTE: load prettier as global variables
  var prettier: typeof import("prettier/standalone");
  var prettierPlugins: [typeof import("prettier/parser-html")];
}

export const usePrettier = () => {
  const [standaloneLoaded, standaloneError] = useScript({
    src: PRETTIER_STANDALONE_SRC,
  });
  const [parserHtmlLoaded, parserHtmlError] = useScript({
    src: PRETTIER_PARSER_HTML_SRC,
  });

  const formatHtml = React.useCallback(
    (html: string) => {
      if (
        !!standaloneLoaded ||
        !!standaloneError ||
        !!parserHtmlLoaded ||
        !!parserHtmlError
      ) {
        throw new Error("failed to load prettier");
      }
      return prettier.format(html, {
        parser: "html",
        plugins: prettierPlugins,
        htmlWhitespaceSensitivity: "ignore",
      }) as string;
    },
    [parserHtmlError, parserHtmlLoaded, standaloneError, standaloneLoaded]
  );

  return { formatHtml };
};
