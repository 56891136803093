import { Disposable, GraphQLTaggedNode } from "react-relay";
import {
  ConnectionConfig,
  ObserverOrCallback,
  PaginationFunction,
  useFragment as _useFragment,
  usePagination as _usePagination,
  useRefetch as _useRefetch,
  useRelayEnvironment as _useRelayEnvironment,
} from "relay-hooks";
import { Environment, OperationType } from "relay-runtime";

type RefetchOptions = {
  force?: boolean;
  fetchPolicy?: "store-or-network" | "network-only";
};

type RefetchFunction = <TOperationType extends OperationType>(
  taggedNode: GraphQLTaggedNode,
  refetchVariables: TOperationType["variables"],
  renderVariables?: any,
  observerOrCallback?: (err: any) => void,
  options?: RefetchOptions
) => {
  dispose(): void;
};

export const useFragment: <FragmentResult, FragmentRef = any>(
  fragmentDef: GraphQLTaggedNode,
  fragmentRef: FragmentRef
) => FragmentResult = _useFragment;

export const useRefetch: <FragmentResult, FragmentRef = any>(
  fragmentDef: GraphQLTaggedNode,
  fragmentRef: FragmentRef
) => [FragmentResult, RefetchFunction] = _useRefetch;

export const usePagination: <
  FragmentResult,
  FragmentRef = any,
  Props = {},
  Variables = {}
>(
  fragmentDef: GraphQLTaggedNode,
  fragmentRef: FragmentRef
) => [
  FragmentResult,
  {
    loadMore: (
      connectionConfig: ConnectionConfig,
      pageSize: number,
      observerOrCallback: ObserverOrCallback,
      options?: RefetchOptions
    ) => Disposable;
    hasMore: PaginationFunction<Props, Variables>["hasMore"];
    isLoading: PaginationFunction<Props, Variables>["isLoading"];
    refetchConnection?: PaginationFunction<
      Props,
      Variables
    >["refetchConnection"];
  }
] = _usePagination;

export const useRelayEnvironment: () => Environment = _useRelayEnvironment;
