import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { CampaignListForAdmin_Query } from "~/__relay_artifacts__/CampaignListForAdmin_Query.graphql";
import { PageHeader } from "~/components/PageHeader";

import { CampaignListTableCardForAdmin } from "./CampaignListTableCardForAdmin";

const query = graphql`
  query CampaignListForAdmin_Query(
    $first: Int
    $after: String
    $withArchived: Boolean
  ) {
    ...CampaignListTableCardForAdmin_root
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader title="キャンペーン管理" backTo="/" />
    {children}
  </>
);

export const CampaignListForAdmin: React.FC = () => {
  const { props, error } = useQuery<CampaignListForAdmin_Query>(query, {
    withArchived: false,
  });
  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <CampaignListTableCardForAdmin root={props} />
    </Wrapper>
  );
};
