/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CampaignDomainRestrictionStatus = "ALLOW" | "DENY" | "%future added value";
export type CreateCampaignDomainRestrictionInput = {
    clientMutationId?: string | null;
    campaignId: string;
    domain: string;
    status: CampaignDomainRestrictionStatus;
};
export type CreateCampaignDomainRestrictionMutationVariables = {
    connections: Array<string>;
    input: CreateCampaignDomainRestrictionInput;
};
export type CreateCampaignDomainRestrictionMutationResponse = {
    readonly createCampaignDomainRestriction: {
        readonly campaignDomainRestrictionEdge: {
            readonly node: {
                readonly id: string;
                readonly domain: string;
                readonly status: CampaignDomainRestrictionStatus;
            } | null;
        } | null;
    } | null;
};
export type CreateCampaignDomainRestrictionMutation = {
    readonly response: CreateCampaignDomainRestrictionMutationResponse;
    readonly variables: CreateCampaignDomainRestrictionMutationVariables;
};



/*
mutation CreateCampaignDomainRestrictionMutation(
  $input: CreateCampaignDomainRestrictionInput!
) {
  createCampaignDomainRestriction(input: $input) {
    campaignDomainRestrictionEdge {
      node {
        id
        domain
        status
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CampaignDomainRestrictionEdge",
  "kind": "LinkedField",
  "name": "campaignDomainRestrictionEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignDomainRestriction",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCampaignDomainRestrictionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignDomainRestrictionPayload",
        "kind": "LinkedField",
        "name": "createCampaignDomainRestriction",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCampaignDomainRestrictionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignDomainRestrictionPayload",
        "kind": "LinkedField",
        "name": "createCampaignDomainRestriction",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "campaignDomainRestrictionEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed2b34c522c9408aa569268c6d9d0025",
    "id": null,
    "metadata": {},
    "name": "CreateCampaignDomainRestrictionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCampaignDomainRestrictionMutation(\n  $input: CreateCampaignDomainRestrictionInput!\n) {\n  createCampaignDomainRestriction(input: $input) {\n    campaignDomainRestrictionEdge {\n      node {\n        id\n        domain\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6df0b9a0bdd3a2540eb1ee0fc8bc6123';
export default node;
