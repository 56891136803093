import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateMaintenanceInput,
  UpdateMaintenanceMutation,
} from "~/__relay_artifacts__/UpdateMaintenanceMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateMaintenanceMutation($input: UpdateMaintenanceInput!) {
    updateMaintenance(input: $input) {
      maintenance {
        id
        message
        createdAt
        updatedAt
        ...MaintenanceEditForm_maintenance
      }
    }
  }
`;

export const useUpdateMaintenanceMutation = () => {
  const environment = useRelayEnvironment();

  const updateMaintenanceMutation = React.useCallback(
    (input: UpdateMaintenanceInput) =>
      commitMutation<UpdateMaintenanceMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateMaintenanceMutation };
};
