import "firebase/auth";
import "~/index.css";

import { createTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import * as Sentry from "@sentry/browser";
import accounting from "accounting";
import ja from "date-fns/locale/ja";
import firebase from "firebase/app";
import { SnackbarProvider } from "notistack";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RelayEnvironmentProvider } from "relay-hooks";

import { App } from "~/App";
import { initGlobalState } from "~/globalState";
import { modernEnvironment } from "~/lib/Environment";
import { LocalizedDateFnsUtils } from "~/lib/LocalizedDateFnsUtils";
import { unregister } from "~/registerServiceWorker";

import { ErrorBoundary } from "./ErrorBoundary";

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

initGlobalState();

unregister();

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

accounting.settings = {
  ...accounting.settings,
  currency: {
    ...accounting.settings.currency,
    symbol: "¥",
    precision: 0,
  },
};

const theme = createTheme();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={LocalizedDateFnsUtils} locale={ja}>
        <SnackbarProvider maxSnack={3}>
          <RelayEnvironmentProvider environment={modernEnvironment}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </RelayEnvironmentProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
