/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GrossBudgetConsumptionReportList_project = {
    readonly id: string;
    readonly report: {
        readonly measures: ReadonlyArray<ReadonlyArray<string>>;
    };
    readonly campaign: {
        readonly monthlyBudget: {
            readonly amount: number;
        };
        readonly dailyBudgets: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly date: string;
                    readonly baseAmount: number;
                } | null;
            } | null> | null;
        };
    } | null;
    readonly " $refType": "GrossBudgetConsumptionReportList_project";
};
export type GrossBudgetConsumptionReportList_project$data = GrossBudgetConsumptionReportList_project;
export type GrossBudgetConsumptionReportList_project$key = {
    readonly " $data"?: GrossBudgetConsumptionReportList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"GrossBudgetConsumptionReportList_project">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "campaignId"
    },
    {
      "kind": "RootArgument",
      "name": "dimensions"
    },
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "measures"
    },
    {
      "kind": "RootArgument",
      "name": "month"
    },
    {
      "kind": "RootArgument",
      "name": "orders"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrossBudgetConsumptionReportList_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "dimensions",
          "variableName": "dimensions"
        },
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "measures",
          "variableName": "measures"
        },
        {
          "kind": "Variable",
          "name": "orders",
          "variableName": "orders"
        }
      ],
      "concreteType": "Report",
      "kind": "LinkedField",
      "name": "report",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "measures",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "campaignId"
        }
      ],
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MonthlyBudget",
          "kind": "LinkedField",
          "name": "monthlyBudget",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            },
            {
              "kind": "Variable",
              "name": "month",
              "variableName": "month"
            }
          ],
          "concreteType": "DailyBudgetConnection",
          "kind": "LinkedField",
          "name": "dailyBudgets",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DailyBudgetEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DailyBudget",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "date",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baseAmount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
(node as any).hash = 'e760ec6670eef765af2d744c19d4c53f';
export default node;
