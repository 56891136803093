import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { FormValues, UserForm, schema } from "~/components/UserForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createUserMutation from "~/mutations/CreateUserMutation";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues = {
  email: "",
  password: "",
};

export const UserCreateForm: React.FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createUser } = await createUserMutation(environment, values);
        if (!createUser?.userEdge) throw new Error("assertion failed");
        enqueueSnackbar("ユーザーを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [enqueueSnackbar, environment, formErrorHandler, onSubmitCompleted]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={UserForm}
    />
  );
};
