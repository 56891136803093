/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdxCreativeStatus = "APPROVED" | "CONDITIONALLY_APPROVED" | "DISAPPROVED" | "NOT_CHECKED" | "PENDING_REVIEW" | "UNKNOWN" | "%future added value";
export type CreativeSetting_project = {
    readonly trackers: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly associationType: string;
            } | null;
        } | null> | null;
    };
    readonly creative: {
        readonly id: string;
        readonly title: string;
        readonly createdAt: string;
        readonly adxStatus: AdxCreativeStatus | null;
        readonly adxSubmittedAt: string | null;
        readonly tracker: {
            readonly id: string;
            readonly title: string;
            readonly associationType: string;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"AdxSubmitForm_creative">;
    } | null;
    readonly " $refType": "CreativeSetting_project";
};
export type CreativeSetting_project$data = CreativeSetting_project;
export type CreativeSetting_project$key = {
    readonly " $data"?: CreativeSetting_project$data;
    readonly " $fragmentRefs": FragmentRefs<"CreativeSetting_project">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "associationType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "creativeId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreativeSetting_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackerConnection",
      "kind": "LinkedField",
      "name": "trackers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TrackerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Tracker",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "creativeId"
        }
      ],
      "concreteType": "Creative",
      "kind": "LinkedField",
      "name": "creative",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adxStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adxSubmittedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tracker",
          "kind": "LinkedField",
          "name": "tracker",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdxSubmitForm_creative"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();
(node as any).hash = 'e46a90a5e4716dec7ef8af5696b847c5';
export default node;
