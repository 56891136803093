import { Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";

import { DialogButton } from "~/components/DialogButton";
import {
  FormValues as NumberFormValues,
  PlacementBulkUpdateNumberForm,
} from "~/components/PlacementBulkUpdateNumberForm";
import {
  PlacementBulkUpdateSwitchForm,
  FormValues as SwitchFormValues,
} from "~/components/PlacementBulkUpdateSwitchForm";
import { useBulkUpdateAdSlotPlacementMutation } from "~/mutations/BulkUpdateAdSlotPlacementMutation";

type FormValues = NumberFormValues | SwitchFormValues;

type Props = {
  fieldKey: string;
  fieldName: string;
  adSlotId: string;
  isDistributionCampaigns: boolean;
  disabled: boolean;
  initialValue: FormValues["targetValue"];
};

export const AdSlotPlacementBulkUpdateButton: React.FC<Props> = ({
  disabled,
  adSlotId,
  isDistributionCampaigns,
  fieldKey,
  fieldName,
  initialValue,
}) => {
  const { bulkUpdateAdSlotPlacementMutation } =
    useBulkUpdateAdSlotPlacementMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = React.useCallback<(values: FormValues) => Promise<void>>(
    async (values) => {
      try {
        await bulkUpdateAdSlotPlacementMutation(
          {
            adSlotId,
            [fieldKey]: values.targetValue,
          },
          isDistributionCampaigns
        );
        enqueueSnackbar(`${fieldName}を更新しました。`, {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(err, {
          variant: "error",
        });
      }
    },
    [
      fieldKey,
      fieldName,
      adSlotId,
      enqueueSnackbar,
      bulkUpdateAdSlotPlacementMutation,
      isDistributionCampaigns,
    ]
  );

  return (
    <Tooltip title={`${fieldName}の一括更新`}>
      <DialogButton
        title={`${fieldName}の一括更新`}
        disabled={disabled}
        render={({ close }) => {
          if (typeof initialValue === "number") {
            return (
              <PlacementBulkUpdateNumberForm
                initialValues={{ targetValue: initialValue }}
                onSubmit={(values) => {
                  onSubmit(values);
                  close();
                }}
                fieldName={fieldName}
              />
            );
          } else if (typeof initialValue === "boolean") {
            return (
              <PlacementBulkUpdateSwitchForm
                initialValues={{ targetValue: initialValue }}
                onSubmit={(values) => {
                  onSubmit(values);
                  close();
                }}
                fieldName={fieldName}
              />
            );
          } else {
            throw new Error("assertion failed");
          }
        }}
      >
        {fieldName}
      </DialogButton>
    </Tooltip>
  );
};
