import { Button, Grid } from "@material-ui/core";
import { FormikNumberField, FormikSelectField } from "@vrize/vrizead-use";
import { Form, Formik, FormikConfig } from "formik";
import * as React from "react";

import {
  AdSlotPlatform,
  AdSlotSspProvider,
} from "~/__relay_artifacts__/PlacementCreateButton_ad.graphql";

export type FormValues = {
  sspProvider: AdSlotSspProvider | "__none__";
  platform: AdSlotPlatform | "__none__";
  width: number | null;
  height: number | null;
};

export type Props = {
  initialValues: FormValues;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const sspProviderOptions = [
  { label: "ALL", value: "__none__" },
  { label: "ADSTIR", value: "ADSTIR" },
  { label: "ADX", value: "ADX" },
  { label: "VRIZEAD", value: "VRIZEAD" },
  { label: "PROFITX", value: "PROFITX" },
  { label: "GENIEE", value: "GENIEE" },
  { label: "FLUCT", value: "FLUCT" },
  { label: "GMO", value: "GMO" },
  { label: "OPENX", value: "OPENX" },
  { label: "SUPERSHIP", value: "SUPERSHIP" },
  { label: "INMOBI", value: "INMOBI" },
  { label: "BIDSWITCH_GMO", value: "BIDSWITCH_GMO" },
  { label: "BIDSWITCH_GENIEE", value: "BIDSWITCH_GENIEE" },
  { label: "BIDSWITCH_SCALEOUT", value: "BIDSWITCH_SCALEOUT" },
  { label: "BIDSWITCH_MICROAD", value: "BIDSWITCH_MICROAD" },
];

const platformOptions = [
  { value: "__none__", label: "未選択" },
  { value: "SITE", label: "Site" },
  { value: "APP", label: "App" },
];

export const AdSlotFilterForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
}) => (
  <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            displayEmpty
            name="sspProvider"
            label="プロバイダ"
            options={sspProviderOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            displayEmpty
            name="platform"
            label="プラットフォーム"
            options={platformOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField fullWidth name="width" label="幅" />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField fullWidth name="height" label="高さ" />
        </Grid>
        <Button fullWidth type="submit" color="primary" variant="contained">
          適用する
        </Button>
      </Grid>
    </Form>
  </Formik>
);
