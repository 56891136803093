import {
  CacheConfig,
  Environment,
  Network,
  QueryResponseCache,
  RecordSource,
  Store,
} from "relay-runtime";

import { FIREBASE_ID_TOKEN } from "./constants";

const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

const cache = new QueryResponseCache({
  size: 20000,
  ttl: TWENTY_FOUR_HOURS,
});

const fetchQuery = async (
  operation: any,
  variables: any,
  cacheConfig: CacheConfig
) => {
  const queryID = operation.text;
  const isMutation = operation.operationKind === "mutation";
  const isQuery = operation.operationKind === "query";
  const forceFetch = cacheConfig && cacheConfig.force;
  const fromCache = cache.get(queryID, variables);

  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  const response = await fetch(process.env.REACT_APP_RELAY_ENDPOINT!, {
    method: "POST",
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem(FIREBASE_ID_TOKEN)}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) throw new Error(response.statusText);

  const json = await response.json();
  if (
    // NOTE: サーバーサイドでランタイムエラーが起きた場合のレスポンスをハンドリングする
    json &&
    json.errors &&
    json.errors[0] &&
    json.errors[0].extensions &&
    (json.errors[0].extensions.code === "SERVICE_UNAVAILABLE" ||
      json.errors[0].extensions.code === "MAINTENANCE_ERROR" ||
      json.errors[0].extensions.code === "REPORT_GENERATION_FAILED")
  ) {
    // エラーが発生した場合、useQuery から取得できる `error` に以下の例外が格納される
    throw new Error(
      `${json.errors[0].extensions.code}: ${json.errors[0].message}`
    );
  }
  if (isQuery && json) cache.set(queryID, variables, json);
  if (isMutation) cache.clear();
  return json;
};

export const modernEnvironment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});
