/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignReportFormContainer_project = {
    readonly id: string;
    readonly campaigns: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly fee: number | null;
                readonly promotion: {
                    readonly id: string;
                } | null;
            } | null;
        } | null> | null;
    };
    readonly promotions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
            } | null;
        } | null> | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"ReportList_project">;
    readonly " $refType": "CampaignReportFormContainer_project";
};
export type CampaignReportFormContainer_project$data = CampaignReportFormContainer_project;
export type CampaignReportFormContainer_project$key = {
    readonly " $data"?: CampaignReportFormContainer_project$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignReportFormContainer_project">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "withArchived"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignReportFormContainer_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "withArchived",
          "variableName": "withArchived"
        }
      ],
      "concreteType": "CampaignConnection",
      "kind": "LinkedField",
      "name": "campaigns",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fee",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Promotion",
                  "kind": "LinkedField",
                  "name": "promotion",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PromotionConnection",
      "kind": "LinkedField",
      "name": "promotions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PromotionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Promotion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportList_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();
(node as any).hash = 'edfd51c0f3788d4e0ea132b6feef1438';
export default node;
