import { useFormErrorHandler } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { ExpenseForm, FormValues, schema } from "~/components/ExpenseForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createExpenseMutation from "~/mutations/CreateExpenseMutation";

type Props = {
  campaignId: string;
  onSubmitCompleted: () => void;
  selectableBeforeDate: boolean;
};

const initialValues: FormValues = {
  date: new Date(),
  rate: 0,
  maxAssignableExpenseRate: 10,
};

export const ExpenseCreateForm: React.FC<Props> = ({
  campaignId,
  selectableBeforeDate,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const formErrorHandler = useFormErrorHandler();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ date, rate }, { setErrors }) => {
      try {
        const newDate = DateFns.formatISO(date, { representation: "date" });
        const { createExpense } = await createExpenseMutation(
          environment,
          { campaignId, date: newDate, rate },
          {
            connectionName: "ExpenseListTableCard_expenses",
            parentId: campaignId,
          }
        );
        if (!createExpense?.expenseEdge?.node) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("追加費用を作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      campaignId,
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => (
        <ExpenseForm
          {...formikHelpers}
          campaignId={campaignId}
          selectableBeforeDate={selectableBeforeDate}
        />
      )}
    </Formik>
  );
};
