import * as React from "react";
import { graphql } from "react-relay";

import { CampaignList_Query } from "~/__relay_artifacts__/CampaignList_Query.graphql";
import { CampaignList_project } from "~/__relay_artifacts__/CampaignList_project.graphql";
import { CampaignListTableCard } from "~/components/CampaignListTableCard";
import { CreativeListTableCard } from "~/components/CreativeListTableCard";
import { PageHeader } from "~/components/PageHeader";
import ScheduleSetList from "~/components/ScheduleSetList";
import { TrackerList } from "~/components/TrackerList";
import { useFragment } from "~/lib/relay-hooks";
import { useTabs } from "~/lib/useTabs";

type Props = {
  projectRef: CampaignList_Query["response"]["project"];
};

const tabs = [
  { id: "campaigns", label: "キャンペーン一覧" },
  { id: "creatives", label: "クリエイティブ一覧" },
  { id: "schedule_sets", label: "スケジュールセット一覧" },
  { id: "mmp_setting", label: "MMP設定" },
] as const;

const projectFragment = graphql`
  fragment CampaignList_project on Project {
    id
    ...CampaignListTableCard_project
    ...CreativeListTableCard_project
    ...ScheduleSetList_project
    ...TrackerList_project
  }
`;

export const CampaignList: React.FC<Props> = ({ projectRef }) => {
  const { currentTabName, tabsElement } = useTabs(tabs);
  const project = useFragment<CampaignList_project>(
    projectFragment,
    projectRef
  );

  return (
    <>
      <PageHeader title="キャンペーン" tabs={tabsElement} />
      {currentTabName === "campaigns" && (
        <CampaignListTableCard project={project} />
      )}
      {currentTabName === "creatives" && (
        <CreativeListTableCard project={project} />
      )}
      {currentTabName === "schedule_sets" && (
        <ScheduleSetList project={project} />
      )}
      {currentTabName === "mmp_setting" && <TrackerList projectRef={project} />}
    </>
  );
};
