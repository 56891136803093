import { Grid } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  title: string;
  adm: string;
  width?: number;
  height?: number;
  iurl?: string;
  os: "IOS" | "ANDROID" | "UNKNOWN";
  viewableValidationEnabled: boolean;
  tagList: string[];
  note: string | null;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  note: yup.string().required(),
});

export const CreativeNoteForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField
          multiline
          required
          fullWidth
          name="note"
          label="メモ"
          margin="normal"
          rows={20}
        />
      </Grid>
    </Grid>
  </DialogForm>
);
