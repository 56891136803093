import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateExcludeScheduleInput,
  UpdateExcludeScheduleMutation,
} from "~/__relay_artifacts__/UpdateExcludeScheduleMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateExcludeScheduleMutation($input: UpdateExcludeScheduleInput!) {
    updateExcludeSchedule(input: $input) {
      excludeSchedule {
        id
        name
        since
        till
        latestVersion
      }
    }
  }
`;

export const useUpdateExcludeScheduleMutation = () => {
  const environment = useRelayEnvironment();

  const updateExcludeScheduleMutation = React.useCallback(
    (input: UpdateExcludeScheduleInput) =>
      commitMutation<UpdateExcludeScheduleMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateExcludeScheduleMutation };
};
