import { useFormErrorHandler } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { InvoiceEditForm_invoice } from "~/__relay_artifacts__/InvoiceEditForm_invoice.graphql";
import { FormValues, InvoiceForm, schema } from "~/components/InvoiceForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import updateInvoiceMutation from "~/mutations/UpdateInvoiceMutation";

type Props = {
  invoiceRef: FragmentRef<InvoiceEditForm_invoice>;
  onSubmitCompleted: () => void;
};

const fragment = graphql`
  fragment InvoiceEditForm_invoice on Invoice {
    id
    title
    billingDate
    invoiceNumber
    dueDate
    periodSince
    periodTill
  }
`;

export const InvoiceEditForm: React.FC<Props> = ({
  invoiceRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const formErrorHandler = useFormErrorHandler();
  const { currentDate, periodSince, periodTill, dueDate } =
    React.useMemo(() => {
      const currentDate = new Date();
      const lastMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
      );
      return {
        currentDate: currentDate.toISOString(),
        periodSince: DateFns.startOfMonth(lastMonth).toISOString(),
        periodTill: DateFns.lastDayOfMonth(lastMonth).toISOString(),
        dueDate: DateFns.lastDayOfMonth(
          DateFns.addMonths(currentDate, 1)
        ).toISOString(),
      };
    }, []);
  const invoice = useFragment<InvoiceEditForm_invoice>(fragment, invoiceRef);

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: invoice.title || "3D AD配信費用",
      billingDate: invoice.billingDate || currentDate,
      dueDate: invoice.dueDate || dueDate,
      periodSince: invoice.periodSince || periodSince,
      periodTill: invoice.periodTill || periodTill,
      invoiceLines: [],
    }),
    [invoice, currentDate, periodSince, periodTill, dueDate]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        await updateInvoiceMutation(environment, {
          id: invoice.id,
          title: values.title,
          invoiceNumber: invoice.invoiceNumber,
          billingDate: values.billingDate,
          dueDate: values.dueDate,
          periodSince: values.periodSince,
          periodTill: values.periodTill,
          invoiceLines: values.invoiceLines,
        });
        enqueueSnackbar("請求書を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      invoice.id,
      invoice.invoiceNumber,
      environment,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => <InvoiceForm {...formikHelpers} />}
    </Formik>
  );
};
