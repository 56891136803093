import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeletePlacementSetMutation } from "~/__relay_artifacts__/DeletePlacementSetMutation.graphql";

const mutation = graphql`
  mutation DeletePlacementSetMutation($input: DeletePlacementSetInput!) {
    deletePlacementSet(input: $input) {
      deletedPlacementSetId
    }
  }
`;

const commit = (environment: Environment, placementSetId: string) =>
  commitMutation<DeletePlacementSetMutation>(environment, {
    mutation,
    variables: {
      input: { placementSetId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: "client:root",
        connectionKeys: [
          {
            key: "PlacementSetListTableCard_placementSets",
          },
        ],
        pathToConnection: ["root", "PlacementSets"],
        deletedIDFieldName: "deletedPlacementSetId",
      },
    ],
  });

export default commit;
