import { CircularProgress, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  FormikDateField,
  FormikNumberField,
  FormikTextField,
} from "@vrize/vrizead-use";
import { formatNumber } from "accounting";
import * as DateFns from "date-fns";
import { FieldArray, Form, FormikProps } from "formik";
import * as R from "ramda";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";
import * as yup from "yup";

import { InvoiceForm_Query } from "~/__relay_artifacts__/InvoiceForm_Query.graphql";

import { DialogForm } from "./DialogForm";

const useStyles = makeStyles({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "220px",
  },
});

const query = graphql`
  query InvoiceForm_Query(
    $projectId: ID!
    $periodSince: DateTime!
    $periodTill: DateTime!
  ) {
    project(id: $projectId) {
      invoiceLines(periodSince: $periodSince, periodTill: $periodTill) {
        measures
      }
    }
  }
`;

export type FormValues = yup.Asserts<typeof schema>;

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  title: yup.string().required(),
  periodSince: yup.string().required(),
  periodTill: yup.string().required(),
  billingDate: yup.string().required(),
  dueDate: yup.string().required(),
  invoiceLines: yup
    .array(
      yup.object({
        description: yup.string().required(),
        quantity: yup.number().required(),
        unitPrice: yup.string().required(),
      })
    )
    .required(),
});

const itemNameSuffix = (currentDate: Date): string => {
  const targetMonth =
    DateFns.getMonth(currentDate) === 0 ? 12 : DateFns.getMonth(currentDate);
  return R.concat("_", R.concat(targetMonth.toString(), "月配信分"));
};

export const InvoiceForm: React.FC<Props> = ({ values, setFieldValue }) => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const { props } = useQuery<InvoiceForm_Query>(query, {
    projectId,
    periodSince: values.periodSince,
    periodTill: values.periodTill,
  });

  const invoiceLines = React.useMemo(() => {
    if (!props) return [];
    if (!props.project) return [];
    return props.project.invoiceLines.measures.map((item) => {
      return {
        description: R.concat(item[0], itemNameSuffix(new Date())),
        quantity: 1,
        unitPrice: item[1],
      };
    });
  }, [props]);

  React.useEffect(() => {
    setFieldValue("invoiceLines", invoiceLines);
  }, [invoiceLines, setFieldValue]);

  return (
    <DialogForm>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormikTextField
              required
              fullWidth
              name="title"
              label="件名"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <FormikDateField
              required
              fullWidth
              name="billingDate"
              label="請求日"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <FormikDateField
              required
              fullWidth
              name="dueDate"
              label="お支払い期限日"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <FormikDateField
              required
              fullWidth
              name="periodSince"
              label="期間（始め）"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <FormikDateField
              required
              fullWidth
              name="periodTill"
              label="期間（終わり）"
              margin="normal"
            />
          </Grid>
        </Grid>
        <FieldArray
          name="invoiceLines"
          render={(_) => (
            <>
              {values.invoiceLines.length > 0 ? (
                values.invoiceLines.map((item, index) => (
                  <Grid container spacing={3} key={index}>
                    <Grid item xs={12}>
                      <FormikTextField
                        required
                        fullWidth
                        name={`invoiceLines[${index}].description`}
                        label="品目"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        required
                        fullWidth
                        name={`invoiceLines[${index}].unitPrice`}
                        label="単価"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikNumberField
                        required
                        fullWidth
                        name={`invoiceLines[${index}].quantity`}
                        label="数量1"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        required
                        fullWidth
                        disabled
                        name="price"
                        label="価格"
                        margin="normal"
                        value={
                          item.unitPrice && item.quantity
                            ? formatNumber(
                                Number(item.unitPrice) * Number(item.quantity)
                              )
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <div className={classes.circularProgressWrapper}>
                  <CircularProgress variant="indeterminate" size="60px" />
                </div>
              )}
            </>
          )}
        />
      </Form>
    </DialogForm>
  );
};
