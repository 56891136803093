import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreatePlacementSettingInput,
  CreatePlacementSettingMutation,
} from "~/__relay_artifacts__/CreatePlacementSettingMutation.graphql";

const mutation = graphql`
  mutation CreatePlacementSettingMutation(
    $input: CreatePlacementSettingInput!
  ) {
    createPlacementSetting(input: $input) {
      placementSettingEdge {
        node {
          id
          adSlot {
            id
            name
            width
            height
            tagId
            bundle
            note
            bannerFormat
            platform
            sspProvider
            targetWinRate
          }
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreatePlacementSettingInput) =>
  commitMutation<CreatePlacementSettingMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.placementSetId,
        connectionInfo: [
          {
            key: "PlacementSettingListTableCard_placementSettings",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "placementSettingEdge",
      },
    ],
  });

export default commit;
