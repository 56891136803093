/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type CopyCampaignInput = {
    clientMutationId?: string | null;
    campaignId: string;
};
export type CopyCampaignMutationVariables = {
    input: CopyCampaignInput;
};
export type CopyCampaignMutationResponse = {
    readonly copyCampaign: {
        readonly campaignEdge: {
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly status: CampaignStatus;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly adomain: string | null;
                readonly ads: {
                    readonly totalCount: number;
                };
                readonly os: Os | null;
                readonly osVersionMin: string | null;
                readonly " $fragmentRefs": FragmentRefs<"CampaignEditForm_campaign">;
            } | null;
        } | null;
    } | null;
};
export type CopyCampaignMutation = {
    readonly response: CopyCampaignMutationResponse;
    readonly variables: CopyCampaignMutationVariables;
};



/*
mutation CopyCampaignMutation(
  $input: CopyCampaignInput!
) {
  copyCampaign(input: $input) {
    campaignEdge {
      node {
        id
        title
        status
        createdAt
        updatedAt
        adomain
        ads {
          totalCount
        }
        os
        osVersionMin
        ...CampaignEditForm_campaign
      }
    }
  }
}

fragment CampaignEditForm_campaign on Campaign {
  id
  title
  viewTrackingUrl
  clickTrackingUrl
  status
  bidStrategy
  reportingOption
  goalAmount
  note
  adomain
  fee
  feeMin
  frequencyCapEnabled
  frequencyCapAmount
  frequencyCapInterval
  periodSince
  periodTill
  os
  osVersionMin
  isBanditEnabled
  latestVersion
  isAutoRefreshPlacementsEnabled
  isPacingEnabled
  isPricingExplorationEnabled
  isCarryoverEnabled
  dailyBudget {
    id
    date
    baseAmount
  }
  monthlyBudget {
    id
    date
    amount
  }
  costCpi
  categories
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adomain",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AdConnection",
  "kind": "LinkedField",
  "name": "ads",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "os",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "osVersionMin",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CopyCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyCampaignPayload",
        "kind": "LinkedField",
        "name": "copyCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignEdge",
            "kind": "LinkedField",
            "name": "campaignEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CampaignEditForm_campaign"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CopyCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyCampaignPayload",
        "kind": "LinkedField",
        "name": "copyCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignEdge",
            "kind": "LinkedField",
            "name": "campaignEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewTrackingUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clickTrackingUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bidStrategy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportingOption",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goalAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fee",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feeMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequencyCapEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequencyCapAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequencyCapInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodSince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodTill",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBanditEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAutoRefreshPlacementsEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPacingEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPricingExplorationEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCarryoverEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DailyBudget",
                    "kind": "LinkedField",
                    "name": "dailyBudget",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baseAmount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MonthlyBudget",
                    "kind": "LinkedField",
                    "name": "monthlyBudget",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "costCpi",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "categories",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "551769073729aff42b37737d54f8dd83",
    "id": null,
    "metadata": {},
    "name": "CopyCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation CopyCampaignMutation(\n  $input: CopyCampaignInput!\n) {\n  copyCampaign(input: $input) {\n    campaignEdge {\n      node {\n        id\n        title\n        status\n        createdAt\n        updatedAt\n        adomain\n        ads {\n          totalCount\n        }\n        os\n        osVersionMin\n        ...CampaignEditForm_campaign\n      }\n    }\n  }\n}\n\nfragment CampaignEditForm_campaign on Campaign {\n  id\n  title\n  viewTrackingUrl\n  clickTrackingUrl\n  status\n  bidStrategy\n  reportingOption\n  goalAmount\n  note\n  adomain\n  fee\n  feeMin\n  frequencyCapEnabled\n  frequencyCapAmount\n  frequencyCapInterval\n  periodSince\n  periodTill\n  os\n  osVersionMin\n  isBanditEnabled\n  latestVersion\n  isAutoRefreshPlacementsEnabled\n  isPacingEnabled\n  isPricingExplorationEnabled\n  isCarryoverEnabled\n  dailyBudget {\n    id\n    date\n    baseAmount\n  }\n  monthlyBudget {\n    id\n    date\n    amount\n  }\n  costCpi\n  categories\n}\n"
  }
};
})();
(node as any).hash = '1c0c2f5e531bb4e22c0f20f72c1de113';
export default node;
