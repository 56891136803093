import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FC, useCallback, useState } from "react";

import {
  CreativePreview,
  Props as CreativePreviewProps,
} from "~/components/CreativePreview";

type Props = {
  html: CreativePreviewProps["html"];
  height: CreativePreviewProps["height"];
  width: CreativePreviewProps["width"];
  disabled: boolean;
};

const useStyles = makeStyles({
  disableFrame: {
    border: "solid 1px #C0C0C0",
  },
  disableText: {
    color: "#AAAAAA",
  },
  button: {
    marginRight: 10,
  },
});

export const CreativePreviewButton: FC<Props> = ({
  html,
  height,
  width,
  disabled,
}) => {
  const classes = useStyles();
  const [playing, setPlaying] = useState(false);
  const [displayed, setDisplayed] = useState(false);

  const handleButtonClick = useCallback(() => {
    setDisplayed(true);
    setPlaying(false);
    setInterval(() => setPlaying(true), 0);
  }, []);

  return disabled ? (
    <Box
      display="flex"
      p={1}
      justifyContent="center"
      className={classes.disableFrame}
      width={100}
    >
      <Box className={classes.disableText}>no preview</Box>
    </Box>
  ) : (
    <Box display="flex" alignItems="center">
      <Button
        variant="outlined"
        className={classes.button}
        onClick={handleButtonClick}
      >
        {!displayed ? "プレビュー表示" : "再生"}
      </Button>
      {displayed && (
        <CreativePreview
          html={html}
          width={width}
          height={height}
          playing={playing}
        />
      )}
    </Box>
  );
};
