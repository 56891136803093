import { Avatar, Chip, ListItemText, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import * as React from "react";

import { parseISO } from "~/lib/parseISO";

import { CreativePreviewDialog } from "./CreativePreviewDialog";

type Props = {
  id: string;
  title: string;
  createdAt: string;
  html: string | null;
  height: number | null;
  width: number | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
  chipWrapper: {
    width: 150,
  },
}));

export const CreativeLabelItem: React.FC<Props> = React.memo(
  ({ id, title, createdAt, html, height, width }) => {
    const classes = useStyles();
    const [dialogOpen, openDialog, closeDialog] = useOpenState();
    const formattedCreatedAt = React.useMemo(
      () => parseISO(createdAt),
      [createdAt]
    );

    return (
      <>
        <div className={classes.chipWrapper}>
          <Chip
            variant="outlined"
            size="small"
            className={classes.chip}
            avatar={<Avatar>ID</Avatar>}
            label={atob(id)}
          />
        </div>
        <ListItemText
          primary={title}
          secondary={
            <>
              作成日: {formattedCreatedAt} |{" "}
              <span onClick={openDialog}>プレビューを開く</span>
            </>
          }
        />
        {html && height && width && (
          <CreativePreviewDialog
            open={dialogOpen}
            html={html}
            height={height}
            width={width}
            onClose={closeDialog}
          />
        )}
      </>
    );
  }
);
