/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MaintenanceEditForm_maintenance = {
    readonly id: string;
    readonly message: string;
    readonly " $refType": "MaintenanceEditForm_maintenance";
};
export type MaintenanceEditForm_maintenance$data = MaintenanceEditForm_maintenance;
export type MaintenanceEditForm_maintenance$key = {
    readonly " $data"?: MaintenanceEditForm_maintenance$data;
    readonly " $fragmentRefs": FragmentRefs<"MaintenanceEditForm_maintenance">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MaintenanceEditForm_maintenance",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Maintenance",
  "abstractKey": null
};
(node as any).hash = '716e72ff33e22490d7b94c05503f14cf';
export default node;
