import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { useCreateMessageMutation } from "~/mutations/CreateMessageMutation";

import { MessageForm } from "./MessageForm";
import { FormValues, schema } from "./schema";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues = {
  body: "",
};

export const MessageCreateForm: React.FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { createMessageMutation } = useCreateMessageMutation();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createMessage } = await createMessageMutation(values);
        if (!createMessage?.messageEdge) throw new Error("assertion failed");
        enqueueSnackbar("メッセージを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      createMessageMutation,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(formikHelpers) => <MessageForm {...formikHelpers} />}
    </Formik>
  );
};
