import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteCampaignAllowIpMutation } from "~/__relay_artifacts__/DeleteCampaignAllowIpMutation.graphql";

const mutation = graphql`
  mutation DeleteCampaignAllowIpMutation(
    $connections: [ID!]!
    $input: DeleteCampaignAllowIpInput!
  ) {
    deleteCampaignAllowIp(input: $input) {
      deletedCampaignAllowIpId @deleteEdge(connections: $connections)
    }
  }
`;

export const deleteCampaignAllowIpMutation = (
  environment: Environment,
  campaignAllowIpId: string,
  connectionID: string
) =>
  commitMutation<DeleteCampaignAllowIpMutation>(environment, {
    mutation,
    variables: {
      input: { campaignAllowIpId },
      connections: [connectionID],
    },
  });
