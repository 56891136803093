import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  RefreshAdPlacementsInput,
  RefreshAdPlacementsMutation,
} from "~/__relay_artifacts__/RefreshAdPlacementsMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation RefreshAdPlacementsMutation($input: RefreshAdPlacementsInput!) {
    refreshAdPlacements(input: $input) {
      ad {
        placements {
          edges {
            node {
              id
              correctionTerm
              isEnabled
              proportion
              latestVersion
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;

export const useRefreshAdPlacementsMutation = () => {
  const environment = useRelayEnvironment();

  const refreshAdPlacementsMutation = useCallback(
    (input: RefreshAdPlacementsInput) =>
      commitMutation<RefreshAdPlacementsMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { refreshAdPlacementsMutation };
};
