import { useSnackbar } from "notistack";
import { pick } from "ramda";
import * as React from "react";

import {
  EditableTextField,
  Props as EditableTextFieldProps,
} from "~/components/EditableTextField";
import { useUpdatePlacementMutation } from "~/mutations/UpdatePlacementMutation";

import { StyledEditableTextFieldWrapper } from "./styles";
import { PartialPlacement } from "./types";

type Props = {
  placement: PartialPlacement;
};

export const ProportionField: React.FC<Props> = ({ placement }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updatePlacementMutation } = useUpdatePlacementMutation();

  const handleSubmit = React.useCallback<EditableTextFieldProps["onSubmit"]>(
    async ({ input }, { resetForm }) => {
      try {
        if (input === placement.proportion) return;
        if (typeof input !== "number") throw new Error("assertion error");
        await updatePlacementMutation({
          ...pick(["id", "correctionTerm", "proportion"], placement),
          clientVersion: placement.latestVersion,
          proportion: input,
        });
        enqueueSnackbar("配信割合を更新しました。", { variant: "success" });
      } catch (_) {
        enqueueSnackbar("配信割合の更新に失敗しました。", { variant: "error" });
        resetForm();
      }
    },
    [enqueueSnackbar, placement, updatePlacementMutation]
  );

  return (
    <StyledEditableTextFieldWrapper>
      <EditableTextField
        type="number"
        value={placement.proportion}
        onSubmit={handleSubmit}
      />
    </StyledEditableTextFieldWrapper>
  );
};
