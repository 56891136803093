/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignDetail_project = {
    readonly campaign: {
        readonly id: string;
        readonly title: string;
        readonly " $fragmentRefs": FragmentRefs<"CampaignEditForm_campaign" | "AdListTableCard_campaign" | "ExpenseListTableCard_campaign" | "CampaignAudits_campaign" | "ExcludeScheduleListTableCard_campaign" | "DailyBudgetListDataSheet_campaign" | "MonthlyBudgetListTableCard_campaign" | "CampaignRestrictionListCard_campaign" | "CampaignDebugSettingCard_campaign">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CampaignSetting_project">;
    readonly " $refType": "CampaignDetail_project";
};
export type CampaignDetail_project$data = CampaignDetail_project;
export type CampaignDetail_project$key = {
    readonly " $data"?: CampaignDetail_project$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignDetail_project">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "campaignId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignDetail_project",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "campaignId"
        }
      ],
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CampaignEditForm_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdListTableCard_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpenseListTableCard_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CampaignAudits_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExcludeScheduleListTableCard_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DailyBudgetListDataSheet_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MonthlyBudgetListTableCard_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CampaignRestrictionListCard_campaign"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CampaignDebugSettingCard_campaign"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignSetting_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
(node as any).hash = '435ec9c69cadb839469cc20c7bd3d776';
export default node;
