import {
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { graphql } from "react-relay";
import { Link } from "react-router-dom";

import { ClientCampaignListTableCard_project } from "~/__relay_artifacts__/ClientCampaignListTableCard_project.graphql";
import { ClientCampaignList_Query } from "~/__relay_artifacts__/ClientCampaignList_Query.graphql";
import { ListTable } from "~/components/ListTable";
import { TableCell } from "~/components/atoms/TableCell";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  projectRef: ClientCampaignList_Query["response"]["project"];
};

const fragment = graphql`
  fragment ClientCampaignListTableCard_project on Project {
    id
    campaigns(first: $first, after: $after) {
      edges {
        node {
          id
          title
        }
      }
      totalCount
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  loadButton: {
    marginTop: 5,
    marginBottom: 5,
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const ClientCampaignListTableCard: React.FC<Props> = ({
  projectRef,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);

  const project = useFragment<ClientCampaignListTableCard_project>(
    fragment,
    projectRef
  );
  const totalCount = project.campaigns.totalCount;

  const campaigns = React.useMemo(() => {
    const edges = (project.campaigns && project.campaigns.edges) || [];
    return edges.slice(page * perPage, page * perPage + perPage).map((edge) => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [page, perPage, project.campaigns]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          キャンペーン一覧
        </Typography>
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>タイトル</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign.id}>
              <TableCell>
                <Link to={`daily_budgets/${campaign.id}`}>
                  {campaign.title}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(event) => setPerPage(Number(event.target.value))}
      />
    </Paper>
  );
};
