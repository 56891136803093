import { useSnackbar } from "notistack";
import * as React from "react";

import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { modernEnvironment } from "~/lib/Environment";
import deleteScheduleMutation from "~/mutations/DeleteScheduleMutation";

type Props = {
  scheduleSetId: string;
  scheduleId: string;
};

export const ScheduleDeleteButton: React.FC<Props> = ({
  children,
  scheduleId,
  scheduleSetId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = React.useCallback(async () => {
    try {
      const { deleteSchedule } = await deleteScheduleMutation(
        modernEnvironment,
        scheduleSetId,
        scheduleId
      );
      const id = deleteSchedule?.deletedScheduleId;
      if (!id) throw new Error("assertion failed");
      enqueueSnackbar(`スケジュールを削除しました。`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [enqueueSnackbar, scheduleId, scheduleSetId]);

  return (
    <ConfirmButton
      variant="outlined"
      color="secondary"
      confirmTitle="このスケジュールを削除しますか？"
      confirmContent=""
      onAgree={(changeDialog) => {
        onDelete().then(() => changeDialog(false));
      }}
    >
      {children}
    </ConfirmButton>
  );
};
