import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as React from "react";

type Props = {
  id: string;
  title: string;
  selected?: boolean;
  onAddClick: (id: Props["id"]) => void;
};

const StyledListItem = styled(ListItem)({
  minWidth: 700,
});

const StyledAddButton = styled(Button)({
  whiteSpace: "nowrap",
  margin: 3,
});

export const CampaignListItem: React.FC<Props> = ({
  id,
  title,
  selected = false,
  onAddClick,
}) => {
  const handleButtonClick = React.useCallback(() => {
    onAddClick(id);
  }, [id, onAddClick]);

  return (
    // @ts-expect-error
    <StyledListItem selected={selected}>
      <ListItemText>
        <Typography>{title}</Typography>
      </ListItemText>
      <Box display="flex" flexDirection="column" width={100}>
        <StyledAddButton
          variant="outlined"
          color="primary"
          disabled={selected}
          onClick={handleButtonClick}
        >
          {selected ? "選択済み" : "追加"}
        </StyledAddButton>
      </Box>
    </StyledListItem>
  );
};
