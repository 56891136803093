import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateAdInput,
  CreateAdMutation,
} from "~/__relay_artifacts__/CreateAdMutation.graphql";
import { ConnectionUpdaterOptions } from "~/lib/mutationUtils";

const mutation = graphql`
  mutation CreateAdMutation($input: CreateAdInput!) {
    createAd(input: $input) {
      adEdge {
        node {
          id
          title
          adomain
          status
          createdAt
          placements {
            totalCount
          }
          ...AdEditForm_ad
        }
      }
    }
  }
`;

// TODO: do not use type assertion
const configs = (options?: ConnectionUpdaterOptions): any => {
  return !!options
    ? [
        {
          type: "RANGE_ADD",
          parentID: options.parentId,
          connectionInfo: [
            {
              key: options.connectionName,
              rangeBehavior: "append",
            },
          ],
          edgeName: "adEdge",
        },
      ]
    : undefined;
};

const commit = (
  environment: Environment,
  input: CreateAdInput,
  options?: ConnectionUpdaterOptions
) =>
  commitMutation<CreateAdMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: configs(options),
  });

export default commit;
