import { Button, MenuItem, Select, TextField, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { PlacementBulkCreateButton_Query } from "~/__relay_artifacts__/PlacementBulkCreateButton_Query.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ResponsiveDialog } from "~/components/ResponsiveDialog";
import { useCreatePlacementMutation } from "~/mutations/CreatePlacementMutation";

import { CampaignAdList } from "./CampaignAdList";
import {
  PlacementCreateForm,
  Props as PlacementCreateFormProps,
} from "./PlacementCreateForm";

type Props = {
  usedAdIds: string[];
  onSubmitCompleted: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    marginRight: theme.spacing(1),
  },
}));

const query = graphql`
  query PlacementBulkCreateButton_Query(
    $id: ID!
    $os: Os
    $width: Int
    $height: Int
    $first: Int
    $after: String
  ) {
    adSlot(id: $id) {
      id
      sspProvider
    }
    ...CampaignAdList_root
  }
`;

export const PlacementBulkCreateButton: React.FC<Props> = ({
  usedAdIds,
  onSubmitCompleted,
}) => {
  const classes = useStyles();
  const { adSlotId } = useParams<{ adSlotId: string }>();
  const [width, setWidth] = React.useState<number | null>(null);
  const [height, setHeight] = React.useState<number | null>(null);
  const [os, setOs] = React.useState<"IOS" | "ANDROID" | null>(null);
  const { props } = useQuery<PlacementBulkCreateButton_Query>(query, {
    id: adSlotId,
    width,
    height,
    os,
    first: 100,
  });
  const { createPlacementMutation } = useCreatePlacementMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAdIds, setSelectedAdIds] = React.useState<string[]>([]);
  const [dialogOpen, openDialog, closeDialog] = useOpenState(false);

  const handleAdAddClick = React.useCallback(
    (adId: string) =>
      setSelectedAdIds(
        selectedAdIds.includes(adId)
          ? selectedAdIds.filter((id) => id !== adId)
          : [...selectedAdIds, adId]
      ),
    [selectedAdIds]
  );

  const handleSubmit = React.useCallback<PlacementCreateFormProps["onSubmit"]>(
    async ({ correctionTerm, proportion }) => {
      try {
        await Promise.all(
          selectedAdIds.map((adId) =>
            createPlacementMutation({
              adId,
              adSlotId,
              correctionTerm,
              proportion,
            })
          )
        );
        enqueueSnackbar(`配信候補を${selectedAdIds.length}件追加しました`, {
          variant: "success",
        });
        setSelectedAdIds([]);
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar("配信候補の追加に失敗しました", { variant: "error" });
      }
    },
    [
      adSlotId,
      createPlacementMutation,
      enqueueSnackbar,
      onSubmitCompleted,
      selectedAdIds,
    ]
  );

  const handleWidthChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => setWidth(parseInt(e.target.value)), []);

  const handleHeightChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => setHeight(parseInt(e.target.value)), []);

  const handleOsChange = React.useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      if (event.target.value === "IOS" || event.target.value === "ANDROID") {
        setOs(event.target.value);
      } else {
        setOs(null);
      }
    },
    []
  );

  return (
    <DialogButton
      fullScreen
      withMargin={false}
      enableAlert={false}
      title="プレイスメント作成"
      toolbarContent={
        <>
          <Select
            className={classes.formField}
            placeholder="OS"
            onChange={handleOsChange}
            value={os}
          >
            <MenuItem value={""}>None</MenuItem>
            <MenuItem value={"IOS"}>IOS</MenuItem>
            <MenuItem value={"ANDROID"}>ANDROID</MenuItem>
          </Select>
          <TextField
            value={width}
            type="number"
            className={classes.formField}
            placeholder="Width"
            onChange={handleWidthChange}
          />
          <TextField
            value={height}
            type="number"
            className={classes.formField}
            placeholder="Height"
            onChange={handleHeightChange}
          />
          <Button variant="contained" onClick={openDialog}>
            選択を確定する
          </Button>
        </>
      }
      render={({ close }) => (
        <>
          {!props ? (
            <></>
          ) : (
            <CampaignAdList
              rootRef={props}
              selectedAdIds={selectedAdIds}
              onAdAddClick={handleAdAddClick}
              searchParams={{
                os: os,
                width: width,
                height: height,
              }}
              usedAdIds={usedAdIds}
            />
          )}
          {props?.adSlot && (
            <ResponsiveDialog
              fullScreen
              title="パラメータ入力"
              open={dialogOpen}
              onClose={closeDialog}
            >
              <PlacementCreateForm
                onSubmit={handleSubmit}
                onSubmitCompleted={() => {
                  closeDialog();
                  close();
                }}
              />
            </ResponsiveDialog>
          )}
        </>
      )}
    >
      一括作成
    </DialogButton>
  );
};
