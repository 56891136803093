import { Switch } from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";

import { useUpdateCampaignStatusMutation } from "~/mutations/UpdateCampaignStatusMutation";

import { Campaign } from "./types";
import { useValidationErrorHandler } from "./useValidationErrorHandler";

type Props = {
  campaign: Campaign;
};

export const StatusSwitch: React.FC<Props> = ({ campaign }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleValidationError = useValidationErrorHandler();
  const { updateCampaignStatusMutation } = useUpdateCampaignStatusMutation();

  const handleChange = React.useCallback(async () => {
    try {
      await updateCampaignStatusMutation({
        id: campaign.id,
        status: campaign.status === "ACTIVE" ? "STOPPED" : "ACTIVE",
        clientVersion: campaign.latestVersion,
      });
      enqueueSnackbar("ステータスを更新しました。", { variant: "success" });
    } catch (err) {
      handleValidationError(err);
    }
  }, [
    updateCampaignStatusMutation,
    campaign,
    enqueueSnackbar,
    handleValidationError,
  ]);

  return (
    <Switch checked={campaign.status === "ACTIVE"} onChange={handleChange} />
  );
};
