import { Buffer } from "buffer";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useOpenState } from "@vrize/vrizead-use";
import { useSnackbar } from "notistack";
import { ChangeEventHandler, VFC, useCallback, useMemo, useState } from "react";
import { FragmentRef, graphql } from "relay-runtime";

import { CampaignDenyTagIdListCard_campaign } from "~/__relay_artifacts__/CampaignDenyTagIdListCard_campaign.graphql";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import { createCampaignDenyTagIdMutation } from "~/mutations/CreateCampaignDenyTagIdMutation";
import { deleteCampaignDenyTagIdMutation } from "~/mutations/DeleteCampaignDenyTagIdMutation";

import { ListTable } from "../ListTable";

type ValidationError = {
  message: string;
  path: any;
};

type Props = {
  campaignRef: FragmentRef<CampaignDenyTagIdListCard_campaign>;
};

const fragment = graphql`
  fragment CampaignDenyTagIdListCard_campaign on Campaign {
    id
    campaignDenyTagIds(first: 150)
      @connection(key: "CampaignDenyTagIdListCard_campaignDenyTagIds") {
      __id
      edges {
        node {
          id
          tagId
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  paper: { margin: theme.spacing(3) },
}));

export const CampaignDenyTagIdListCard: VFC<Props> = ({ campaignRef }) => {
  const classes = useStyles();
  const [alertDialogOpen, openAlertDialog, closeAlertDialog] = useOpenState();
  const [formDialogOpen, openFormDialog, closeFormDialog] = useOpenState();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [deleteId, setDeleteId] = useState("");

  const campaign = useFragment<CampaignDenyTagIdListCard_campaign>(
    fragment,
    campaignRef
  );

  const campaignDenyTagIds = useMemo(() => {
    return (campaign.campaignDenyTagIds.edges || []).map((edge) => {
      if (!edge?.node) throw new Error("assertion error");
      return edge?.node;
    });
  }, [campaign.campaignDenyTagIds.edges]);

  const handleCreate = useCallback<CreateDialogProps["onCreate"]>(
    async (input) => {
      try {
        await createCampaignDenyTagIdMutation(
          environment,
          {
            ...input,
            campaignId: campaign.id,
          },
          campaign.campaignDenyTagIds.__id,
          "CampaignDenyTagIdEdge"
        );
        enqueueSnackbar("配信禁止タグIDを追加しました", { variant: "success" });
        closeFormDialog();
      } catch (err: any) {
        const errors = err.extensions.validationErrors as ValidationError[];
        errors.forEach((e) =>
          enqueueSnackbar(`${err.extensions.code}: ${e.message}`, {
            variant: "error",
          })
        );
      }
    },
    [
      campaign.campaignDenyTagIds.__id,
      campaign.id,
      closeFormDialog,
      enqueueSnackbar,
      environment,
    ]
  );
  const handleDelete = useCallback(async () => {
    try {
      await deleteCampaignDenyTagIdMutation(
        environment,
        deleteId,
        campaign.campaignDenyTagIds.__id
      );
      enqueueSnackbar("配信禁止タグIDを削除しました", {
        variant: "success",
      });
      closeAlertDialog();
      setDeleteId("");
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  }, [
    campaign.campaignDenyTagIds.__id,
    closeAlertDialog,
    deleteId,
    enqueueSnackbar,
    environment,
  ]);

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant="subtitle1">配信禁止タグID</Typography>
        <Button onClick={openFormDialog}>追加</Button>
      </Toolbar>
      <ListTable minWidth={500}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>tag_id</TableCell>
            <TableCell align="center">アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaignDenyTagIds.map((denyTagId) => (
            <TableRow key={denyTagId.id}>
              <TableCell>
                {Buffer.from(denyTagId.id, "base64").toString()}
              </TableCell>
              <TableCell>{denyTagId.tagId}</TableCell>
              <TableCell align="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    openAlertDialog();
                    setDeleteId(denyTagId.id);
                  }}
                >
                  削除
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <DeleteDialog
        isOpen={alertDialogOpen}
        onDelete={handleDelete}
        onClose={closeAlertDialog}
      />
      <CreateDialog
        isOpen={formDialogOpen}
        onCreate={handleCreate}
        onClose={closeFormDialog}
      />
    </Paper>
  );
};

type CreateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (input: { tagId: string }) => void;
};

const CreateDialog: VFC<CreateDialogProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const [tagIdText, setTagIdText] = useState("");
  const [tagIdValidation, setTagIdValidation] = useState(false);

  const handleTagIdTextChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      setTagIdText(e.currentTarget.value);
      if (tagIdText !== "") setTagIdValidation(false);
    },
    [tagIdText]
  );

  const handleCreate = useCallback(() => {
    if (tagIdText === "") {
      setTagIdValidation(false);
    } else {
      onCreate({ tagId: tagIdText });
      setTagIdText("");
    }
  }, [tagIdText, onCreate]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>配信禁止タグID設定の追加</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="tag_id"
            value={tagIdText}
            error={tagIdValidation}
            onChange={handleTagIdTextChange}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCreate}>
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type DeleteDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteDialog: VFC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  onDelete,
}) => {
  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>配信禁止タグIDを削除しますか？</DialogTitle>
        <DialogActions>
          <Button autoFocus color="primary" onClick={onClose}>
            キャンセル
          </Button>
          <Button color="secondary" onClick={onDelete}>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
