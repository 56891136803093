/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateInvoiceInput = {
    clientMutationId?: string | null;
    projectId: string;
    title?: string | null;
    periodSince: string;
    periodTill: string;
    billingDate?: string | null;
    dueDate?: string | null;
    invoiceLines?: Array<InvoiceLineInput> | null;
};
export type InvoiceLineInput = {
    description: string;
    unitPrice: string;
    quantity: number;
};
export type CreateInvoiceMutationVariables = {
    input: CreateInvoiceInput;
};
export type CreateInvoiceMutationResponse = {
    readonly createInvoice: {
        readonly invoiceEdge: {
            readonly node: {
                readonly id: string;
                readonly freeeInvoiceId: string;
                readonly title: string | null;
                readonly periodSince: string | null;
                readonly periodTill: string | null;
                readonly invoiceNumber: number | null;
                readonly billingDate: string | null;
                readonly dueDate: string | null;
            } | null;
        } | null;
    } | null;
};
export type CreateInvoiceMutation = {
    readonly response: CreateInvoiceMutationResponse;
    readonly variables: CreateInvoiceMutationVariables;
};



/*
mutation CreateInvoiceMutation(
  $input: CreateInvoiceInput!
) {
  createInvoice(input: $input) {
    invoiceEdge {
      node {
        id
        freeeInvoiceId
        title
        periodSince
        periodTill
        invoiceNumber
        billingDate
        dueDate
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateInvoicePayload",
    "kind": "LinkedField",
    "name": "createInvoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceEdge",
        "kind": "LinkedField",
        "name": "invoiceEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invoice",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freeeInvoiceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodSince",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodTill",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "invoiceNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billingDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInvoiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInvoiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7c50c84ccf9777c1a2c2af4fdbeae296",
    "id": null,
    "metadata": {},
    "name": "CreateInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation CreateInvoiceMutation(\n  $input: CreateInvoiceInput!\n) {\n  createInvoice(input: $input) {\n    invoiceEdge {\n      node {\n        id\n        freeeInvoiceId\n        title\n        periodSince\n        periodTill\n        invoiceNumber\n        billingDate\n        dueDate\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7ceb3a32ee763808b9739fa2d6f737a4';
export default node;
