import { Box, Tooltip } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import * as React from "react";
import { graphql } from "react-relay";
import { useHistory, useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { AdDetailPage_Query } from "~/__relay_artifacts__/AdDetailPage_Query.graphql";
import { DialogButton } from "~/components/DialogButton";
import { PageHeader } from "~/components/PageHeader";
import { PlacementList } from "~/components/PlacementList";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { AdEditForm } from "~/containers/AdEditForm";
import { usePlacementFilterCache } from "~/globalState";
import { modernEnvironment } from "~/lib/Environment";
import { useTabs } from "~/lib/useTabs";
import deleteAdMutation from "~/mutations/DeleteAdMutation";

import { AdAudits } from "./AdAudits";
import { AdCreativeList } from "./AdCreativeList";
import { AdDetail } from "./AdDetail";

type PageContentProps = {
  project: AdDetailPage_Query["response"]["project"];
};

const tabs = [
  { id: "placements", label: "配信先" },
  { id: "ad_creatives", label: "クリエイティブ" },
  { id: "settings", label: "設定" },
  { id: "ad_audits", label: "変更履歴" },
] as const;

const PageContent: React.FC<PageContentProps> = ({ project }) => {
  const history = useHistory();
  const { projectId, campaignId, id } = useParams<{
    id: string;
    campaignId: string;
    projectId: string;
  }>();
  const { currentTabName, tabsElement } = useTabs(tabs);
  const ad = React.useMemo(() => {
    if (!project?.ad) throw new Error("assertion error");
    return project.ad;
  }, [project]);

  const handleConfirmationButtonAgree = React.useCallback(async () => {
    if (!ad) return;
    await deleteAdMutation(modernEnvironment, id);
    history.push(`/projects/${projectId}/campaigns/${campaignId}`);
  }, [ad, id, history, projectId, campaignId]);

  return (
    <>
      <PageHeader
        title={
          <Box>
            {ad.campaign.title}
            <Box ml={1} display="inline">
              {">"}
            </Box>
            <Box ml={1} display="inline">
              {ad.title}
            </Box>
            <Box ml={1} display="inline" color="text.hint">
              #{window.atob(ad.id)}
            </Box>
          </Box>
        }
        backTo={`/projects/${projectId}/campaigns/${campaignId}`}
        tabs={tabsElement}
        breadcrumbs={[
          {
            title: "キャンペーン一覧",
            link: `/projects/${projectId}/campaigns`,
          },
          {
            title: "キャンペーン詳細",
            link: `/projects/${projectId}/campaigns/${campaignId}`,
          },
          {
            title: "広告詳細",
            link: `/projects/${projectId}/campaigns/${campaignId}/ads/${id}`,
          },
        ]}
      >
        <Box flexGrow={1} />
        <Tooltip title="編集する">
          <DialogButton
            title="広告編集"
            render={({ close }) => (
              <AdEditForm
                // @ts-expect-error
                adRef={ad}
                onSubmitCompleted={close}
              />
            )}
          >
            <EditIcon />
            編集
          </DialogButton>
        </Tooltip>
        <ConfirmButton
          key="delete"
          color="inherit"
          onAgree={handleConfirmationButtonAgree}
          confirmTitle="この広告を削除しますか？"
        >
          <DeleteIcon />
          削除
        </ConfirmButton>
      </PageHeader>
      {currentTabName === "placements" && (
        <PlacementList ad={ad} projectId={projectId} />
      )}
      {currentTabName === "ad_creatives" && <AdCreativeList adRef={ad} />}
      {currentTabName === "settings" && <AdDetail adRef={ad} />}
      {currentTabName === "ad_audits" && <AdAudits adRef={ad} />}
    </>
  );
};

const query = graphql`
  query AdDetailPage_Query(
    $projectId: ID!
    $adId: ID!
    $isEnabled: Boolean
    $tagId: String
    $first: Int
    $after: String
    $count: Int
    $cursor: String
    $search: String
  ) {
    project(id: $projectId) {
      ad(id: $adId) {
        id
        title
        ...AdDetail_ad
        ...PlacementList_ad
        ...AdEditForm_ad
        ...AdCreativeList_ad
        ...AdAudits_ad
        campaign {
          id
          title
        }
      }
    }
  }
`;

export const AdDetailPage: React.FC = () => {
  const { projectId, id } = useParams<{ id: string; projectId: string }>();
  const [filterCache] = usePlacementFilterCache(projectId, id);
  const { props, error } = useQuery<AdDetailPage_Query>(query, {
    projectId,
    adId: id,
    isEnabled: filterCache.showIsEnabledOnly ? true : undefined,
    first: 100,
    count: 100,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  return <PageContent project={props.project} />;
};
