import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateCreativeTrackerSettingInput,
  UpdateCreativeTrackerSettingMutation,
} from "~/__relay_artifacts__/UpdateCreativeTrackerSettingMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateCreativeTrackerSettingMutation(
    $input: UpdateCreativeTrackerSettingInput!
  ) {
    updateCreativeTrackerSetting(input: $input) {
      creative {
        id
        tracker {
          id
          title
        }
      }
    }
  }
`;

export const useUpdateCreativeTrackerSettingMutation = () => {
  const environment = useRelayEnvironment();

  const updateCreativeTrackerSettingMutation = useCallback(
    (input: UpdateCreativeTrackerSettingInput) =>
      commitMutation<UpdateCreativeTrackerSettingMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateCreativeTrackerSettingMutation };
};
