import { Divider, List } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { PlacementCreateButton_Query } from "~/__relay_artifacts__/PlacementCreateButton_Query.graphql";
import { PlacementCreateButton_ad } from "~/__relay_artifacts__/PlacementCreateButton_ad.graphql";
import { AdSlotListItem } from "~/components/AdSlotListItem";
import { ResponsiveDialog } from "~/components/ResponsiveDialog";
import {
  SearchDialogButton,
  Props as SearchDialogButtonProps,
} from "~/components/SearchDialogButton";
import { usePlacementAdSlotFilterCache } from "~/globalState";
import { useRefetch } from "~/lib/relay-hooks";

import {
  AdSlotFilterForm,
  Props as AdSlotFilterFormProps,
  FormValues,
} from "./AdSlotFilterForm";
import { PlacementCreateForm } from "./PlacementCreateForm";

type Props = {
  onSubmitCompleted?: () => void;
};

const adFragment = graphql`
  fragment PlacementCreateButton_ad on Ad {
    selectableAdSlots(
      first: 150
      sspProvider: $sspProvider
      platform: $platform
      width: $width
      height: $height
      search: $adSlotSearch
    ) {
      edges {
        node {
          id
          name
          tagId
          platform
          sspProvider
          note
          bundle
          width
          height
          adxBillingId
        }
      }
    }
  }
`;

const query = graphql`
  query PlacementCreateButton_Query(
    $projectId: ID!
    $adId: ID!
    $sspProvider: [AdSlotSspProvider!]!
    $platform: AdSlotPlatform
    $width: Int
    $height: Int
    $adSlotSearch: String
  ) {
    project(id: $projectId) {
      ad(id: $adId) {
        ...PlacementCreateButton_ad
      }
    }
  }
`;

const StyledList = styled(List)({
  overflowX: "scroll",
});

export const PlacementCreateButton: React.FC<Props> = ({
  onSubmitCompleted,
}) => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const [dialogOpen, openDialog, closeDialog] = useOpenState();
  const [adSlotSearch, setAdSlotSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [selectedAdSlotId, setSelectedAdSlotId] = React.useState<string | null>(
    null
  );
  const [filterValues, setFilterValues] = usePlacementAdSlotFilterCache(
    projectId,
    id
  );
  const { props } = useQuery<PlacementCreateButton_Query>(query, {
    adId: id,
    projectId,
    sspProvider: [],
  });
  const [ad, refetch] = useRefetch<PlacementCreateButton_ad>(
    adFragment,
    props?.project?.ad!
  );

  const adSlots = React.useMemo(() => {
    const edges = ad?.selectableAdSlots?.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw Error("assertion error");
      return edge.node;
    });
  }, [ad]);

  const selectedAdSlot = React.useMemo(
    () => adSlots.find((adSlot) => adSlot.id === selectedAdSlotId),
    [adSlots, selectedAdSlotId]
  );

  const refetchAdSlot = React.useCallback(
    (values: FormValues, search: string) => {
      setLoading(true);
      refetch<PlacementCreateButton_Query>(
        query,
        {
          projectId,
          adId: id,
          sspProvider:
            values.sspProvider !== "__none__" ? [values.sspProvider] : [],
          platform: values.platform !== "__none__" ? values.platform : null,
          width: values.width,
          height: values.height,
          adSlotSearch: search,
        },
        undefined,
        () => setLoading(false),
        { force: true }
      );
    },
    [id, projectId, refetch]
  );

  const handleFilterSubmit = React.useCallback<
    AdSlotFilterFormProps["onSubmit"]
  >(
    (values) => {
      setFilterValues(values);
      refetchAdSlot(values, adSlotSearch);
    },
    [adSlotSearch, refetchAdSlot, setFilterValues]
  );

  const handleSearchSubmit = React.useCallback<
    SearchDialogButtonProps["onSubmit"]
  >(
    (search) => {
      setAdSlotSearch(search);
      refetchAdSlot(filterValues, search);
    },
    [filterValues, refetchAdSlot]
  );

  const handleAdSlotAddClick = React.useCallback(
    (id: string) => {
      setSelectedAdSlotId(id);
      openDialog();
    },
    [openDialog]
  );

  return (
    <SearchDialogButton
      title="配信先追加"
      placeholder="Tag ID or 名前で検索"
      loading={loading}
      renderFilterContent={({ close }) => (
        <AdSlotFilterForm
          initialValues={filterValues}
          onSubmit={(...args) => {
            handleFilterSubmit(...args);
            close();
          }}
        />
      )}
      renderDialogContent={({ close }) => (
        <StyledList>
          {adSlots.length !== 0 ? (
            adSlots.map((adSlot) => (
              <>
                <AdSlotListItem
                  adSlotId={adSlot.id}
                  adSlotName={adSlot.name || "未設定"}
                  sspProvider={adSlot.sspProvider}
                  tagId={adSlot.tagId || "未設定"}
                  platform={adSlot.platform || "未設定"}
                  adxBillingId={adSlot.adxBillingId || "未設定"}
                  bundle={adSlot.bundle || "未設定"}
                  width={String(adSlot.width) || "未設定"}
                  height={String(adSlot.height) || "未設定"}
                  onAddClick={handleAdSlotAddClick}
                />
                <Divider />
              </>
            ))
          ) : (
            <div style={{ textAlign: "center", verticalAlign: "center" }}>
              検索条件に一致する配信先が存在しません
            </div>
          )}
          <ResponsiveDialog
            open={dialogOpen}
            title="パラメータ入力"
            onClose={closeDialog}
          >
            {selectedAdSlot && (
              <PlacementCreateForm
                adSlotId={selectedAdSlot.id}
                onSubmitCompleted={() => {
                  onSubmitCompleted?.();
                  closeDialog();
                  close();
                }}
              />
            )}
          </ResponsiveDialog>
        </StyledList>
      )}
      onSubmit={handleSearchSubmit}
    >
      作成
    </SearchDialogButton>
  );
};
