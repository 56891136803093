import * as holiday_jp from "@holiday-jp/holiday_jp";
import { Button, Paper, Theme, Toolbar, Typography } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import accounting from "accounting";
import * as DateFns from "date-fns";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { FragmentRef, graphql } from "react-relay";
import { useParams } from "react-router";

import { DailyBudgetListDataSheet_Query } from "~/__relay_artifacts__/DailyBudgetListDataSheet_Query.graphql";
import { DailyBudgetListDataSheet_campaign } from "~/__relay_artifacts__/DailyBudgetListDataSheet_campaign.graphql";
import { DialogButton } from "~/components/DialogButton";
import { useDailyBudgetByMonthCache } from "~/globalState";
import { useRefetch } from "~/lib/relay-hooks";
import { delayChunkPromise } from "~/lib/utils";
import { useDeleteDailyBudgetMutation } from "~/mutations/DeleteDailyBudgetMutation";
import { useUpsertDailyBudgetMutation } from "~/mutations/UpsertDailyBudgetMutation";

import { DailyBudgetProratedForm } from "./DailyBudgetProratedForm";
import { GrossBudgetConsumptionReportListContainer } from "./GrossBudgetConsumptionReportList";

interface GridElement extends ReactDataSheet.Cell<GridElement> {
  id: string;
  value: string;
  date?: string;
}

class DailyBudgetDataSheet extends ReactDataSheet<GridElement> {}

const backgroundStyleByDate = (date: string | undefined) => {
  if (date === undefined) return undefined;
  const isHoliday = holiday_jp.isHoliday(new Date(date));
  const isSunday = DateFns.isSunday(new Date(date));
  const isSaturday = DateFns.isSaturday(new Date(date));
  return isHoliday || isSunday || isSaturday
    ? { background: "#FFE4E1" }
    : undefined;
};

const CellRenderer: ReactDataSheet.CellRenderer<GridElement, string> = (
  props
) => {
  return (
    <td
      style={backgroundStyleByDate(props.cell.date)}
      onMouseDown={props.onMouseDown}
      onMouseOver={props.onMouseOver}
      onDoubleClick={props.onDoubleClick}
      onContextMenu={props.onContextMenu}
      className={props.className}
    >
      {props.children}
    </td>
  );
};

type Props = {
  campaignRef: FragmentRef<DailyBudgetListDataSheet_campaign>;
};

type CellChange = {
  cell: GridElement | null;
  row: number;
  col: number;
  value: string;
};

const fragment = graphql`
  fragment DailyBudgetListDataSheet_campaign on Campaign {
    id
    latestVersion
    dailyBudgets(first: 100, month: $month)
      @connection(key: "DailyBudgetListDataSheet_dailyBudgets") {
      edges {
        node {
          id
          date
          amount
          baseAmount
          createdAt
          updatedAt
        }
      }
      totalCount
    }
  }
`;

const query = graphql`
  query DailyBudgetListDataSheet_Query(
    $projectId: ID!
    $campaignId: ID!
    $month: ISO8601Date!
  ) {
    project(id: $projectId) {
      campaign(id: $campaignId) {
        ...DailyBudgetListDataSheet_campaign
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  dataSheet: {
    width: "70%",
    margin: "auto",
    boxSizing: "content-box",
  },
}));

const useDailyBudgets = (
  campaignRef: Props["campaignRef"],
  projectId: string
) => {
  const [campaign, refetch] = useRefetch<DailyBudgetListDataSheet_campaign>(
    fragment,
    campaignRef
  );
  const { upsertDailyBudgetMutation } = useUpsertDailyBudgetMutation();
  const { deleteDailyBudgetMutation } = useDeleteDailyBudgetMutation(
    campaign.id
  );

  const refetchDailyBudgets = React.useCallback(
    (month: Date) => {
      const variables = {
        projectId,
        campaignId: campaign.id,
        month: DateFns.formatISO(month),
      };
      refetch<DailyBudgetListDataSheet_Query>(query, variables);
    },
    [campaign.id, projectId, refetch]
  );

  const upsertDailyBudgets = React.useCallback(
    async (dailyBudgets: { amount: number; date: string }[], month: Date) => {
      try {
        const mutations = dailyBudgets.map((dailyBudget) => {
          return upsertDailyBudgetMutation(
            {
              date: dailyBudget.date,
              baseAmount: dailyBudget.amount,
              campaignId: campaign.id,
              clientVersion: campaign.latestVersion,
            },
            month
          );
        });
        await delayChunkPromise(mutations);
      } catch (err) {
        throw new Error(err);
      }
    },
    [campaign.id, campaign.latestVersion, upsertDailyBudgetMutation]
  );

  const deleteDailyBudgets = React.useCallback(
    async (ids: string[]) => {
      try {
        const mutations = ids.map((id) => {
          return deleteDailyBudgetMutation({
            dailyBudgetId: id,
          });
        });
        await delayChunkPromise(mutations);
      } catch (err) {
        throw new Error(err);
      }
    },
    [deleteDailyBudgetMutation]
  );

  return {
    campaign,
    refetchDailyBudgets,
    upsertDailyBudgets,
    deleteDailyBudgets,
  };
};

const useGridElement = () => {
  const firstGridElement = () => {
    return [
      { readOnly: true, value: "日付", id: "" },
      { readOnly: true, value: "予算", id: "" },
      { readOnly: true, value: "繰越後予算", id: "" },
    ];
  };

  const lastGridElement = (
    totalBaseAmount: number,
    totalCarryoverAddAmount: number
  ) => {
    return [
      { readOnly: true, value: "Total(未入力日補完)", id: "" },
      {
        readOnly: true,
        value: accounting.formatNumber(totalBaseAmount),
        id: "",
      },
      {
        readOnly: true,
        value: accounting.formatNumber(totalCarryoverAddAmount),
        id: "",
      },
    ];
  };

  return {
    firstGridElement,
    lastGridElement,
  };
};

export const DailyBudgetListDataSheet: React.FC<Props> = ({ campaignRef }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    campaign,
    refetchDailyBudgets,
    upsertDailyBudgets,
    deleteDailyBudgets,
  } = useDailyBudgets(campaignRef, projectId);
  const { firstGridElement, lastGridElement } = useGridElement();
  const [cache, setCache] = useDailyBudgetByMonthCache(projectId, campaign.id);
  const [month, setMonth] = React.useState<Date>(
    DateFns.parseISO(cache.month) || new Date()
  );

  const daysInMonth = React.useMemo(() => {
    const days = DateFns.eachDayOfInterval({
      start: DateFns.startOfMonth(month),
      end: DateFns.endOfMonth(month),
    });
    return days.map((day) =>
      DateFns.formatISO(day, { representation: "date" })
    );
  }, [month]);

  const dailyBudgets = React.useMemo(() => {
    const edges = campaign.dailyBudgets.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [campaign.dailyBudgets.edges]);

  // MEMO: https://github.com/vrize/vrizead/issues/2336
  const calculateTotalAmount = React.useCallback(
    (elements: GridElement[][], i: number) => {
      const amountList = elements.reduce((acum, element, index) => {
        if (index === 0) {
          const amount =
            element[i].value === "" ? 0 : parseInt(element[i].value);
          acum.push(amount);
          return acum;
        }
        const amount =
          element[i].value === ""
            ? acum[index - 1]
            : parseInt(element[i].value);
        acum.push(amount);
        return acum;
      }, [] as number[]);
      return amountList.reduce((sum, amount) => sum + amount, 0);
    },
    []
  );

  const grid = React.useMemo(() => {
    const gridElements: GridElement[][] = daysInMonth.map((day) => {
      const dailyBudget = dailyBudgets.find(
        (dailyBudget) => dailyBudget.date === day
      );
      const id = dailyBudget !== undefined ? dailyBudget.id : "";
      const amount =
        dailyBudget !== undefined ? dailyBudget.baseAmount.toString() : "";
      const carryoverAddAmount =
        dailyBudget !== undefined ? dailyBudget.amount.toString() : "";
      return [
        { value: day, id: id, date: day, readOnly: true },
        {
          value: amount,
          id: id,
          date: day,
          readOnly: DateFns.isBefore(new Date(day), DateFns.endOfYesterday()),
        },
        { value: carryoverAddAmount, id: id, date: day, readOnly: true },
      ];
    });
    const totalBaseAmount = calculateTotalAmount(gridElements, 1);
    const totalCarryoverAddAmount = calculateTotalAmount(gridElements, 2);
    gridElements.unshift(firstGridElement());
    gridElements.push(
      lastGridElement(totalBaseAmount, totalCarryoverAddAmount)
    );
    return gridElements;
  }, [
    daysInMonth,
    dailyBudgets,
    firstGridElement,
    lastGridElement,
    calculateTotalAmount,
  ]);

  const handleSheetChanged = React.useCallback(
    async (changes: ReactDataSheet.CellsChangedArgs<GridElement>) => {
      try {
        const upsertTargetChanges = changes.filter(
          (change): change is CellChange =>
            change.value !== null && change.value !== ""
        );
        const upsertTargetDailyBudgets = upsertTargetChanges.map((change) => {
          return {
            date: grid[change.row][change.col - 1].value,
            amount: accounting.unformat(change.value),
          };
        });
        await upsertDailyBudgets(upsertTargetDailyBudgets, month);

        const deleteTargetChanges = changes.filter((change) => {
          const id = grid[change.row][change.col].id;
          return (change.value === null || change.value === "") && id !== "";
        });
        const deleteTargetDailyBudgetIds = deleteTargetChanges.map((change) => {
          const id = grid[change.row][change.col].id;
          return id;
        });
        await deleteDailyBudgets(deleteTargetDailyBudgetIds);

        enqueueSnackbar("日予算の更新を行いました", { variant: "success" });
        await refetchDailyBudgets(month);
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [
      grid,
      enqueueSnackbar,
      month,
      upsertDailyBudgets,
      deleteDailyBudgets,
      refetchDailyBudgets,
    ]
  );

  const handleArrowBackSubmit = React.useCallback(async () => {
    const targetMonth = DateFns.subMonths(month, 1);
    await refetchDailyBudgets(targetMonth);
    setMonth(targetMonth);
    setCache({ month: DateFns.formatISO(targetMonth) });
  }, [month, refetchDailyBudgets, setCache]);

  const handleArrowForwardSubmit = React.useCallback(async () => {
    const targetMonth = DateFns.addMonths(month, 1);
    await refetchDailyBudgets(targetMonth);
    setMonth(targetMonth);
    setCache({ month: DateFns.formatISO(targetMonth) });
  }, [month, refetchDailyBudgets, setCache]);

  return (
    <>
      <Paper className={classes.root}>
        <Toolbar>
          <Typography variant="subtitle1" color="inherit">
            日予算一覧
          </Typography>
          <Button onClick={handleArrowBackSubmit}>
            <ArrowBackIcon />
          </Button>
          <Typography variant="subtitle1" color="inherit">
            {R.slice(0, 7, DateFns.formatISO(month))}
          </Typography>
          <Button onClick={handleArrowForwardSubmit}>
            <ArrowForwardIcon />
          </Button>
          <DialogButton
            title="日予算を日割りで算出する"
            render={({ close }) => (
              <DailyBudgetProratedForm
                campaignClientVersion={campaign.latestVersion}
                selectedMonth={month}
                onSubmitCompleted={close}
              />
            )}
          >
            <span>日割り計算をする</span>
          </DialogButton>
        </Toolbar>
        <DailyBudgetDataSheet
          className={classes.dataSheet}
          data={grid}
          valueRenderer={(cell) => cell.value}
          cellRenderer={CellRenderer}
          onCellsChanged={(changes) => handleSheetChanged(changes)}
        />
      </Paper>
      <Paper className={classes.root}>
        <GrossBudgetConsumptionReportListContainer
          projectId={projectId}
          campaignId={campaign.id}
          currentSettingMonth={DateFns.formatISO(month)}
        />
      </Paper>
    </>
  );
};
