import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateAdStatusInput,
  UpdateAdStatusMutation,
} from "~/__relay_artifacts__/UpdateAdStatusMutation.graphql";

const mutation = graphql`
  mutation UpdateAdStatusMutation($input: UpdateAdStatusInput!) {
    updateAdStatus(input: $input) {
      ad {
        id
        status
        latestVersion
      }
    }
  }
`;

export const useUpdateAdStatusMutation = () => {
  const environment = useRelayEnvironment();

  const updateAdStatusMutation = useCallback(
    (input: UpdateAdStatusInput) =>
      commitMutation<UpdateAdStatusMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateAdStatusMutation };
};
