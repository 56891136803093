import initReactnPersist from "reactn-persist";

import { AdFilterFormValues } from "~/components/AdListTableCard";
import {
  AdSlotFilterFormValues,
  adSlotListTableCardFilterInitialValues,
} from "~/components/AdSlotListTableCard";
import { AdSlotPlacementFilterFormValues } from "~/components/AdSlotPlacementList";
import { DailyBudgetByMonth } from "~/components/DailyBudgetListDataSheet";
import { PlacementAdSlotFilterFormValues } from "~/components/PlacementCreateButton";
import { PlacementFilterFormValues } from "~/components/PlacementList";
import { PlacementSettingAdSlotFilterFormValues } from "~/components/PlacementSettingListTableCard";
import { graphqlSchemaHash } from "~/lib/graphqlSchemaHash";
import { ReportFormValues } from "~/pages/Home/Report";

type AdFilterCache = {
  [compoundId: string]: AdFilterFormValues | undefined;
};

type AdSlotFilterCache = AdSlotFilterFormValues;

type ReportCache = {
  [projectId: string]: ReportFormValues | undefined;
};

type AdSlotPlacementFilterCache = {
  [adSlotId: string]: AdSlotPlacementFilterFormValues | undefined;
};

type PlacementAdSlotFilterCache = {
  [compoundId: string]: PlacementAdSlotFilterFormValues | undefined;
};

type PlacementFilterCache = {
  [compoundId: string]: PlacementFilterFormValues | undefined;
};

type PlacementSettingAdSlotFilterCache = {
  [placementSetId: string]: PlacementSettingAdSlotFilterFormValues | undefined;
};

type DailyBudgetByMonthCache = {
  [compoundId: string]: DailyBudgetByMonth | undefined;
};

declare module "reactn/default" {
  export interface State {
    adFilterCache: AdFilterCache;
    adSlotFilterCache: AdSlotFilterCache;
    reportCache: ReportCache;
    adSlotPlacementFilterCache: AdSlotPlacementFilterCache;
    placementAdSlotFilterCache: PlacementAdSlotFilterCache;
    placementFilterCache: PlacementFilterCache;
    placementSettingAdSlotFilterCache: PlacementSettingAdSlotFilterCache;
    dailyBudgetByMonthCache: DailyBudgetByMonthCache;
  }
}

const REACTN_CACHE_VERSION = graphqlSchemaHash;
const REACTN_CACHE_VERSION_KEY = "@vrize/cache-version";
const REACTN_CACHE_KEY = "@vrize/reactn";

export const clearPersistedReactnCaches = () => {
  localStorage.removeItem(REACTN_CACHE_KEY);
};

// NOTE: set initial state with persisted data
export const initGlobalState = () => {
  const cacheVersion = localStorage.getItem(REACTN_CACHE_VERSION_KEY);
  if (cacheVersion !== REACTN_CACHE_VERSION) {
    // NOTE: schema.graphql が更新されたらキャッシュを破棄する
    // see: https://github.com/vrize/vrizevideo-demo/issues/2866
    localStorage.setItem(REACTN_CACHE_VERSION_KEY, REACTN_CACHE_VERSION);
    localStorage.removeItem(REACTN_CACHE_KEY);
  }
  initReactnPersist({
    storage: localStorage,
    key: REACTN_CACHE_KEY,
    whitelist: [
      "adFilterCache",
      "adSlotFilterCache",
      "adSlotPlacementFilterCache",
      "placementAdSlotFilterCache",
      "placementFilterCache",
      "placementSettingAdSlotFilterCache",
      "dailyBudgetByMonthCache",
    ],
    initialValue: {
      adFilterCache: {},
      adSlotFilterCache: adSlotListTableCardFilterInitialValues,
      adSlotPlacementFilterCache: {},
      placementAdSlotFilterCache: {},
      placementFilterCache: {},
      placementSettingAdSlotFilterCache: {},
      dailyBudgetByMonthCache: {},
      reportCache: {},
    },
  });
};
