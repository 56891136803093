/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExpenseForm_QueryVariables = {
    campaignId: string;
    date: string;
};
export type ExpenseForm_QueryResponse = {
    readonly campaign: {
        readonly maxAssignableExpenseRate: number | null;
    } | null;
};
export type ExpenseForm_Query = {
    readonly response: ExpenseForm_QueryResponse;
    readonly variables: ExpenseForm_QueryVariables;
};



/*
query ExpenseForm_Query(
  $campaignId: ID!
  $date: ISO8601Date!
) {
  campaign(id: $campaignId) {
    maxAssignableExpenseRate(date: $date)
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "date",
      "variableName": "date"
    }
  ],
  "kind": "ScalarField",
  "name": "maxAssignableExpenseRate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExpenseForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExpenseForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a54463de8e60c8e852ca0d4934b168be",
    "id": null,
    "metadata": {},
    "name": "ExpenseForm_Query",
    "operationKind": "query",
    "text": "query ExpenseForm_Query(\n  $campaignId: ID!\n  $date: ISO8601Date!\n) {\n  campaign(id: $campaignId) {\n    maxAssignableExpenseRate(date: $date)\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cbeb6a730dd3843004adcc15a7df13f6';
export default node;
