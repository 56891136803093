import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpsertMonthlyBudgetInput,
  UpsertMonthlyBudgetMutation,
} from "~/__relay_artifacts__/UpsertMonthlyBudgetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpsertMonthlyBudgetMutation(
    $input: UpsertMonthlyBudgetInput!
    $first: Int
    $after: String
  ) {
    upsertMonthlyBudget(input: $input) {
      campaign {
        id
        ...MonthlyBudgetListTableCard_campaign
      }
    }
  }
`;

export const useUpsertMonthlyBudgetMutation = () => {
  const environment = useRelayEnvironment();

  const upsertMonthlyBudgetMutation = React.useCallback(
    (input: UpsertMonthlyBudgetInput) =>
      commitMutation<UpsertMonthlyBudgetMutation>(environment, {
        mutation,
        variables: { input, first: null, after: null },
      }),
    [environment]
  );

  return { upsertMonthlyBudgetMutation };
};
