import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import {
  FormValues,
  MembershipForm,
  schema,
} from "~/components/MembershipForm";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import createMembershipMutation from "~/mutations/CreateMembershipMutation";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues = {
  email: "",
  role: "GUEST" as const,
};

export const MembershipCreateForm: React.FC<Props> = ({
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { projectId } = useParams<{ projectId: string }>();
  const environment = useRelayEnvironment();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createMembership } = await createMembershipMutation(
          environment,
          {
            projectId,
            ...values,
          }
        );
        const membershipEdge = createMembership?.membershipEdge;
        if (!membershipEdge) throw new Error("assertion failed");
        enqueueSnackbar("メンバーを追加しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      environment,
      formErrorHandler,
      onSubmitCompleted,
      projectId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      render={MembershipForm}
    />
  );
};
