import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteCampaignDomainRestrictionMutation } from "~/__relay_artifacts__/DeleteCampaignDomainRestrictionMutation.graphql";

const mutation = graphql`
  mutation DeleteCampaignDomainRestrictionMutation(
    $connections: [ID!]!
    $input: DeleteCampaignDomainRestrictionInput!
  ) {
    deleteCampaignDomainRestriction(input: $input) {
      deletedCampaignDomainRestrictionId @deleteEdge(connections: $connections)
    }
  }
`;

export const deleteCampaignDomainRestrictionMutation = (
  environment: Environment,
  campaignDomainRestrictionId: string,
  connectionID: string
) =>
  commitMutation<DeleteCampaignDomainRestrictionMutation>(environment, {
    mutation,
    variables: {
      input: { campaignDomainRestrictionId },
      connections: [connectionID],
    },
  });
