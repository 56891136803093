/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type PlacementCreateButton_ad = {
    readonly selectableAdSlots: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly tagId: string | null;
                readonly platform: AdSlotPlatform | null;
                readonly sspProvider: AdSlotSspProvider;
                readonly note: string | null;
                readonly bundle: string | null;
                readonly width: number | null;
                readonly height: number | null;
                readonly adxBillingId: string | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "PlacementCreateButton_ad";
};
export type PlacementCreateButton_ad$data = PlacementCreateButton_ad;
export type PlacementCreateButton_ad$key = {
    readonly " $data"?: PlacementCreateButton_ad$data;
    readonly " $fragmentRefs": FragmentRefs<"PlacementCreateButton_ad">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "adSlotSearch"
    },
    {
      "kind": "RootArgument",
      "name": "height"
    },
    {
      "kind": "RootArgument",
      "name": "platform"
    },
    {
      "kind": "RootArgument",
      "name": "sspProvider"
    },
    {
      "kind": "RootArgument",
      "name": "width"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlacementCreateButton_ad",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 150
        },
        {
          "kind": "Variable",
          "name": "height",
          "variableName": "height"
        },
        {
          "kind": "Variable",
          "name": "platform",
          "variableName": "platform"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "adSlotSearch"
        },
        {
          "kind": "Variable",
          "name": "sspProvider",
          "variableName": "sspProvider"
        },
        {
          "kind": "Variable",
          "name": "width",
          "variableName": "width"
        }
      ],
      "concreteType": "AdSlotConnection",
      "kind": "LinkedField",
      "name": "selectableAdSlots",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdSlotEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdSlot",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tagId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "platform",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sspProvider",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "note",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bundle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "adxBillingId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ad",
  "abstractKey": null
};
(node as any).hash = '70066618b1ac0602aa430fb48585f875';
export default node;
