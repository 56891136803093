import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { ScheduleEditForm_schedule } from "~/__relay_artifacts__/ScheduleEditForm_schedule.graphql";
import { FormValues, ScheduleForm, schema } from "~/components/ScheduleForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateScheduleMutation } from "~/mutations/UpdateScheduleMutation";

type Props = {
  scheduleRef: FragmentRef<ScheduleEditForm_schedule>;
  onSubmitCompleted: () => void;
};

const scheduleFragment = graphql`
  fragment ScheduleEditForm_schedule on Schedule {
    id
    since
    till
    latestVersion
  }
`;

export const ScheduleEditForm: React.FC<Props> = ({
  scheduleRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updateScheduleMutation } = useUpdateScheduleMutation();
  const schedule = useFragment<ScheduleEditForm_schedule>(
    scheduleFragment,
    scheduleRef
  );

  const initialValues = React.useMemo<FormValues>(() => {
    const { id, latestVersion, ...rest } = schedule;
    return rest;
  }, [schedule]);

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ since, till, ...values }, { setErrors }) => {
      try {
        if (!since || !till) throw new Error("assertion failed");
        const { updateSchedule } = await updateScheduleMutation({
          id: schedule.id,
          since,
          till,
          clientVersion: schedule.latestVersion,
          ...values,
        });
        if (!updateSchedule?.schedule) throw new Error("assertion failed");
        enqueueSnackbar("スケジュールを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      schedule,
      updateScheduleMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={ScheduleForm}
    />
  );
};
