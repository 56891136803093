/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PromotionDetail_QueryVariables = {
    projectId: string;
    promotionId: string;
};
export type PromotionDetail_QueryResponse = {
    readonly project: {
        readonly promotion: {
            readonly id: string;
            readonly title: string;
            readonly " $fragmentRefs": FragmentRefs<"PromotionEditForm_promotion" | "PromotionCampaignListTableCard_promotion">;
        } | null;
    } | null;
};
export type PromotionDetail_Query = {
    readonly response: PromotionDetail_QueryResponse;
    readonly variables: PromotionDetail_QueryVariables;
};



/*
query PromotionDetail_Query(
  $projectId: ID!
  $promotionId: ID!
) {
  project(id: $projectId) {
    promotion(id: $promotionId) {
      id
      title
      ...PromotionEditForm_promotion
      ...PromotionCampaignListTableCard_promotion
    }
    id
  }
}

fragment PromotionCampaignListTableCard_promotion on Promotion {
  id
  campaigns(first: 100) {
    edges {
      node {
        id
        title
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment PromotionEditForm_promotion on Promotion {
  id
  title
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "promotionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "promotionId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PromotionDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Promotion",
            "kind": "LinkedField",
            "name": "promotion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PromotionEditForm_promotion"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PromotionCampaignListTableCard_promotion"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PromotionDetail_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Promotion",
            "kind": "LinkedField",
            "name": "promotion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "CampaignConnection",
                "kind": "LinkedField",
                "name": "campaigns",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CampaignEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Campaign",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "campaigns(first:100)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "PromotionCampaignListTableCard_campaigns",
                "kind": "LinkedHandle",
                "name": "campaigns"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36723a10e4b1452661486be9ce41f81d",
    "id": null,
    "metadata": {},
    "name": "PromotionDetail_Query",
    "operationKind": "query",
    "text": "query PromotionDetail_Query(\n  $projectId: ID!\n  $promotionId: ID!\n) {\n  project(id: $projectId) {\n    promotion(id: $promotionId) {\n      id\n      title\n      ...PromotionEditForm_promotion\n      ...PromotionCampaignListTableCard_promotion\n    }\n    id\n  }\n}\n\nfragment PromotionCampaignListTableCard_promotion on Promotion {\n  id\n  campaigns(first: 100) {\n    edges {\n      node {\n        id\n        title\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PromotionEditForm_promotion on Promotion {\n  id\n  title\n}\n"
  }
};
})();
(node as any).hash = '5cf9b93b7e6c0ba93ed1b08250124ada';
export default node;
