import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";

import { AdSlotListTableCard_root } from "~/__relay_artifacts__/AdSlotListTableCard_root.graphql";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import UpdateAdSlotMutation from "~/mutations/UpdateAdSlotMutation";

type Props = {
  adSlot: NonNullable<
    NonNullable<
      NonNullable<AdSlotListTableCard_root["adSlots"]["edges"]>[0]
    >["node"]
  >;
  onSubmitCompleted: () => void;
};

export const ArchiveButton: React.FC<Props> = ({
  adSlot,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const mode = adSlot.archived ? "復元" : "アーカイブ";

  const handleArchive = React.useCallback(async () => {
    try {
      const params = R.pick(["id", "targetWinRate"], adSlot);
      const { updateAdSlot } = await UpdateAdSlotMutation(environment, {
        ...params,
        archived: !adSlot.archived,
      });
      if (!(updateAdSlot && updateAdSlot.adSlot))
        throw new Error("assertion failed");
      onSubmitCompleted();
      enqueueSnackbar(`${mode}しました。`, { variant: "success" });
    } catch (_) {
      enqueueSnackbar(`${mode}に失敗しました。`, { variant: "error" });
    }
  }, [adSlot, environment, onSubmitCompleted, enqueueSnackbar, mode]);

  return (
    <ConfirmButton
      variant="outlined"
      color={mode === "アーカイブ" ? "secondary" : "primary"}
      confirmTitle={`
        このキャンペーンを${mode}しますか？
        アーカイブすると関連するプレイスメントが削除されます。
      `}
      onAgree={async (changeDialog) => {
        await handleArchive();
        changeDialog(false);
      }}
    >
      {mode}
    </ConfirmButton>
  );
};
