import {
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as React from "react";
import { ConnectionConfig, FragmentRef, graphql } from "react-relay";

import { MonthlyBudgetListTableCard_campaign } from "~/__relay_artifacts__/MonthlyBudgetListTableCard_campaign.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import MonthlyBudgetCreateForm from "~/containers/MonthlyBudgetCreateForm";
import MonthlyBudgetEditForm from "~/containers/MonthlyBudgetEditForm";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { usePagination } from "~/lib/relay-hooks";
import { useDeleteMonthlyBudgetMutation } from "~/mutations/DeleteMonthlyBudgetMutation";

type Props = {
  campaignRef: FragmentRef<MonthlyBudgetListTableCard_campaign>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  spacer: {
    flex: "1 1 100%",
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
  button: {
    whiteSpace: "nowrap",
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const campaignFragment = graphql`
  fragment MonthlyBudgetListTableCard_campaign on Campaign {
    id
    project {
      id
    }
    monthlyBudgets(first: $first, after: $after)
      @connection(key: "MonthlyBudgetListTableCard_monthlyBudgets") {
      edges {
        node {
          id
          date
          amount
          createdAt
          updatedAt
          ...MonthlyBudgetEditForm_monthlyBudget
        }
      }
      totalCount
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.monthlyBudgets,
  getVariables: ({ id, project }: any, { count, cursor }) => ({
    projectId: project.id,
    campaignId: id,
    first: count,
    after: cursor,
  }),
  query: graphql`
    query MonthlyBudgetListTableCard_Query(
      $projectId: ID!
      $campaignId: ID!
      $first: Int
      $after: String
    ) {
      project(id: $projectId) {
        campaign(id: $campaignId) {
          ...MonthlyBudgetListTableCard_campaign
        }
      }
    }
  `,
};

export const MonthlyBudgetListTableCard: React.FC<Props> = ({
  campaignRef,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [campaign, { hasMore, loadMore }] =
    usePagination<MonthlyBudgetListTableCard_campaign>(
      campaignFragment,
      campaignRef
    );
  const { deleteMonthlyBudgetMutation } = useDeleteMonthlyBudgetMutation(
    campaign.id
  );
  const totalCount = campaign.monthlyBudgets.totalCount;

  const budgets = React.useMemo(() => {
    const edges = campaign.monthlyBudgets.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [campaign.monthlyBudgets.edges, page, perPage]);

  const handleDeleteClick = React.useCallback(
    async (monthlyBudgetId: string) => {
      try {
        const { deleteMonthlyBudget } = await deleteMonthlyBudgetMutation({
          monthlyBudgetId,
        });
        if (!deleteMonthlyBudget?.deletedMonthlyBudgetId) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("日予算を削除しました", { variant: "success" });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [deleteMonthlyBudgetMutation, enqueueSnackbar]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          月予算一覧
        </Typography>
        <div className={classes.actions}>
          <DialogButton
            title="月予算作成"
            render={({ close }) => (
              <MonthlyBudgetCreateForm
                campaignId={campaign.id}
                onSubmitCompleted={() => {
                  close();
                }}
              />
            )}
          >
            <Icon className={classes.leftIcon}>add</Icon>
            <span>作成する</span>
          </DialogButton>
        </div>
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>予算</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>更新日</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgets.map((budget) => (
            <TableRow key={budget.id}>
              <TableCell>{budget.date}</TableCell>
              <TableCell>{budget.amount}</TableCell>
              <TableCell>{parseISO(budget.createdAt)}</TableCell>
              <TableCell>{parseISO(budget.updatedAt)}</TableCell>
              <TableCell>
                <DialogButton
                  title="月予算編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <MonthlyBudgetEditForm
                      monthlyBudgetRef={budget}
                      onSubmitCompleted={() => {
                        close();
                      }}
                    />
                  )}
                >
                  <span>編集</span>
                </DialogButton>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="このスケジュールを削除しますか？"
                  onAgree={async (changeDialog) => {
                    await handleDeleteClick(budget.id);
                    changeDialog(false);
                  }}
                >
                  削除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, page) => {
            hasMore() && loadMore(connectionConfig, perPage, noop);
            setPage(page);
          }}
          onRowsPerPageChange={(event) =>
            setPerPage(parseInt(event.target.value))
          }
        />
      </ListTable>
    </Paper>
  );
};
