/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateCampaignAllowIpInput = {
    clientMutationId?: string | null;
    campaignId: string;
    ip: string;
};
export type CreateCampaignAllowIpMutationVariables = {
    connections: Array<string>;
    edgeTypeName: string;
    input: CreateCampaignAllowIpInput;
};
export type CreateCampaignAllowIpMutationResponse = {
    readonly createCampaignAllowIp: {
        readonly campaignAllowIp: {
            readonly id: string;
            readonly ip: string;
        } | null;
    } | null;
};
export type CreateCampaignAllowIpMutation = {
    readonly response: CreateCampaignAllowIpMutationResponse;
    readonly variables: CreateCampaignAllowIpMutationVariables;
};



/*
mutation CreateCampaignAllowIpMutation(
  $input: CreateCampaignAllowIpInput!
) {
  createCampaignAllowIp(input: $input) {
    campaignAllowIp {
      id
      ip
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "edgeTypeName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CampaignAllowIp",
  "kind": "LinkedField",
  "name": "campaignAllowIp",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ip",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCampaignAllowIpMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignAllowIpPayload",
        "kind": "LinkedField",
        "name": "createCampaignAllowIp",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCampaignAllowIpMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignAllowIpPayload",
        "kind": "LinkedField",
        "name": "createCampaignAllowIp",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "campaignAllowIp",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Variable",
                "name": "edgeTypeName",
                "variableName": "edgeTypeName"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa45c1a39d603a25edf221e15ccce041",
    "id": null,
    "metadata": {},
    "name": "CreateCampaignAllowIpMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCampaignAllowIpMutation(\n  $input: CreateCampaignAllowIpInput!\n) {\n  createCampaignAllowIp(input: $input) {\n    campaignAllowIp {\n      id\n      ip\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '29431f2fdbadc00e9ab8b588e82ea49c';
export default node;
