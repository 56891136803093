import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { ConnectionConfig, FragmentRef, graphql } from "react-relay";

import { ExcludeScheduleListTableCard_campaign } from "~/__relay_artifacts__/ExcludeScheduleListTableCard_campaign.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { noop } from "~/lib/noop";
import { parseISO } from "~/lib/parseISO";
import { usePagination } from "~/lib/relay-hooks";
import { useDeleteExcludeScheduleMutation } from "~/mutations/DeleteExcludeScheduleMutation";

import {
  ExcludeScheduleCreateForm,
  ExcludeScheduleEditForm,
} from "./ExcludeScheduleForms";

type Props = {
  campaignRef: FragmentRef<ExcludeScheduleListTableCard_campaign>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 200,
  },
}));

const campaignFragment = graphql`
  fragment ExcludeScheduleListTableCard_campaign on Campaign {
    id
    project {
      id
    }
    excludeSchedules(first: $first, after: $after)
      @connection(key: "ExcludeScheduleListTableCard_excludeSchedules") {
      edges {
        node {
          id
          name
          since
          till
          ...ExcludeScheduleEditForm_excludeSchedule
        }
      }
      totalCount
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.excludeSchedules,
  getVariables: ({ id, project }: any, { count, cursor }) => ({
    projectId: project.id,
    campaignId: id,
    first: count,
    after: cursor,
  }),
  query: graphql`
    query ExcludeScheduleListTableCard_Query(
      $projectId: ID!
      $campaignId: ID!
      $first: Int
      $after: String
    ) {
      project(id: $projectId) {
        campaign(id: $campaignId) {
          ...ExcludeScheduleListTableCard_campaign
        }
      }
    }
  `,
};

export const ExcludeScheduleListTableCard: FC<Props> = ({ campaignRef }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [campaign, { hasMore, loadMore }] =
    usePagination<ExcludeScheduleListTableCard_campaign>(
      campaignFragment,
      campaignRef
    );
  const { deleteExcludeScheduleMutation } = useDeleteExcludeScheduleMutation(
    campaign.id
  );
  const totalCount = campaign.excludeSchedules.totalCount;

  const excludeSchedules = useMemo(() => {
    const edges = campaign.excludeSchedules.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [campaign.excludeSchedules.edges, page, perPage]);

  const handleDeleteClick = useCallback(
    async (excludeScheduleId: string) => {
      try {
        const { deleteExcludeSchedule } = await deleteExcludeScheduleMutation({
          excludeScheduleId,
        });
        if (!deleteExcludeSchedule?.deletedExcludeScheduleId) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("配信停止設定を削除しました", { variant: "success" });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [deleteExcludeScheduleMutation, enqueueSnackbar]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          配信停止設定一覧
        </Typography>
        <DialogButton
          title="配信停止設定作成"
          render={({ close }) => (
            <ExcludeScheduleCreateForm
              campaignId={campaign.id}
              onSubmitCompleted={close}
            />
          )}
        >
          作成
        </DialogButton>
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>開始日</TableCell>
            <TableCell>終了日</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {excludeSchedules.map((excludeSchedule) => (
            <TableRow key={excludeSchedule.id}>
              <TableCell>{atob(excludeSchedule.id)}</TableCell>
              <TableCell>{excludeSchedule.name}</TableCell>
              <TableCell>
                {!!excludeSchedule.since
                  ? parseISO(excludeSchedule.since, "yyyy/MM/dd HH:mm:ss")
                  : "未設定"}
              </TableCell>
              <TableCell>
                {!!excludeSchedule.till
                  ? parseISO(excludeSchedule.till, "yyyy/MM/dd HH:mm:ss")
                  : "未設定"}
              </TableCell>
              <TableCell>
                <DialogButton
                  title="配信停止設定編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <ExcludeScheduleEditForm
                      excludeScheduleRef={excludeSchedule}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="この配信停止設定を削除しますか？"
                  onAgree={async (changeDialog) => {
                    await handleDeleteClick(excludeSchedule.id);
                    changeDialog(false);
                  }}
                >
                  削除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          hasMore() && loadMore(connectionConfig, perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};
