import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  BulkCreatePlacementSettingInput,
  BulkCreatePlacementSettingMutation,
} from "~/__relay_artifacts__/BulkCreatePlacementSettingMutation.graphql";

const mutation = graphql`
  mutation BulkCreatePlacementSettingMutation(
    $input: BulkCreatePlacementSettingInput!
    $first: Int
    $after: String
  ) {
    bulkCreatePlacementSetting(input: $input) {
      placementSet {
        id
        ...PlacementSettingListTableCard_placementSet
      }
    }
  }
`;

const commit = (
  environment: Environment,
  input: BulkCreatePlacementSettingInput
) =>
  commitMutation<BulkCreatePlacementSettingMutation>(environment, {
    mutation,
    variables: {
      input,
      first: 100,
    },
  });

export default commit;
