import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteMembershipMutation } from "~/__relay_artifacts__/DeleteMembershipMutation.graphql";

const mutation = graphql`
  mutation DeleteMembershipMutation($input: DeleteMembershipInput!) {
    deleteMembership(input: $input) {
      deletedMembershipId
    }
  }
`;

// TODO: do not use any type
const commit = (environment: Environment, itemId: string, config: any) =>
  commitMutation<DeleteMembershipMutation>(environment, {
    mutation,
    variables: {
      input: {
        id: itemId,
      },
    },
    configs: [config],
  });

export default commit;
