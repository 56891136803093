/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdCategory = "COMIC_BOOKS" | "DRAWING_SKETCHING" | "VIDEO_COMPUTER_GAMES" | "%future added value";
export type BidStrategy = "MANUAL" | "ML" | "%future added value";
export type CampaignReportingOption = "CPI" | "CPM" | "SIMEJI" | "%future added value";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type CampaignEditFormForAdmin_campaign = {
    readonly id: string;
    readonly title: string;
    readonly status: CampaignStatus;
    readonly bidStrategy: BidStrategy;
    readonly reportingOption: CampaignReportingOption;
    readonly goalAmount: number;
    readonly frequencyCapEnabled: boolean;
    readonly periodSince: string;
    readonly periodTill: string | null;
    readonly isBanditEnabled: boolean;
    readonly latestVersion: number | null;
    readonly dailyBudget: {
        readonly id: string;
        readonly date: string;
        readonly baseAmount: number;
    };
    readonly monthlyBudget: {
        readonly id: string;
        readonly date: string;
        readonly amount: number;
    };
    readonly categories: ReadonlyArray<AdCategory>;
    readonly " $refType": "CampaignEditFormForAdmin_campaign";
};
export type CampaignEditFormForAdmin_campaign$data = CampaignEditFormForAdmin_campaign;
export type CampaignEditFormForAdmin_campaign$key = {
    readonly " $data"?: CampaignEditFormForAdmin_campaign$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignEditFormForAdmin_campaign">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignEditFormForAdmin_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bidStrategy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportingOption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequencyCapEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodTill",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBanditEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DailyBudget",
      "kind": "LinkedField",
      "name": "dailyBudget",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "baseAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MonthlyBudget",
      "kind": "LinkedField",
      "name": "monthlyBudget",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categories",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();
(node as any).hash = 'e318024045ddaa0f53c87289f485ea87';
export default node;
