import {
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { useDebounce } from "react-use";
import { ConnectionConfig } from "relay-hooks";

import { ClientCreativeListTableCard_project } from "~/__relay_artifacts__/ClientCreativeListTableCard_project.graphql";
import { CreativePreviewButton } from "~/components/CreativePreviewButton";
import { ListTable } from "~/components/ListTable";
import { Period } from "~/components/Period";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { TableCell } from "~/components/atoms/TableCell";
import { noop } from "~/lib/noop";
import { usePagination } from "~/lib/relay-hooks";
import { useArchiveCreativeMutation } from "~/mutations/ArchiveCreativeMutation";

type Props = {
  projectRef: FragmentRef<ClientCreativeListTableCard_project>;
};

const fragment = graphql`
  fragment ClientCreativeListTableCard_project on Project {
    id
    creatives(first: $first, after: $after, withArchived: false, title: $title)
      @connection(key: "ClientCreativeListTableCard_creatives") {
      totalCount
      edges {
        node {
          id
          title
          adm
          height
          width
          latestVersion
          periodSince
          periodTill
        }
      }
    }
  }
`;

const connectionConfig: ConnectionConfig = {
  getConnectionFromProps: (props: any) => props.project,
  getVariables: (_, { count, cursor }, fragmentVariables) => ({
    first: count,
    after: cursor,
    ...fragmentVariables,
  }),
  query: graphql`
    query ClientCreativeListTableCard_Query(
      $projectId: ID!
      $first: Int
      $after: String
      $title: String
    ) {
      project(id: $projectId) {
        ...ClientCreativeListTableCard_project
      }
    }
  `,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

export const ClientCreativeListTableCard: React.FC<Props> = ({
  projectRef,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { archiveCreativeMutation } = useArchiveCreativeMutation();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [searchTitle, setSearchTitle] = React.useState<string | null>(null);

  const [project, { hasMore, loadMore, refetchConnection }] =
    usePagination<ClientCreativeListTableCard_project>(fragment, projectRef);

  const creatives = React.useMemo(() => {
    const creativeEdges = project.creatives.edges || [];
    return creativeEdges
      .slice(page * perPage, page * perPage + perPage)
      .map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return node;
      });
  }, [project, page, perPage]);
  const totalCount = creatives.length;

  const handleRefreshCreativeList = React.useCallback(() => {
    refetchConnection?.(connectionConfig, perPage, noop, {
      projectId: project.id,
      title: searchTitle,
    });
  }, [refetchConnection, searchTitle, project.id, perPage]);

  const handleTextFieldChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    const value = e.target.value;
    setSearchTitle(value || null);
  }, []);

  const handleArchiveCreative = React.useCallback(
    async (creativeId: string, latestVersion: number | null) => {
      try {
        await archiveCreativeMutation({
          id: creativeId,
          clientVersion: latestVersion,
        });
        enqueueSnackbar("アーカイブしました", { variant: "success" });
        handleRefreshCreativeList();
      } catch {
        enqueueSnackbar("アーカイブに失敗しました。", { variant: "error" });
      }
    },
    [archiveCreativeMutation, enqueueSnackbar, handleRefreshCreativeList]
  );

  useDebounce(handleRefreshCreativeList, 500, [searchTitle]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1">クリエイティブ一覧</Typography>
      </Toolbar>
      <Toolbar>
        <TextField
          fullWidth
          label="タイトルで絞り込む"
          value={searchTitle}
          onChange={handleTextFieldChange}
        />
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>タイトル</TableCell>
            <TableCell>配信期間</TableCell>
            <TableCell>プレビュー</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {creatives.map((creative, index) => (
            <TableRow key={index}>
              <TableCell>{creative.title}</TableCell>
              <TableCell>
                <Period
                  since={creative.periodSince}
                  till={creative.periodTill}
                />
              </TableCell>
              <TableCell>
                <CreativePreviewButton
                  html={creative.adm || ""}
                  height={creative.height || 100}
                  width={creative.width || 200}
                  disabled={!creative.adm}
                />
              </TableCell>
              <TableCell>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle={`このクリエイティブをアーカイブしますか？`}
                  disabled={false}
                  onAgree={async (changeDialog) => {
                    await handleArchiveCreative(
                      creative.id,
                      creative.latestVersion
                    );
                    changeDialog(false);
                  }}
                >
                  {"アーカイブ"}
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          hasMore() &&
            loadMore(connectionConfig, perPage, noop, { force: true });
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};
