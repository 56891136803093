/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteCampaignTrackerSettingInput = {
    clientMutationId?: string | null;
    campaignId: string;
};
export type DeleteCampaignTrackerSettingMutationVariables = {
    input: DeleteCampaignTrackerSettingInput;
};
export type DeleteCampaignTrackerSettingMutationResponse = {
    readonly deleteCampaignTrackerSetting: {
        readonly campaign: {
            readonly id: string;
            readonly tracker: {
                readonly id: string;
                readonly title: string;
            } | null;
        } | null;
    } | null;
};
export type DeleteCampaignTrackerSettingMutation = {
    readonly response: DeleteCampaignTrackerSettingMutationResponse;
    readonly variables: DeleteCampaignTrackerSettingMutationVariables;
};



/*
mutation DeleteCampaignTrackerSettingMutation(
  $input: DeleteCampaignTrackerSettingInput!
) {
  deleteCampaignTrackerSetting(input: $input) {
    campaign {
      id
      tracker {
        id
        title
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteCampaignTrackerSettingPayload",
    "kind": "LinkedField",
    "name": "deleteCampaignTrackerSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Tracker",
            "kind": "LinkedField",
            "name": "tracker",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCampaignTrackerSettingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCampaignTrackerSettingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9be5ec659b7eea628d5c0221a697414b",
    "id": null,
    "metadata": {},
    "name": "DeleteCampaignTrackerSettingMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCampaignTrackerSettingMutation(\n  $input: DeleteCampaignTrackerSettingInput!\n) {\n  deleteCampaignTrackerSetting(input: $input) {\n    campaign {\n      id\n      tracker {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '25637cef5284e3112f17bfbd257e1d0a';
export default node;
