import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateScheduleInput,
  UpdateScheduleMutation,
} from "~/__relay_artifacts__/UpdateScheduleMutation.graphql";

const mutation = graphql`
  mutation UpdateScheduleMutation($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      schedule {
        id
        createdAt
        since
        till
        latestVersion
      }
    }
  }
`;

export const useUpdateScheduleMutation = () => {
  const environment = useRelayEnvironment();

  const updateScheduleMutation = useCallback(
    (input: UpdateScheduleInput) =>
      commitMutation<UpdateScheduleMutation>(environment, {
        mutation,
        variables: {
          input,
        },
      }),
    [environment]
  );

  return { updateScheduleMutation };
};
