/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ArchiveCreativeInput = {
    clientMutationId?: string | null;
    id: string;
    clientVersion?: number | null;
};
export type ArchiveCreativeMutationVariables = {
    input: ArchiveCreativeInput;
};
export type ArchiveCreativeMutationResponse = {
    readonly archiveCreative: {
        readonly creative: {
            readonly id: string;
            readonly title: string;
            readonly adm: string | null;
            readonly height: number | null;
            readonly width: number | null;
            readonly archived: boolean;
            readonly latestVersion: number | null;
            readonly periodSince: string | null;
            readonly periodTill: string | null;
        } | null;
    } | null;
};
export type ArchiveCreativeMutation = {
    readonly response: ArchiveCreativeMutationResponse;
    readonly variables: ArchiveCreativeMutationVariables;
};



/*
mutation ArchiveCreativeMutation(
  $input: ArchiveCreativeInput!
) {
  archiveCreative(input: $input) {
    creative {
      id
      title
      adm
      height
      width
      archived
      latestVersion
      periodSince
      periodTill
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ArchiveCreativePayload",
    "kind": "LinkedField",
    "name": "archiveCreative",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Creative",
        "kind": "LinkedField",
        "name": "creative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latestVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodSince",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodTill",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveCreativeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArchiveCreativeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b897e5b537c5dd3107a6f56e51961564",
    "id": null,
    "metadata": {},
    "name": "ArchiveCreativeMutation",
    "operationKind": "mutation",
    "text": "mutation ArchiveCreativeMutation(\n  $input: ArchiveCreativeInput!\n) {\n  archiveCreative(input: $input) {\n    creative {\n      id\n      title\n      adm\n      height\n      width\n      archived\n      latestVersion\n      periodSince\n      periodTill\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c70cd41d6881c08324b4c4fdbb804b7d';
export default node;
