import {
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  VolumeUp as CampaignIcon,
  Description as DescriptionIcon,
  ExitToApp as ExitToAppIcon,
  Home as HomeIcon,
  InsertChartOutlined as InsertChartOutlinedIcon,
  MovieCreation as MovieCreationIcon,
  SpeakerGroup as PromotionIcon,
} from "@material-ui/icons";
import * as React from "react";
import { graphql } from "react-relay";
import { useLocation } from "react-router";

import { ProjectDrawerMenuList_project } from "~/__relay_artifacts__/ProjectDrawerMenuList_project.graphql";
import { ProjectDrawerMenuList_viewer } from "~/__relay_artifacts__/ProjectDrawerMenuList_viewer.graphql";
import { ProjectHeader_project } from "~/__relay_artifacts__/ProjectHeader_project.graphql";
import { ProjectHeader_viewer } from "~/__relay_artifacts__/ProjectHeader_viewer.graphql";
import { AccountButton } from "~/components/AccountButton";
import { LogoutButton } from "~/components/LogoutButton";
import { LinkListItem } from "~/components/atoms/LinkListItem";
import { useFragment } from "~/lib/relay-hooks";

type Props = {
  projectRef: ProjectHeader_project;
  viewerRef: ProjectHeader_viewer;
};

const matches = (path: string, re: RegExp) => !!path.match(re);

const projectFragment = graphql`
  fragment ProjectDrawerMenuList_project on Project {
    id
    name
    viewerCanReadCampaign
    viewerCanManageCreative
    viewerCanAdministrate
  }
`;

const viewerFragment = graphql`
  fragment ProjectDrawerMenuList_viewer on User {
    ...AccountButton_viewer
    role
  }
`;

export const ProjectDrawerMenuList: React.FC<Props> = ({
  projectRef,
  viewerRef,
}) => {
  const location = useLocation();
  const project = useFragment<ProjectDrawerMenuList_project>(
    projectFragment,
    projectRef
  );
  const viewer = useFragment<ProjectDrawerMenuList_viewer>(
    viewerFragment,
    viewerRef
  );

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <List>
        <AccountButton viewerRef={viewer} />
        <Divider />
        <LinkListItem
          button
          to={`/projects/${project.id}/home`}
          selected={matches(location.pathname, /home/)}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="ホーム" />
        </LinkListItem>
        {project.viewerCanAdministrate && (
          <>
            <LinkListItem
              button
              to={`/projects/${project.id}/campaigns`}
              selected={
                matches(location.pathname, /campaigns/) ||
                matches(location.pathname, /ads/) ||
                matches(location.pathname, /creatives/)
              }
            >
              <ListItemIcon>
                <CampaignIcon />
              </ListItemIcon>
              <ListItemText primary="キャンペーン" />
            </LinkListItem>

            <LinkListItem
              button
              to={`/projects/${project.id}/promotions`}
              selected={matches(location.pathname, /promotions/)}
            >
              <ListItemIcon>
                <PromotionIcon />
              </ListItemIcon>
              <ListItemText primary="プロモーション" />
            </LinkListItem>

            <LinkListItem
              button
              to={`/projects/${project.id}/invoices`}
              selected={matches(location.pathname, /invoices/)}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="請求書" />
            </LinkListItem>

            <LinkListItem
              button
              to={`/projects/${project.id}/members`}
              selected={matches(location.pathname, /members/)}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="メンバー一覧" />
            </LinkListItem>
          </>
        )}
        {project.viewerCanReadCampaign && (
          <LinkListItem
            button
            to={`/projects/${project.id}/daily_budgets`}
            selected={matches(location.pathname, /daily_budgets/)}
          >
            <ListItemIcon>
              <InsertChartOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="キャンペーン（日予算）" />
          </LinkListItem>
        )}
        {project.viewerCanManageCreative && (
          <LinkListItem
            button
            to={`/projects/${project.id}/creatives`}
            selected={matches(location.pathname, /creatives/)}
          >
            <ListItemIcon>
              <MovieCreationIcon />
            </ListItemIcon>
            <ListItemText primary="クリエイティブ" />
          </LinkListItem>
        )}
      </List>
      <Box flexGrow={1} />
      <List>
        {project.viewerCanAdministrate && (
          <LinkListItem button to="/admin/campaigns">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="管理" />
          </LinkListItem>
        )}
        <LogoutButton key="logout" />
      </List>
    </Box>
  );
};
