import { ButtonProps } from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import * as React from "react";

import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { modernEnvironment } from "~/lib/Environment";
import deleteUserMutation from "~/mutations/DeleteUserMutation";

type Props = {
  userId: string;
} & ButtonProps;

const UserDeleteButton: React.FC<Props> = ({ children, userId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = React.useCallback(
    async (userId: string) => {
      try {
        const { deleteUser } = await deleteUserMutation(
          modernEnvironment,
          userId
        );

        const id = deleteUser && deleteUser.deletedUserId;
        if (!id) throw new Error("assertion failed");

        enqueueSnackbar(`ユーザーを削除しました。`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [enqueueSnackbar]
  );

  return (
    <ConfirmButton
      onAgree={(changeDialog) => {
        onDelete(userId).then(() => {
          changeDialog(false);
        });
      }}
      variant="outlined"
      color="inherit"
      confirmTitle="このユーザーを削除しますか？"
      confirmContent=""
    >
      {children}
    </ConfirmButton>
  );
};

export default UserDeleteButton;
