import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useOpenState } from "@vrize/vrizead-use";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { ScheduleListButton_scheduleSet } from "~/__relay_artifacts__/ScheduleListButton_scheduleSet.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ResponsiveDialog } from "~/components/ResponsiveDialog";
import { ScheduleCreateForm } from "~/containers/ScheduleCreateForm";
import { ScheduleDeleteButton } from "~/containers/ScheduleDeleteButton";
import { ScheduleEditForm } from "~/containers/ScheduleEditForm";
import { parseISO } from "~/lib/parseISO";
import { useFragment } from "~/lib/relay-hooks";
import { useMobile } from "~/lib/useMobile";

type Props = {
  scheduleSetRef: FragmentRef<ScheduleListButton_scheduleSet>;
};

const scheduleSetFragment = graphql`
  fragment ScheduleListButton_scheduleSet on ScheduleSet {
    id
    schedules(first: 100) @connection(key: "ScheduleList_schedules") {
      edges {
        node {
          id
          till
          since
          ...ScheduleEditForm_schedule
        }
      }
    }
  }
`;

export const ScheduleListButton: React.FC<Props> = ({ scheduleSetRef }) => {
  const { isMobile } = useMobile();
  const [dialogOpen, openDialog, closeDialog] = useOpenState();
  const scheduleSet = useFragment<ScheduleListButton_scheduleSet>(
    scheduleSetFragment,
    scheduleSetRef
  );

  const schedules = React.useMemo(() => {
    const edges = scheduleSet.schedules?.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [scheduleSet.schedules]);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={openDialog}>
        編集
      </Button>
      <ResponsiveDialog
        title="スケジュール一覧"
        open={dialogOpen}
        onClose={closeDialog}
        toolbarContent={
          <DialogButton
            variant={isMobile ? "contained" : "text"}
            title="スケジュール作成"
            render={({ close }) => (
              <ScheduleCreateForm
                scheduleSetId={scheduleSet.id}
                onSubmitCompleted={close}
              />
            )}
          >
            <Icon>add</Icon> 新規作成
          </DialogButton>
        }
      >
        <ListTable minWidth={700}>
          <TableHead>
            <TableRow>
              <TableCell>開始日</TableCell>
              <TableCell>終了日</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule) => (
              <TableRow key={schedule.id} hover>
                <TableCell>
                  {!!schedule.since
                    ? parseISO(schedule.since, "HH:mm")
                    : "未設定"}
                </TableCell>
                <TableCell>
                  {!!schedule.till
                    ? parseISO(schedule.till, "HH:mm")
                    : "未設定"}
                </TableCell>
                <TableCell>
                  <DialogButton
                    title="スケジュール編集"
                    variant="outlined"
                    color="primary"
                    render={({ close }) => (
                      <ScheduleEditForm
                        scheduleRef={schedule}
                        onSubmitCompleted={close}
                      />
                    )}
                  >
                    編集
                  </DialogButton>
                  <ScheduleDeleteButton
                    scheduleId={schedule.id}
                    scheduleSetId={scheduleSet.id}
                  >
                    削除
                  </ScheduleDeleteButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ListTable>
      </ResponsiveDialog>
    </>
  );
};
