import { Grid } from "@material-ui/core";
import { FormikTextField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = yup.Asserts<typeof schema>;

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
});

export const UserForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField name="email" label="Email" margin="normal" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          name="password"
          label="Password"
          type="password"
          margin="normal"
          autoComplete="current-password"
        />
      </Grid>
    </Grid>
  </DialogForm>
);
