import { Switch } from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";

import { useUpdatePlacementIsEnabledMutation } from "~/mutations/UpdatePlacementIsEnabledMutation";

import { PartialPlacement } from "./types";

type Props = {
  placement: PartialPlacement;
  onChange?: () => void;
};

export const IsEnabledSwitch: React.FC<Props> = ({ placement, onChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updatePlacementIsEnabledMutation } =
    useUpdatePlacementIsEnabledMutation();

  const handleChange = React.useCallback(async () => {
    try {
      await updatePlacementIsEnabledMutation({
        id: placement.id,
        isEnabled: !placement.isEnabled,
        clientVersion: placement.latestVersion,
      });
      enqueueSnackbar("ステータスを更新しました。", { variant: "success" });
      onChange?.();
    } catch (_) {
      enqueueSnackbar("ステータスの更新に失敗しました。", { variant: "error" });
    }
  }, [enqueueSnackbar, onChange, placement, updatePlacementIsEnabledMutation]);

  return <Switch checked={placement.isEnabled} onChange={handleChange} />;
};
