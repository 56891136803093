/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DimensionValue = "DATE" | "GENRE" | "OS" | "%future added value";
export type MeasureFormula = "AVG" | "INT_SUM" | "SUM" | "%future added value";
export type MeasureValue = "CLICK" | "CLICK_COUNT" | "CTR" | "CVR" | "DATE" | "GROSS_BUDGET_CONSUMPTION" | "GROSS_CPC" | "GROSS_CPI" | "IMPRESSION" | "INSTALL" | "MEDIA_WIN_COUNT" | "NET_BUDGET_CONSUMPTION" | "NET_CPC" | "NET_CPI" | "OS" | "TOTAL_INSTALL_COUNT" | "WIN_COUNT" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type MeasureInput = {
    value: MeasureValue;
    formula?: MeasureFormula | null;
};
export type DimensionInput = {
    value: DimensionValue;
};
export type FilterInput = {
    periodSince: string;
    periodTill: string;
    campaignIds?: Array<string> | null;
    promotionIds?: Array<string> | null;
    adUnitIds?: Array<string> | null;
    os?: Array<Os> | null;
};
export type OrderInput = {
    value: MeasureValue;
    isDesc?: boolean | null;
};
export type GrossBudgetConsumptionReportList_QueryVariables = {
    projectId: string;
    campaignId: string;
    month: string;
    measures: Array<MeasureInput>;
    dimensions: Array<DimensionInput>;
    filter: FilterInput;
    orders?: Array<OrderInput> | null;
};
export type GrossBudgetConsumptionReportList_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"GrossBudgetConsumptionReportList_project">;
    } | null;
};
export type GrossBudgetConsumptionReportList_Query = {
    readonly response: GrossBudgetConsumptionReportList_QueryResponse;
    readonly variables: GrossBudgetConsumptionReportList_QueryVariables;
};



/*
query GrossBudgetConsumptionReportList_Query(
  $projectId: ID!
  $campaignId: ID!
  $month: ISO8601Date!
  $measures: [MeasureInput!]!
  $dimensions: [DimensionInput!]!
  $filter: FilterInput!
  $orders: [OrderInput!]
) {
  project(id: $projectId) {
    ...GrossBudgetConsumptionReportList_project
    id
  }
}

fragment GrossBudgetConsumptionReportList_project on Project {
  id
  report(measures: $measures, dimensions: $dimensions, filter: $filter, orders: $orders) {
    measures
  }
  campaign(id: $campaignId) {
    monthlyBudget {
      amount
      id
    }
    dailyBudgets(first: 50, month: $month) {
      edges {
        node {
          date
          baseAmount
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "campaignId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dimensions"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "measures"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orders"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GrossBudgetConsumptionReportList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GrossBudgetConsumptionReportList_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "GrossBudgetConsumptionReportList_Query",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "dimensions",
                "variableName": "dimensions"
              },
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "measures",
                "variableName": "measures"
              },
              {
                "kind": "Variable",
                "name": "orders",
                "variableName": "orders"
              }
            ],
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "measures",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "campaignId"
              }
            ],
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MonthlyBudget",
                "kind": "LinkedField",
                "name": "monthlyBudget",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 50
                  },
                  {
                    "kind": "Variable",
                    "name": "month",
                    "variableName": "month"
                  }
                ],
                "concreteType": "DailyBudgetConnection",
                "kind": "LinkedField",
                "name": "dailyBudgets",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DailyBudgetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DailyBudget",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "baseAmount",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b80cb8a8e9f38663aa0366ceb8f7000d",
    "id": null,
    "metadata": {},
    "name": "GrossBudgetConsumptionReportList_Query",
    "operationKind": "query",
    "text": "query GrossBudgetConsumptionReportList_Query(\n  $projectId: ID!\n  $campaignId: ID!\n  $month: ISO8601Date!\n  $measures: [MeasureInput!]!\n  $dimensions: [DimensionInput!]!\n  $filter: FilterInput!\n  $orders: [OrderInput!]\n) {\n  project(id: $projectId) {\n    ...GrossBudgetConsumptionReportList_project\n    id\n  }\n}\n\nfragment GrossBudgetConsumptionReportList_project on Project {\n  id\n  report(measures: $measures, dimensions: $dimensions, filter: $filter, orders: $orders) {\n    measures\n  }\n  campaign(id: $campaignId) {\n    monthlyBudget {\n      amount\n      id\n    }\n    dailyBudgets(first: 50, month: $month) {\n      edges {\n        node {\n          date\n          baseAmount\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '989f2b9180a98b3d1de483d0cd09d97d';
export default node;
