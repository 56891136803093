/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectHeader_project = {
    readonly " $fragmentRefs": FragmentRefs<"ProjectSelectionButton_project" | "ProjectDrawerMenuList_project">;
    readonly " $refType": "ProjectHeader_project";
};
export type ProjectHeader_project$data = ProjectHeader_project;
export type ProjectHeader_project$key = {
    readonly " $data"?: ProjectHeader_project$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectHeader_project">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectHeader_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectSelectionButton_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectDrawerMenuList_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
(node as any).hash = 'e5920924afb2aa4c16593ce6b8a4253f';
export default node;
