import * as React from "react";
import { graphql } from "react-relay";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { PromotionList_Query } from "~/__relay_artifacts__/PromotionList_Query.graphql";
import { PageHeader } from "~/components/PageHeader";
import { PromotionListTableCard } from "~/components/PromotionListTableCard";

const query = graphql`
  query PromotionList_Query($projectId: ID!) {
    project(id: $projectId) {
      ...PromotionListTableCard_project
    }
  }
`;

export const PromotionList: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props, error } = useQuery<PromotionList_Query>(query, {
    projectId,
  });

  if (error) return <div>{error.message}</div>;
  if (!props) return <div>Loading...</div>;
  if (!props.project) return <div>not found</div>;

  return (
    <>
      <PageHeader title="プロモーション" />
      <PromotionListTableCard projectRef={props.project} />
    </>
  );
};
