import { Grid } from "@material-ui/core";
import { FormikDateTimeField, FormikNumberField } from "@vrize/vrizead-use";
import { useFormikContext } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "~/components/DialogForm";

export type FormValues = {
  periodSince: string;
  periodTill: string | null;
  monthlyBudgetAmount: number;
  dailyBudgetAmount: number;
};

export const schema = yup.object({
  periodSince: yup.string().required(),
  periodTill: yup.string().nullable(),
  monthlyBudgetAmount: yup.number().required(),
  dailyBudgetAmount: yup.number().required(),
});

export const CampaignFormForAdmin: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormikDateTimeField
            required
            fullWidth
            name="periodSince"
            label="開始日"
            minDate={new Date()}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateTimeField
            fullWidth
            name="periodTill"
            label="終了日"
            minDate={values.periodSince ? values.periodSince : new Date()}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField
            required
            fullWidth
            name="monthlyBudgetAmount"
            label="月予算"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikNumberField
            required
            fullWidth
            name="dailyBudgetAmount"
            label="日予算"
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
};
