import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback } from "react";
import { useParams } from "react-router";

import { useCreateTrackerMutation } from "~/mutations/CreateTrackerMutation";

import { FormValues, TrackerForm, schema } from "./TrackerForm";

type Props = {
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  title: "",
  provider: "SINGULAR",
  parameters: [],
  adjustEventCallbackKey: null,
  associationType: "campaign",
};

export const TrackerCreateForm: FC<Props> = ({ onSubmitCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();
  const formErrorHandler = useFormErrorHandler();
  const { createTrackerMutation } = useCreateTrackerMutation();

  const onSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const parameters = values.parameters.reduce(
          (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
          {}
        );
        const parametersJson = JSON.stringify(parameters);
        const { createTracker } = await createTrackerMutation({
          ...values,
          projectId,
          parameters: parametersJson,
        });
        if (!createTracker) throw new Error("assertion failed");
        enqueueSnackbar("トラッカーを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar("トラッカーの作成に失敗しました", { variant: "error" });
        formErrorHandler(err, setErrors);
      }
    },
    [
      createTrackerMutation,
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
      projectId,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <TrackerForm />
    </Formik>
  );
};
