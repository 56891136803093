import { useCallback } from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { useRelayEnvironment } from "relay-hooks";

import {
  BulkUpdateAdPlacementInput,
  BulkUpdateAdPlacementMutation,
} from "~/__relay_artifacts__/BulkUpdateAdPlacementMutation.graphql";

const mutation = graphql`
  mutation BulkUpdateAdPlacementMutation(
    $input: BulkUpdateAdPlacementInput!
    $count: Int
    $cursor: String
    $isEnabled: Boolean
    $tagId: String
  ) {
    bulkUpdateAdPlacement(input: $input) {
      ad {
        id
        latestVersion
        ...PlacementList_ad
      }
    }
  }
`;

export const useBulkUpdateAdPlacementMutation = () => {
  const environment = useRelayEnvironment();

  const bulkUpdateAdPlacementMutation = useCallback(
    (
      input: BulkUpdateAdPlacementInput,
      isEnabled: boolean | null,
      tagId: string | null
    ) =>
      commitMutation<BulkUpdateAdPlacementMutation>(environment, {
        mutation,
        variables: {
          input,
          count: 100,
          isEnabled,
          tagId,
        },
      }),
    [environment]
  );

  return { bulkUpdateAdPlacementMutation };
};
