import { Grid } from "@material-ui/core";
import { FormikNumberField } from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = yup.Asserts<typeof schema>;

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  cpa: yup.number().required(),
  cvr: yup.number().required(),
  cpmCorrectionTerm: yup.number().required(),
  ctrCorrectionTerm: yup.number().required(),
  initialDistributionAlpha: yup.number().required(),
  initialDistributionBeta: yup.number().required(),
});

export const ThompsonFilteringStrategyForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikNumberField
          required
          fullWidth
          name="cpa"
          label="CPA"
          helperText="予測したCTRと目標CPAを使って、適切なbid priceを推論する。"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField
          required
          fullWidth
          name="cvr"
          label="CVR"
          helperText="運用者が予想したCVRを入力する。"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField
          required
          fullWidth
          name="cpmCorrectionTerm"
          label="CPM Correction Term"
          helperText="bid priceの補正係数する。予測したbid priceに、この値を掛けたものを入札時のpriceとして使用する。"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField
          required
          fullWidth
          name="ctrCorrectionTerm"
          label="CTR Correction Term"
          helperText="インターナルオークションで勝ち抜ける確率を運用時に調整したい場合に使用。CTRにこの値をかけたものをインターナルオークションで使用する。"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField
          required
          fullWidth
          name="initialDistributionAlpha"
          label="Initial Distribution Alpha"
          helperText="Thompson Samplingを行う際に使用する事前分布を設定するためのパラメータ。Beta関数のalphaに相当する。一様分布とする場合1を入力する。"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField
          required
          fullWidth
          name="initialDistributionBeta"
          label="Initial Distribution Beta"
          helperText="Thompson Samplingを行う際に使用する事前分布を設定するためのパラメータ。Beta関数のbetaに相当する。一様分布とする場合1を入力する。"
        />
      </Grid>
    </Grid>
  </DialogForm>
);
