import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { graphql } from "react-relay";

import { AdScheduleSetCard_ad } from "~/__relay_artifacts__/AdScheduleSetCard_ad.graphql";
import { AdScheduleSetSelectButton_ad } from "~/__relay_artifacts__/AdScheduleSetSelectButton_ad.graphql";
import {
  AdScheduleSetSelectButtonForm,
  FormValues,
  schema,
} from "~/components/AdScheduleSetSelectButtonForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import deleteScheduleSettingMutation from "~/mutations/DeleteScheduleSettingMutation";
import { useUpdateAdScheduleSetMutation } from "~/mutations/UpdateAdScheduleSetMutation";

type Props = {
  adRef: AdScheduleSetCard_ad;
};

const adFragment = graphql`
  fragment AdScheduleSetSelectButton_ad on Ad {
    id
    latestVersion
    scheduleSetting {
      id
      scheduleSet {
        id
        title
        createdAt
      }
    }
    selectableScheduleSets {
      edges {
        node {
          id
          title
          createdAt
        }
      }
    }
  }
`;

export const AdScheduleSetSelectButton: React.FC<Props> = ({ adRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const ad = useFragment<AdScheduleSetSelectButton_ad>(adFragment, adRef);
  const { updateAdScheduleSetMutation } = useUpdateAdScheduleSetMutation();

  const initialValues = React.useMemo(() => {
    const edges = ad.selectableScheduleSets?.edges || [];
    const scheduleSets = edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return { id: node.id, title: node.title };
    });
    return {
      id: ad.scheduleSetting?.scheduleSet.id,
      selectableItems: scheduleSets,
    } as FormValues;
  }, [ad.scheduleSetting, ad.selectableScheduleSets]);

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const message = "広告のスケジュールセットを更新しました";
        if (!values.id) {
          if (!ad.scheduleSetting) return;
          const { deleteScheduleSetting } = await deleteScheduleSettingMutation(
            environment,
            ad.scheduleSetting.id
          );
          if (!deleteScheduleSetting?.deletedScheduleSettingId) {
            throw new Error("assertion failed");
          }
          enqueueSnackbar(message, { variant: "success" });
        } else {
          const { updateAdScheduleSet } = await updateAdScheduleSetMutation({
            id: ad.id,
            clientVersion: ad.latestVersion,
            scheduleSetId: values.id,
          });
          if (!updateAdScheduleSet?.ad) throw new Error("assertion failed");
          enqueueSnackbar(message, { variant: "success" });
        }
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      ad.id,
      ad.latestVersion,
      ad.scheduleSetting,
      enqueueSnackbar,
      environment,
      formErrorHandler,
      updateAdScheduleSetMutation,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AdScheduleSetSelectButtonForm />
    </Formik>
  );
};
