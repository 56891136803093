import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";
import { FragmentRef, graphql } from "react-relay";

import { AdxSubmitForm_creative } from "~/__relay_artifacts__/AdxSubmitForm_creative.graphql";
import { AdxForm, FormValues, schema } from "~/components/AdxForm";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import submitAdxCreativeMutation from "~/mutations/SubmitAdxCreativeMutation";

type Props = {
  creativeRef: FragmentRef<AdxSubmitForm_creative>;
  onSubmitCompleted: () => void;
};

const creativeFragment = graphql`
  fragment AdxSubmitForm_creative on Creative {
    id
    width
    height
  }
`;

export const AdxSubmitForm: FC<Props> = ({
  creativeRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const environment = useRelayEnvironment();
  const { id, ...rest } = useFragment<AdxSubmitForm_creative>(
    creativeFragment,
    creativeRef
  );

  const initialValues = useMemo(
    () => ({ creativeId: id, adomain: null, ...rest }),
    [id, rest]
  );

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { creativeId, adomain, width, height, ...restValues } = values;
        if (!creativeId) throw new Error("assertion failed");
        if (!adomain) throw new Error("assertion failed");
        if (!width) throw new Error("assertion failed");
        if (!height) throw new Error("assertion failed");
        const { submitAdxCreative } = await submitAdxCreativeMutation(
          environment,
          {
            creativeId,
            adomain,
            width,
            height,
            ...restValues,
          }
        );
        const creative = submitAdxCreative?.creativeEdge?.node;
        if (!creative) throw new Error("assertion failed");
        enqueueSnackbar("Adxにクリエイティブを提出しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [enqueueSnackbar, environment, formErrorHandler, onSubmitCompleted]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      render={AdxForm}
    />
  );
};
