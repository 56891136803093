import * as React from "react";
import { graphql } from "react-relay";
import { Redirect, useParams } from "react-router";
import { useQuery } from "relay-hooks";

import { CampaignRoot_Query } from "~/__relay_artifacts__/CampaignRoot_Query.graphql";

const query = graphql`
  query CampaignRoot_Query($projectId: ID!, $campaignId: ID!) {
    project(id: $projectId) {
      campaign(id: $campaignId) {
        id
      }
    }
  }
`;

const useCampaign = () => {
  const { id, projectId } = useParams<{ projectId: string; id: string }>();
  const { props } = useQuery<CampaignRoot_Query>(query, {
    projectId,
    campaignId: id,
  });

  return {
    projectId,
    isLoading: !props,
    campaign: props && props.project && props.project.campaign,
  };
};

export const CampaignRoot: React.FC = ({ children }) => {
  const { projectId, isLoading, campaign } = useCampaign();
  if (isLoading) return <>Loading...</>;
  if (!campaign) return <Redirect to={`/projects/${projectId}/campaigns`} />;
  return <>{children}</>;
};
