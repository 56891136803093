/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MonthlyBudgetListTableCard_campaign = {
    readonly id: string;
    readonly project: {
        readonly id: string;
    };
    readonly monthlyBudgets: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly date: string;
                readonly amount: number;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly " $fragmentRefs": FragmentRefs<"MonthlyBudgetEditForm_monthlyBudget">;
            } | null;
        } | null> | null;
        readonly totalCount: number;
    };
    readonly " $refType": "MonthlyBudgetListTableCard_campaign";
};
export type MonthlyBudgetListTableCard_campaign$data = MonthlyBudgetListTableCard_campaign;
export type MonthlyBudgetListTableCard_campaign$key = {
    readonly " $data"?: MonthlyBudgetListTableCard_campaign$data;
    readonly " $fragmentRefs": FragmentRefs<"MonthlyBudgetListTableCard_campaign">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "monthlyBudgets"
        ]
      }
    ]
  },
  "name": "MonthlyBudgetListTableCard_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "monthlyBudgets",
      "args": null,
      "concreteType": "MonthlyBudgetConnection",
      "kind": "LinkedField",
      "name": "__MonthlyBudgetListTableCard_monthlyBudgets_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MonthlyBudgetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyBudget",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MonthlyBudgetEditForm_monthlyBudget"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();
(node as any).hash = 'ae3773a962c5de9ecfe4dd5300b2e2fb';
export default node;
