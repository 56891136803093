import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateCampaignDenyTagIdInput,
  CreateCampaignDenyTagIdMutation,
} from "~/__relay_artifacts__/CreateCampaignDenyTagIdMutation.graphql";

const mutation = graphql`
  mutation CreateCampaignDenyTagIdMutation(
    $connections: [ID!]!
    $edgeTypeName: String!
    $input: CreateCampaignDenyTagIdInput!
  ) {
    createCampaignDenyTagId(input: $input) {
      campaignDenyTagId
        @appendNode(connections: $connections, edgeTypeName: $edgeTypeName) {
        id
        tagId
      }
    }
  }
`;

export const createCampaignDenyTagIdMutation = (
  environment: Environment,
  input: CreateCampaignDenyTagIdInput,
  connectionId: string,
  edgeTypeName: string
) => {
  return commitMutation<CreateCampaignDenyTagIdMutation>(environment, {
    mutation,
    variables: {
      input,
      connections: [connectionId],
      edgeTypeName: edgeTypeName,
    },
  });
};

export type { CreateCampaignDenyTagIdInput };
