import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateDenyTagIdInput,
  CreateDenyTagIdMutation,
} from "~/__relay_artifacts__/CreateDenyTagIdMutation.graphql";

const mutation = graphql`
  mutation CreateDenyTagIdMutation($input: CreateDenyTagIdInput!) {
    createDenyTagId(input: $input) {
      denyTagIdEdge {
        node {
          id
          tagId
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateDenyTagIdInput) =>
  commitMutation<CreateDenyTagIdMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: "client:root",
        connectionInfo: [
          {
            key: "DenyTagIdList_denyTagIds",
            rangeBehavior: "append",
          },
        ],
        edgeName: "denyTagIdEdge",
      },
    ],
  });

export default commit;
