import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateScheduleSetInput,
  CreateScheduleSetMutation,
} from "~/__relay_artifacts__/CreateScheduleSetMutation.graphql";

const mutation = graphql`
  mutation CreateScheduleSetMutation($input: CreateScheduleSetInput!) {
    createScheduleSet(input: $input) {
      scheduleSetEdge {
        node {
          id
          title
          createdAt
          ...ScheduleListButton_scheduleSet
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateScheduleSetInput) =>
  commitMutation<CreateScheduleSetMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.projectId,
        connectionInfo: [
          {
            key: "ScheduleSetList_scheduleSets",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "scheduleSetEdge",
      },
    ],
  });

export default commit;
