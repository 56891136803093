/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteCampaignDomainRestrictionInput = {
    clientMutationId?: string | null;
    campaignDomainRestrictionId: string;
};
export type DeleteCampaignDomainRestrictionMutationVariables = {
    connections: Array<string>;
    input: DeleteCampaignDomainRestrictionInput;
};
export type DeleteCampaignDomainRestrictionMutationResponse = {
    readonly deleteCampaignDomainRestriction: {
        readonly deletedCampaignDomainRestrictionId: string;
    } | null;
};
export type DeleteCampaignDomainRestrictionMutation = {
    readonly response: DeleteCampaignDomainRestrictionMutationResponse;
    readonly variables: DeleteCampaignDomainRestrictionMutationVariables;
};



/*
mutation DeleteCampaignDomainRestrictionMutation(
  $input: DeleteCampaignDomainRestrictionInput!
) {
  deleteCampaignDomainRestriction(input: $input) {
    deletedCampaignDomainRestrictionId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedCampaignDomainRestrictionId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCampaignDomainRestrictionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCampaignDomainRestrictionPayload",
        "kind": "LinkedField",
        "name": "deleteCampaignDomainRestriction",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCampaignDomainRestrictionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCampaignDomainRestrictionPayload",
        "kind": "LinkedField",
        "name": "deleteCampaignDomainRestriction",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedCampaignDomainRestrictionId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b44c77caac6b4164e95b377e0a40215",
    "id": null,
    "metadata": {},
    "name": "DeleteCampaignDomainRestrictionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCampaignDomainRestrictionMutation(\n  $input: DeleteCampaignDomainRestrictionInput!\n) {\n  deleteCampaignDomainRestriction(input: $input) {\n    deletedCampaignDomainRestrictionId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b9ce9f7c962ba676b71bc9d8f0205a61';
export default node;
