import { Button, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FormikSelectField, useFormErrorHandler } from "@vrize/vrizead-use";
import { Form, Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo } from "react";

import { useCreateCampaignTrackerSettingMutation } from "~/mutations/CreateCampaignTrackerSettingMutation";
import { useDeleteCampaignTrackerSettingMutation } from "~/mutations/DeleteCampaignTrackerSettingMutation";
import { useUpdateCampaignTrackerSettingMutation } from "~/mutations/UpdateCampaignTrackerSettingMutation";

type FormValues = {
  trackerId: string;
};

type Props = {
  campaignId: string;
  initialTrackerId: FormValues["trackerId"];
  trackerOptions: { value: string; label: string }[];
};

const StyledGridContainer = styled(Grid)({
  width: "100%",
  padding: "8px 16px",
});

export const CampaignTrackerSelectForm: FC<Props> = ({
  campaignId,
  initialTrackerId,
  trackerOptions,
}) => {
  const formErrorHandler = useFormErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { createCampaignTrackerSettingMutation } =
    useCreateCampaignTrackerSettingMutation();
  const { updateCampaignTrackerSettingMutation } =
    useUpdateCampaignTrackerSettingMutation();
  const { deleteCampaignTrackerSettingMutation } =
    useDeleteCampaignTrackerSettingMutation();

  const initialValues = useMemo(
    () => ({ trackerId: initialTrackerId }),
    [initialTrackerId]
  );

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ trackerId }, { setErrors }) => {
      const label = "トラッカー設定";
      try {
        if (initialValues.trackerId !== "" && trackerId === "") {
          const { deleteCampaignTrackerSetting } =
            await deleteCampaignTrackerSettingMutation(campaignId);
          const tracker = deleteCampaignTrackerSetting?.campaign?.tracker;
          if (tracker) throw new Error("assertion failed");
          enqueueSnackbar(`${label}を削除しました`, { variant: "success" });
        } else if (initialValues.trackerId === "" && trackerId !== "") {
          const { createCampaignTrackerSetting } =
            await createCampaignTrackerSettingMutation({
              campaignId,
              trackerId,
            });
          const tracker = createCampaignTrackerSetting?.campaign?.tracker;
          if (!tracker) throw new Error("assertion failed");
          enqueueSnackbar(`${label}を作成しました`, { variant: "success" });
        } else {
          // update
          const { updateCampaignTrackerSetting } =
            await updateCampaignTrackerSettingMutation({
              campaignId,
              trackerId,
            });
          const tracker = updateCampaignTrackerSetting?.campaign?.tracker;
          if (!tracker) throw new Error("assertion failed");
          enqueueSnackbar(`${label}を更新しました`, { variant: "success" });
        }
      } catch (error) {
        formErrorHandler(error, setErrors);
      }
    },
    [
      campaignId,
      enqueueSnackbar,
      formErrorHandler,
      initialValues.trackerId,
      createCampaignTrackerSettingMutation,
      deleteCampaignTrackerSettingMutation,
      updateCampaignTrackerSettingMutation,
    ]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <Form>
          <StyledGridContainer container spacing={3}>
            <Grid item xs={10}>
              <FormikSelectField
                fullWidth
                name="trackerId"
                label="選択中のトラッカー"
                options={trackerOptions}
              />
              {dirty && (
                <p style={{ color: "red" }}>
                  まだトラッカー変更は保存されていません
                </p>
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!dirty}
              >
                保存
              </Button>
            </Grid>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};
