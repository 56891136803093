import * as DateFns from "date-fns";
import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpsertDailyBudgetInput,
  UpsertDailyBudgetMutation,
} from "~/__relay_artifacts__/UpsertDailyBudgetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpsertDailyBudgetMutation(
    $input: UpsertDailyBudgetInput!
    $month: ISO8601Date!
  ) {
    upsertDailyBudget(input: $input) {
      campaign {
        id
        ...DailyBudgetListDataSheet_campaign
      }
    }
  }
`;

export const useUpsertDailyBudgetMutation = () => {
  const environment = useRelayEnvironment();

  const upsertDailyBudgetMutation = React.useCallback(
    (input: UpsertDailyBudgetInput, month: Date) =>
      commitMutation<UpsertDailyBudgetMutation>(environment, {
        mutation,
        variables: { input, month: DateFns.formatISO(month) },
      }),
    [environment]
  );

  return { upsertDailyBudgetMutation };
};
