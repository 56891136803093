import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useOpenState } from "@vrize/vrizead-use";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { Link } from "react-router-dom";

import { ScheduleSetAdListButton_scheduleSet } from "~/__relay_artifacts__/ScheduleSetAdListButton_scheduleSet.graphql";
import { useFragment } from "~/lib/relay-hooks";

import { ListTable } from "./ListTable";
import { ResponsiveDialog } from "./ResponsiveDialog";

type Props = {
  scheduleSetRef: FragmentRef<ScheduleSetAdListButton_scheduleSet>;
  projectId: string;
};

const scheduleSetFragment = graphql`
  fragment ScheduleSetAdListButton_scheduleSet on ScheduleSet {
    id
    ads {
      edges {
        node {
          id
          title
          status
        }
      }
    }
  }
`;

export const ScheduleSetAdListButton: React.FC<Props> = ({
  scheduleSetRef,
  projectId,
}) => {
  const [dialogOpen, openDialog, closeDialog] = useOpenState();
  const scheduleSet = useFragment<ScheduleSetAdListButton_scheduleSet>(
    scheduleSetFragment,
    scheduleSetRef
  );

  const ads = React.useMemo(() => {
    const edges = scheduleSet.ads?.edges || [];
    return edges.map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [scheduleSet.ads]);

  return (
    <>
      <Button variant="outlined" color="inherit" onClick={openDialog}>
        開く
      </Button>
      <ResponsiveDialog
        title="広告一覧"
        open={dialogOpen}
        onClose={closeDialog}
      >
        <ListTable minWidth={700}>
          <TableHead>
            <TableRow>
              <TableCell>タイトル</TableCell>
              <TableCell>ステータス</TableCell>
              <TableCell>詳細</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ads.map((ad) => (
              <TableRow key={ad.id} hover>
                <TableCell>{ad.title}</TableCell>
                <TableCell>{ad.status}</TableCell>
                <TableCell>
                  <Link to={`/projects/${projectId}/ads/${ad.id}`}>
                    {ad.title}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ListTable>
      </ResponsiveDialog>
    </>
  );
};
