import { Grid } from "@material-ui/core";
import { FormikSelectField, FormikTextField } from "@vrize/vrizead-use";
import { FC } from "react";
import * as yup from "yup";

import { DialogForm } from "~/components/DialogForm";
import { ParametersChipForm } from "~/components/ParametersChipForm";

export type FormValues = {
  title: string;
  provider: "SINGULAR" | "APPSFLYER" | "ADJUST" | "VRIZE";
  parameters: {
    key: string;
    value: string;
  }[];
  adjustEventCallbackKey: string | null;
  associationType: string;
};

export const schema = yup.object({
  title: yup.string().required(),
  provider: yup
    .string()
    .oneOf(["SINGULAR", "APPSFLYER", "ADJUST", "VRIZE"])
    .required(),
  parameters: yup
    .array(
      yup.object({
        key: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .required(),
  adjustEventCallbackKey: yup.string().nullable(),
  associationType: yup
    .string()
    .oneOf(["campaign", "creative", "both"], "Invalid association type")
    .required("Association type is required"),
});

const providerOptions = [
  { value: "SINGULAR", label: "SINGULAR" },
  { value: "APPSFLYER", label: "APPSFLYER" },
  { value: "ADJUST", label: "ADJUST" },
  { value: "VRIZE", label: "VRIZE" },
];

const associationTypeOptions = [
  { value: "campaign", label: "campaign" },
  { value: "creative", label: "creative" },
  { value: "both", label: "both" },
];

export const TrackerForm: FC = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          required
          name="title"
          label="トラッカー名"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikSelectField
          required
          fullWidth
          name="provider"
          label="プロバイダ"
          options={providerOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <ParametersChipForm name="parameters" />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          name="adjustEventCallbackKey"
          label="Adjust Event Callback Key"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikSelectField
          required
          fullWidth
          name="associationType"
          label="紐付け対象"
          options={associationTypeOptions}
        />
      </Grid>
    </Grid>
  </DialogForm>
);
