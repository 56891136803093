/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateDenyTagIdInput = {
    clientMutationId?: string | null;
    tagId: string;
};
export type CreateDenyTagIdMutationVariables = {
    input: CreateDenyTagIdInput;
};
export type CreateDenyTagIdMutationResponse = {
    readonly createDenyTagId: {
        readonly denyTagIdEdge: {
            readonly node: {
                readonly id: string;
                readonly tagId: string;
            } | null;
        } | null;
    } | null;
};
export type CreateDenyTagIdMutation = {
    readonly response: CreateDenyTagIdMutationResponse;
    readonly variables: CreateDenyTagIdMutationVariables;
};



/*
mutation CreateDenyTagIdMutation(
  $input: CreateDenyTagIdInput!
) {
  createDenyTagId(input: $input) {
    denyTagIdEdge {
      node {
        id
        tagId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDenyTagIdPayload",
    "kind": "LinkedField",
    "name": "createDenyTagId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DenyTagIdEdge",
        "kind": "LinkedField",
        "name": "denyTagIdEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DenyTagId",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDenyTagIdMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDenyTagIdMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "27805f381d1c2d0fe17fe66d97f45990",
    "id": null,
    "metadata": {},
    "name": "CreateDenyTagIdMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDenyTagIdMutation(\n  $input: CreateDenyTagIdInput!\n) {\n  createDenyTagId(input: $input) {\n    denyTagIdEdge {\n      node {\n        id\n        tagId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '93674a0db2409a423e442b9e17d8e402';
export default node;
