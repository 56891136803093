import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateCreativeInput,
  UpdateCreativeMutation,
} from "~/__relay_artifacts__/UpdateCreativeMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation UpdateCreativeMutation($input: UpdateCreativeInput!) {
    updateCreative(input: $input) {
      creative {
        id
        title
        adm
        height
        width
        createdAt
        updatedAt
        sdkVersion
        periodTill
        periodSince
        metaAdmVersion
        note
        metaOs
        metaPosition
        archived
        latestVersion
        ...CreativeEditForm_creative
      }
    }
  }
`;

export const useUpdateCreativeMutation = () => {
  const environment = useRelayEnvironment();

  const updateCreativeMutation = React.useCallback(
    (input: UpdateCreativeInput) =>
      commitMutation<UpdateCreativeMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateCreativeMutation };
};
