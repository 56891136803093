/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CampaignListForAdmin_QueryVariables = {
    first?: number | null;
    after?: string | null;
    withArchived?: boolean | null;
};
export type CampaignListForAdmin_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CampaignListTableCardForAdmin_root">;
};
export type CampaignListForAdmin_Query = {
    readonly response: CampaignListForAdmin_QueryResponse;
    readonly variables: CampaignListForAdmin_QueryVariables;
};



/*
query CampaignListForAdmin_Query(
  $first: Int
  $after: String
  $withArchived: Boolean
) {
  ...CampaignListTableCardForAdmin_root
}

fragment CampaignEditFormForAdmin_campaign on Campaign {
  id
  title
  status
  bidStrategy
  reportingOption
  goalAmount
  frequencyCapEnabled
  periodSince
  periodTill
  isBanditEnabled
  latestVersion
  dailyBudget {
    id
    date
    baseAmount
  }
  monthlyBudget {
    id
    date
    amount
  }
  categories
}

fragment CampaignListTableCardForAdmin_root on Query {
  campaigns(first: $first, after: $after, orderBy: {field: ID, direction: DESC}, withArchived: $withArchived) {
    edges {
      node {
        ...CampaignEditFormForAdmin_campaign
        id
        title
        status
        bidStrategy
        reportingOption
        fee
        periodSince
        periodTill
        goalAmount
        frequencyCapEnabled
        archived
        os
        isBanditEnabled
        latestVersion
        categories
        project {
          id
        }
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "ID"
    }
  },
  {
    "kind": "Variable",
    "name": "withArchived",
    "variableName": "withArchived"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignListForAdmin_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CampaignListTableCardForAdmin_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignListForAdmin_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CampaignConnection",
        "kind": "LinkedField",
        "name": "campaigns",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bidStrategy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportingOption",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goalAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "frequencyCapEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodSince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "periodTill",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBanditEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DailyBudget",
                    "kind": "LinkedField",
                    "name": "dailyBudget",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baseAmount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MonthlyBudget",
                    "kind": "LinkedField",
                    "name": "monthlyBudget",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "categories",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fee",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "os",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CampaignListTableCard_campaigns",
        "kind": "LinkedHandle",
        "name": "campaigns"
      }
    ]
  },
  "params": {
    "cacheID": "98bc1812d438a9a112f66b31c0ab6c20",
    "id": null,
    "metadata": {},
    "name": "CampaignListForAdmin_Query",
    "operationKind": "query",
    "text": "query CampaignListForAdmin_Query(\n  $first: Int\n  $after: String\n  $withArchived: Boolean\n) {\n  ...CampaignListTableCardForAdmin_root\n}\n\nfragment CampaignEditFormForAdmin_campaign on Campaign {\n  id\n  title\n  status\n  bidStrategy\n  reportingOption\n  goalAmount\n  frequencyCapEnabled\n  periodSince\n  periodTill\n  isBanditEnabled\n  latestVersion\n  dailyBudget {\n    id\n    date\n    baseAmount\n  }\n  monthlyBudget {\n    id\n    date\n    amount\n  }\n  categories\n}\n\nfragment CampaignListTableCardForAdmin_root on Query {\n  campaigns(first: $first, after: $after, orderBy: {field: ID, direction: DESC}, withArchived: $withArchived) {\n    edges {\n      node {\n        ...CampaignEditFormForAdmin_campaign\n        id\n        title\n        status\n        bidStrategy\n        reportingOption\n        fee\n        periodSince\n        periodTill\n        goalAmount\n        frequencyCapEnabled\n        archived\n        os\n        isBanditEnabled\n        latestVersion\n        categories\n        project {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c5523f643fa0ee004f2a1f4e19b1da79';
export default node;
