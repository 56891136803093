import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteDenyTagIdMutation } from "~/__relay_artifacts__/DeleteDenyTagIdMutation.graphql";

const mutation = graphql`
  mutation DeleteDenyTagIdMutation($input: DeleteDenyTagIdInput!) {
    deleteDenyTagId(input: $input) {
      deletedDenyTagIdId
    }
  }
`;

const commit = (environment: Environment, denyTagIdId: string) =>
  commitMutation<DeleteDenyTagIdMutation>(environment, {
    mutation,
    variables: {
      input: { denyTagIdId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: "client:root",
        connectionKeys: [{ key: "DenyTagIdList_denyTagIds" }],
        pathToConnection: ["root", "denyTagIds"],
        deletedIDFieldName: "deletedDenyTagIdId",
      },
    ],
  });

export default commit;
