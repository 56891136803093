import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { useMount } from "react-use";
import { useGlobal } from "reactn";

import { CampaignReportFormContainer_Query } from "~/__relay_artifacts__/CampaignReportFormContainer_Query.graphql";
import { CampaignReportFormContainer_project } from "~/__relay_artifacts__/CampaignReportFormContainer_project.graphql";
import { UserRole } from "~/__relay_artifacts__/Report_Query.graphql";
import { useRefetch } from "~/lib/relay-hooks";

import {
  CampaignReportForm,
  FormValues,
  validationSchema,
} from "./CampaignReportForm";
import { dimensions, measures, orders } from "./constants";

type Props = {
  projectRef: FragmentRef<CampaignReportFormContainer_project>;
  userRole: UserRole;
};

const projectFragment = graphql`
  fragment CampaignReportFormContainer_project on Project {
    id
    campaigns(withArchived: $withArchived) {
      edges {
        node {
          id
          title
          fee
          promotion {
            id
          }
        }
      }
    }
    promotions {
      edges {
        node {
          id
          title
        }
      }
    }
    ...ReportList_project
  }
`;

const query = graphql`
  query CampaignReportFormContainer_Query(
    $projectId: ID!
    $measures: [MeasureInput!]!
    $dimensions: [DimensionInput!]!
    $filter: FilterInput!
    $orders: [OrderInput!]
    $withArchived: Boolean
  ) {
    project(id: $projectId) {
      ...CampaignReportFormContainer_project
    }
  }
`;

const PERIOD_SINCE_MIN_DATE = DateFns.startOfDay(
  new Date(2019, 9, 1)
).toISOString();

/** レポートの取得期間を返す（当日3日前00:00:00から当日の前日23:59:59まで） */
const getPeriods = (role: UserRole) => {
  const currentDate = new Date();
  const currentHours = DateFns.getHours(currentDate);
  const startOfToday = DateFns.startOfDay(currentDate);
  const startDayOfMonth = DateFns.startOfMonth(currentDate);
  // NOTE: 翌日の11:00を超えたら前日を選択できるようにする
  const endOfOneDayAgo =
    currentHours >= 11
      ? DateFns.subDays(startOfToday, 1)
      : DateFns.subDays(startOfToday, 2);
  return {
    periodSince: startDayOfMonth.toISOString(),
    periodTill:
      role === "ADMIN"
        ? DateFns.startOfToday().toISOString()
        : endOfOneDayAgo.toISOString(),
  };
};

const useProject = (projectRef: Props["projectRef"]) => {
  const [project, refetch] = useRefetch<CampaignReportFormContainer_project>(
    projectFragment,
    projectRef
  );

  const refetchProject = React.useCallback(
    (values: FormValues, force: boolean, callback?: (err: any) => void) => {
      const variables = {
        projectId: project.id,
        measures,
        dimensions,
        orders,
        filter: values,
        withArchived: true,
      };
      refetch<CampaignReportFormContainer_Query>(
        query,
        variables,
        undefined,
        callback,
        { force }
      );
    },
    [project.id, refetch]
  );

  return { project, refetchProject };
};

export const CampaignReportFormContainer: React.FC<Props> = ({
  projectRef,
  userRole,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { project, refetchProject } = useProject(projectRef);
  const [caches, setCaches] = useGlobal("reportCache");
  const currentCache = caches[project.id];
  const isCached = !!currentCache;

  const initialCampaignIds = React.useMemo(() => {
    const edges = project.campaigns?.edges || [];
    const node = edges.find((edge) => edge?.node?.promotion == null)?.node;
    return !!node ? [node.id] : [];
  }, [project.campaigns]);

  const initialPromotionIds = React.useMemo(() => {
    const edges = project.promotions?.edges || [];
    const node = edges[0] && edges[0].node;
    return !!node ? [node.id] : [];
  }, [project.promotions]);

  const { periodSince, periodTill } = React.useMemo(
    () => getPeriods(userRole),
    [userRole]
  );

  const initialValues = React.useMemo(
    () =>
      currentCache || {
        campaignIds: initialCampaignIds,
        promotionIds: initialPromotionIds,
        os: ["IOS", "ANDROID"],
        periodSince,
        periodTill,
      },
    [
      currentCache,
      initialCampaignIds,
      initialPromotionIds,
      periodSince,
      periodTill,
    ]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setStatus }) => {
      setCaches({ ...caches, [project.id]: values });
      return new Promise<void>((resolve) =>
        // NOTE: ignore relay cache
        refetchProject(values, true, (error) => {
          if (!!error) {
            if (error.message.includes("MAINTENANCE_ERROR")) {
              setStatus(error);
              return;
            }
            enqueueSnackbar(error.message, { variant: "error" });
          }
          resolve();
        })
      );
    },
    [caches, project.id, refetchProject, setCaches, enqueueSnackbar]
  );

  // NOTE: dispatch `refetch` function on the first rendering if cache.values exists
  useMount(() => {
    if (!currentCache) return;
    // NOTE: use relay cache
    refetchProject(currentCache, false);
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <CampaignReportForm
        isFormCached={isCached}
        project={project}
        periodSinceMinDate={PERIOD_SINCE_MIN_DATE}
        periodTillMaxDate={periodTill}
      />
    </Formik>
  );
};
