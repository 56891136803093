/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateScheduleSetInput = {
    clientMutationId?: string | null;
    projectId: string;
    title: string;
};
export type CreateScheduleSetMutationVariables = {
    input: CreateScheduleSetInput;
};
export type CreateScheduleSetMutationResponse = {
    readonly createScheduleSet: {
        readonly scheduleSetEdge: {
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly createdAt: string;
                readonly " $fragmentRefs": FragmentRefs<"ScheduleListButton_scheduleSet">;
            } | null;
        } | null;
    } | null;
};
export type CreateScheduleSetMutation = {
    readonly response: CreateScheduleSetMutationResponse;
    readonly variables: CreateScheduleSetMutationVariables;
};



/*
mutation CreateScheduleSetMutation(
  $input: CreateScheduleSetInput!
) {
  createScheduleSet(input: $input) {
    scheduleSetEdge {
      node {
        id
        title
        createdAt
        ...ScheduleListButton_scheduleSet
      }
    }
  }
}

fragment ScheduleEditForm_schedule on Schedule {
  id
  since
  till
  latestVersion
}

fragment ScheduleListButton_scheduleSet on ScheduleSet {
  id
  schedules(first: 100) {
    edges {
      node {
        id
        till
        since
        ...ScheduleEditForm_schedule
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateScheduleSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateScheduleSetPayload",
        "kind": "LinkedField",
        "name": "createScheduleSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleSetEdge",
            "kind": "LinkedField",
            "name": "scheduleSetEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleSet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleListButton_scheduleSet"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateScheduleSetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateScheduleSetPayload",
        "kind": "LinkedField",
        "name": "createScheduleSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleSetEdge",
            "kind": "LinkedField",
            "name": "scheduleSetEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleSet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "till",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "since",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "latestVersion",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "schedules(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "ScheduleList_schedules",
                    "kind": "LinkedHandle",
                    "name": "schedules"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83a270ffb951bb0911c86c70d23b2235",
    "id": null,
    "metadata": {},
    "name": "CreateScheduleSetMutation",
    "operationKind": "mutation",
    "text": "mutation CreateScheduleSetMutation(\n  $input: CreateScheduleSetInput!\n) {\n  createScheduleSet(input: $input) {\n    scheduleSetEdge {\n      node {\n        id\n        title\n        createdAt\n        ...ScheduleListButton_scheduleSet\n      }\n    }\n  }\n}\n\nfragment ScheduleEditForm_schedule on Schedule {\n  id\n  since\n  till\n  latestVersion\n}\n\nfragment ScheduleListButton_scheduleSet on ScheduleSet {\n  id\n  schedules(first: 100) {\n    edges {\n      node {\n        id\n        till\n        since\n        ...ScheduleEditForm_schedule\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c1e5dd74ed4a73c736ceb237e5dae4cd';
export default node;
