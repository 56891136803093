import * as DateFns from "date-fns";
import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { GrossBudgetConsumptionReportListContainer_Query } from "~/__relay_artifacts__/GrossBudgetConsumptionReportListContainer_Query.graphql";

import { GrossBudgetConsumptionReportList } from "./GrossBudgetConsumptionReportList";
import { dimensions, measures, orders } from "./constants";

type Props = {
  currentSettingMonth: string;
  projectId: string;
  campaignId: string;
};

const query = graphql`
  query GrossBudgetConsumptionReportListContainer_Query(
    $projectId: ID!
    $campaignId: ID!
    $month: ISO8601Date!
    $measures: [MeasureInput!]!
    $dimensions: [DimensionInput!]!
    $filter: FilterInput!
    $orders: [OrderInput!]
  ) {
    project(id: $projectId) {
      ...GrossBudgetConsumptionReportList_project
    }
  }
`;

export const GrossBudgetConsumptionReportListContainer: React.FC<Props> = ({
  projectId,
  campaignId,
  currentSettingMonth,
}) => {
  const isCurrentMonth =
    DateFns.parseISO(currentSettingMonth).getMonth() ===
    DateFns.startOfToday().getMonth();

  const startOfToday = DateFns.startOfToday();
  const startOfYesterday = DateFns.startOfYesterday();

  const periodSince = DateFns.formatISO(DateFns.startOfMonth(startOfToday));
  const periodTill = DateFns.isFirstDayOfMonth(startOfToday)
    ? DateFns.formatISO(startOfToday)
    : DateFns.formatISO(startOfYesterday);

  const { props, error } =
    useQuery<GrossBudgetConsumptionReportListContainer_Query>(query, {
      projectId,
      campaignId,
      month: currentSettingMonth,
      measures,
      dimensions,
      filter: {
        periodSince,
        periodTill,
        campaignIds: [campaignId],
      },
      orders,
    });

  if (error) return <>{error.message}</>;
  if (!isCurrentMonth) return <>選択月が今月ではありません</>;
  if (!props) return <>Loading</>;
  if (!props.project) return <>not found</>;
  return (
    <GrossBudgetConsumptionReportList
      projectRef={props.project}
      campaignId={campaignId}
      currentSettingMonth={currentSettingMonth}
      reportPeriod={{
        since: periodSince,
        till: periodTill,
      }}
    />
  );
};
