import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { AdDetail_ad } from "~/__relay_artifacts__/AdDetail_ad.graphql";
import { parseISO } from "~/lib/parseISO";
import { useFragment } from "~/lib/relay-hooks";

import { AdScheduleSetCard } from "./AdScheduleSetCard";
import { ThompsonFilteringStrategyCard } from "./ThompsonFilteringStrategyCard";

type Props = {
  adRef: FragmentRef<AdDetail_ad>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(3),
  },
}));

const adFragment = graphql`
  fragment AdDetail_ad on Ad {
    id
    title
    adomain
    status
    createdAt
    ...AdScheduleSetCard_ad
    thompsonFilteringStrategy {
      ...ThompsonFilteringStrategyCard_thompsonFilteringStrategy
    }
  }
`;

export const AdDetail: React.FC<Props> = ({ adRef }) => {
  const classes = useStyles();
  const ad = useFragment<AdDetail_ad>(adFragment, adRef);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">基本設定</Typography>
        </Toolbar>
        <List>
          <ListItem>
            <ListItemText primary="名前" secondary={ad.title} />
          </ListItem>
          <ListItem>
            <ListItemText primary="状態" secondary={ad.status} />
          </ListItem>
          <ListItem>
            <ListItemText primary="作成日" secondary={parseISO(ad.createdAt)} />
          </ListItem>
        </List>
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="subtitle1">目標設定</Typography>
        </Toolbar>
      </Paper>
      <AdScheduleSetCard adRef={ad} />
      <ThompsonFilteringStrategyCard
        thompsonFilteringStrategyRef={ad.thompsonFilteringStrategy}
      />
    </div>
  );
};
