import {
  AppBar,
  Box,
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  Slide,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Close as CloseIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import * as React from "react";

import { useMobile } from "~/lib/useMobile";

export type Props = {
  title?: string;
  fullScreen?: boolean;
  minWidth?: number;
  toolbarContent?: React.ReactNode;
  withMargin?: boolean;
} & DialogProps;

const StyledMobileAppBar = styled(AppBar)<Theme, { withMargin: boolean }>(
  ({ theme, withMargin }) => ({
    position: "sticky",
    marginBottom: withMargin ? theme.spacing(2) : 0,
  })
);

const StyledMobileTitle = styled(Typography)<Theme>(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}));

const StyledWrapper = styled("div")<
  Theme,
  { isMobile: boolean; minWidth?: number }
>(({ isMobile, minWidth }) => ({
  minWidth: !isMobile ? minWidth : undefined,
  maxHeight: !isMobile ? "80vh" : undefined,
}));

const Transition = React.forwardRef<
  HTMLDivElement,
  Omit<TransitionProps, "direction" | "ref">
  // @ts-expect-error
>((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ResponsiveDialog: React.FC<Props> = ({
  children,
  title,
  minWidth,
  toolbarContent,
  fullScreen = false,
  withMargin = true,
  onClose,
  ...dialogProps
}) => {
  const { isMobile } = useMobile();
  return (
    <Dialog
      {...dialogProps}
      TransitionComponent={Transition}
      fullScreen={fullScreen || isMobile}
      onClose={onClose}
    >
      <StyledWrapper isMobile={isMobile} minWidth={minWidth}>
        {fullScreen || isMobile ? (
          <StyledMobileAppBar withMargin={withMargin}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onClose as any}>
                <CloseIcon />
              </IconButton>
              {title && (
                <StyledMobileTitle variant="h6">{title}</StyledMobileTitle>
              )}
              {toolbarContent}
            </Toolbar>
          </StyledMobileAppBar>
        ) : (
          title && (
            <Box display="flex">
              <DialogTitle>{title}</DialogTitle>
              {toolbarContent}
            </Box>
          )
        )}
        {children}
      </StyledWrapper>
    </Dialog>
  );
};
