/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlacementListTableCard_QueryVariables = {
    adSlotId: string;
    first?: number | null;
    after?: string | null;
    isDistributedCampaigns?: boolean | null;
};
export type AdSlotPlacementListTableCard_QueryResponse = {
    readonly adSlot: {
        readonly " $fragmentRefs": FragmentRefs<"AdSlotPlacementListTableCard_adSlot">;
    };
};
export type AdSlotPlacementListTableCard_Query = {
    readonly response: AdSlotPlacementListTableCard_QueryResponse;
    readonly variables: AdSlotPlacementListTableCard_QueryVariables;
};



/*
query AdSlotPlacementListTableCard_Query(
  $adSlotId: ID!
  $first: Int
  $after: String
  $isDistributedCampaigns: Boolean
) {
  adSlot(id: $adSlotId) {
    ...AdSlotPlacementListTableCard_adSlot
    id
  }
}

fragment AdSlotPlacementListTableCard_adSlot on AdSlot {
  id
  name
  tagId
  sspProvider
  width
  height
  platform
  bundle
  adxBillingId
  placements(first: $first, after: $after, isDistributedCampaigns: $isDistributedCampaigns) {
    edges {
      node {
        id
        isEnabled
        correctionTerm
        proportion
        latestVersion
        ad {
          id
          title
          campaign {
            id
            title
            project {
              id
            }
          }
        }
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adSlotId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDistributedCampaigns"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "adSlotId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isDistributedCampaigns",
    "variableName": "isDistributedCampaigns"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdSlotPlacementListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AdSlot",
        "kind": "LinkedField",
        "name": "adSlot",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdSlotPlacementListTableCard_adSlot"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdSlotPlacementListTableCard_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AdSlot",
        "kind": "LinkedField",
        "name": "adSlot",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tagId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sspProvider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platform",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bundle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adxBillingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "PlacementConnection",
            "kind": "LinkedField",
            "name": "placements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlacementEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Placement",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "correctionTerm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "proportion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "latestVersion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Ad",
                        "kind": "LinkedField",
                        "name": "ad",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Campaign",
                            "kind": "LinkedField",
                            "name": "campaign",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Project",
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "isDistributedCampaigns"
            ],
            "handle": "connection",
            "key": "AdSlotPlacementListTableCard_placements",
            "kind": "LinkedHandle",
            "name": "placements"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4c3d3b32b5aed5bfaa78505754a4bf2",
    "id": null,
    "metadata": {},
    "name": "AdSlotPlacementListTableCard_Query",
    "operationKind": "query",
    "text": "query AdSlotPlacementListTableCard_Query(\n  $adSlotId: ID!\n  $first: Int\n  $after: String\n  $isDistributedCampaigns: Boolean\n) {\n  adSlot(id: $adSlotId) {\n    ...AdSlotPlacementListTableCard_adSlot\n    id\n  }\n}\n\nfragment AdSlotPlacementListTableCard_adSlot on AdSlot {\n  id\n  name\n  tagId\n  sspProvider\n  width\n  height\n  platform\n  bundle\n  adxBillingId\n  placements(first: $first, after: $after, isDistributedCampaigns: $isDistributedCampaigns) {\n    edges {\n      node {\n        id\n        isEnabled\n        correctionTerm\n        proportion\n        latestVersion\n        ad {\n          id\n          title\n          campaign {\n            id\n            title\n            project {\n              id\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '86bdf35b3ba0ab5eccf355d495b74637';
export default node;
