/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteScheduleInput = {
    clientMutationId?: string | null;
    scheduleId: string;
};
export type DeleteScheduleMutationVariables = {
    input: DeleteScheduleInput;
};
export type DeleteScheduleMutationResponse = {
    readonly deleteSchedule: {
        readonly deletedScheduleId: string;
    } | null;
};
export type DeleteScheduleMutation = {
    readonly response: DeleteScheduleMutationResponse;
    readonly variables: DeleteScheduleMutationVariables;
};



/*
mutation DeleteScheduleMutation(
  $input: DeleteScheduleInput!
) {
  deleteSchedule(input: $input) {
    deletedScheduleId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSchedulePayload",
    "kind": "LinkedField",
    "name": "deleteSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedScheduleId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteScheduleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteScheduleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5070aa78ac4659ce3ca9b4649e9e9336",
    "id": null,
    "metadata": {},
    "name": "DeleteScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteScheduleMutation(\n  $input: DeleteScheduleInput!\n) {\n  deleteSchedule(input: $input) {\n    deletedScheduleId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ddd2a3ce0c91c8eb64bbb02afa4aab91';
export default node;
