/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreativeNoteEditForm_creative = {
    readonly id: string;
    readonly title: string;
    readonly adm: string | null;
    readonly width: number | null;
    readonly height: number | null;
    readonly iurl: string | null;
    readonly tagList: ReadonlyArray<string> | null;
    readonly note: string | null;
    readonly latestVersion: number | null;
    readonly " $refType": "CreativeNoteEditForm_creative";
};
export type CreativeNoteEditForm_creative$data = CreativeNoteEditForm_creative;
export type CreativeNoteEditForm_creative$key = {
    readonly " $data"?: CreativeNoteEditForm_creative$data;
    readonly " $fragmentRefs": FragmentRefs<"CreativeNoteEditForm_creative">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreativeNoteEditForm_creative",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iurl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tagList",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "Creative",
  "abstractKey": null
};
(node as any).hash = 'cbbc0c94b79319d4e130c54167acebae';
export default node;
