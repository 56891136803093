import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { useQuery } from "relay-hooks";

import { PlacementSettingFormList_Query } from "~/__relay_artifacts__/PlacementSettingFormList_Query.graphql";
import { PlacementSettingListTableCard_placementSet } from "~/__relay_artifacts__/PlacementSettingListTableCard_placementSet.graphql";
import { ListTable } from "~/components/ListTable";
import { SearchDialogButton } from "~/components/SearchDialogButton";
import { TruncatedTextWithTooltip } from "~/components/TruncatedTextWithTooltip";
import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { usePlacementSettingAdSlotFilterCache } from "~/globalState";
import { noop } from "~/lib/noop";
import { useRelayEnvironment } from "~/lib/relay-hooks";
import deletePlacementSettingMutation from "~/mutations/DeletePlacementSettingMutation";

import { PlacementSettingAdSlotBulkInsertDialog } from "./PlacementSettingAdSlotBulkInsertDialog";
import { PlacementSettingAdSlotFilterForm } from "./PlacementSettingAdSlotFilterForm";
import { PlacementSettingFormList, Query } from "./PlacementSettingFormList";

type Props = {
  relay: RelayPaginationProp;
  placementSet: PlacementSettingListTableCard_placementSet;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    width: 100,
  },
  actions: {
    display: "flex",
  },
}));

const baseVariables = {
  withDeleted: false,
  orderBy: { field: "NAME", direction: "DESC" } as const,
  domain: null,
  tagId: null,
  bundle: null,
  platform: [],
  logicType: [],
  withArchived: false,
};

const PlacementSettingListTableCard: FC<Props> = ({ relay, placementSet }) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [search, setSearch] = useState("");
  const classes = useStyles();
  const environment = useRelayEnvironment();
  const { enqueueSnackbar } = useSnackbar();
  const [filterValues, setFilterValues] = usePlacementSettingAdSlotFilterCache(
    placementSet.id
  );
  const { props } = useQuery<PlacementSettingFormList_Query>(Query, {
    ...filterValues,
    ...baseVariables,
    search,
    sspProvider: filterValues.sspProvider ? [filterValues.sspProvider] : [],
    platform: filterValues.platform ? [filterValues.platform] : [],
  });
  const totalCount = placementSet.placementSettings.totalCount;

  const placementSettings = useMemo(() => {
    const edges = placementSet?.placementSettings?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [page, perPage, placementSet]);

  const handleDeleteClick = useCallback(
    async (placementSettingId: string) => {
      try {
        const { deletePlacementSetting } = await deletePlacementSettingMutation(
          environment,
          placementSet.id,
          placementSettingId
        );
        if (!deletePlacementSetting?.deletedPlacementSettingId) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("プレイスメントセットに紐づくAdSlotを削除しました", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, environment, placementSet.id]
  );

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          一覧
        </Typography>
        <SearchDialogButton
          title="AdSlot追加"
          placeholder="Tag ID or 名前で検索"
          onSubmit={setSearch}
          renderFilterContent={({ close }) => (
            <PlacementSettingAdSlotFilterForm
              initialValues={filterValues}
              onSubmit={(values) => {
                setFilterValues(values);
                close();
              }}
            />
          )}
          renderDialogContent={() =>
            props && (
              <PlacementSettingFormList
                adSlotsRef={props}
                placementSettingRef={placementSet.placementSettings}
              />
            )
          }
          renderFreeContent={({ close }) =>
            props && (
              <PlacementSettingAdSlotBulkInsertDialog
                name={search}
                filterFormValues={filterValues}
                onSubmitCompleted={close}
              />
            )
          }
          freeContentTitle="一括登録"
        >
          作成
        </SearchDialogButton>
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>名前</TableCell>
            <TableCell>プロバイダ</TableCell>
            <TableCell>タグID</TableCell>
            <TableCell>プラットフォーム</TableCell>
            <TableCell>バンドル</TableCell>
            <TableCell>ドメイン</TableCell>
            <TableCell>サイズ</TableCell>
            <TableCell>ADX BILLING ID</TableCell>
            <TableCell>目標勝率</TableCell>
            <TableCell>メモ</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placementSettings.map((placementSetting) => (
            <TableRow key={placementSetting.id}>
              <TableCell>{atob(placementSetting.adSlot.id)}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip
                  tooltip={placementSetting.adSlot.name || "未設定"}
                >
                  {placementSetting.adSlot.name}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>{placementSetting.adSlot.sspProvider}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip
                  tooltip={placementSetting.adSlot.tagId}
                >
                  {placementSetting.adSlot.tagId}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>
                {placementSetting.adSlot.platform || "未設定"}
              </TableCell>
              <TableCell>
                {placementSetting.adSlot.bundle || "未設定"}
              </TableCell>
              <TableCell>{placementSetting.adSlot.domain}</TableCell>
              <TableCell>
                <TruncatedTextWithTooltip
                  tooltip={
                    "banner.format (h,w) : " +
                      placementSetting.adSlot.bannerFormat || ""
                  }
                >
                  {placementSetting.adSlot.width || "未設定"},{" "}
                  {placementSetting.adSlot.height || "未設定"}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>
                {placementSetting.adSlot.adxBillingId || "未設定"}
              </TableCell>
              <TableCell>
                {placementSetting.adSlot.targetWinRate * 100}%
              </TableCell>
              <TableCell>
                <TruncatedTextWithTooltip
                  tooltip={placementSetting.adSlot.note || "メモ無し"}
                >
                  {placementSetting.adSlot.note}
                </TruncatedTextWithTooltip>
              </TableCell>
              <TableCell>
                <ConfirmButton
                  variant="outlined"
                  color="secondary"
                  confirmTitle="このAdSlotをデータセットから削除しますか？"
                  onAgree={() => handleDeleteClick(placementSetting.id)}
                >
                  削除
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(event) =>
          setPerPage(parseInt(event.target.value))
        }
      />
    </Paper>
  );
};

export default createPaginationContainer(
  PlacementSettingListTableCard,
  {
    placementSet: graphql`
      fragment PlacementSettingListTableCard_placementSet on PlacementSet {
        id
        placementSettings(first: $first, after: $after)
          @connection(
            key: "PlacementSettingListTableCard_placementSettings"
            filters: []
          ) {
          edges {
            node {
              id
              adSlot {
                id
                name
                domain
                tagId
                bundle
                note
                width
                height
                sspProvider
                platform
                bannerFormat
                deletedAt
                adxBillingId
                targetWinRate
              }
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.placementSet.placementSettings as any;
    },
    getVariables(props, { count, cursor }) {
      return {
        placementSetId: props.placementSet.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query PlacementSettingListTableCard_Query(
        $placementSetId: ID!
        $first: Int
        $after: String
      ) {
        placementSet(id: $placementSetId) {
          title
          ...PlacementSettingListTableCard_placementSet
        }
      }
    `,
  }
);
