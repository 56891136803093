import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";

import { useCreateExcludeScheduleMutation } from "~/mutations/CreateExcludeScheduleMutation";

import { ExcludeScheduleForm, FormValues, schema } from "./ExcludeScheduleForm";

type Props = {
  campaignId: string;
  onSubmitCompleted: () => void;
};

const initialValues = {
  name: "",
  since: new Date().toISOString(),
  till: new Date().toISOString(),
};

export const ExcludeScheduleCreateForm: React.FC<Props> = ({
  campaignId,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { createExcludeScheduleMutation } = useCreateExcludeScheduleMutation();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createExcludeSchedule } = await createExcludeScheduleMutation({
          ...values,
          campaignId,
        });
        if (!createExcludeSchedule?.excludeScheduleEdge) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("配信停止設定を作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      campaignId,
      createExcludeScheduleMutation,
      enqueueSnackbar,
      formErrorHandler,
      onSubmitCompleted,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <ExcludeScheduleForm />
    </Formik>
  );
};
