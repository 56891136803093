import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteCampaignDenyTagIdMutation } from "~/__relay_artifacts__/DeleteCampaignDenyTagIdMutation.graphql";

const mutation = graphql`
  mutation DeleteCampaignDenyTagIdMutation(
    $connections: [ID!]!
    $input: DeleteCampaignDenyTagIdInput!
  ) {
    deleteCampaignDenyTagId(input: $input) {
      deletedId @deleteEdge(connections: $connections)
    }
  }
`;

export const deleteCampaignDenyTagIdMutation = (
  environment: Environment,
  campaignDenyTagIdId: string,
  connectionID: string
) =>
  commitMutation<DeleteCampaignDenyTagIdMutation>(environment, {
    mutation,
    variables: {
      input: { id: campaignDenyTagIdId },
      connections: [connectionID],
    },
  });
