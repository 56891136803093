/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotNoteEditForm_placement = {
    readonly adSlot: {
        readonly note: string | null;
    };
    readonly " $refType": "AdSlotNoteEditForm_placement";
};
export type AdSlotNoteEditForm_placement$data = AdSlotNoteEditForm_placement;
export type AdSlotNoteEditForm_placement$key = {
    readonly " $data"?: AdSlotNoteEditForm_placement$data;
    readonly " $fragmentRefs": FragmentRefs<"AdSlotNoteEditForm_placement">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdSlotNoteEditForm_placement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdSlot",
      "kind": "LinkedField",
      "name": "adSlot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Placement",
  "abstractKey": null
};
(node as any).hash = '28022f222e6efcaa82c7b07040281e5f';
export default node;
