import { useSnackbar } from "notistack";
import * as React from "react";

import { ConfirmButton } from "~/components/atoms/ConfirmButton";
import { modernEnvironment } from "~/lib/Environment";
import deleteScheduleSetMutation from "~/mutations/DeleteScheduleSetMutation";

type Props = {
  scheduleSetId: string;
  projectId: string;
};

export const ScheduleSetDeleteButton: React.FC<Props> = ({
  children,
  scheduleSetId,
  projectId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = React.useCallback(async () => {
    try {
      const { deleteScheduleSet } = await deleteScheduleSetMutation(
        modernEnvironment,
        scheduleSetId,
        projectId
      );
      const id = deleteScheduleSet?.deletedScheduleSetId;
      if (!id) throw new Error("assertion failed");
      enqueueSnackbar(`スケジュールセットを削除しました。`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [enqueueSnackbar, projectId, scheduleSetId]);

  return (
    <ConfirmButton
      variant="outlined"
      color="secondary"
      confirmTitle="このスケジュールセットを削除しますか？"
      confirmContent=""
      onAgree={(changeDialog) => {
        onDelete().then(() => changeDialog(false));
      }}
    >
      {children}
    </ConfirmButton>
  );
};
