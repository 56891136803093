import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  CreateMaintenanceInput,
  CreateMaintenanceMutation,
} from "~/__relay_artifacts__/CreateMaintenanceMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation CreateMaintenanceMutation($input: CreateMaintenanceInput!) {
    createMaintenance(input: $input) {
      maintenanceEdge {
        node {
          id
          message
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const useCreateMaintenanceMutation = () => {
  const environment = useRelayEnvironment();

  const createMaintenanceMutation = React.useCallback(
    (input: CreateMaintenanceInput) =>
      commitMutation<CreateMaintenanceMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_ADD",
            parentID: "client:root",
            edgeName: "maintenanceEdge",
            connectionInfo: [
              {
                key: "MaintenanceListTableCard_maintenances",
                rangeBehavior: "prepend",
              },
            ],
          },
        ],
      }),
    [environment]
  );

  return { createMaintenanceMutation };
};
