import * as React from "react";
import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  DeleteDailyBudgetInput,
  DeleteDailyBudgetMutation,
} from "~/__relay_artifacts__/DeleteDailyBudgetMutation.graphql";
import { useRelayEnvironment } from "~/lib/relay-hooks";

const mutation = graphql`
  mutation DeleteDailyBudgetMutation($input: DeleteDailyBudgetInput!) {
    deleteDailyBudget(input: $input) {
      deletedDailyBudgetId
    }
  }
`;

export const useDeleteDailyBudgetMutation = (campaignId: string) => {
  const environment = useRelayEnvironment();

  const deleteDailyBudgetMutation = React.useCallback(
    (input: DeleteDailyBudgetInput) =>
      commitMutation<DeleteDailyBudgetMutation>(environment, {
        mutation,
        variables: { input },
        configs: [
          {
            type: "RANGE_DELETE",
            parentID: campaignId,
            connectionKeys: [{ key: "DailyBudgetListDataSheet_dailyBudgets" }],
            pathToConnection: ["campaign", "dailyBudgets"],
            deletedIDFieldName: "deletedDailyBudgetId",
          },
        ],
      }),
    [campaignId, environment]
  );

  return { deleteDailyBudgetMutation };
};
