/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BidStrategy = "MANUAL" | "ML" | "%future added value";
export type CampaignReportingOption = "CPI" | "CPM" | "SIMEJI" | "%future added value";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type CampaignSetting_project = {
    readonly trackers: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly associationType: string;
            } | null;
        } | null> | null;
    };
    readonly campaign: {
        readonly id: string;
        readonly title: string;
        readonly status: CampaignStatus;
        readonly bidStrategy: BidStrategy;
        readonly reportingOption: CampaignReportingOption;
        readonly monthlyBudget: {
            readonly amount: number;
        };
        readonly dailyBudget: {
            readonly baseAmount: number;
        };
        readonly createdAt: string;
        readonly adomain: string | null;
        readonly tracker: {
            readonly id: string;
            readonly title: string;
            readonly associationType: string;
        } | null;
    } | null;
    readonly " $refType": "CampaignSetting_project";
};
export type CampaignSetting_project$data = CampaignSetting_project;
export type CampaignSetting_project$key = {
    readonly " $data"?: CampaignSetting_project$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignSetting_project">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "associationType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "campaignId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignSetting_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackerConnection",
      "kind": "LinkedField",
      "name": "trackers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TrackerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Tracker",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "campaignId"
        }
      ],
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bidStrategy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reportingOption",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MonthlyBudget",
          "kind": "LinkedField",
          "name": "monthlyBudget",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DailyBudget",
          "kind": "LinkedField",
          "name": "dailyBudget",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baseAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adomain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tracker",
          "kind": "LinkedField",
          "name": "tracker",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();
(node as any).hash = '95aaca233c927868e5d38faf52d851c9';
export default node;
