import * as React from "react";

export class ErrorBoundary extends React.Component {
  // @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  // @ts-ignore
  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    // @ts-ignore
    if (this.state.error) {
      return (
        <div>
          {/* 
            // @ts-ignore */}
          <h2>{this.state.error.toString()}</h2>
        </div>
      );
    }
    return this.props.children;
  }
}
