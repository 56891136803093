/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExpenseUpsertForm_QueryVariables = {
    campaignId: string;
    date: string;
};
export type ExpenseUpsertForm_QueryResponse = {
    readonly campaign: {
        readonly maxAssignableExpenseRate: number | null;
        readonly expense: {
            readonly id: string;
            readonly rate: number;
        } | null;
    } | null;
};
export type ExpenseUpsertForm_Query = {
    readonly response: ExpenseUpsertForm_QueryResponse;
    readonly variables: ExpenseUpsertForm_QueryVariables;
};



/*
query ExpenseUpsertForm_Query(
  $campaignId: ID!
  $date: ISO8601Date!
) {
  campaign(id: $campaignId) {
    maxAssignableExpenseRate(date: $date)
    expense(date: $date) {
      id
      rate
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "maxAssignableExpenseRate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "Expense",
  "kind": "LinkedField",
  "name": "expense",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rate",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExpenseUpsertForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExpenseUpsertForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63623be17e75176b7de66f2bdbff1eeb",
    "id": null,
    "metadata": {},
    "name": "ExpenseUpsertForm_Query",
    "operationKind": "query",
    "text": "query ExpenseUpsertForm_Query(\n  $campaignId: ID!\n  $date: ISO8601Date!\n) {\n  campaign(id: $campaignId) {\n    maxAssignableExpenseRate(date: $date)\n    expense(date: $date) {\n      id\n      rate\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '1f8dec9cfb23275cbebef5bed9c872f1';
export default node;
