import * as R from "ramda";
import * as React from "react";
import { graphql } from "react-relay";
import { useMount } from "react-use";
import { useGlobal } from "reactn";
import { useQuery } from "relay-hooks";

import { AdSlotList_Query } from "~/__relay_artifacts__/AdSlotList_Query.graphql";
import {
  AdSlotFilterFormValues,
  AdSlotListTableCard,
} from "~/components/AdSlotListTableCard";
import { PageHeader } from "~/components/PageHeader";
import { useQueryString } from "~/lib/useQueryString";

const query = graphql`
  query AdSlotList_Query(
    $count: Int
    $cursor: String
    $withDeleted: Boolean!
    $orderBy: AdSlotOrder!
    $domain: String
    $bundle: String
    $width: Int
    $height: Int
    $sspProvider: [AdSlotSspProvider!]!
    $platform: [AdSlotPlatform!]!
    $logicType: [PricingLogic!]!
    $adxBillingId: String
    $search: String
    $withArchived: Boolean
  ) {
    ...AdSlotListTableCard_root
  }
`;

const useCachedVariables = () => {
  const { params } = useQueryString<AdSlotFilterFormValues>();
  const [caches, setCaches] = useGlobal("adSlotFilterCache");
  const [variables] = React.useState({
    count: 100,
    cursor: null,
    withDeleted: params.withDeleted || caches.withDeleted,
    orderBy: {
      field: params.orderField || caches.orderField,
      direction: params.orderDirection || caches.orderDirection,
    },
    domain: params.domain || caches.domain,
    bundle: params.bundle || caches.bundle,
    width: params.width || caches.width,
    height: params.height || caches.height,
    sspProvider: params.sspProvider || caches.sspProvider,
    platform: params.platform || caches.platform,
    logicType: params.logicType || caches.logicType,
    adxBillingId: params.adxBillingId || caches.adxBillingId,
    search: "",
    withArchived: false,
  });

  useMount(() =>
    setCaches(
      R.omit(["search", "count", "cursor", "orderBy", "withArchived"], {
        ...variables,
        orderField: variables.orderBy.field,
        orderDirection: variables.orderBy.direction,
      })
    )
  );

  return { variables };
};

export const AdSlotList: React.FC = () => {
  const { variables } = useCachedVariables();
  const { props, error } = useQuery<AdSlotList_Query>(query, variables);

  return (
    <>
      <PageHeader shouldUseHistoryBack title="配信枠管理" />
      {(() => {
        if (error) return error.message;
        if (!props) return "Loading...";
        return <AdSlotListTableCard root={props} />;
      })()}
    </>
  );
};
