/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AdSlotOrderField = "BUNDLE" | "CREATED_AT" | "DOMAIN" | "NAME" | "SSP_PROVIDER" | "TAG_ID" | "UPDATED_AT" | "%future added value";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type PricingLogic = "FLOOR_PLUS" | "MANUAL" | "ML" | "%future added value";
export type AdSlotOrder = {
    direction: OrderDirection;
    field: AdSlotOrderField;
};
export type PlacementSettingFormList_QueryVariables = {
    count?: number | null;
    cursor?: string | null;
    withDeleted: boolean;
    orderBy: AdSlotOrder;
    domain?: string | null;
    bundle?: string | null;
    width?: number | null;
    height?: number | null;
    sspProvider: Array<AdSlotSspProvider>;
    platform: Array<AdSlotPlatform>;
    logicType: Array<PricingLogic>;
    adxBillingId?: string | null;
    search?: string | null;
    withArchived?: boolean | null;
};
export type PlacementSettingFormList_QueryResponse = {
    readonly adSlots: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly sspProvider: AdSlotSspProvider;
                readonly tagId: string | null;
                readonly platform: AdSlotPlatform | null;
                readonly bundle: string | null;
                readonly width: number | null;
                readonly height: number | null;
                readonly adxBillingId: string | null;
                readonly targetWinRate: number;
            } | null;
        } | null> | null;
    };
};
export type PlacementSettingFormList_Query = {
    readonly response: PlacementSettingFormList_QueryResponse;
    readonly variables: PlacementSettingFormList_QueryVariables;
};



/*
query PlacementSettingFormList_Query(
  $count: Int
  $cursor: String
  $withDeleted: Boolean!
  $orderBy: AdSlotOrder!
  $domain: String
  $bundle: String
  $width: Int
  $height: Int
  $sspProvider: [AdSlotSspProvider!]!
  $platform: [AdSlotPlatform!]!
  $logicType: [PricingLogic!]!
  $adxBillingId: String
  $search: String
  $withArchived: Boolean
) {
  adSlots(first: $count, after: $cursor, withDeleted: $withDeleted, orderBy: $orderBy, domain: $domain, bundle: $bundle, width: $width, height: $height, sspProvider: $sspProvider, platform: $platform, logicType: $logicType, adxBillingId: $adxBillingId, search: $search, withArchived: $withArchived) {
    edges {
      node {
        id
        name
        sspProvider
        tagId
        platform
        bundle
        width
        height
        adxBillingId
        targetWinRate
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adxBillingId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bundle"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domain"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "height"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "logicType"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sspProvider"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "width"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withDeleted"
},
v14 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "adxBillingId",
        "variableName": "adxBillingId"
      },
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "cursor"
      },
      {
        "kind": "Variable",
        "name": "bundle",
        "variableName": "bundle"
      },
      {
        "kind": "Variable",
        "name": "domain",
        "variableName": "domain"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "count"
      },
      {
        "kind": "Variable",
        "name": "height",
        "variableName": "height"
      },
      {
        "kind": "Variable",
        "name": "logicType",
        "variableName": "logicType"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "platform",
        "variableName": "platform"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sspProvider",
        "variableName": "sspProvider"
      },
      {
        "kind": "Variable",
        "name": "width",
        "variableName": "width"
      },
      {
        "kind": "Variable",
        "name": "withArchived",
        "variableName": "withArchived"
      },
      {
        "kind": "Variable",
        "name": "withDeleted",
        "variableName": "withDeleted"
      }
    ],
    "concreteType": "AdSlotConnection",
    "kind": "LinkedField",
    "name": "adSlots",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdSlotEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlot",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sspProvider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platform",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bundle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adxBillingId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "targetWinRate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlacementSettingFormList_Query",
    "selections": (v14/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v13/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v11/*: any*/),
      (v5/*: any*/),
      (v10/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlacementSettingFormList_Query",
    "selections": (v14/*: any*/)
  },
  "params": {
    "cacheID": "b2d674c2f0c8440247d7ce4b663a66c9",
    "id": null,
    "metadata": {},
    "name": "PlacementSettingFormList_Query",
    "operationKind": "query",
    "text": "query PlacementSettingFormList_Query(\n  $count: Int\n  $cursor: String\n  $withDeleted: Boolean!\n  $orderBy: AdSlotOrder!\n  $domain: String\n  $bundle: String\n  $width: Int\n  $height: Int\n  $sspProvider: [AdSlotSspProvider!]!\n  $platform: [AdSlotPlatform!]!\n  $logicType: [PricingLogic!]!\n  $adxBillingId: String\n  $search: String\n  $withArchived: Boolean\n) {\n  adSlots(first: $count, after: $cursor, withDeleted: $withDeleted, orderBy: $orderBy, domain: $domain, bundle: $bundle, width: $width, height: $height, sspProvider: $sspProvider, platform: $platform, logicType: $logicType, adxBillingId: $adxBillingId, search: $search, withArchived: $withArchived) {\n    edges {\n      node {\n        id\n        name\n        sspProvider\n        tagId\n        platform\n        bundle\n        width\n        height\n        adxBillingId\n        targetWinRate\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '19436b4b684169ef15018f9e3002943b';
export default node;
