/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpsertExpenseInput = {
    clientMutationId?: string | null;
    campaignId: string;
    date: string;
    rate?: number | null;
    clientVersion?: number | null;
};
export type UpsertExpenseMutationVariables = {
    input: UpsertExpenseInput;
};
export type UpsertExpenseMutationResponse = {
    readonly upsertExpense: {
        readonly expense: {
            readonly id: string;
            readonly date: string;
            readonly rate: number;
        } | null;
    } | null;
};
export type UpsertExpenseMutation = {
    readonly response: UpsertExpenseMutationResponse;
    readonly variables: UpsertExpenseMutationVariables;
};



/*
mutation UpsertExpenseMutation(
  $input: UpsertExpenseInput!
) {
  upsertExpense(input: $input) {
    expense {
      id
      date
      rate
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertExpensePayload",
    "kind": "LinkedField",
    "name": "upsertExpense",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Expense",
        "kind": "LinkedField",
        "name": "expense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertExpenseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertExpenseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ad6f61939e7cf189b622fcb74eb31ed5",
    "id": null,
    "metadata": {},
    "name": "UpsertExpenseMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertExpenseMutation(\n  $input: UpsertExpenseInput!\n) {\n  upsertExpense(input: $input) {\n    expense {\n      id\n      date\n      rate\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b013da9ddea8f573657d9cdecdb4c934';
export default node;
