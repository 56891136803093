import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";

import { PlacementSetEditForm_placementSet } from "~/__relay_artifacts__/PlacementSetEditForm_placementSet.graphql";
import {
  FormValues,
  PlacementSetForm,
  schema,
} from "~/components/PlacementSetForm";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdatePlacementSetMutation } from "~/mutations/UpdatePlacementSetMutation";

type Props = {
  placementSetRef: FragmentRef<PlacementSetEditForm_placementSet>;
  onSubmitCompleted: () => void;
};

const fragment = graphql`
  fragment PlacementSetEditForm_placementSet on PlacementSet {
    id
    title
    latestVersion
  }
`;

export const PlacementSetEditForm: React.FC<Props> = ({
  placementSetRef,
  onSubmitCompleted,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const { updatePlacementSetMutation } = useUpdatePlacementSetMutation();
  const placementSet = useFragment<PlacementSetEditForm_placementSet>(
    fragment,
    placementSetRef
  );
  const { id } = placementSet;

  const initialValues = React.useMemo<FormValues>(
    () => R.pick(["title"], placementSet),
    [placementSet]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updatePlacementSet } = await updatePlacementSetMutation({
          id,
          title: values.title,
          clientVersion: placementSet.latestVersion,
        });
        if (!updatePlacementSet?.placementSet) {
          throw new Error("assertion failed");
        }
        enqueueSnackbar("プレイスメントセットを更新しました", {
          variant: "success",
        });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      updatePlacementSetMutation,
      id,
      placementSet,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      render={PlacementSetForm}
    />
  );
};
