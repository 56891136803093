import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreatePlacementSetInput,
  CreatePlacementSetMutation,
} from "~/__relay_artifacts__/CreatePlacementSetMutation.graphql";

const mutation = graphql`
  mutation CreatePlacementSetMutation($input: CreatePlacementSetInput!) {
    createPlacementSet(input: $input) {
      placementSetEdge {
        node {
          id
          title
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreatePlacementSetInput) =>
  commitMutation<CreatePlacementSetMutation>(environment, {
    mutation,
    variables: {
      input,
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: "client:root",
        connectionInfo: [
          {
            key: "PlacementSetListTableCard_placementSets",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "placementSetEdge",
      },
    ],
  });

export default commit;
