import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteScheduleMutation } from "~/__relay_artifacts__/DeleteScheduleMutation.graphql";

const mutation = graphql`
  mutation DeleteScheduleMutation($input: DeleteScheduleInput!) {
    deleteSchedule(input: $input) {
      deletedScheduleId
    }
  }
`;

const commit = (
  environment: Environment,
  scheduleSetId: string,
  scheduleId: string
) =>
  commitMutation<DeleteScheduleMutation>(environment, {
    mutation,
    variables: {
      input: { scheduleId },
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: scheduleSetId,
        connectionKeys: [
          {
            key: "ScheduleList_schedules",
          },
        ],
        pathToConnection: ["scheduleSets", "schedules"],
        deletedIDFieldName: "deletedScheduleId",
      },
    ],
  });

export default commit;
