import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateProjectInput,
  UpdateProjectMutation,
} from "~/__relay_artifacts__/UpdateProjectMutation.graphql";

const mutation = graphql`
  mutation UpdateProjectMutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        id
        name
        freeePartnerId
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdateProjectInput) =>
  commitMutation<UpdateProjectMutation>(environment, {
    mutation,
    variables: {
      input,
    },
  });

export default commit;
