import { useFormErrorHandler } from "@vrize/vrizead-use";
import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as R from "ramda";
import * as React from "react";
import { graphql } from "react-relay";

import { AdEditForm_ad } from "~/__relay_artifacts__/AdEditForm_ad.graphql";
import { AdListTableCard_campaign } from "~/__relay_artifacts__/AdListTableCard_campaign.graphql";
import { AdForm, FormValues, schema } from "~/components/AdForm";
import { noop } from "~/lib/noop";
import { useFragment } from "~/lib/relay-hooks";
import { useUpdateAdMutation } from "~/mutations/UpdateAdMutation";

type AdRef = NonNullable<
  NonNullable<AdListTableCard_campaign["ads"]["edges"]>[0]
>["node"];

type Props = {
  adRef: AdRef;
  onSubmitCompleted?: () => void;
};

const adFragment = graphql`
  fragment AdEditForm_ad on Ad {
    id
    title
    adomain
    categories
    isRotatable
    ignoreIdfa
    width
    height
    sspProvider
    platform
    latestVersion
  }
`;

export const AdEditForm: React.FC<Props> = ({
  adRef,
  onSubmitCompleted = noop,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const formErrorHandler = useFormErrorHandler();
  const ad = useFragment<AdEditForm_ad>(adFragment, adRef);
  const { updateAdMutation } = useUpdateAdMutation();

  const initialValues = React.useMemo(
    () =>
      R.pick(
        [
          "title",
          "adomain",
          "isRotatable",
          "ignoreIdfa",
          "width",
          "height",
          "sspProvider",
          "platform",
        ],
        ad
      ) as FormValues,
    [ad]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      const { ...rest } = values;
      try {
        const { updateAd } = await updateAdMutation({
          id: ad.id,
          clientVersion: ad.latestVersion,
          ...rest,
        });
        if (!updateAd?.ad) throw new Error("assertion failed");
        enqueueSnackbar("キャンペーンを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        formErrorHandler(err, setErrors);
      }
    },
    [
      updateAdMutation,
      ad.id,
      ad.latestVersion,
      enqueueSnackbar,
      onSubmitCompleted,
      formErrorHandler,
    ]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <AdForm />
    </Formik>
  );
};
