import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteScheduleSettingMutation } from "~/__relay_artifacts__/DeleteScheduleSettingMutation.graphql";

const mutation = graphql`
  mutation DeleteScheduleSettingMutation($input: DeleteScheduleSettingInput!) {
    deleteScheduleSetting(input: $input) {
      deletedScheduleSettingId
    }
  }
`;

const commit = (environment: Environment, scheduleSettingId: string) =>
  commitMutation<DeleteScheduleSettingMutation>(environment, {
    mutation,
    variables: {
      input: { scheduleSettingId },
    },
  });

export default commit;
