import { useCallback } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";

import {
  UpdateAdInput,
  UpdateAdMutation,
} from "~/__relay_artifacts__/UpdateAdMutation.graphql";

const mutation = graphql`
  mutation UpdateAdMutation($input: UpdateAdInput!) {
    updateAd(input: $input) {
      ad {
        id
        title
        adomain
        status
        createdAt
        latestVersion
        placements {
          totalCount
        }
        ...AdEditForm_ad
      }
    }
  }
`;

export const useUpdateAdMutation = () => {
  const environment = useRelayEnvironment();

  const updateAdMutation = useCallback(
    (input: UpdateAdInput) =>
      commitMutation<UpdateAdMutation>(environment, {
        mutation,
        variables: { input },
      }),
    [environment]
  );

  return { updateAdMutation };
};
