import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";

import { BlockList_Query } from "~/__relay_artifacts__/BlockList_Query.graphql";
import { BlockList as BlockListTableCard } from "~/components/BlockList";
import { PageHeader } from "~/components/PageHeader";

const query = graphql`
  query BlockList_Query {
    ...BlockList_root
  }
`;

const Wrapper: React.FC = ({ children }) => (
  <>
    <PageHeader title="ブロックリスト" backTo="/" />
    {children}
  </>
);

export const BlockList: React.FC = () => {
  const { props, error } = useQuery<BlockList_Query>(query, {
    count: 100,
  });

  if (error) return <Wrapper>{error.message}</Wrapper>;
  if (!props) return <Wrapper>Loading...</Wrapper>;
  return (
    <Wrapper>
      <BlockListTableCard blockRef={props} />
    </Wrapper>
  );
};
