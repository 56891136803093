import { Grid } from "@material-ui/core";
import { FormikSwitchField } from "@vrize/vrizead-use";
import { Formik } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "~/components/DialogForm";

export type FormValues = {
  targetValue: boolean;
};

const schema = yup.object({
  targetValue: yup.boolean().required(),
});

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  fieldName: string;
};

export const PlacementBulkUpdateSwitchForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  fieldName,
}) => {
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      <DialogForm>
        <Grid container spacing={3}>
          <FormikSwitchField required name="targetValue" label={fieldName} />
        </Grid>
      </DialogForm>
    </Formik>
  );
};
