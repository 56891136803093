import { Button, Grid, Typography } from "@material-ui/core";
import { Form, Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useParams } from "react-router";

import { useRelayEnvironment } from "~/lib/relay-hooks";
import bulkCreatePlacementSettingMutation from "~/mutations/BulkCreatePlacementSettingMutation";

import { FilterFormValues } from "./types";

type FormValues = {};

type Props = {
  name: string | null;
  filterFormValues: FilterFormValues;
  onSubmitCompleted: () => void;
};

const initialValues = {};

export const PlacementSettingAdSlotBulkInsertDialog: React.FC<Props> = ({
  name,
  filterFormValues,
  onSubmitCompleted,
}) => {
  const environment = useRelayEnvironment();
  const { enqueueSnackbar } = useSnackbar();
  const { placementSetId } = useParams<{ placementSetId: string }>();

  const onSubmit = React.useCallback<
    FormikConfig<FormValues>["onSubmit"]
  >(async () => {
    try {
      await bulkCreatePlacementSettingMutation(environment, {
        ...filterFormValues,
        placementSetId,
        name,
      });
      enqueueSnackbar("プレイスメントセットにAdSlotを追加しました", {
        variant: "success",
      });
      onSubmitCompleted();
    } catch (err) {
      enqueueSnackbar("プレイスメントセットへのAdSlotの追加に失敗しました", {
        variant: err,
      });
    }
  }, [
    environment,
    filterFormValues,
    placementSetId,
    name,
    enqueueSnackbar,
    onSubmitCompleted,
  ]);

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              検索されたAdSlotすべてを登録してよろしいですか？
            </Typography>
          </Grid>
          <Button fullWidth type="submit" color="primary" variant="contained">
            適用する
          </Button>
        </Grid>
      </Form>
    </Formik>
  );
};
