import { Theme, Tooltip, Typography } from "@material-ui/core";
import { styled, withStyles } from "@material-ui/styles";
import * as React from "react";
import { Link } from "react-router-dom";

import { CampaignListTableCard_project } from "~/__relay_artifacts__/CampaignListTableCard_project.graphql";

type CampaignNode = NonNullable<
  NonNullable<
    NonNullable<CampaignListTableCard_project["campaigns"]["edges"]>[0]
  >["node"]
>;

type Props = {
  campaign: CampaignNode;
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledUl = styled("ul")({
  paddingLeft: 24,
});

export const CampaignTitle: React.FC<Props> = ({ campaign }) => {
  const stoppedAds = React.useMemo(() => {
    const adEdges = campaign.stoppedAds.edges || [];
    return adEdges.map((edge) => {
      if (!edge?.node) throw new Error("assertion error");
      return edge.node;
    });
  }, [campaign.stoppedAds.edges]);

  const stoppedCreatives = React.useMemo(() => {
    const adEdges = campaign.stoppedCreatives.edges || [];
    return adEdges.map((edge) => {
      if (!edge?.node) throw new Error("assertion error");
      return edge.node;
    });
  }, [campaign.stoppedCreatives.edges]);

  const stoppedPlacements = React.useMemo(() => {
    const adEdges = campaign.stoppedPlacements.edges || [];
    return adEdges.map((edge) => {
      if (!edge?.node) throw new Error("assertion error");
      return edge.node;
    });
  }, [campaign.stoppedPlacements.edges]);

  const hasStoppedItems = React.useMemo(
    () =>
      stoppedAds.length !== 0 ||
      stoppedCreatives.length !== 0 ||
      stoppedPlacements.length !== 0,
    [stoppedAds.length, stoppedCreatives.length, stoppedPlacements.length]
  );

  return (
    <HtmlTooltip
      disableHoverListener={!hasStoppedItems}
      title={
        <React.Fragment>
          {stoppedAds.length > 0 && (
            <>
              <Typography color="inherit">配信停止中の広告</Typography>
              <StyledUl>
                {stoppedAds.map((ad) => (
                  <li key={ad.id}>
                    {ad.title}({ad.id})
                  </li>
                ))}
              </StyledUl>
            </>
          )}
          {stoppedCreatives.length > 0 && (
            <>
              <Typography color="inherit">
                配信停止中のクリエイティブ
              </Typography>
              <StyledUl>
                {stoppedCreatives.map((creative) => (
                  <li key={creative.id}>
                    {creative.title}({atob(creative.id)})
                  </li>
                ))}
              </StyledUl>
            </>
          )}
          {stoppedPlacements.length > 0 && (
            <>
              <Typography color="inherit">
                配信停止中のプレイスメント
              </Typography>
              <StyledUl>
                {stoppedPlacements.map((placement) => (
                  <li key={placement.id}>{placement.adSlot.tagId}</li>
                ))}
              </StyledUl>
            </>
          )}
        </React.Fragment>
      }
    >
      <Link to={`campaigns/${campaign.id}`}>{campaign.title}</Link>
    </HtmlTooltip>
  );
};
